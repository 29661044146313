<template>
  <div class="form-config-container">
    <el-form
      ref="form"
      label-position="top"
      :model="$data"
    >
      <template v-if="!isCompoundWidgetMode">
        <el-form-item label="标签对齐方式">
          <el-radio-group v-model="data.labelPosition">
            <el-radio-button label="left">
              左对齐
            </el-radio-button>
            <el-radio-button label="right">
              右对齐
            </el-radio-button>
            <el-radio-button label="top">
              顶部对齐
            </el-radio-button>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="字段标签宽度">
          <el-input-number
            v-model="data.labelWidth"
            :min="0"
            :max="200"
            :step="10"
          />
        </el-form-item>
      </template>

      <template v-if="isCompoundWidgetMode">
        <el-form-item
          :rules="[{ required: true, message: '必填'}]"
          prop="form_name"
          label="套件名称"
          style="margin-bottom: 0"
        >
          <el-input
            v-model="form_name"
            :placeholder="placeholdername"
          />
        </el-form-item>
        <el-form-item
          v-if="data.immutable"
          :rules="[{ required: true, message: '必填'}]"
          prop="componentKey"
          label="套件key"
        >
          <el-input
            v-model="componentKey"
            :disabled="$route.query.formId !== undefined && !!componentOldKey"
            placeholder="套件key"
          />
        </el-form-item>
        <el-form-item label="套件说明">
          <el-input
            v-model="data.information"
            type="textarea"
            :rows="2"
            placeholder="请输入"
          />
        </el-form-item>
      </template>
    </el-form>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {
        return {
          validation: [],
          information: ''
        }
      }
    },
    componentOldKey: {
      type: String,
      default: ''
    },
    placeholdername: {
      type: String,
      default: '表单名称'
    },
    formName: {
      type: String,
      default: ''
    },
    componentKeyOut: {
      type: String,
      default: ''
    },
    isCompoundWidgetMode: {
      type: Boolean,
      default: false
    },
    validations: {
      type: Array,
      default: () => [
        {
          name: 'validate1',
          key: 'v1'
        },
        {
          name: 'validate2',
          key: 'v2'
        },
        {
          name: 'validate3',
          key: 'v3'
        },
        {
          name: 'validate4',
          key: 'v4'
        }
      ]
    }
  },
  data () {
    return {
      form_name: this.formName,
      componentKey: this.componentKeyOut
    }
  },
  watch: {
    formName () {
      this.form_name = this.formName
    },
    form_name () {
      this.$emit('updateFormName', this.form_name)
    },
    componentKeyOut () {
      this.componentKey = this.componentKeyOut
    },
    componentKey () {
      this.$emit('updateComponentKey', this.componentKey)
    }
  },
  methods: {
    validate () {
      this.$refs.form.validate(v => {
        console.log(v)
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.form-config-container
  .el-form-item
    padding-bottom 1rem!important
</style>
