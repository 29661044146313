<template>
  <div>
    <el-button
      type="primary"
      icon="el-icon-edit"
      circle
      @click="visible=true"
    />
    <div>
      <el-tag
        v-for="(v,k) in funs_text"
        :key="k"
        type="info"
        style="margin-top:10px;"
        class="my-tag"
      >
        {{ v.name }}
        <span>{{ v.params.length>0?"（":"" }}</span>
        <div
          v-for="(item,i) in v.params"
          :key="i"
        >
          {{ item }}{{ i===v.params.length-1?"":"，" }}
        </div>
        <span>{{ v.params.length>0?"）":"" }}</span>
      </el-tag>
    </div>

    <el-dialog
      v-if="visible"
      :visible.sync="visible"
      :show-close="false"
      :title="`${dataSourceMode ? '数据源' : '调用'}选择`"
      width="800px"
      append-to-body
    >
      <el-row>
        <el-col
          :span="8"
          class="my-box my-box-right"
        >
          <h4 style="margin:0 0 15px 7px;color: #606266;font-size: 14px;">
            {{ dataSourceMode ? '数据源' : '函数' }}
          </h4>
          <el-checkbox-group
            v-if="radioOrCheckout==='checkout'"
            v-model="fun"
            @change="funChange"
          >
            <template v-for="(v,k) in functions">
              <div
                v-if="type.length > 0 ? type.includes(v.type) : true"
                :key="k"
                class="my-cascader-node"
                :class="k===checked_fun?'in-active-path':''"
                @click="()=>funCheckClick(k)"
              >
                <el-checkbox
                  :label="k"
                  class="my-checkbox"
                >
                  {{ v.name || v.label }}
                </el-checkbox>
              </div>
            </template>
          </el-checkbox-group>
          <el-radio-group
            v-if="radioOrCheckout==='radio'"
            v-model="fun[0]"
            @change="funChange"
          >
            <template v-for="(v,k) in functions">
              <div
                v-if="type.length > 0 ? type.includes(v.type) : true"
                :key="k"
                class="my-cascader-node"
                :class="k===checked_fun?'in-active-path':''"
                @click="()=>funCheckClick(k)"
              >
                <el-radio :label="k">
                  {{ v.name || v.label }}
                </el-radio>
              </div>
            </template>
          </el-radio-group>
        </el-col>
        <el-col
          :span="8"
          class="my-box my-box-right"
        >
          <h4 style="margin:0 0 15px 7px;color: #606266;font-size: 14px;">
            参数
          </h4>
          <div
            v-for="(v,k) in params"
            :key="k"
            class="my-cascader-node"
            :class="k===checked_param?'in-active-path':''"
            :style="{color:v.value&&v.value.length>0?'#54c3f1':'#606266'}"
            @click="()=>paramCheckClick(k)"
          >
            {{ v.intro }}
          </div>
        </el-col>
        <el-col
          :span="8"
          class="my-box"
        >
          <h4 style="margin:0 0 15px 7px;color: #606266;font-size: 14px;">
            {{ '参数输入值(表单字段' + (!dataSourceMode ? '、流程信息、固定值' : '') + ')' }}
          </h4>
          <el-checkbox-group
            v-if="radioOrCheckout==='checkout'"
            v-model="param_value"
            :disabled="!output.some(v=>v.name===checked_fun) || (params || []).length===0"
            @change="paramValueChange"
          >
            <div
              v-for="(v,k) in fields"
              :key="k"
              class="my-cascader-node"
            >
              <el-checkbox :label="v.model">
                {{ v.name }}
              </el-checkbox>
            </div>
            <div
              v-for="(v,k) in supfields"
              :key="k"
              class="my-cascader-node"
            >
              <el-checkbox :label="k">
                {{ v }}
              </el-checkbox>
            </div>
            <div
              class="my-cascader-node"
              style="line-height:26px"
            >
              <el-checkbox
                label="自定义"
                @change="inputChange"
              />
            </div>
            <el-input
              v-model="constom_input"
              :disabled="!checked || !output.some(v=>v.name===checked_fun) || (params || []).length===0"
              placeholder="自定义字段value"
              clearable
              class="my-cascader-node"
              @change="changeInput"
            />
          </el-checkbox-group>
          <el-radio-group
            v-else-if="radioOrCheckout==='radio'"
            v-model="param_value[0]"
            @change="paramValueChange"
          >
            <div
              v-for="(v,k) in fields"
              :key="k"
              class="my-cascader-node"
            >
              <el-radio :label="v.model">
                {{ v.name }}
              </el-radio>
            </div>
            <div
              v-for="(v,k) in supfields"
              :key="k"
              class="my-cascader-node"
            >
              <el-radio :label="k">
                {{ v }}
              </el-radio>
            </div>
          </el-radio-group>
        </el-col>
      </el-row>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="visible=false">取 消</el-button>
        <el-button
          type="primary"
          @click="ok"
        >确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { clone } from 'ramda'

export default {
  name: 'FunctionSelectAndConfig', // 组件：数据源、契约及其参数配置
  props: {
    dataSourceMode: {
      type: Boolean,
      default: false
    },
    // 可选的数据源类型
    type: {
      type: Array,
      default: () => []
    },
    // 可选函数、契约等的k-v对象
    functions: {
      type: Object,
      default: () => {
        return {}
      }
    },
    // 表单字段一维数组
    fields: {
      type: Array,
      default: () => []
    },
    supfields: {
      type: Object,
      default: () => {}
    },
    // 已有的旧的值
    config: {
      type: Array,
      default: () => {
        return []
      }
    },
    // 多选 or 单选
    radioOrCheckout: {
      type: String,
      default: () => 'checkout'
    }
  },
  data() {
    return {
      visible: false,
      fun: this.config.map(v => v.name),
      param_value: [],
      checked_fun: null,
      checked_param: null,
      output: JSON.parse(JSON.stringify(this.config)),
      funs_text: this.set_funs_text(this.config),
      constom_input: '',
      checked: false,
      old_checked_param: null,
      old_checked_fun: null
    }
  },
  computed: {
    params() {
      // if(!this.checked_fun) return [];
      const fun = this.output.find(v => v.name === this.checked_fun)
      // const nParams = this.functions[this.checked_fun].params;
      // if (!fun) return nParams;
      // return nParams.reduce((acc,curr)=>{
      //   const old=fun.params.find(v=>v.key===curr.key&&v.intro===curr.intro);
      //   if(old) return acc.concat([old]);
      //   return acc.concat([curr]);
      // },[]);
      if (this.checked_fun) {
        if (!fun) {
          return this.functions[this.checked_fun].params
        }
        const newParams = clone(this.functions[this.checked_fun].params)
        for (let pIndex = 0; pIndex < newParams.length; pIndex++) {
          if (fun.params && fun.params.length > 0) {
            let inside = false
            for (let i = 0; i < fun.params.length; i++) {
              if (this.dataSourceMode) {
                if (fun.params[i].intro === newParams[pIndex].intro) {
                  newParams[pIndex] = fun.params[i]
                  inside = true
                  break
                }
              } else {
                if (fun.params[i].key === newParams[pIndex].key) {
                  newParams[pIndex] = fun.params[i]
                  inside = true
                  break
                }
              }
            }
            if (!inside) {
              fun.params.splice(pIndex, 0, newParams[pIndex])
            }
          }
        }
        return newParams
      } else {
        return []
      }
    },
    fieldsKeys () {
      return this.fields.map(item => item.model)
    }
  },
  watch: {
    config: {
      handler(val) {
        this.output = JSON.parse(JSON.stringify(val))
        this.fun = this.output.map(v => v.name)
        this.checked_fun = null
        this.funs_text = this.set_funs_text()
      },
      deep: true
    },
    checked_param(newValue) {
        this.old_checked_param = newValue
    },
    checked_fun(newValue) {
      this.old_checked_fun = newValue
    }
  },
  methods: {
    funCheckClick(val) {
      this.checked_fun = val
      if (this.param_value.find(item => item === '自定义') && this.constom_input.length >= 0) {
        var index = this.param_value.findIndex(item => item === '自定义')
        this.param_value.splice(index, 1, [this.constom_input])
        const fun = this.output.find(v => v.name === this.old_checked_fun)
        fun.params[this.old_checked_param].value = JSON.parse(JSON.stringify(this.param_value))
      }
      this.checked_param = 0
      this.old_checked_param = 0
      this.constom_input = ''
      this.checked = false
      this.reset_param_value()
    },
    paramCheckClick(val) {
      console.log(this.constom_input.length)
      this.checked_param = val
      if (this.param_value.find(item => item === '自定义') && this.constom_input.length >= 0) {
        var index = this.param_value.findIndex(item => item === '自定义')
        this.param_value.splice(index, 1, [this.constom_input])
        const fun = this.output.find(v => v.name === this.checked_fun)
        fun.params[this.old_checked_param].value = JSON.parse(JSON.stringify(this.param_value))
      }
      this.constom_input = ''
      this.checked = false
      this.reset_param_value()
    },
    funChange() {
      this.output = this.fun.map(v => {
        const fun = this.output.find(item => item.name === v)
        if (fun) return fun
        const params = (this.functions[v].params || []).map(v => {
          return Object.assign({ value: null }, v)
        })
        return { name: v, params }
      })
    },
    paramValueChange() {
      this.param_value = this.param_value.filter(
        item => this.fieldsKeys.includes(item)
        || (this.supfields ? Object.keys(this.supfields).includes(item) : true)
        || item === '自定义'
      )
      const fun = this.output.find(v => v.name === this.checked_fun)
      fun.params[this.checked_param].value = JSON.parse(JSON.stringify(this.param_value))
    },
    reset_param_value() {
      const fun = this.output.find(v => v.name === this.checked_fun)
      // console.log(fun)
      if (fun) {
        const param = fun.params[this.checked_param].value
        // console.log(param)
        this.param_value = param ? (Array.isArray(param) ? param : []) : []
        // console.log(this.param_value)
        if(this.param_value && this.param_value.findIndex(item => Array.isArray(item)) >= 0) {
          // console.log(this.param_value)
          var index = this.param_value.findIndex(item => Array.isArray(item))
          this.constom_input = this.param_value[index][0]
          this.param_value.splice(index, 1, '自定义')
          // console.log(this.param_value)
          this.checked = true
        }

      } else {
        this.param_value = []
      }
    },
    ok() {
      let param = null
      const verify_fun = this.output.find(v => {
        if (!v.params || v.params.length === 0) return false
        param = v.params.find(item => {
          if (!item.value || (Array.isArray(item.value) && item.value.length < 1)) {
            return true
          }
        })
        return param
      })
      if (this.param_value.find(item => item === '自定义') && this.constom_input.length >= 0) {
        var index = this.param_value.findIndex(item => item === '自定义')
        this.param_value.splice(index, 1, [this.constom_input])
        const fun = this.output.find(v => v.name === this.checked_fun)
        fun.params[this.checked_param].value = JSON.parse(JSON.stringify(this.param_value))
      }
      if (verify_fun) {
        const string_fun = this.functions[verify_fun.name]
        this.$message.error({ message: `“${string_fun.name}”函数的参数“${param.intro}”未取值！`, duration: 10000, showClose: true })
      } else {
        this.visible = false
        this.funs_text = this.set_funs_text()
        this.$emit('change', this.output.filter(v => this.functions[v.name]))
      }
    },
    set_funs_text(config) {
      return (config || this.output).map(v => {
        const fun = this.functions[v.name]
        if (!fun) return { name: '', params: [] }
        let text = []
        try {
          text = (v.params || []).map(item => {
            var index = item.value.findIndex(item => Array.isArray(item))
            const paramValues = (item.value || []).map(v => {
              const p = this.fields.find(val => val.model === v)
              return p ? p.name : this.supfields[v] || item.value[index]
            })
            return item.intro + ' -> ' + paramValues
          })
        } catch (err) {
          console.error(err, '备注：开发阶段的老数据不做兼容！')
        }
        return {
          name: fun.name,
          params: text
        }
      })
    },
    inputChange(e) {
      this.checked = e
    },
    changeInput() {
      // var index = this.param_value.findIndex((item) => item==='自定义')
      // if (this.constom.constom_input.length > 0) {
      //   this.constom_obj[this.checked_fun + this.checked_param] = {
      //     checked: false,
      //     key: this.checked_param,
      //     value: [this.constom.constom_input]
      //   }
      //   this.param_value.splice(index, 1, [this.constom.constom_input])
      // }
    }
  }
}
</script>
<style>
.my-tag {
  height: auto !important;
  white-space: inherit !important ;
}
.my-cascader-node {
  position: relative;
  display: flex;
  padding: 7px 30px 7px 20px;
  line-height: 1.2rem;
  outline: none;
}
.my-cascader-node.in-active-path {
  background: #e1f3d8;
}
.my-box {
  height: 500px;
  overflow-y: auto;
}
.my-box-right {
  border-right: 1px solid #dcdfe6;
}
.my-checkbox{
  pointer-events:none;
}
.my-checkbox .el-checkbox__input{
  pointer-events: auto !important;
}
</style>
