<template>
  <el-form-item
    v-if="element && element.key"
    class="widget-view "
    :class="{active: selectWidget.key == element.key, 'is_req': element.options && element.options.required}"
    :label="element.name"
    @click.native.stop="handleSelectWidget(index)"
  >
    <template v-if="element.type == 'input'">
      <el-input
        v-model="element.options.defaultValue"
        :style="{width: element.options.width}"
        :placeholder="element.options.placeholder"
      >
        <template
          v-if="element.options.prefix !== '' && element.options.prefix !== undefined && element.options.prefix !== null"
          slot="prepend"
        >
          {{ element.options.prefix }}
        </template>
        <template
          v-if="element.options.suffix !== '' && element.options.suffix !== undefined && element.options.suffix !== null"
          slot="append"
        >
          {{ element.options.suffix }}
        </template>
      </el-input>
    </template>

    <template v-if="element.type == 'textarea'">
      <el-input
        v-model="element.options.defaultValue"
        type="textarea"
        :rows="5"
        :style="{width: element.options.width}"
        :placeholder="element.options.placeholder"
      />
    </template>

    <template v-if="element.type == 'instruction'">
      <div>
        <p
          v-for="(str, strIndex) in (element.options.defaultValue || '').split('\n')"
          :key="strIndex"
          class="instruction-line"
        >
          {{ str }}
        </p>
      </div>
    </template>

    <template v-if="element.type == 'number'">
      <el-input-number
        v-model="element.options.defaultValue"
        :disabled="element.options.disabled.value"
        :controls-position="element.options.controlsPosition"
        :style="{width: element.options.width}"
      />
    </template>

    <template v-if="element.type == 'tree'">
      <el-tree
        show-checkbox
        :data="element.options.options"
        :node-key="element.options.nodeKey"
        :props="element.options.defaultProps"
        :default-expand-all="element.options.defaultExpandAll"
        :style="{width: element.options.width}"
      />
    </template>

    <template v-if="element.type == 'cascader'">
      <el-cascader
        :options="element.options.options"
        :placeholder="element.options.placeholder"
        :style="{width: element.options.width}"
      />
    </template>

    <template v-if="element.type == 'radio'">
      <el-radio-group
        v-model="element.options.defaultValue"
        :style="{width: element.options.width}"
      >
        <el-radio
          v-for="(item, indexRadio) in element.options.remote ? remoteOptions : element.options.options"
          :key="indexRadio"
          :style="{display: element.options.inline ? 'inline-block' : 'block'}"
          :label="element.options.remote ? item[element.options.submitKey] : item.value"
        >
          <template v-if="element.options.remote">
            {{ item[element.options.showKey] }}
          </template>
          <template v-else>
            {{ element.options.showLabel ? item.label : item.value }}
          </template>
        </el-radio>
      </el-radio-group>
    </template>

    <template v-if="element.type == 'checkbox'">
      <el-checkbox-group
        v-model="element.options.defaultValue"
        :style="{width: element.options.width}"
      >
        <el-checkbox
          v-for="(item, indexCheckbox) in element.options.remote ? remoteOptions : element.options.options"
          :key="indexCheckbox"
          :style="{display: element.options.inline ? 'inline-block' : 'block'}"
          :label="element.options.remote ? item[element.options.submitKey] : item.value"
        >
          <template v-if="element.options.remote">
            {{ item[element.options.showKey] }}
          </template>
          <template v-else>
            {{ element.options.showLabel ? item.label : item.value }}
          </template>
        </el-checkbox>
      </el-checkbox-group>
    </template>

    <template v-if="element.type == 'time'">
      <el-time-picker
        v-model="element.options.defaultValue"
        :is-range="element.options.isRange"
        :placeholder="element.options.placeholder"
        :start-placeholder="element.options.startPlaceholder"
        :end-placeholder="element.options.endPlaceholder"
        :readonly="element.options.readonly.value"
        :disabled="element.options.disabled.value"
        :editable="element.options.editable"
        :clearable="element.options.clearable"
        :arrow-control="element.options.arrowControl"
        :style="{width: element.options.width}"
      />
    </template>

    <template v-if="element.type == 'date'">
      <el-date-picker
        v-model="element.options.defaultValue"
        :type="element.options.type"
        :is-range="element.options.isRange"
        :placeholder="element.options.placeholder"
        :start-placeholder="element.options.startPlaceholder"
        :end-placeholder="element.options.endPlaceholder"
        :readonly="element.options.readonly.value"
        :disabled="element.options.disabled.value"
        :editable="element.options.editable"
        :clearable="element.options.clearable"
        :style="{width: element.options.width}"
      />
    </template>

    <template v-if="element.type == 'rate'">
      <el-rate
        v-model="element.options.defaultValue"
        :max="element.options.max"
        :disabled="element.options.disabled.value"
        :allow-half="element.options.allowHalf"
      />
    </template>

    <template v-if="element.type == 'color'">
      <el-color-picker
        v-model="element.options.defaultValue"
        :disabled="element.options.disabled.value"
        :show-alpha="element.options.showAlpha"
      />
    </template>

    <template v-if="element.type == 'select'">
      <el-select
        v-model="element.options.defaultValue"
        :disabled="element.options.disabled.value"
        :multiple="element.options.multiple"
        :clearable="element.options.clearable"
        :placeholder="element.options.placeholder"
        :style="{width: element.options.width}"
      >
        <el-option
          v-for="item in element.options.options"
          :key="item.value"
          :value="item.value"
          :label="element.options.showLabel?item.label:item.value"
        />
      </el-select>
    </template>

    <template v-if="element.type=='switch'">
      <el-switch
        v-model="element.options.defaultValue"
        :disabled="element.options.disabled.value"
      />
    </template>

    <template v-if="element.type=='slider'">
      <el-slider
        v-model="element.options.defaultValue"
        :min="element.options.min"
        :max="element.options.max"
        :disabled="element.options.disabled.value"
        :step="element.options.step"
        :show-input="element.options.showInput"
        :range="element.options.range"
        :style="{width: element.options.width}"
      />
    </template>

    <template v-if="element.type=='imgupload'">
      <file-upload
        :disabled="element.options.disabled.value"
        :limit="element.options.limit"
        :multiple="element.options.multiple"
        type="img"
      />
      <!-- <fm-upload
        v-model="element.options.defaultValue"
        :disabled="element.options.disabled.value"
        :style="{'width': element.options.width}"
        :width="element.options.size.width"
        :height="element.options.size.height"
        token="xxx"
        domain="xxx"
      /> -->
    </template>

    <template v-if="element.type=='fileupload'">
      <file-upload
        :disabled="element.options.disabled.value"
        :limit="element.options.limit"
        :multiple="element.options.multiple"
      />
      <!-- <el-upload
        :action="element.options.domain"
        :disabled="element.options.disabled.value"
        :multiple="element.options.multiple"
        :limit="element.options.limit"
        :file-list="element.options.fileList"
        :list-type="element.options.listType"
      >
        <el-button
          type="primary"
        >
          点击上传
        </el-button>
      </el-upload> -->
    </template>

    <template v-if="element.type=='download'">
      <el-button
        v-model="element.options.defaultValue"
        :disabled="element.options.disabled.value"
        type="text"
      >
        {{ element.name }}
      </el-button>
    </template>

    <template v-if="['link', 'customdata', 'customoperate'].includes(element.type)">
      <el-button
        v-model="element.options.defaultValue"
        :disabled="element.options.disabled.value"
        type="text"
      >
        {{ element.options.buttonText || element.name }}
      </el-button>
    </template>

    <!-- <template v-if="element.type=='customoperate'">
      <el-button
        v-model="element.options.defaultValue"
        :disabled="element.options.disabled.value"
        type="text"
      >
        {{ element.options.buttonText || element.name }}
      </el-button>
    </template> -->

    <!-- <template v-if="element.type=='table'">
      <table
        style="width: 100%;border-collapse: collapse;border: 1px solid #dcdfe6;overflow: auto;"
      >
        <tbody style="border: 1px solid #dcdfe6;">
          <tr
            v-for="(row, rowIndex) in tableData"
            :key="rowIndex"
            style="border: 1px solid #dcdfe6;"
          >
            <td
              v-for="(item, colIndex) in row"
              :key="colIndex"
              :rowspan="item.row"
              :colspan="item.col"
              style="border: 1px solid #dcdfe6;padding: 0.5rem 0.5rem;word-break: break-all;line-height: 1.3rem;"
            >
              {{ item.value }}
            </td>
          </tr>
        </tbody>
      </table>
    </template> -->
    <statement
      v-if="element.type=='table'"
      :editable="false"
    />

    <template v-if="element.type=='blank'">
      <div style="height: 50px;color: #999;background: #eee;line-height:50px;text-align:center;">
        自定义区域
      </div>
    </template>

    <el-button
      v-if="isCompoundEnd || (!element.immutable && selectWidget.key == element.key)"
      title="删除"
      class="widget-action-delete"
      circle
      plain
      icon="el-icon-delete"
      type="danger"
      :style="{'right': (isCompoundEnd ? '10px' : '20px')}"
      @click.stop="handleWidgetDelete(index)"
    >
      <!-- <icon
        name="regular/trash-alt"
        style="width: 12px;height: 12px;"
      /> -->
    </el-button>

    <!-- <el-popover
      v-if="isCompoundEnd"
      placement="bottom"
      title="套件说明"
      width="200"
      trigger="hover"
    >
      <div>
        {{ compound ? compound.data.config.information || '' : '套件列表未找到与之匹配的套件，请检查' }}
      </div> -->
    <el-button
      v-if="isCompoundEnd"
      title="套件说明"
      class="widget-action-clone"
      circle
      plain
      icon="el-icon-info"
      type="primary"
      style="right: 60px;"
      @click="showCompoundTips(compound)"
    />
    <!-- </el-popover> -->

    <el-button
      v-if="!element.immutable && selectWidget.key == element.key"
      title="复制"
      class="widget-action-clone"
      circle
      plain
      icon="el-icon-copy-document"
      type="primary"
      @click.stop="handleWidgetClone(index)"
    >
      <!-- <icon
        name="regular/clone"
        style="width: 12px;height: 12px;"
      /> -->
    </el-button>
  </el-form-item>
</template>

<script>
// import axios from 'axios'
import Statement from './formComponents/Statement'
import FileUpload from './formComponents/Upload'
import { clone } from 'ramda'

export default {
  name: 'WidgetFormItem', // 表单设计时：单个表单组件展示
  components: {
    Statement,
    FileUpload
  },
  props: {
    element: {
      type: Object,
      default: () => {}
    },
    select: {
      type: Object,
      default: () => {}
    },
    index: {
      type: Number,
      default: 0
    },
    data: {
      type: Object,
      default: () => {}
    },
    canDeleteField: { // 判断组件是否能够删除
      type: Function,
      default: null
    },
    validateCompound: { // 删除组件和，处理套件依赖的清除
      type: Function,
      default: null
    },
    startEditId: { // 开始编辑套件的id：打开弹窗
      type: Function,
      default: null
    },
    getCompoundByKeyOrId: { // 根据组件获取所在套件信息
      type: Function,
      default: null
    },
    showCompoundTips: { // 展示套件说明
      type: Function,
      default: null
    },
    isCompoundEnd: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      selectWidget: this.select || {},
      remoteOptions: []
    }
  },
  computed: {
      compound () {
        return this.getCompoundByKeyOrId(this.data.list[this.index])
      }
  },
  watch: {
    select (val) {
      this.selectWidget = val
    },
    selectWidget: {
      handler (val) {
        this.$emit('update:select', val)
      },
      deep: true
    },
    'selectWidget.options.remoteDataKey': function () {
      this.updateRemoteOptions()
    }
  },
  created () {
    // 可选项是远程数据源时，获取可选项对应的远程数据源
    this.updateRemoteOptions()
  },
  methods: {
    async updateRemoteOptions () {
      // when remote options datas,get datas
      if (this.element.options && this.element.options.remote) {
        const res = await this.$http.post(
          '/workflow/data-source',
          {
            key: this.element.options.remoteDataKey
          }
        )
        if (res.data.code === 0) {
          // this.$set(this.element.options, 'remoteOptions', res.data.data)
          this.remoteOptions = res.data.data
        }
      }
    },
    handleSelectWidget (index) {
      this.selectWidget = this.data.list[index]
    },
    handleWidgetDelete (index) {
      // 验证是否能删除
      if (this.canDeleteField) {
        if (this.isCompoundEnd) {
          // 组件是不可编辑的套件的最后一个时，代表删除整个套件：需要验证套件的每个子孙组件是否能被删除
          const compoundId = this.data.list[index].compoundId
          for (let i = 0; i < this.data.list.length; i++) {
            if (this.data.list[i].compoundId === compoundId) {
              let deleteValidate = this.canDeleteField(this.data.list[i])
              if (deleteValidate) {
                this.$message.error({ message: deleteValidate, duration: 10000, showClose: true })
                return
              }
            }
          }
        } else {
          let deleteValidate = this.canDeleteField(this.data.list[index])
          if (deleteValidate) {
            this.$message.error({ message: deleteValidate, duration: 10000, showClose: true })
            return
          }
        }
      }
      // 处理active的组件
      const compoundArr = this.data.list.filter(item => item.compoundId === this.data.list[index].compoundId)
      const newIndex = index - compoundArr.length
      if (this.data.list.length - 1 === index) {
        if (this.isCompoundEnd) {
          if (newIndex < 0) {
            this.selectWidget = {}
          } else {
            this.selectWidget = this.data.list[newIndex]
          }
        } else {
          if (index === 0) {
            this.selectWidget = {}
          } else {
            this.selectWidget = this.data.list[index - 1]
          }
        }
      } else {
        this.selectWidget = this.data.list[index + 1]
      }
      // 删除组件
      this.$nextTick(() => {
        if (this.isCompoundEnd) {
          this.data.compound.forEach((item, i) => {
            if (item.compoundId === this.data.list[index].compoundId) {
              this.data.compound.splice(i, 1)
            }
          })
          this.data.list.splice(newIndex + 1, compoundArr.length)
        } else {
          const compoundId = this.data.list[index].compoundId
          this.data.list.splice(index, 1)
          this.validateCompound(compoundId)
        }
      })
    },
    handleWidgetClone (index) {
      // let cloneData = {
      //   ...this.data.list[index],
      //   options: { ...this.data.list[index].options },
      //   key: Date.parse(new Date()) + '_' + Math.ceil(Math.random() * 99999)
      // }
      let cloneData = clone(this.data.list[index])
      cloneData.key = Date.parse(new Date()) + '_' + Math.ceil(Math.random() * 99999)
      cloneData.model = cloneData.type + '_' + cloneData.key

      // if (this.data.list[index].type === 'radio' || this.data.list[index].type === 'checkbox') {
      //   cloneData = {
      //     ...cloneData,
      //     options: {
      //       ...cloneData.options,
      //       options: cloneData.options.options.map(item => ({ ...item }))
      //     }
      //   }
      // }

      this.data.list.splice(index, 0, cloneData)

      this.$nextTick(() => {
        this.selectWidget = this.data.list[index + 1]
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.instruction-line
  height 20px
  line-height 20px
  margin 10px 0 0.5rem 0
  word-break break-word
.el-select
.el-date-editor
.el-input-number
.el-cascader
  width 100%
</style>
