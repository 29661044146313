<template>
  <div>
    <el-form
      ref="generateForm"
      v-loading="loadingCompute"
      :model="models"
      :rules="rules"
      :label-position="isMobile ? 'top' : formData.config.labelPosition"
      :label-width="formData.config.labelWidth + 'px'"
      class="generate-form"
    >
      <template v-for="(item, itemIndex) in formData.list">
        <!-- grid -->
        <template v-if="operations.displays[item.model] && item.type == 'grid'">
          <div
            v-for="lineIndex in gridIndexs[item.model].lines"
            :key="item.key + '_grid_' + lineIndex"
          >
            <el-row
              v-show="operations.shows[item.model]"
              :key="item.key + '_' + itemIndex + lineIndex"
              :class="['grid-box']"
              :gutter="item.options.gutter ? item.options.gutter : 0"
              :justify="item.options.justify"
              :align="item.options.align"
              type="flex"
            >
              <el-col
                v-for="(col, colIndex) in item.columns"
                :key="item.key + '_' + itemIndex + lineIndex + colIndex"
                :span="col.span"
                :style="{'flex': col.span}"
              >
                <div
                  v-for="(citem, colLineIndex) in col.list"
                  :key="citem.key"
                >
                  <template v-if="(gridIndexs[item.model].indexs[colIndex][lineIndex - 2] !== undefined ? (colLineIndex > gridIndexs[item.model].indexs[colIndex][lineIndex - 2]) : true) && colLineIndex <= gridIndexs[item.model].indexs[colIndex][lineIndex - 1] && operations.displays[citem.model]">
                    <el-form-item
                      v-if="citem.type=='blank'"
                      v-show="operations.shows[citem.model]"
                      :key="citem.key"
                      :label="citem.name"
                      :prop="citem.model"
                      :class="[{'el-form-item-instruction': isLabel}]"
                    >
                      <slot
                        :name="citem.model"
                        :model="models"
                      />
                    </el-form-item>
                    <generate-form-item
                      v-else-if="editable || (citem.type === 'tree' && (models[citem.model] && models[citem.model].length > 0) && (!formExtends[citem.model + 'Extends'] || !formExtends[citem.model + 'Extends'].options || formExtends[citem.model + 'Extends'].options.findIndex(i => !i) !== -1))"
                      v-show="operations.shows[citem.model]"
                      :key="citem.key"
                      :is-first="itemIndex === 0 && lineIndex === 1 && colIndex === 0"
                      :models="models"
                      :form-extends="formExtends"
                      :operations="operations"
                      :rules="rules"
                      :widget="citem"
                      :flow-id="flowId"
                      :validate-field="validateField"
                      :options="operations.options[citem.model]"
                      @updateComponentValue="updateComponentValue"
                    />
                    <generate-show-item
                      v-else
                      v-show="operations.shows[citem.model]"
                      :key="citem.key"
                      :models="models"
                      :form-extends="formExtends"
                      :operations="operations"
                      :rules="rules"
                      :widget="citem"
                      :flow-id="flowId"
                      :linear-form-list="linearFormList"
                      :form-mounted="formMounted"
                    />
                  </template>
                </div>
              </el-col>
            </el-row>
          </div>
        </template>

        <!-- 子表单-开始 -->
        <template v-else-if="operations.displays[item.model] && item.type === 'subform'">
          <!-- 行内子表单-开始 -->
          <div
            v-if="editable ? (!isMobile && (item.options.blockDisplay === false || !item.options.blockDisplay)) : (item.options.detailBlockDisplay === undefined ? (item.options.blockDisplay === false || !item.options.blockDisplay) : !item.options.detailBlockDisplay)"
            v-show="operations.shows[item.model]"
            :key="item.key"
            :style="{
              'padding-left': (item.options.gutter ? item.options.gutter / 2 : 0) + 'px',
              'padding-right': ((item.options.addAndDelete && width > 780) ? 0 : (formData.config.labelPosition === 'left' || formData.config.labelPosition === 'right') ? 0 : 0) + 'px',
              overflow: 'auto'
            }"
            class="sub-form-box"
          >
            <label
              v-if="item.name"
              class="el-form-item__label"
              :style="{
                'margin-left': (item.options.gutter ? -item.options.gutter / 2 : 0) + 'px',
                'width': ((formData.config.labelPosition === 'left' || formData.config.labelPosition === 'right') ? formData.config.labelWidth : '') + 'px',
                'color': 'rgba(14,31,53,.45)'
              }"
            >{{ item.name }}</label>
            <!-- 表头 -->
            <div
              :style="{
                'overflow-x': 'auto',
                'padding-left': (item.columns.length >= 4 || width < 780 ? 10 : 10) + 'px',
                'padding-right': editable ? (item.options.addAndDelete ? 36 : '') + 'px' : (item.columns.length >= 4 || width < 780 ? 10 : 10) + 'px',
                'margin-left': ((formData.config.labelPosition === 'left' || formData.config.labelPosition === 'right') ? 10 : '') + 'px',
                'margin-bottom': (20) + 'px',
                'min-width': isMobile ? item.columns.length * 5 + 'rem' : ''
              }"
            >
              <div
                :style="{
                  'min-width': '100%',
                  'width': (item.options.subformWidth) + 'px'
                }"
              >
                <el-row
                  :gutter="item.options.gutter ? item.options.gutter : 0"
                  type="flex"
                  class="sub-form-title"
                  :style="{
                    'margin-left': ((formData.config.labelPosition === 'left' || formData.config.labelPosition === 'right') ? -10 : '') + 'px',
                    'margin-right': 0 + 'px',
                  }"
                >
                  <template v-for="(col, colIndex) in item.columns">
                    <el-col
                      v-if="subformCol(col.list, 'displays')"
                      v-show="subformCol(col.list, 'shows')"
                      :key="item.key + 'h' + colIndex"
                      :span="col.span"
                      :style="{'flex': col.span}"
                      class="subform-first-title"
                    >
                      <p
                        :style="{
                          'margin-left': -(item.options.gutter ? item.options.gutter / 2 : 0) + 'px',
                          'margin-right': -(item.options.gutter ? item.options.gutter / 2 : 0) + 'px',
                          'padding-left': (item.options.gutter ? item.options.gutter / 2 : 0) + 'px',
                          'padding-right': (item.options.gutter ? item.options.gutter / 2 : 0) + 'px',
                          'font-size': 14 + 'px'
                        }"
                        class="subform-titles"
                      >
                        {{ col.list[0] ? col.list[0].name : '' }}
                      </p>
                    </el-col>
                  </template>
                </el-row>
              </div>
              <!-- 表内容 -->
              <div
                :style="{
                  'min-width': '100%',
                  'width': (item.options.subformWidth)+ 'px'
                }"
              >
                <el-row
                  v-for="(subformItem, subformItemIndex) in (subformData.lines[item.model] || 1)"
                  :key="item.key + '_' + subformItemIndex"
                  type="flex"
                  :class="['compound-box', 'sub-form', { 'last-sub-form': editable && (subformData.lines[item.model] || 1) === (subformItemIndex + 1) }]"
                  :gutter="item.options.gutter ? item.options.gutter : 0"
                  :justify="item.options.justify"
                  :align="item.options.align"
                  :style="{
                    'margin-left': ((formData.config.labelPosition === 'left' || formData.config.labelPosition === 'right') ? (item.columns.length >= 4 || width < 780 ? -10 : - 10) : '') + 'px',
                    'margin-right': 0 + 'px'
                  }"
                >
                  <el-button
                    v-if="editable && item.options.addAndDelete && subformItemIndex >= 0 && (subformData.lines[item.model] || 1) > 1"
                    class="delete-subform-item"
                    type="text"
                    icon="el-icon-delete"
                    @click="deleteItem(item, subformItemIndex)"
                  />
                  <template v-for="(col, colIndex) in item.columns">
                    <el-col
                      v-if="subformCol(col.list, 'displays')"
                      v-show="subformCol(col.list, 'shows')"
                      :key="item.key + '_' + subformItemIndex + colIndex"
                      :span="col.span"
                      :style="{'flex': col.span}"
                    >
                      <template v-for="(citem, indexInCol) in col.list">
                        <div
                          v-if="operations.displays[citem.model]"
                          v-show="operations.shows[citem.model]"
                          :key="item.key + '_' + subformItemIndex + indexInCol + indexInCol"
                          class="inline-sub-form"
                          style="width: 100%;"
                        >
                          <p
                            v-if="editable && !subformItemIndex && indexInCol!== 0"
                            :style="{
                              'margin-left': -(item.options.gutter ? item.options.gutter / 2 : 0) + 'px',
                              'margin-right': -(item.options.gutter ? item.options.gutter / 2 : 0) + 'px',
                              'padding-left': (item.options.gutter ? item.options.gutter / 2 : 0) + 'px',
                              'padding-right': (item.options.gutter ? item.options.gutter / 2 : 0) + 'px',
                            }"
                            class="subform-titles"
                          >
                            {{ citem.name }}
                          </p>
                          <el-form-item
                            v-if="citem.type=='blank'"
                            :key="citem.key + subformItemIndex"
                            :label="subformItemIndex !== 0 ? '' : citem.name"
                            :prop="citem.model"
                          >
                            <slot
                              :name="citem.model"
                              :model="models"
                            />
                          </el-form-item>
                          <generate-form-item
                            v-else-if="editable"
                            :key="citem.key + subformItemIndex"
                            :is-first="itemIndex === 0 && subformItemIndex === 0 && colIndex === 0 && indexInCol === 0"
                            :sub-form-key="subformItemIndex"
                            :models="models"
                            :form-extends="formExtends"
                            :operations="operations"
                            :rules="rules"
                            :widget="citem"
                            :gutter="(item.options.gutter ? item.options.gutter / 2 : 0)"
                            :flow-id="flowId"
                            :validate-field="validateField"
                            :options="operations.options[citem.model] ? (Array.isArray(operations.options[citem.model]) ? operations.options[citem.model] : operations.options[citem.model]['item' + subformItemIndex]) : undefined"
                            :style="{
                              'margin-left': ((formData.config.labelPosition === 'left' || formData.config.labelPosition === 'right') ? -formData.config.labelWidth : '') + 'px',
                              'margin-top': 10 + 'px',
                              'word-break': 'break-all'
                            }"
                            @updateComponentValue="updateComponentValue"
                          />
                          <generate-show-item
                            v-else
                            :key="citem.key + subformItemIndex"
                            :sub-form-key="subformItemIndex"
                            :models="models"
                            :form-extends="formExtends"
                            :operations="operations"
                            :rules="rules"
                            :widget="citem"
                            :gutter="(item.options.gutter ? item.options.gutter / 2 : 0)"
                            :flow-id="flowId"
                            :linear-form-list="linearFormList"
                            :style="{
                              'margin-top': 10 + 'px',
                              'word-break': 'break-all'
                            }"
                            :form-mounted="formMounted"
                          />
                        </div>
                      </template>
                    </el-col>
                  </template>
                  <el-button
                    v-if="editable && item.options.addAndDelete && (subformData.lines[item.model] || 1) === (subformItemIndex + 1)"
                    type="primary"
                    class="add-btn"
                    @click="addItem(item, subformItemIndex + 1)"
                  >
                    {{ item.options.btnText || '+' }}
                  </el-button>
                </el-row>
              </div>
            </div>
          </div>
          <!-- 行内子表单-结束 -->

          <!-- 块状子表单-开始 -->
          <div
            v-else
            v-show="operations.shows[item.model]"
            :key="item.key"
            :style="{
              'margin-bottom': 20 + 'px',
              'padding-left': (item.options.gutter ? item.options.gutter / 2 : 0) + 'px',
              'padding-right': ((item.options.addAndDelete && width > 780) ? 0 : (formData.config.labelPosition === 'left' || formData.config.labelPosition === 'right') ? 0 : 0) + 'px',
            }"
            class="sub-form-box"
          >
            <label
              v-if="item.name"
              class="el-form-item__label"
              :style="{
                'margin-left': (item.options.gutter ? -item.options.gutter / 2 : 0) + 'px',
                'width': ((formData.config.labelPosition === 'left' || formData.config.labelPosition === 'right') ? formData.config.labelWidth : '') + 'px',
                'color': 'rgba(14,31,53,.45)'
              }"
            >{{ item.name }}</label>
            <!-- 表头 -->
            <div
              :style="{
                'overflow-x': 'auto',
                'padding-left': (item.columns.length >= 4 || width < 780 ? 10 : 10) + 'px',
                'padding-right': (item.options.addAndDelete ? 36 : '') + 'px',
                'margin-left': ((formData.config.labelPosition === 'left' || formData.config.labelPosition === 'right') ? 10 : '') + 'px',
                'margin-bottom': (20) + 'px'
              }"
            >
              <div
                :style="{
                  'min-width': '100%',
                  'width': (isMobile ? 'auto' : ((item.options.subformWidth)+ 'px'))
                }"
              >
                <el-row
                  v-for="(subformItem, subformItemIndex) in (subformData.lines[item.model] || 1)"
                  :key="item.key + '_' + subformItemIndex"
                  type="flex"
                  :class="['compound-box', 'block-sub-form', 'sub-form', { 'last-sub-form': editable && (subformData.lines[item.model] || 1) === (subformItemIndex + 1) }]"
                  :gutter="item.options.gutter ? item.options.gutter : 0"
                  :justify="item.options.justify"
                  :align="item.options.align"
                  :style="{
                    'border': '1px dashed #dcdfe6cc',
                    'display': 'flex',
                    'margin-left': ((formData.config.labelPosition === 'left' || formData.config.labelPosition === 'right') ? - 10 : '') + 'px',
                    'margin-right': 0 + 'px'
                  }"
                >
                  <div
                    style="margin: 10px;width: 100%;"
                  >
                    <span style="font-size: 14px;color:#606266">
                      {{ item.name }}
                    </span>
                    <div
                      style="width: 20px;height:20px;border-radius: 50%;text-align: center;line-height: 20px;color: white;background-color: #2196f3b5;display:inline-block"
                    >
                      {{ subformItemIndex + 1 }}
                    </div>
                  </div>
                  <div style="width: 100%;">
                    <el-button
                      v-if="editable && item.options.addAndDelete && subformItemIndex >= 0 && (subformData.lines[item.model] || 1) > 1"
                      class="delete-subform-item"
                      type="text"
                      icon="el-icon-delete"
                      @click="deleteItem(item, subformItemIndex)"
                    />
                    <el-col
                      v-for="(col, colIndex) in item.columns"
                      :key="item.key + '_' + subformItemIndex + colIndex"
                      :span="col.span"
                      class="block-sub-formCol"
                      style="border: 0"
                    >
                      <template v-for="(citem, indexInCol) in col.list">
                        <div
                          v-if="operations.displays[citem.model]"
                          v-show="operations.shows[citem.model]"
                          :key="item.key + '_' + subformItemIndex + indexInCol + indexInCol"
                        >
                          <generate-form-item
                            v-if="editable"
                            :key="citem.key + subformItemIndex"
                            :sub-form-key="subformItemIndex"
                            :models="models"
                            :form-extends="formExtends"
                            :operations="operations"
                            :rules="rules"
                            :widget="citem"
                            :gutter="(item.options.gutter ? item.options.gutter / 2 : 0)"
                            :flow-id="flowId"
                            :validate-field="validateField"
                            :options="operations.options[citem.model] ? (Array.isArray(operations.options[citem.model]) ? operations.options[citem.model] : operations.options[citem.model]['item' + subformItemIndex]) : undefined"
                            :style="{
                              'margin-top': 10 + 'px',
                              'word-break': 'break-all'
                            }"
                            @updateComponentValue="updateComponentValue"
                          />
                          <generate-show-item
                            v-else
                            :key="citem.key + subformItemIndex"
                            :sub-form-key="subformItemIndex"
                            :models="models"
                            :form-extends="formExtends"
                            :operations="operations"
                            :rules="rules"
                            :widget="citem"
                            :gutter="(item.options.gutter ? item.options.gutter / 2 : 0)"
                            :flow-id="flowId"
                            :linear-form-list="linearFormList"
                            :style="{
                              'margin-top': 10 + 'px',
                              'word-break': 'break-all'
                            }"
                            :form-mounted="formMounted"
                          />
                        </div>
                      </template>
                    </el-col>
                    <el-button
                      v-if="editable && item.options.addAndDelete && (subformData.lines[item.model] || 1) === (subformItemIndex + 1)"
                      type="primary"
                      class="add-btn"
                      @click="addItem(item, subformItemIndex + 1)"
                    >
                      <!-- {{ $t('message.common.add') }}
                      <i class="el-icon-plus" /> -->
                      {{ item.options.btnText || '+' }}
                    </el-button>
                  </div>
                </el-row>
              </div>
            </div>
          </div>
          <!-- 块状子表单-结束 -->
        </template>
        <!-- 子表单-结束 -->

        <template v-else-if="item.type == 'blank'">
          <el-form-item
            :key="item.key"
            :label="item.name"
            :prop="item.model"
          >
            <slot
              :name="item.model"
              :model="models"
            />
          </el-form-item>
        </template>

        <template v-else-if="operations.displays[item.model]">
          <generate-form-item
            v-if="editable || (item.type === 'tree' && (models[item.model] && models[item.model].length > 0) && (!formExtends[item.model + 'Extends'] || !formExtends[item.model + 'Extends'].options || formExtends[item.model + 'Extends'].options.findIndex(i => !i) !== -1))"
            v-show="operations.shows[item.model]"
            :key="item.key"
            :models="models"
            :form-extends="formExtends"
            :operations="operations"
            :rules="rules"
            :widget="item"
            :flow-id="flowId"
            :validate-field="validateField"
            :is-first="itemIndex === 0"
            :options="operations.options[item.model]"
            @updateComponentValue="updateComponentValue"
          />
          <generate-show-item
            v-else
            v-show="operations.shows[item.model]"
            :key="item.key"
            :models="models"
            :form-extends="formExtends"
            :operations="operations"
            :rules="rules"
            :widget="item"
            :flow-id="flowId"
            :linear-form-list="linearFormList"
            :form-mounted="formMounted"
          />
        </template>
      </template>
    </el-form>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapState } from 'vuex'
import GenerateFormItem from './GenerateFormItem'
import GenerateShowItem from './GenerateShowItem'
import * as computeFunction from '../util/computeFunction'
import { getLinearFormList } from '../util/index'
import { evaluation } from '../util/evaluation'
import { clone } from 'ramda'
import { isInSubform } from '../util/index'

/**
 * 根据值、可选项、可选项的取值key，获取选中项
 * @param value 数据源的key
 * @param options 数据源的参数
 * @param key 需要取值的可选项的属性key
 * @return activeOptions Array；value 命中的可选项组成的数组和新值
 */
const getActiveOptionsAndValue = (value, options, key) => {
  const activeOptions = []
  if (!options || options.length < 1) {
    if (Array.isArray(value)) {
      value.splice(0)
    } else {
      value = undefined
    }
  } else {
    if (Array.isArray(value)) {
      // 值是数组时：多选select、checkbox
      for (let j = 0; j < value.length; j++) {
        const option = options.find(option => option[key] === value[j])
        if (option) {
          activeOptions.push(option)
        } else {
          value.splice(j, 1)
        }
      }
    } else {
      // 值不是数组时：单选select、radio
      if (Array.isArray(options)) {
        options.forEach(option => {
          if (value === option[key]) {
            activeOptions.push(option)
          }
        })
      } else {
        console.log(options, value, key)
      }

      if (activeOptions.length < 1) {
        value = undefined
      }
    }
  }

  return {
    activeOptions,
    value
  }
}

/**
 * 根据值和可选项，更新activeOptions和值
 * @param component 组件
 * @param operations 属性集合
 * @param isInSubformComponent 是否子表单内组件
 */
const updateActiveOptionsAndValue = (component, operations, isInSubformComponent,remoteSearchSelectOptions = []) => {
  // 是否默认选中第一项
  const defaultFirst = ['radio', 'select'].includes(component.type) && !component.options.multiple && component.options.remote && component.options.defaultFirst
  // activeOptions：有可选项的（radio、checkbox、select），根据可选项和值，计算出active options
  if (['select', 'radio', 'checkbox'].includes(component.type)) {
    const key = component.options.remote ? (component.options.submitKey || 'value') : 'value'
    if(component.type === 'select' && component.options.multiple && component.options.remote && component.options.remoteFilter && component.options.filterable){
      const value = (defaultFirst && remoteSearchSelectOptions[0] && !operations.models[component.model]) ? remoteSearchSelectOptions[0][key] : operations.models[component.model]
      let activeOptions = []
      let l = value?.length ?? 0
      if (l > 0) {
        for (let j = 0; j < value?.length; j++) {
          const option = remoteSearchSelectOptions.find(option => option[key] === value[j])
          if (option) {
            activeOptions.push(option)
          }
        }
      }
      Vue.set(operations.activeOptions, component.model, activeOptions)
    }else if(isInSubformComponent) {
      // 本组件是子表单内组件时
      if (!Object.keys(operations.activeOptions).includes(component.model)) {
        Vue.set(operations.activeOptions, component.model, {item0: []})
      }
      for (let i = 0; i < operations.models[component.model].length; i++) {
        const subformLineKey = 'item' + i
        const options = Array.isArray(operations.options[component.model]) ? operations.options[component.model] : (operations.options[component.model][subformLineKey] || [])
        // 默认选中第一项：赋值
        const value = (defaultFirst && options[0] && !operations.models[component.model][subformLineKey]) ? options[0][key] : operations.models[component.model][subformLineKey]
        const res = getActiveOptionsAndValue(value, options, key)
        Vue.set(operations.activeOptions[component.model], subformLineKey, res.activeOptions)
        Vue.set(operations.models[component.model], subformLineKey, res.value)
      }
    } else {
      // 本组件不是子表单内组件时
      const options = operations.options[component.model]
      // 默认选中第一项：赋值
      const value = (defaultFirst && options[0] && !operations.models[component.model]) ? options[0][key] : operations.models[component.model]
      const res = getActiveOptionsAndValue(value, options, key)
      Vue.set(operations.activeOptions, component.model, res.activeOptions)
      Vue.set(operations.models, component.model, res.value)
    }
  }
  return operations.activeOptions[component.model]
}

export default {
  name: 'FmGenerateForm', // 组件：整个表单的容器，实现联动等
  components: {
    GenerateFormItem,
    GenerateShowItem
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    value: {
      type: Object,
      default: () => {}
    },
    editable: { // 是否可操作表单
      type: Boolean,
      default: true
    },
    flowId: {
      type: String,
      default: ''
    },
    userName: {
      type: String,
      default: ''
    },
    userDepartmentName:{
      type: String,
      default: ''
    }
    // useVisibility: { // 是否用流程节点的可见性覆盖设计时的可见与否
    //   type: Boolean,
    //   default: false
    // }
  },
  data () {
    return {
      //非子表单的远程搜索下拉选择框的options
      remoteSearchSelectOptions:{},
      promiseMap: {},
      computeQueue: [],
      loadingCompute: false, // 是否正在计算组件各属性、值
      formData: this.data,
      models: this.getModels(),
      rules: {},
      operationsData: {
        displays: {}, // 使用与否
        models: {}, // 值集合
        shows: {}, // 展示与否
        disableds: {}, // 禁用与否
        readonlys: {}, // 只读与否
        options: {}, // 可选项集合
        activeOptions: {} // 选中项集合
      },
      formExtends: this.getExtends(),
      width: window.document.body.clientWidth || window.innerWidth,
      formMounted: false,
      // isMobile: window.screen && window.screen.availWidth && window.screen.availWidth <= 768
      isMobile: window.document.body.clientWidth && window.document.body.clientWidth <= 768
    }
  },
  computed: {
    ...mapState(['loading']),
    // 子表单与其内组件的对应关系、行数等数据
    subformData () {
      const obj = {
        inToOutMap: {},
        outToInMap: {},
        lines: {}
      };
      (this.formData.list || []).forEach(c => {
        if (c.type === 'subform') {
          obj.outToInMap[c.model] = []
          obj.lines[c.model] = 0
          if (c.columns && c.columns.length > 0) {
            c.columns.forEach(column => {
              if (column.list && column.list.length > 0) {
                column.list.forEach(ic => {
                  obj.outToInMap[c.model].push(ic.model)
                  obj.inToOutMap[ic.model] = c.model
                  if (this.models[ic.model] && this.models[ic.model].length > obj.lines[c.model]) {
                    obj.lines[c.model] = this.models[ic.model].length
                  }
                })
              }
            })
          }
        }
      })
      return obj
    },
    // 操作配置：计算属性
    operations () {
      const obj = {
        displays: {},
        models: {},
        shows: {},
        disableds: {},
        readonlys: {},
        options: {},
        activeOptions: {}
      }
      if (this.editable) {
        // 申请时
        return this.formData.list && this.operationsData ? this.operationsData : obj
      } else {
        // 查看时
        const keys = Object.keys(this.value)
        keys.forEach(item => {
          // 基础组件字段存在时，且有扩展配置时
          if (!/Extends$/.test(item) && this.formExtends[item + 'Extends'] && this.formExtends[item + 'Extends'].config) {
            const configKeys = Object.keys(this.formExtends[item + 'Extends'].config)
            configKeys.forEach(key => {
              this.$set(obj[key + 's'], item, this.formExtends[item + 'Extends'].config[key])
            })
          }
          // 子表单和栅格组件：只有扩展配置
          if (/Extends$/.test(item) && !Object.keys(this.models).includes(item.slice(0, -7))) {
            const configKeys = Object.keys(this.formExtends[item].config)
            configKeys.forEach(key => {
              const model = item.slice(0, -7)
              this.$set(obj[key + 's'], model, this.formExtends[item].config[key])
              // 循环遍历栅格和子表单内部的组件：内部组件全部不使用、不显示时，容器元素也不使用、不显示
              let display = false
              let show = false
              this.data.list.forEach(component => {
                if (component.model === model && component.columns) {
                  component.columns.forEach(column => {
                    if (column.list) {
                      column.list.forEach(inComponent => {
                        // displays
                        if (Object.keys(this.models).includes(inComponent.model) && this.formExtends[inComponent.model + 'Extends'] && this.formExtends[inComponent.model + 'Extends'].config && this.formExtends[inComponent.model + 'Extends'].config.display !== false) {
                          display = true
                        }
                        // shows
                        if (this.formExtends[inComponent.model + 'Extends'] && this.formExtends[inComponent.model + 'Extends'].config && this.formExtends[inComponent.model + 'Extends'].config.show !== false) {
                          show = true
                        }
                      })
                    }
                  })
                }
              })

              if (!display) {
                this.$set(obj['displays'], model, false)
              }
              if (!show) {
                this.$set(obj['shows'], model, false)
              }
            })
          }
        })
        this.linearFormList.forEach(item => {
          if (item.type === 'customdata' || (item.type === 'link' && item.options.stage === 'apply')) {
            this.$set(obj['displays'], item.model, false)
            this.$set(obj['shows'], item.model, false)
          }
          if (item.type === 'tips' || (item.type === 'customshow' && item.options.show.type === 1 && !item.options.show.value)) {
            this.$set(obj['shows'], item.model, false)
          }
        })
      }
      return obj
    },
    // 线性一维控件数组:含容器组件
    listWithContainer () {
      let arr = []
      if (this.formData && this.formData.list) {
        arr = [...this.formData.list]
        this.formData.list.forEach(c => {
          if ((c.type === 'grid' || c.type === 'subform') && c.columns && c.columns.length > 0) {
            c.columns.forEach(column => {
              if (column.list && column.list.length > 0) {
                arr = arr.concat([...column.list])
              }
            })
          }
        })
      }
      return arr
    },
    // 线性一维控件数组：不含容器组件
    linearFormList () {
      return getLinearFormList(this.formData.list)
    },
    // 组件hash，key是组件的model属性
    componentsMap () {
      const obj = {}
      this.listWithContainer.forEach(c => {
        obj[c.model] = c
      })
      return obj
    },
    // 组件依赖关系矩阵
    dependTree () {
      const obj = {}
      this.listWithContainer.forEach(c => {
        obj[c.model] = {}
      })
      this.listWithContainer.forEach(c => {
        // 公式处理
        const properties = ['display', 'valueConfig', 'show', 'disabled', 'readonly']
        properties.forEach(property => {
          if (c.options && c.options[property] && c.options[property].type === 2 && c.options[property].formula && c.options[property].formula.length > 0) {
            c.options[property].formula.forEach(item => {
              if (item.type === 'variate' || item.type === 'subform') {
                obj[item.value][c.model] = true
              }
              if (item.type === 'source' && item.params && item.params.length > 0) {
                item.params.forEach(p => {
                  if (p && p.value && p.value.length > 0) {
                    p.value.forEach(m => {
                      obj[m][c.model] = true
                    })
                  }
                })
              }
            })
          }
        })
        // 值的数据源处理
        if (c.options && c.options.valueConfig && c.options.valueConfig.type === 4 && c.options.valueConfig.params && c.options.valueConfig.params.length > 0) {
          c.options.valueConfig.params.forEach(p => {
            if (p && p.value && p.value.length > 0) {
              p.value.forEach(m => {
                obj[m][c.model] = true
              })
            }
          })
        }
        // 值的组件依赖处理
        if (c.options && c.options.valueConfig && c.options.valueConfig.type === 3 && c.options.valueConfig.dependItemModel) {
          obj[c.options.valueConfig.dependItemModel][c.model] = true
        }
        // 可选项是数据源的参数处理
        if (c.options.remote && c.options.params && c.options.params.length > 0) {
          c.options.params.forEach(p => {
            if (p && p.value && p.value.length > 0) {
              p.value.forEach(m => {
                obj[m][c.model] = true
              })
            }
          })
        }
        // 可选项是子表单内组件的处理
        if (!c.options.remote && c.options.isSubformData && c.options.subformDataKey) {
          obj[c.options.subformDataKey][c.model] = true
        }
        // 容器处理：容器内的组件依赖容器本身
        this.listWithContainer.forEach(out => {
          if (['grid', 'subform'].includes(out.type)) {
            if (out.columns) {
              out.columns.forEach(column => {
                if (column.list) {
                  column.list.forEach(inComponent => {
                    obj[out.model][inComponent.model] = true
                  })
                }
              })
            }
          }
        })
      })
      Object.keys(obj).forEach(k => {
        obj[k] = Object.keys(obj[k])
      })
      return obj
    },
    gridIndexs () {
      let gridIndexs = {}
      if (this.formData.list && this.formData.list.length > 0) {
        this.formData.list.forEach(item => {
          if (item.type !== 'grid') {
            return
          }
          let indexs = []
          for (let i = 0; i < item.columns.length; i++) {
            indexs[i] = []
            for (let j = 0; j < item.columns[i].list.length; j++) {
              if (this.operations.displays[item.columns[i].list[j].model] && this.operations.shows[item.columns[i].list[j].model]) {
                indexs[i].push(j)
              }
            }
            if (item.columns[i].list.length > 0 && (indexs[i].length === 0 || (indexs[i][indexs[i].length - 1] < item.columns[i].list.length - 1))) {
              indexs[i].push(item.columns[i].list.length - 1)
            }
          }
          let lines = 0
          for (let i = 0; i < indexs.length; i++) {
            if (lines < indexs[i].length) {
              lines = indexs[i].length
            }
          }
          gridIndexs[item.model] = {
            indexs,
            lines
          }
        })
      }
      return gridIndexs
    }
  },
  watch: {
    value: {
      deep: true,
      handler () {
        if (!this.editable) {
          this.models=this.getModels()
          this.formExtends= this.getExtends()
        }
      }
    },
    models: {
      async handler () {
        if (this.editable) {
          this.$emit('input', {...this.models, ...this.formExtends})
        }
      },
      deep: true
    },
    formExtends: {
      async handler () {
        if (this.editable) {
          this.$emit('input', {...this.models, ...this.formExtends})
        }
      },
      deep: true
    }
  },
  async created () {
    if (this.editable) {
      this.initRules(this.formData.list)
      this.loadingCompute = true
      this.computeQueue.push(new Promise(async resolve => {
        // 初始化时：需要先把值给到，不然渲染出的组件初始值为undefined，可能会报错
        const operations = await this.initOperations(this.formData.list,true)
        this.$set(this.$data, 'models', operations.models)
        this.$nextTick(async () => {
          this.operationsData = operations
          this.$nextTick(() => {
            this.loadingCompute = false
            resolve()
          })
        })
      }))
    }
    window.setFormData = this.setFormData

    if (!this.editable) {
      // 遍历值，依次展示提示
      for (let i = 0; i < this.linearFormList.length; i++) {
        const c = this.linearFormList[i]
        if (c.type === 'tips' && (typeof c.options.stage === 'undefined' || c.options.stage === 'approve' || c.options.stage === 'all') && this.operations.displays[c.model] && Object.keys(this.models).includes(c.model)) {
          const h = this.$createElement
          await this.$alert(this.models[c.model], this.$t('message.common.info'), {
            type: 'info',
            customClass: 'detail-tips',
            message: h('p', null, [
              h('span', { style: 'color: red;font-size: 16px;' }, (c.options.valueConfig.type == 1 && c.options.defaultValue_i18n) ? c.options.defaultValue_i18n : this.models[c.model])
            ]),
            showClose: false,
            confirmButtonText: this.$t('message.common.ok')
          })
        }
      }
    }
  },
  mounted () {
    this.$nextTick(() =>{
      this.formMounted = true
    })
  },
  methods: {
    ...mapActions(['getStoreDataSource', 'getRemoteFunction']),
    //按照组件的依赖关系进行分类，如果A依赖于B，那么B的children包含A
    classifyComponent(arr) {
      let result = []
      arr.forEach(item => {
        let isRemote = item?.options?.remote ?? false;
        let hasParams = item?.options?.params?.length > 0 ?? false;
        let remoteValue = item?.options?.valueConfig?.params?.length > 0 ?? false
        let remoteValueDataSourceKey = item?.options?.valueConfig?.dataSourceKey ?? ""
        //可选性来自于远程数据源 或者 默认值来自于数据源
        let isChildren = (isRemote && hasParams) || (remoteValue == true && remoteValueDataSourceKey != "")
        if (isChildren == false) {
          result.push(item)
        }
      })
      result.forEach(children => {
        this.traverse(children, arr)
      })
      return result
    },
    traverse(item, arr) {
      let temp = []
      arr.forEach(i => {
        //可选项处理
        if (i?.options?.remote === true) {
          i?.options?.params?.forEach(p => {
            p.value.forEach(v => {
              if (v == item.model) {
                temp.push(i)
              }
            })
          })
        }
        let dKey = i?.options?.valueConfig?.dataSourceKey ?? ""
        if (dKey != "") {
          //默认值处理
          i?.options?.valueConfig?.params?.forEach(p => {
            p.value.forEach(v => {
              if (v == item.model) {
                temp.push(i)
              }
            })
          })
        }
      })
      if (temp.length > 0) {
        item.children = temp
        item.children.forEach(c => {
          this.traverse(c, arr)
        })
      }
    },
    // 解密之后更新表单数据
    updateData () {
      this.models=this.getModels()
      this.formExtends= this.getExtends()
    },
    // 判断子表单列是否使用、展示
    subformCol (list, key) {
      if (!list || list.length < 1) {
        return true
      }
      for (let i = 0; i < list.length; i++) {
        if (this.operations[key][list[i].model]) {
          return true
        }
      }
      return false
    },
    // 设置表单字段值：给注入组件使用
    async setFormData (data) {
      try {
        await this.computeQueue[this.computeQueue.length - 1]
        Object.keys(data).forEach(model => {
          if (Object.keys(this.models).includes(model)) {
            // 清除组件的计算状态，以便重新计算
            const component = this.getComponentByModel(model)
            component.isComputed = false
            this.dependTree[model].forEach(k => {
              this.clearComputeStatusByModel(k)
            })
            // 若是子表单内组件时：同步同一个子表单内的其他组件的length（行数）
            const subformModel = this.subformData.inToOutMap[model]
            if (subformModel && this.subformData.lines[subformModel] < data[model].length) {
              this.subformData.outToInMap[subformModel].forEach(inModel => {
                this.models[inModel] && (this.models[inModel].length = data[model].length)
              })
            }
            this.$set(this.models, model, data[model])
          }
        })

        this.operationsData = await this.initOperations(this.formData.list)
        this.$nextTick(() => {
          this.$set(this.$data, 'models', this.operationsData.models)
        })

        window.endCustomData()
        window.models = undefined
      } catch (e) {
        console.error(e)
        window.endCustomData()
        window.models = undefined
      }
    },
    // 获取value中的值到models中
    getModels () {
      const obj = {}
      if (this.value) {
        Object.keys(this.value).forEach(key => {
          if (!/Extends$/.test(key)) {
            obj[key] = this.value[key]
          }
        })
      }
      return obj
    },
    // 获取value中的扩展到extends中
    getExtends () {
      const obj = {}
      if (this.value) {
        Object.keys(this.value).forEach(key => {
          if (/Extends$/.test(key)) {
            obj[key] = this.value[key]
          }
        })
      }
      return obj
    },
    /**
     * 获取数据源的数据
     * @param dataSourceKey 数据源的key
     * @param params 数据源的参数
     * @param property 需要取的数据源结果的属性key
     * @param index 子表单内组件的行号索引：第几行，方便找对应的行。undefined时表示本组件是非子表单
     * @param models 本次计算的组件值的集合对象
     * @param filterKey 可选项的远程搜索关键词
     */
    async getDataSource (dataSourceKey, params, property, index, models, filterKey) {
      // // 开始请求数据
      const res = await this.getStoreDataSource({
        key: dataSourceKey,
        models: models || this.models,
        params,
        inSubform: index !== undefined,
        linearFormList: this.linearFormList,
        subFormKey: index,
        filterKey
      })
      if (res && property) {
        return res[property]
      } else {
        return res
      }
    },
    // 获取指定数据源的属性property的值
    // async getDataSourcePropertyValue (dataSourceKey, params, property, index, models) {
    //   const res = await this.getDataSource(dataSourceKey, params, property, index, models)
    //   if (dataSourceKey === 'isTerUsageItem') {
    //     console.log(arguments, res, 5555)
    //   }
    //   return property ? res[property] : res
    // },
    // 公式转换为多维多层数组：以小括号为界，（函数是左括号，带有fun值）
    formatExpression (formula) {
      let res = {
        arr: [],
        index: 0
      }
      let index = 0
      for (let i = 0; i < formula.length; i++) {
        if (i < index) {
          continue
        }
        if (formula[i].type === 'parenthesis' && formula[i].value === '(') {
          const inRes = this.formatExpression(formula.slice(i + 1))
          if (formula[i].fun) {
            inRes.arr.fun = formula[i].fun
            inRes.arr.id = formula[i].id
          }
          res.arr.push(inRes.arr)
          index = i + inRes.index + 2
        } else if (formula[i].type === 'parenthesis' && formula[i].value === ')') {
          res.index = i
          break
        } else {
          res.arr.push(formula[i])
        }
      }
      return res
    },
    /**
     * 计算已经格式化为多维数组的公式
     * @param arr 要计算的数组
     * @param isFunctionParams 数组是否为函数参数
     * @param index 子表单内组件的行号索引：第几行，方便找对应的行。undefined时表示本组件是非子表单
     * @param type 公式计算的类型：默认值公式计算、验证公式计算(validate，即验证：有特殊处理)
     * @param operations 本次计算的组件值的集合对象
     */
    async computeArr (arr, isFunctionParams, index, type, operations) {
      const models = (operations && operations.models) ? operations.models : this.models
      const operationsData = operations ? operations : this.operationsData
      try {
        let valueArr = []
        let stack = []
        // let str = ''
        for (let i = 0; i < arr.length; i++) {
          // 遍历数组递归计算
          if (Array.isArray(arr[i])) {

            // 括号内的转为数组，先计算
            if (arr[i].fun) {
  
              // 函数处理时
              const computeRes = await this.computeArr(arr[i], true, index, type, operations)

              if (type === 'validate' && (computeRes instanceof Error)) {
    
                return computeRes
              }
              console.log(computeFunction[arr[i].fun],'123');
              // 本地local的同步函数时
              if (computeFunction[arr[i].fun]) {
                valueArr[i] = computeFunction[arr[i].fun](...computeRes)
              } else {
                // 远程异步函数时
                try {
                  const res = await this.getRemoteFunction({
                    key: arr[i].id,
                    params: computeRes
                  })
                  if (res.code === 0) {
                    valueArr[i] = res.data
                  } else {
                    if (type === 'validate') {
                      return new Error(res.message)
                    }
                    valueArr[i] = undefined
                    console.error(res.message)
                  }
                } catch (e) {
                  if (type === 'validate') {
                    return new Error(e.message)
                  }
                  valueArr[i] = undefined
                  console.error(e.message)
                }
              }
            } else if (arr[i].length === 0 || arr[i][0] === null || typeof arr[i][0] !== 'object') {
              valueArr[i] = arr[i]
            } else {
              // 普通数组时
              valueArr[i] = await this.computeArr(arr[i], false, index, type, operations)
            }
          } else if (arr[i].type === 'variate') {
            // 公式变量是表单属性值时
            const widget = this.linearFormList.filter(item => {
              return item.model === arr[i].value
            })[0]
            if (index !== undefined) {
              // 计算子表单内组件的验证公式时
              if (widget && widget.inSubform && (!arr.fun || !['reduceAdd', 'reduceSub', 'reduceMul', 'reduceDiv','reduceMax','reduceMin','reduceCut'].includes(arr.fun))) {
                // 变量指向的组件是子表单内的组件时，且不是累计运算的参数
                valueArr[i] = models[arr[i].value]['item' + index]              
              } else {
                // 变量指向的组件是非子表单内的组件时，或是子表单内组件且是累计元算的参数
                valueArr[i] = models[arr[i].value]
              }
            } else {
              valueArr[i] = models[arr[i].value]
            }
            if(widget.type==='number' || widget.options.dataType === 'number'){
              if(typeof valueArr[i] === 'object' && valueArr[i]){
                // 复制副本，避免影响原始值
                valueArr[i] = JSON.parse(JSON.stringify(valueArr[i]))
                Object.keys(valueArr[i]).forEach(v=>{
                  if(v!='length'){
                    valueArr[i][v]=Number(valueArr[i][v] || 0)
                  }
                })
              }else{
                valueArr[i] = Number(valueArr[i] || 0)
              }
            }
            if(!operationsData.displays[arr[i].value]){
              valueArr[i] = undefined;
            }
          } else if (arr[i].type === 'source') {
            // 公式变量是远端数据源时
            // valueArr[i] = await this.getDataSourcePropertyValue(arr[i].source, arr[i].params, arr[i].value, index, models)
            valueArr[i] = await this.getDataSource(arr[i].source, arr[i].params, arr[i].value, index, models)
          } else if (arr[i].type === 'number' || arr[i].type === 'string' || arr[i].type === 'boolean') {
            // 公式变量时手动输入的数字、字符串、布尔值时
            valueArr[i] = arr[i].value
          } else {
            // 其他情况：运算符或子表单项
            valueArr[i] = arr[i]
          }

          // 数组是非函数参数时：普通表达式
          if (!isFunctionParams) {
            if (valueArr[i] && valueArr[i].type === 'bool') {
              // 布尔运算符
              // str += (valueArr[i].value === 'AND' ? '&&' : '||')
              stack.push(arr[i])
            } else if (valueArr[i] && (valueArr[i].type === 'calculate' || valueArr[i].type === 'compare')) {
              // 计算运算符和比较运算符
              // str += valueArr[i].value
              stack.push(arr[i])
            } else {
              // 分情况处理计算
              if (typeof valueArr[i] === 'string') {
                // 字符串时
                // str += '"' + valueArr[i] + '"'
                stack.push({
                  type: 'string',
                  value: valueArr[i]
                })
              } else if (Array.isArray(valueArr[i]) && (valueArr[i].length === 0 || valueArr[i][0] === null || typeof valueArr[i][0] !== 'object')) {
                // 数组值
                stack[i] = {
                  type: 'array',
                  value: valueArr[i]
                }
              } else if (typeof valueArr[i] === 'object' && valueArr[i] !== null) {
                // 子表单时
                // 旧的处理逻辑
                if (valueArr[i].value) {
                  const model = models[valueArr[i].value]
                  if (model) {
                    for (let j = 0; j < model.length; j++) {
                      stack.push({
                        type: typeof model['item' + j],
                        value: model['item' + j]
                      })
                      if (j < model.length - 1) {
                        stack.push({
                          type: 'calculate',
                          value: valueArr[i].operator
                        })
                      }
                    }
                  }
                } else {
                  //  新的处理逻辑
                  const model = valueArr[i]
                  for (let j = 0; j < model.length; j++) {
                    stack.push(model['item' + j])
                  }
                  return stack
                }
              } else {
                // 其他情况
                stack[i] = {
                  type: typeof valueArr[i],
                  value: valueArr[i]
                }
              }
            }
          }
        }
        let res
        // 数组是函数参数时
        if (isFunctionParams) {
          const params = []
          let startIndex = 0
          for (let i = 0; i < valueArr.length; i++) {
            if (valueArr[i] && valueArr[i].type === 'comma') {
              params.push(await this.computeArr(valueArr.slice(startIndex, i), false, index, type, operations))
              startIndex = i + 1
            }
          }
          params.push(await this.computeArr(valueArr.slice(startIndex), false, index, type, operations))
          res = params
        } else {
          // 数组是普通表达式时
          res = evaluation(stack, models)
        }
        return res
      } catch (e) {
        return undefined
      }
    },
    /**
     * 计算公式，返回结果
     * @param formula 公式数组
     * @param index 子表单内组件的行号索引：第几行，方便找对应的行。undefined时表示本组件是非子表单
     * @param type 公式计算的类型：默认值公式计算、验证公式计算
     * @param operations 本次计算的组件的属性值的集合对象
     */
    async computeFormula (formula, index, type, operations) {
      const res = await this.computeArr(this.formatExpression(formula).arr, false, index, type, operations)
      return res
    },
    // 初始化表单的验证规则
    initRules (genList) {
      for (let i = 0; i < genList.length; i++) {
        const { verify } = genList[i].options
        if (genList[i].type === 'grid' || genList[i].type === 'subform') {
          genList[i].columns.forEach(item => {
            this.initRules(item.list, genList[i].type === 'subform')
          })
        } else {
          // 生成验证规则
          if (genList[i].options && genList[i].options.required) {
            // required没有导入rules，手动重新导入
            if (!genList[i].rules || genList[i].rules.length < 1) {
              // 不存在rules、或rules为零项
              genList[i].rules = [{ required: true, message: this.$t('message.common.validate.required') }]
            } else {
              let required = false
              for (let j = 0; j < genList[i].rules.length; j++) {
                if (Object.keys(genList[i].rules[j]).includes('required')) {
                  required = true
                  break
                }
              }
              if (!required) {
                genList[i].rules.unshift({ required: true, message: this.$t('message.common.validate.required') })
              }
            }
          }

          // 根据组件不同，设定不同的验证触发方式
          let triggerType = 'blur'

          this.rules[genList[i].model] = [...(genList[i].rules ? genList[i].rules.map(item => {
            let newItem = clone(item)
            if (newItem.message === '必须填写' || newItem.message === '必填') {
              newItem.message = this.$t('message.common.validate.required')
            }
            if (newItem.message === '格式不正确' || newItem.message === '格式不正确') {
              newItem.message = this.$t('message.common.validate.wrongFormat')
            }
            newItem.message = genList[i].name + ' ' + newItem.message
            newItem.trigger = triggerType
            return newItem
          }) : [])]

          // 正则
          if (genList[i].options && genList[i].options.pattern) {
            this.rules[genList[i].model].push({ pattern: eval(genList[i].options.pattern), message: this.$t('message.common.validate.wrongFormat'), trigger: triggerType })
          }
          if (verify && verify.formula.length > 0) {
            this.rules[genList[i].model].push({
              validator: async (rule, value, cb) => {
                // 为空时直接通过：必填前置用来验证不为空
                if (value === undefined || value === null || value === '') {
                  cb()
                } else {
                  try {
                    const res = await this.computeFormula(verify.formula, genList[i].inSubform ? parseInt(rule.field.split('.item')[1]) : undefined, 'validate')
                    if (res instanceof Error) {
                      cb(new Error(res.message || verify.message || this.$t('message.common.validate.validateFaild')))
                    } else if (res) {
                      cb()
                    } else {
                      cb(new Error(verify.message || this.$t('message.common.validate.validateFaild')))
                    }
                  } catch (e) {
                    cb(new Error(e.message || verify.message))
                  }
                }
              },
              trigger: triggerType
            })
          }
        }
      }
    },
    /**
     * 计算组件的各个需要的属性值：使用与否、值、显示与否、禁用与否、只读与否
     * @param component 组件
     * @param operations 属性值集合对象
     * @param inSubform 是否是子表单内的子组件
     * @param containerDisplay 包含本组件的容器组件是否使用：容器组件不使用则本组件也不使用
     */
    async computeComponentProperties (component, operations, inSubform, containerDisplay) {
      // 本次递归已计算的，则跳过
      // console.log(component)
      if (component && !component.isComputed) {
        // console.log(component.name)
        let res = {}
        const isInSubformComponent = component.inSubform || inSubform // 是否是子表单内的子组件
        // display:使用与否
        if (component.options.display) {
          if (component.type === 'customshow' && component.options.contentType !== 2) {
            res.displays = false
          } else if (component.options.display.type === 1) {
            res.displays = component.options.display.value
          } else if (component.options.display.formula && component.options.display.formula.length > 0) {
            await this.waitDependInformulaComplete(component.options.display.formula, operations)
            res.displays = await this.computeFormula(component.options.display.formula, undefined, undefined, operations)
          }
          // 子表单或者栅格不使用时，其内的所有组件都不使用
          if (typeof containerDisplay !== 'undefined' && !containerDisplay) {
            res.displays = false
          }
        }
        this.$set(operations.displays, component.model, res.displays)
        if (!res.displays) {
          // 标识本组件为本次递归的已计算状态
          component.isComputed = true
          delete operations.models[component.model]
          delete this.models[component.model]
          return operations
        }

        // models:值
        if (component.options.valueConfig) {
          if (component.options.valueConfig.type === 1) {
            // 值是静态值时
            if (Object.keys(operations.models).indexOf(component.model) < 0) {
              // 静态值时：只有初始化时且formdata里面无key时（即第一次、初始化数据结构）需要计算并替换
              let value = clone(component.options.defaultValue)
              if (isInSubformComponent) {
                value = {
                  length: this.subformData.lines[this.subformData.inToOutMap[component.model]] || 1
                }
                for (let itemIndex = 0; itemIndex < value.length; itemIndex++) {
                  value['item' + itemIndex] = clone(component.options.defaultValue)
                }
              }
              this.$set(operations.models, component.model, value)
            } else if (isInSubformComponent) {
              // formdata有key，但是组件是子表单内组件时：需要循环检查每行，没有key的需要填充值
              for (let i = 0; i < operations.models[component.model].length; i++) {
                if (!Object.keys(operations.models[component.model]).includes('item' + i)) {
                  this.$set(operations.models[component.model], 'item' + i, clone(component.options.defaultValue))
                }
              }
            }
          }

          if (!operations.models[component.model] && isInSubformComponent) {
            this.$set(operations.models, component.model, { length: this.subformData.lines[this.subformData.inToOutMap[component.model]] || 1 })
          }

          if (component.options.valueConfig.type === 3) {
            // 组件依赖时
            // console.log(component.name, component.options.valueConfig.dependItemModel)
            const dependComponent = this.getComponentByModel(component.options.valueConfig.dependItemModel)
            if (dependComponent) {
              await this.computeComponentProperties(dependComponent, operations)
            }
            if (operations.activeOptions && operations.activeOptions[component.options.valueConfig.dependItemModel]) {
              const activeOptions = operations.activeOptions[component.options.valueConfig.dependItemModel]
              if (Array.isArray(activeOptions)) {
                // options为数组，则被依赖的组件不在子表单内
                if (activeOptions[0]) {
                  // 有active项时，取对应的值
                  if (isInSubformComponent) {
                    // 本组件是子表单内组件时
                    for (let j = 0; j < operations.models[component.model].length; j++) {
                      this.$set(operations.models[component.model], 'item' + j, activeOptions[0][component.options.valueConfig.dependItemProperty || 'value'])
                    }
                  } else {
                    // 本组件不是子表单内组件时
                    this.$set(operations.models, component.model, activeOptions[0][component.options.valueConfig.dependItemProperty || 'value'])
                  }
                } else {
                  // 无active项时，清空当前值
                  if (isInSubformComponent) {
                    // 本组件是子表单内组件时
                    for (let j = 0; j < operations.models[component.model].length; j++) {
                      this.$set(operations.models[component.model], 'item' + j, undefined)
                    }
                  } else {
                    // 本组件不是子表单内组件时
                    this.$set(operations.models, component.model, undefined)
                  }
                }
              } else {
                // options不为数组，则被依赖的组件在子表单内
                if (isInSubformComponent) {
                  // 本组件是子表单内组件时
                  for (let j = 0; j < operations.models[component.model].length; j++) {
                    if (activeOptions['item' + j] && activeOptions['item' + j][0]) {
                      this.$set(operations.models[component.model], 'item' + j, activeOptions['item' + j][0][component.options.valueConfig.dependItemProperty || 'value'])
                    } else {
                      this.$set(operations.models[component.model], 'item' + j, undefined)
                    }
                  }
                }
              }
            }
          }

          if (component.options.valueConfig.type === 4) {
            // 数据源时
            await this.waitDataSourceParamComplete(component.options.valueConfig.params, operations)
            if (isInSubformComponent && operations.models[component.model] && operations.models[component.model].length > 0 && this.hasWidgetInSubform(component.options.valueConfig.params, this.linearFormList)) {
              // 有参数组件是子表单内的组件时，代表结果是当前行的
              for (let j = 0; j < operations.models[component.model].length; j++) {
                const value = await this.getDataSource(component.options.valueConfig.dataSourceKey, component.options.valueConfig.params, component.options.valueConfig.dataSourceProperty, j, operations.models)
                this.$set(operations.models[component.model], 'item' + j, value)
              }
            } else {
              // 没有参数是子表单内的组件时，代表结果是整个组件的
              const value = await this.getDataSource(component.options.valueConfig.dataSourceKey, component.options.valueConfig.params, component.options.valueConfig.dataSourceProperty, undefined, operations.models)
              this.$set(operations.models, component.model, value)
            }
          } else if (component.options.valueConfig.type === 2 && component.options.valueConfig.formula && component.options.valueConfig.formula.length > 0) {
            // formula：公式时
            await this.waitDependInformulaComplete(component.options.valueConfig.formula, operations)
            if (isInSubformComponent) {
              const value = operations.models[component.model] || { item0: undefined, length: this.subformData.lines[this.subformData.inToOutMap[component.model]] || 1}
              this.$set(operations.models, component.model, value)
              if (operations.models[component.model] && operations.models[component.model].length > 0) {
                for (let j = 0; j < operations.models[component.model].length; j++) {
                  const formulaRes = await this.computeFormula(component.options.valueConfig.formula, j, undefined, operations)
                  this.$set(operations.models[component.model], 'item' + j, formulaRes)
                }
              } else {
                this.$set(operations.models, component.model, value)
              }
            } else {
              const formulaRes = await this.computeFormula(component.options.valueConfig.formula, undefined, undefined, operations)
              this.$set(operations.models, component.model, formulaRes)
            }
          }

          if (!operations.models[component.model] && isInSubformComponent) {
            this.$set(operations.models, component.model, { length: this.subformData.lines[this.subformData.inToOutMap[component.model]] || 1 })
          }
        }

        // options：有可选项的（radio、checkbox、select、tree、cascader等），计算出可选项
        if (component.options.remote && component.options.remoteDataKey) {
          // 可选项是远程数据源时
          if (component.options.params && component.options.params.length > 0) {
            // 数据源带参数时
            await this.waitDataSourceParamComplete(component.options.params, operations)
            if (this.hasWidgetInSubform(component.options.params, this.linearFormList) && isInSubformComponent) {
              // 有参数是子表单内组件时：得到的结果是本行的本组件的可选项
              if (!Object.keys(operations.options).includes(component.model)) {
                this.$set(operations.options, component.model, {item0: []})
              }
              for (let i = 0; i < operations.models[component.model].length; i++) {
                this.$set(operations.options[component.model], 'item' + i, (await this.getDataSource(component.options.remoteDataKey, component.options.params, undefined, i, operations.models) || []))
              }
            } else {
              // 参数内无子表单内组件时：得到的结果是所有行的本组件的可选项
              this.$set(operations.options, component.model, (await this.getDataSource(component.options.remoteDataKey, component.options.params, undefined, undefined, operations.models) || []))
            }
          } else if (!operations.options[component.model]) {
            // 数据源无参数时：只有初次(也就是可选项为空时)才请求一次
            this.$set(operations.options, component.model, (await this.getDataSource(component.options.remoteDataKey, undefined, undefined, undefined, operations.models) || []))
          }
        } else if (component.options.isSubformData && component.options.subformDataKey) {
          // 可选项是依赖于子表单的组件时
          const dependComponent = this.getComponentByModel(component.options.subformDataKey)
          if (dependComponent) {
            await this.computeComponentProperties(dependComponent, operations)
          }
          const dependValues = operations.models[component.options.subformDataKey]
          const options = []
          if (dependValues && dependValues.length > 0) {
            for (let i = 0; i < dependValues.length; i++) {
              options.push({
                value: dependValues['item' + i],
                label: dependValues['item' + i]
              })
            }
          }
          this.$set(operations.options, component.model, options)
        } else if (Object.keys(component.options).includes('remote') && Object.keys(component.options).includes('options') && !operations.options[component.model]) {
          // 静态可选项时：只需要初始化第一次
          this.$set(operations.options, component.model, component.options.options || [])
        }

        // activeOptions：有可选项的（radio、checkbox、select），根据可选项和值，计算出active options
        updateActiveOptionsAndValue(component, operations, isInSubformComponent)

        // shows:显示与否
        if (!component.options.show) {
          component.options.show = {
            type: 1, // 1手动设置，2公式计算
            value: true,
            expression: '',
            formula: []
          }
        }
        if (
          (
            (component.type === 'customshow' || component.type === 'tips')
          )
          ||
          (
            (
              component.type === 'customoperate'
              ||
              (component.type === 'link' && component.options.stage === 'approve')
            )
          )
        ) {
          res.shows = false
        } else if (component.options.show.type === 1) {
          res.shows = component.options.show.value
        } else if (component.options.show.formula && component.options.show.formula.length > 0) {
          await this.waitDependInformulaComplete(component.options.show.formula, operations)
          res.shows = await this.computeFormula(component.options.show.formula, undefined, undefined, operations)
        }


        // disabled:禁用与否
        if (component.options.disabled) {
          if (component.options.disabled.type === 1) {
            res.disableds = component.options.disabled.value
          } else {
            await this.waitDependInformulaComplete(component.options.disabled.formula, operations)
            res.disableds = await this.computeFormula(component.options.disabled.formula, undefined, undefined, operations)
          }
        }


        // readonlys:只读与否
        if (component.options.valueConfig && component.options.valueConfig.type === 4) {
          // 默认值是数据源的输入项是否可编辑根据配置
          if (!['date', 'time'].includes(component.type)) {
            if (component.options.editable) {
              res.readonlys = false
            } else {
              res.readonlys = true
            }
          }
        } else if (component.options.valueConfig && component.options.valueConfig.type !== 1 && component.options.valueConfig.type !== 3) { // 组件依赖可改
          res.readonlys = true
        } else if (component.options.readonly) {
          if (component.options.readonly.type === 1) {
            res.readonlys = component.options.readonly.value
          } else {
            await this.waitDependInformulaComplete(component.options.readonly.formula, operations)
            res.readonlys = await this.computeFormula(component.options.readonly.formula, undefined, undefined, operations)
          }
        }

        // 标识本组件为本次递归的已计算状态
        component.isComputed = true
        this.$set(operations.displays, component.model, res.displays)
        this.$set(operations.shows, component.model, res.shows)
        this.$set(operations.disableds, component.model, res.disableds)
        this.$set(operations.readonlys, component.model, res.readonlys)
        return operations
      }
      return operations
    },

    /**
     * 遍历数据源参数，找出所有的组件并计算其属性
     * @param params 数据源参数组成的数组
     * @param operations 属性值集合对象
     */
    async waitDataSourceParamComplete (params, operations) {
      let dependModels = {}

      if (params && params.length > 0) {
        params.forEach(p => {
          if (p && p.value && p.value.length > 0) {
            p.value.forEach(m => {
              dependModels[m] = true
            })
          }
        })
      }
      const components = []
      dependModels = Object.keys(dependModels)
      if (dependModels && dependModels.length > 0) {
        dependModels.forEach(model => {
          const component = this.getComponentByModel(model)
          if (component) {
            components.push(component)
          }
        })
      }
      for (let i = 0; i < components.length; i++) {
        await this.computeComponentProperties(components[i], operations)
      }
      return
    },
    /**
     * 遍历公式，找出所有的组件并计算其属性
     * @param formula 公式的各元素组成的数组
     * @param operations 属性值集合对象
     */
    async waitDependInformulaComplete (formula, operations) {
      const dependModels = []
      let params = []
      formula.forEach(item => {
        if (item.type === 'variate') {
          dependModels.push(item.value)
        }
        if (item.type === 'source' && item.params && item.params.length > 0) {
          params = params.concat(item.params)
        }
      })
      await this.waitDataSourceParamComplete(params, operations)
      const components = []
      if (dependModels && dependModels.length > 0) {
        dependModels.forEach(model => {
          const component = this.getComponentByModel(model)
          if (component) {
            components.push(component)
          }
        })
      }
      for (let i = 0; i < components.length; i++) {
        await this.computeComponentProperties(components[i], operations)
      }
      return
    },
    // 根据组件的model获取组件对象
    getComponentByModel (model) {
      return this.listWithContainer.find(item => item.model === model)
    },
    /**
     * 遍历所有控件, 生成操作配置、计算值
     * @param genList 组件列表
     * @param isCreated
     */
    async initOperations (genList,isCreated = false) {
      let operations = {
        displays: {...this.operationsData.displays},
        shows: {...this.operationsData.shows},
        disableds: {...this.operationsData.disableds},
        readonlys: {...this.operationsData.readonlys},
        options: {...this.operationsData.options},
        activeOptions: {...this.operationsData.activeOptions},
        models: { ...(this.models || {})}
      }
      let groupByDependent = []
      let getChildren = (node, k) => {
        groupByDependent.push(node)
        if (node.children) {
          node.children.forEach(item => {
            item.dependentCount = k + 1;
            getChildren(item, item.dependentCount)
          })
        }
      }
      //将依赖组件进行扁平处理
      this.classifyComponent(genList).forEach((item) => {
        item.dependentCount = 0;
        getChildren(item, item.dependentCount)
      })
      let modelToDep = new Map();
      groupByDependent.forEach(item => {
        if (modelToDep.get(item.model) !== undefined) {
          if (modelToDep.get(item.model) < item.dependentCount) {
            modelToDep.set(item.model,{
              count:item.dependentCount,
              model :item
            });
          }
        }else{
          modelToDep.set(item.model,{
            count:item.dependentCount,
            model :item
          });
        }

      })
      // const urlParams = new URLSearchParams(window.location.search);
      if (isCreated) {
        console.log("batch init")
        let groupByDependentFilter = []
        for (let [, value] of modelToDep.entries()) {
          if (groupByDependentFilter[value.count] === undefined) {
            groupByDependentFilter[value.count] = []
          }
          groupByDependentFilter[value.count].push(value.model)
        }
        console.log('groupByDependentFilter',JSON.parse(JSON.stringify(groupByDependentFilter)))

        let computeComponent = async (g) => {
          for (let i = 0; i < g.length; i++) {
            await this.computeComponentProperties(g[i], operations, false)
            if ((g[i].type === 'grid' || g[i].type === 'subform') && g[i].columns && g[i].columns.length > 0) {
              for (let k = 0; k < g[i].columns.length; k++) {
                if (g[i].columns[k] && g[i].columns[k].list && g[i].columns[k].list.length > 0) {
                  for (let j = 0; j < g[i].columns[k].list.length; j++) {
                    await this.computeComponentProperties(g[i].columns[k].list[j], operations, g[i].type === 'subform', operations.displays[g[i].model])
                  }
                }
              }
            }
          }
        }
        let allTask = [];
        groupByDependentFilter.forEach(async (g) => {
          let group = []
          g.forEach(item => {
            group.push(computeComponent([item]));
          })
          allTask.push(group)
        })
        console.log(' allTask ', allTask)
        for (let index = 0; index < allTask.length; index++) {
          await Promise.all(allTask[index])
        }
      }else{
        for (let i = 0; i < genList.length; i++) {
          await this.computeComponentProperties(genList[i], operations, false)
          if ((genList[i].type === 'grid' || genList[i].type === 'subform') && genList[i].columns && genList[i].columns.length > 0) {
            for (let k = 0; k < genList[i].columns.length; k++) {
              if (genList[i].columns[k] && genList[i].columns[k].list && genList[i].columns[k].list.length > 0) {
                for (let j = 0; j < genList[i].columns[k].list.length; j++) {
                  await this.computeComponentProperties(genList[i].columns[k].list[j], operations, genList[i].type === 'subform', operations.displays[genList[i].model])
                }
              }
            }
          }
        }
      }



      operations = {
        displays: {
          ...this.operationsData.displays,
          ...operations.displays
        },
        shows: {
          ...this.operationsData.shows,
          ...operations.shows
        },
        disableds: {
          ...this.operationsData.disableds,
          ...operations.disableds
        },
        readonlys: {
          ...this.operationsData.readonlys,
          ...operations.readonlys
        },
        options: {
          ...this.operationsData.options,
          ...operations.options
        },
        activeOptions: {
          ...this.operationsData.activeOptions,
          ...operations.activeOptions
        },
        models: { ...this.models, ...operations.models}
      }

      // update extends displays
      Object.keys(operations.displays).forEach(model => {
        if (!this.formExtends[model + 'Extends']) {
          this.$set(this.formExtends, model + 'Extends', { config: { display: operations.displays[model] } })
        } else if (!this.formExtends[model + 'Extends'].config) {
          this.$set(this.formExtends[model + 'Extends'], 'config', { display: operations.displays[model] })
        } else {
          this.$set(this.formExtends[model + 'Extends'].config, 'display', operations.displays[model])
        }
      })
      // update extends shows
      Object.keys(operations.shows).forEach(model => {
        if (!this.formExtends[model + 'Extends']) {
          this.$set(this.formExtends, model + 'Extends', { config: { show: operations.shows[model] } })
        } else if (!this.formExtends[model + 'Extends'].config) {
          this.$set(this.formExtends[model + 'Extends'], 'config', { show: operations.shows[model] })
        } else {
          this.$set(this.formExtends[model + 'Extends'].config, 'show', operations.shows[model])
        }
      })
      // update extends activeOptions
      Object.keys(operations.activeOptions).forEach(model => {
        if (!this.formExtends[model + 'Extends']) {
          this.$set(this.formExtends, model + 'Extends', { options: operations.activeOptions[model] })
        } else {
          this.$set(this.formExtends[model + 'Extends'], 'options', operations.activeOptions[model] )
        }
      })
      return operations
    },
    // 组件值更新，触发重新计算
    async updateComponentValue ({model, data, subFormKey, activeOptions, filterKey}) {
      let subformLineIndex
      if (subFormKey !== '') {
        subformLineIndex = subFormKey
        subFormKey = 'item' + subFormKey
      }
      const promise = new Promise(async resolve => {
        const index = this.computeQueue.length
        await this.computeQueue[index - 1]
        if (promise === this.promiseMap[model]) {
          // 本组件不需要重新计算。清除依赖于本组件的组件的计算状态
          this.dependTree[model].forEach(k => {
            this.clearComputeStatusByModel(k)
          })
          // 赋值 + 验证
          if (subFormKey) {
            this.$set(this.models[model], subFormKey, data)
            this.$set(this.operationsData.models[model], subFormKey, data)
            // 验证
            this.validateField(model + '.' + subFormKey)
          } else {
            this.$set(this.models, model, data)
            this.$set(this.operationsData.models, model, data)
            // 验证
            this.validateField(model)
          }
          const component = this.getComponentByModel(model)
          // 有filterKey时，表示可选项远程搜索：需要更新可选项、更新值
          if (filterKey !== undefined) {
            if (this.hasWidgetInSubform(component.options.params, this.linearFormList) && component.inSubform) {
              // 自身是子表单内组件且有参数是子表单内组件时：得到的结果是本行的本组件的可选项
              this.$set(this.operationsData.options[component.model], subFormKey, (await this.getDataSource(component.options.remoteDataKey, component.options.params, undefined, subformLineIndex, this.models, filterKey) || []))
            } else {
              let res = (await this.getDataSource(component.options.remoteDataKey, component.options.params, undefined, undefined, this.models, filterKey) || []);
              if (component?.options?.multiple === true && component?.options?.remote === true && component?.options?.remoteFilter === true
                && component?.options?.filterable === true && component?.type === "select"
              ) {
                if (this.remoteSearchSelectOptions[component.model] === undefined) {
                  this.remoteSearchSelectOptions[component.model] = []
                }
                for (let i = 0; i < res.length; i++) {
                  this.remoteSearchSelectOptions[component.model].push(res[i])
                }
                console.log(this.remoteSearchSelectOptions,"this.remoteSearchSelectOptions")
              }
              this.$set(this.operationsData.options, component.model, res)
            }
          }
          const updateActiveType = activeOptions && ['tree', 'cascader'].includes(component.type)
          // 更新activeOptions
          if (!updateActiveType) {
            // tree和cascader之外的组件：（select、radio、checkbox）需计算并更新activeOptions
            const res =  updateActiveOptionsAndValue(component, this.operationsData, component.inSubform,this.remoteSearchSelectOptions[component.model] ?? [])
            if (!this.operationsData.activeOptions) {
              this.$set(this.operationsData, 'activeOptions', { model: res })
            } else {
              this.$set(this.operationsData.activeOptions, model, res)
            }
            if (!this.formExtends[model + 'Extends']) {
              this.$set(this.formExtends, model + 'Extends', { options: activeOptions })
            } else {
              this.$set(this.formExtends[model + 'Extends'], 'options', activeOptions)
            }
          } else {
            // tree和cascader：使用传入的参数直接更新activeOptions
            if (subFormKey) {
              if (!this.operationsData.activeOptions) {
                this.$set(this.operationsData, 'activeOptions', { model: { [subFormKey]: activeOptions } })
              } else if (!this.operationsData.activeOptions[model]) {
                this.$set(this.operationsData.activeOptions, model, { [subFormKey]: activeOptions })
              } else {
                this.$set(this.operationsData.activeOptions[model], subFormKey, activeOptions)
              }
              if (!this.formExtends[model + 'Extends']) {
                this.$set(this.formExtends, model + 'Extends', { options: { [subFormKey]: activeOptions } })
              } else if (!this.formExtends[model + 'Extends'].options) {
                this.$set(this.formExtends[model + 'Extends'], 'options', { [subFormKey]: activeOptions })
              } else {
                this.$set(this.formExtends[model + 'Extends'].options, subFormKey, activeOptions)
              }
            } else {
              if (!this.operationsData.activeOptions) {
                this.$set(this.operationsData, 'activeOptions', { model: activeOptions })
              } else {
                this.$set(this.operationsData.activeOptions, model, activeOptions)
              }
              if (!this.formExtends[model + 'Extends']) {
                this.$set(this.formExtends, model + 'Extends', { options: activeOptions })
              } else {
                this.$set(this.formExtends[model + 'Extends'], 'options', activeOptions)
              }
            }
          }

          this.$nextTick(async () => {
            if (promise === this.promiseMap[model]) {
              // console.log(model, data, 666666)
              this.operationsData = await this.initOperations(this.formData.list)
              this.$nextTick(() => {
                if (promise === this.promiseMap[model]) {
                  this.$set(this.$data, 'models', this.operationsData.models)
                  this.$nextTick(() => {
                    resolve()
                  })
                } else {
                  resolve()
                }
              })
            } else {
              resolve()
            }
          })
        } else {
          resolve()
        }
      })
      this.promiseMap[model] = promise
      this.computeQueue.push(promise)
    },
    // 清除组件的已计算状态：会递归清除所有直接、间接依赖本组件的组件的计算状态
    clearComputeStatusByModel (model) {
      this.componentsMap[model].isComputed = false
      this.dependTree[model].forEach(k => {
        this.clearComputeStatusByModel(k)
      })
    },
    // 子表单增加一行
    async addItem (subform) {
      await this.computeQueue[this.computeQueue.length - 1]
      for (let i = 0; i < subform.columns.length; i++) {
        for (let j = 0; j < subform.columns[i].list.length; j++) {
          // 新增一条记录
          const model = subform.columns[i].list[j].model
          if (this.operations.displays[model]) {
            // console.log(subform.columns[i].list[j].name, model, this.models[model], 999)
            this.models[model].length += 1
            // 清除组件的计算状态，以便重新计算
            subform.columns[i].list[j].isComputed = false
            this.dependTree[model].forEach(k => {
              this.clearComputeStatusByModel(k)
            })
          }
        }
      }
      this.operationsData = await this.initOperations(this.formData.list)
      this.$nextTick(() => {
        this.$set(this.$data, 'models', this.operationsData.models)
      })
    },
    // 子表单删除一行
    async deleteItem (subform, index) {
      await this.computeQueue[this.computeQueue.length - 1]
      for (let i = 0; i < subform.columns.length; i++) {
        for (let j = 0; j < subform.columns[i].list.length; j++) {
          const model = subform.columns[i].list[j].model
          // 不使用的组件则不操作
          if (!this.operationsData.displays[model]) {
            continue
          }
          const value = this.models[model]
          const oldLength = value ? value.length : 0
          // 有值且使用的组件才操作
          if (value && this.operations.displays[subform.columns[i].list[j].model]) {
            for (let k = index; k < oldLength - 1; k++) {
              value['item' + k] = value['item' + (k + 1)]
            }
            value.length -= 1
            delete value['item' + value.length]
          }
          // 更新有扩展options快照的组件的快照内容
          if (this.formExtends[model + 'Extends'] && this.formExtends[model + 'Extends'].options) {
            const options = this.formExtends[model + 'Extends'].options
            for (let k = index; k < oldLength - 1; k++) {
              options['item' + k] = options['item' + (k + 1)]
            }
            delete options['item' + (oldLength - 1)]
            this.$set(this.formExtends[model + 'Extends'], 'options', options)
          }
          // 清除组件的计算状态，以便重新计算
          subform.columns[i].list[j].isComputed = false
          this.dependTree[subform.columns[i].list[j].model].forEach(k => {
            this.clearComputeStatusByModel(k)
          })
        }
      }
      this.operationsData = await this.initOperations(this.formData.list)
      this.$nextTick(() => {
        this.$set(this.$data, 'models', this.operationsData.models)
      })
    },
    // 判断是否有参数是子表单内的组件
    hasWidgetInSubform (params, linearFormList) {
      let res = false
      if (params && Array.isArray(params)) {
        for (let i = 0; i < params.length; i++) {
          if (isInSubform(params[i].value[0], linearFormList)) {
            res = true
            break
          }
        }
      }
      return res
    },
    // 获取表单数据：不验证（供用户保存草稿使用）
    getDataWithoutValidate () {
      return new Promise((resolve) => {
        resolve(this.models)
      })
    },
    // 验证并获取表单数据
    getData () {
      return new Promise((resolve, reject) => {
        if (this.loading > 0) {
          reject(new Error(this.$t('message.common.validate.loadingData')).message)
        }
        this.$refs.generateForm.validate((valid, invalid) => {
          if (valid) {
            resolve(this.models)
          } else {
            const errMsg = invalid[Object.keys(invalid)[0]][0].message
            reject(new Error(errMsg).message)
          }
        })
      })
    },
    // 验证指定字段
    validateField (param) {
      this.$refs.generateForm.validateField(param)
    }
  }
}
</script>
<style scoped lang="stylus">
.body-header-department {
  color: rgb(157 163 171);
  font-size: 0.8rem;
  background: white;
  line-height: 1.2rem;
  margin-bottom 15px
}
.el-form-item
  margin-bottom 1.1rem !important
.sub-form
  >>>.el-form-item
    margin-bottom 10px !important
  >>>.el-form-item__content
    padding 0
.el-form--label-left, .el-form--label-right
  .block-sub-form
    >>>.el-form-item
      margin-bottom 22px !important
.compound-box
  margin 1rem 0
.add-btn
  position absolute
  bottom -3rem
  right 0
.sub-form-title
  border-left 1px solid #ddd
  min-height 40px
  background-color #eee
  .subform-titles
    margin 1rem 0
  .subform-first-title
    border 1px solid #ddd
    border-bottom 0
    border-left 0
.sub-form:first-child
    border-top 1px dashed #dcdfe6cc !important
.sub-form
  position relative
  border 1px solid #EFEFF1
  border-top 0 !important
  border-right 0
  min-height 53px
  margin-top 0
  .delete-subform-item
    position absolute
    top 50%
    transform translate(0, -50%)
    right -2rem
    font-size 1.25rem
    color red
  &>.el-col
    display flex
    align-items center
    border-right 1px solid #EFEFF1
    .el-form-item
      >>>.el-form-item__label
        display block
        color rgba(14,31,53,0.45) !important
        border-bottom 1px solid #EFEFF1
    .subform-titles
      border-top 1px solid #EFEFF1
      border-bottom 1px solid #EFEFF1
      margin-top 0
      padding-top 1rem
      padding-bottom 1rem
    .inline-sub-form
      >>>.el-form-item__label
        display none
  &~.sub-form
    border-top 0
    margin-top -1rem
  &:first-child
    >.el-col
      padding-top 0
      .subform-titles
        margin-top -1px
.last-sub-form
  margin-bottom 50px

.el-form-item-instruction
  >>> .el-form-item__content {
    margin-bottom -0.9rem
  }
  >>>.el-form-item__label
    font-size 16px
    color rgba(14,31,53,0.85)
    border-left 3px solid #3657FF
    padding-left .3rem!important
    margin-bottom 0rem
    line-height 1.0rem !important
.block-sub-formCol
  >>>.subform-titles
    font-size 14px
.block-sub-form
  flex-wrap wrap
@media screen and (max-width: 1300px)
  .grid-box
    display block
    &::after
      content ''
      clear both
      height 0
      display block
    .el-col
      width 100%
@media screen and (max-width: 768px)
  .el-form-item
    margin-bottom 1.0rem !important
  .generate-form
    .el-form-item
      >>>.el-select
      >>>.el-date-editor
        width 100%
  .grid-box
    display block
    &::after
      content ''
      clear both
      height 0
      display block
    .el-col
      width 100%
  .block-sub-formCol
    min-width 100% !important
</style>

<style>
body {
  font-family : "PingFangSC-Regular","SF Pro SC","SF Pro Text","SF Pro Icons","PingFang SC","Helvetica Neue","Helvetica","Arial",sans-serif !important
}


.generate-form .el-form-item__label {
  margin-bottom: 0;
  padding-top: 12px;
  line-height: 14px!important;
}

.generate-form .el-form-item__label::before{
  content: "";
  color: #fff0;
  margin-right: 0px;
}

.generate-form .el-form-item__label::after{
  content: ":";
  color: rgba(14,31,53,0.45);
}
.el-form-item-instruction .el-form-item__label::after{
  content: "";
}

.generate-form .el-form-item__content {
  line-height: 14px!important;
}
.el-form-item__error{
  padding-top: 3px;
}

.el-switch__core {
  top: 0.5rem;
}
.el-upload .el-button {
  padding: .6rem !important;
}
.el-radio-group,.el-checkbox-group {
  padding-top: .6rem;
}

@media screen and (max-width: 768px) {
  .generate-form .el-form-item__label {
    margin-bottom: 0.2rem!important;
    line-height: 1.5!important;
    padding: 0 !important;
  }
  .el-form-item__error {
    position: static !important;
  }
  .el-switch__core {
    top: 0 !important;
  }
}

.el-scrollbar>.el-scrollbar__bar {
  opacity: 1 !important;
}
</style>
