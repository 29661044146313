<template>
  <div v-loading="loading">
    <p style="margin-bottom: 1rem">
      {{ tipsContent1 }}<strong>"{{ tipsContent2 }}"</strong>{{ tipsContent3 }}
    </p>
    <el-form
      ref="form"
      :model="formData"
      :rules="rules"
      @submit.native.prevent="submitForm"
    >
      <el-form-item prop="res">
        <el-input
          ref="codeInput"
          v-model="formData.res"
          :placeholder="inputPlaceholder"
        >
          <el-button
            slot="append"
            :class="[{ 'is-disabled': loading || time >= 0 }]"
            :disabled="loading || time >= 0"
            type="button"
            @click="sendCode"
          >
            <span>
              <template v-if="time >= 0"> {{ time }}{{ secondsLater }}</template>{{ sendAgain }}
            </span>
          </el-button>
        </el-input>
      </el-form-item>
      <p
        :class="tipsClass"
        style="height: 72px;line-height: 24px;"
      >
        {{ tips }}
      </p>
    </el-form>
    <span style="display: none">
      <!-- <span> -->
      {{ update }}
    </span>
  </div>
</template>

<script>
import i18n from '../../i18n'


// code的验证函数
const codeValidate = (rule, value, callback) => {
  if (!/^\d{6}$/.test(value)) {
    callback(new Error(rule.message))
  } else {
    callback()
  }
}

export default {
  props: {
    resolve: {
      type: Function,
      reqiure: true,
      default: () => () =>{}
    },
    setApp: {
      type: Function,
      reqiure: true,
      default: () => () =>{}
    },
    param: {
      type: Object,
      reqiure: true,
      default: () => {}
    },
    update: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      formData: {
        res: '',
      },
      requestId: sessionStorage.getItem('requestId') || '',
      tips: '',
      tipsClass: '',
      canSubmit: true,
      intervalObj: undefined,
      time: -1,
      loading: false,
      rules: {
        res: [
          {
            validator: codeValidate,
            message: i18n.t('message.common.code.wrongFormat'),
            trigger: 'blur',
          },
        ],
      },
      tipsContent1: i18n.t('message.common.code.tipsContent1'),
      tipsContent2: (this.param.scopeAlias || []).join(),
      tipsContent3: i18n.t('message.common.code.tipsContent3'),
      secondsLater: i18n.t('message.common.code.secondsLater'),
      sendAgain: i18n.t('message.common.code.sendAgain'),
      inputPlaceholder: i18n.t('message.common.code.placeholder')
    }
  },
  watch: {
    update () {
      this.setApp(this)
      this.initData()
      this.sendCode()
    }
  },
  created() {
    this.setApp(this)
    this.sendCode(true)
  },
  methods: {
    submitForm () {
      const btn = document.querySelector('.code-sure-btn')
      btn && btn.click()
    },
    initData () {
      this.formData.res = ''
      this.requestId = ''
      sessionStorage.removeItem('requestId')
      this.tips = ''
      this.tipsClass = ''
      this.canSubmit = true
      if (this.intervalObj) {
        clearInterval(this.intervalObj)
      }
      this.intervalObj = undefined
      this.time = -1
      this.loading = false

      this.$refs.form.resetFields()
    },
    // 首次发送验证码
    async sendCode (isInit) {
      if (this.loading || this.time >= 0) {
        return
      }
      try {
        // ajax发送验证码
        this.loading = true
        const res = await this.$http.get('/verify-code/demand-verify-code', {
          params: this.param,
        })
        this.loading = false
        this.$refs.codeInput && this.$refs.codeInput.focus()
        if (res.data.code === 0) {
          if (res.data.data.success) {
            this.formData.res = ''
            this.requestId = res.data.data.requestId
            sessionStorage.setItem('requestId', this.requestId)
            this.tips = i18n.t('message.common.code.sentCode')
            this.tipsClass = 'text-success'
            this.canSubmit = true
          } else {
            this.tips = i18n.t('message.common.code.frequent')
            this.tipsClass = 'text-danger'
            this.canSubmit = isInit || false
          }
          this.time = res.data.data.ttl || 10
          this.interval()
        } else {
          this.tips = res.data.message
          this.tipsClass = 'text-danger'
          this.canSubmit = isInit || false
        }
      } catch (e) {
        console.error(e)
        this.tips = i18n.t('message.common.code.wrongSend')
        this.tipsClass = 'text-danger'
        this.canSubmit = isInit || false
      }
    },
    // 验证code，并提交
    validateFun(done) {
      if (!this.canSubmit || this.loading || !this.requestId) {
        return
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          // ajax提交验证码
          this.postCode(
            {
              verify_code: this.formData.res,
              request_id: this.requestId,
              route_name: this.param.routeName,
              force: true,
            },
            done
          )
        }
      })
    },
    // ajax提交短信验证码
    async postCode (params, done) {
      this.tips = ''
      this.tipsClass = ''
      this.loading = true
      try {
        const submitRes = await this.$http.post('/verify-code/save-verify-code', params)
        this.loading = false
        if (submitRes.data.code === 0) {
          if (submitRes.data.data.code === 0) {
            sessionStorage.removeItem('requestId')
            done()
            this.resolve({
              type: 'success',
              message: i18n.t('message.common.code.successSubmit')
            })
          } else if (submitRes.data.data.code === 1) {
            this.tips = i18n.t('message.common.code.wrongCode')
            this.tipsClass = 'text-danger'
          } else if (submitRes.data.data.code === 2) {
            sessionStorage.removeItem('requestId')
            this.tips = i18n.t('message.common.code.wrongTooManyTimes')
            this.tipsClass = 'text-danger'
            this.canSubmit = false
          } else if (submitRes.data.data.code === 3) {
            sessionStorage.removeItem('requestId')
            this.tips = i18n.t('message.common.code.overdueCode')
            this.tipsClass = 'text-danger'
            this.canSubmit = false
          }
        } else {
          done()
          this.resolve({
            type: 'error',
            message: i18n.t('message.common.code.wrongSubmit')
          })
        }
      } catch {
        this.loading = false
        done()
        this.resolve({
          type: 'error',
          message: i18n.t('message.common.code.wrongSubmit')
        })
      }
    },
    // 开启重发倒计时：倒计时完成自动关闭
    interval() {
      if (this.intervalObj) {
        clearInterval(this.intervalObj)
        this.intervalObj = undefined
      }
      this.intervalObj = setInterval(() => {
        if (this.time < 0) {
          clearInterval(this.intervalObj)
          this.intervalObj = undefined
          this.time = -1
        } else {
          this.time -= 1
        }
      }, 1000)
    }
  },
}
</script>

<style lang="stylus" scoped>
// >>>.el-form-item__error
//   display none
</style>
