<template>
  <div
    v-if="show"
    style="position: relative"
  >
    <el-form
      v-if="data.immutable"
      label-position="top"
    >
      <el-form-item
        v-if="data.type !== 'grid'"
        label="标题"
      >
        <el-input
          v-model="data.name"
          readonly
        />
      </el-form-item>

      <el-form-item label="数据绑定Key">
        <el-input
          v-model="data.model"
          readonly
        />
      </el-form-item>
    </el-form>

    <el-form
      v-else
      label-position="top"
      style="position: relative;z-index: 0;"
    >
      <el-form-item
        v-if="data.type !== 'grid'"
        label="标题"
      >
        <el-input
          v-model="data.name"
          @change="nameChange"
        />
      </el-form-item>

      <template v-if="!isCompoundFormItem || data.type === 'grid' || data.type === 'subform'">
        <el-form-item label="数据绑定Key">
          <el-input
            ref="modelInput"
            v-model="newModel"
            @blur="validateModel"
          />
        </el-form-item>
      </template>
      <el-form-item
        v-if="Object.keys(data.options).indexOf('buttonText')>=0"
        label="链接名称"
      >
        <el-input v-model="data.options.buttonText" />
      </el-form-item>

      <el-form-item
        v-if="Object.keys(data.options).indexOf('placeholder')>=0 && (['input', 'textarea', 'select', 'date', 'cascader'].includes(data.type) || (data.type === 'time' && !data.options.isRange))"
        label="占位内容"
      >
        <el-input v-model="data.options.placeholder" />
      </el-form-item>

      <el-form-item
        v-if="Object.keys(data.options).indexOf('prefix')>=0"
        label="前缀"
      >
        <el-input v-model="data.options.prefix" />
      </el-form-item>
      <el-form-item
        v-if="Object.keys(data.options).indexOf('suffix')>=0"
        label="后缀"
      >
        <el-input v-model="data.options.suffix" />
      </el-form-item>
      <el-form-item
        v-if="Object.keys(data.options).indexOf('inline')>=0"
        label="布局方式"
      >
        <el-radio-group v-model="data.options.inline">
          <el-radio-button :label="false">
            块级
          </el-radio-button>
          <el-radio-button :label="true">
            行内
          </el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        v-if="Object.keys(data.options).indexOf('showInput')>=0"
        label="显示输入框"
      >
        <el-switch v-model="data.options.showInput" />
      </el-form-item>
      <el-form-item
        v-if="Object.keys(data.options).indexOf('min')>=0"
        label="最小值"
      >
        <el-input-number
          v-model="data.options.min"
          :min="0"
          :max="100"
          :step="1"
        />
      </el-form-item>
      <el-form-item
        v-if="Object.keys(data.options).indexOf('max')>=0"
        label="最大值"
      >
        <el-input-number
          v-model="data.options.max"
          :min="0"
          :max="100"
          :step="1"
        />
      </el-form-item>
      <el-form-item
        v-if="Object.keys(data.options).indexOf('step')>=0"
        label="步长"
      >
        <el-input-number
          v-model="data.options.step"
          :min="0"
          :max="100"
          :step="1"
        />
      </el-form-item>
      <el-form-item
        v-if="data.type=='select'"
        label="是否多选"
      >
        <el-switch
          v-model="data.options.multiple"
          @change="handleSelectMuliple"
        />
      </el-form-item>
      <el-form-item
        v-if="data.type=='select'"
        label="是否可搜索"
      >
        <el-switch
          v-model="data.options.filterable"
        />
      </el-form-item>
      <el-form-item
        v-if="data.type=='select' && data.options.remote"
        label="是否远程搜索"
      >
        <el-switch
          v-model="data.options.remoteFilter"
        />
      </el-form-item>
      <el-form-item
        v-if="Object.keys(data.options).indexOf('allowHalf')>=0"
        label="允许半选"
      >
        <el-switch
          v-model="data.options.allowHalf"
        />
      </el-form-item>
      <el-form-item
        v-if="Object.keys(data.options).indexOf('showAlpha')>=0"
        label="支持透明度选择"
      >
        <el-switch
          v-model="data.options.showAlpha"
        />
      </el-form-item>
      <!-- <el-form-item
        v-if="Object.keys(data.options).indexOf('showLabel')>=0"
        label="是否显示标签"
      >
        <el-switch
          v-model="data.options.showLabel"
        />
      </el-form-item> -->
      <el-form-item
        v-if="Object.keys(data.options).indexOf('options')>=0"
        label="可选项"
      >
        <el-button-group
          v-model="data.options.remote"
          style="margin-bottom:10px;"
        >
          <el-button
            v-if="data.type !== 'tree' && data.type !== 'cascader'"
            :type="(data.options.remote === false && !data.options.isSubformData) ? 'primary' : ''"
            @click="data.options.remote = false;$set(data.options, 'isSubformData', false)"
          >
            静态数据
          </el-button>
          <el-button
            v-if="!isTemplate"
            :type="(data.options.remote === true && !data.options.isSubformData) ? 'primary' : ''"
            @click="data.options.remote = true;$set(data.options, 'isSubformData', false)"
          >
            数据源
          </el-button>
          <el-button
            v-if="data.type !== 'tree' && data.type !== 'cascader'"
            :type="data.options.isSubformData === true ? 'primary' : ''"
            @click="$set(data.options, 'isSubformData', true);data.options.remote = false"
          >
            子表单
          </el-button>
        </el-button-group>

        <template v-if="data.options.remote">
          <div>
            <p>数据源</p>
            <function-select-and-config
              :functions="dataSources"
              :type="[availableDataSources]"
              :fields="linearFormList"
              :config="[{ params: data.options.params, name: data.options.remoteDataKey }]"
              data-source-mode
              radio-or-checkout="radio"
              @change="optionsDataSourceChange"
            />
            <template v-if="data.type !== 'tree' && data.type !== 'cascader'">
              <p v-if="data.options.remoteDataKey">
                展示字段
              </p>
              <el-select
                v-if="data.options.remoteDataKey"
                v-model="data.options.showKey"
                clearable
              >
                <el-option
                  v-for="(property, index) in (dataSources[data.options.remoteDataKey] ? dataSources[data.options.remoteDataKey].attribute : {})"
                  :key="index"
                  :label="property"
                  :value="index"
                />
              </el-select>
              <p v-if="data.options.remoteDataKey">
                提交字段
              </p>
              <el-select
                v-if="data.options.remoteDataKey"
                v-model="data.options.submitKey"
                clearable
              >
                <el-option
                  v-for="(property, index) in (dataSources[data.options.remoteDataKey] ? dataSources[data.options.remoteDataKey].attribute : {})"
                  :key="index"
                  :label="property"
                  :value="index"
                />
              </el-select>
              <p v-if="data.options.remoteDataKey">
                选项禁用关联属性
              </p>
              <el-select
                v-if="data.options.remoteDataKey"
                v-model="data.options.disableKey"
                clearable
              >
                <el-option
                  v-for="(property, index) in (dataSources[data.options.remoteDataKey] ? dataSources[data.options.remoteDataKey].attribute : {})"
                  :key="index"
                  :label="property"
                  :value="index"
                />
              </el-select>
            </template>
            <template v-if="data.type === 'cascader'">
              <p v-if="data.options.remoteDataKey && !data.options.singleElection">
                多选时各项分隔符
              </p>
              <el-input
                v-if="data.options.remoteDataKey && !data.options.singleElection"
                v-model="data.options.itemSeparator"
              />
              <p v-if="data.options.remoteDataKey">
                各级分隔符
              </p>
              <el-input
                v-if="data.options.remoteDataKey"
                v-model="data.options.separator"
              />
              <p v-if="data.options.remoteDataKey">
                节点展示字段
              </p>
              <el-input
                v-if="data.options.remoteDataKey"
                v-model="data.options.props.label"
              />
              <p v-if="data.options.remoteDataKey">
                节点提交字段
              </p>
              <el-input
                v-if="data.options.remoteDataKey"
                v-model="data.options.props.value"
              />
              <p v-if="data.options.remoteDataKey">
                节点禁用关联属性
              </p>
              <el-input
                v-if="data.options.remoteDataKey"
                v-model="data.options.props.disabled"
              />
            </template>
            <!-- <el-input
              v-model="data.options.remoteFunc"
              size="mini"
              style=""
            >
              <template slot="prepend">
                远端方法
              </template>
            </el-input>
            <el-input
              v-model="data.options.props.value"
              size="mini"
              style=""
            >
              <template slot="prepend">
                值
              </template>
            </el-input>
            <el-input
              v-model="data.options.props.label"
              size="mini"
              style=""
            >
              <template slot="prepend">
                标签
              </template>
            </el-input> -->
          </div>
        </template>
        <template v-else-if="!data.options.isSubformData">
          <template v-if="data.type=='radio' || (data.type=='select'&&!data.options.multiple)">
            <el-radio-group v-model="data.options.defaultValue">
              <draggable
                element="ul"
                :list="data.options.options"
                :options="{group:{ name:'options'}, ghostClass: 'ghost',handle: '.drag-item'}"
              >
                <li
                  v-for="(item, index) in data.options.options"
                  :key="index"
                  style="margin-bottom: 5px"
                >
                  <el-radio
                    :label="item.value"
                    style="margin-right: 5px;"
                  >
                    <el-input
                      v-model="item.value"
                      :style="{'width': data.options.showLabel ? '90px': '190px', marginRight: '10px' }"
                      size="mini"
                    />
                    <el-input
                      v-if="data.options.showLabel"
                      v-model="item.label"
                      style="width:100px;"
                      size="mini"
                    />
                    <!-- <input v-model="item.value"/> -->
                  </el-radio>
                  <i
                    class="drag-item"
                    style="font-size: 16px;margin: 0 5px;cursor: move;"
                  >
                    <icon name="bars" />
                  </i>
                  <el-button
                    circle
                    plain
                    type="danger"
                    size="mini"
                    icon="el-icon-minus"
                    style="padding: 4px;margin-left: 5px;"
                    @click="handleOptionsRemove(index)"
                  />
                </li>
              </draggable>
            </el-radio-group>
          </template>

          <template v-if="data.type=='checkbox' || (data.type=='select' && data.options.multiple)">
            <el-checkbox-group v-model="data.options.defaultValue">
              <draggable
                element="ul"
                :list="data.options.options"
                :options="{group:{ name:'options'}, ghostClass: 'ghost',handle: '.drag-item'}"
              >
                <li
                  v-for="(item, index) in data.options.options"
                  :key="index"
                  style="margin-bottom: 5px"
                >
                  <el-checkbox
                    :label="item.value"
                    style="margin-right: 5px;"
                  >
                    <el-input
                      v-model="item.value"
                      :style="{'width': data.options.showLabel ? '90px': '190px', marginRight: '10px' }"
                      size="mini"
                    />
                    <el-input
                      v-if="data.options.showLabel"
                      v-model="item.label"
                      style="width:100px;"
                      size="mini"
                    />
                  </el-checkbox>
                  <i
                    class="drag-item"
                    style="font-size: 16px;margin: 0 5px;cursor: move;"
                  >
                    <icon name="bars" />
                  </i>
                  <el-button
                    circle
                    plain
                    type="danger"
                    size="mini"
                    icon="el-icon-minus"
                    style="padding: 4px;margin-left: 5px;"
                    @click="handleOptionsRemove(index)"
                  />
                </li>
              </draggable>
            </el-checkbox-group>
          </template>
          <div style="margin-left: 22px;">
            <el-button
              type="text"
              @click="handleAddOption"
            >
              添加选项
            </el-button>
          </div>
        </template>
        <template v-else>
          <p>
            子表单组件
          </p>
          <el-select
            v-model="data.options.subformDataKey"
          >
            <el-option
              v-for="(widget, index) in linearSubformList"
              :key="index"
              :label="widget.name"
              :value="widget.model"
            />
          </el-select>
        </template>
      </el-form-item>

      <!-- 下拉可选项是数据源时：默认选择第一项配置 -->
      <el-form-item
        v-if="(data.type=='select' || data.type=='radio') && data.options.remote && !data.options.multiple"
        label="默认选中第一项"
      >
        <el-switch
          v-model="data.options.defaultFirst"
        />
      </el-form-item>

      <template v-if="data.type=='customshow'">
        <el-form-item label="内容形式">
          <el-radio-group v-model="data.options.contentType">
            <el-radio :label="1">
              HTML内容
            </el-radio>
            <el-radio :label="2">
              iframe页面
            </el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item
          v-if="data.type=='customshow' && data.options.contentType === 2"
          label="iframe高度"
        >
          <el-input v-model.number="data.options.iframeHeight">
            <template slot="append">
              px
            </template>
          </el-input>
        </el-form-item>
      </template>

      <el-form-item
        v-if="Object.keys(data.options).indexOf('defaultValue')>=0 && data.type !== 'imgupload' && data.type !== 'fileupload'"
        :label="data.type === 'download' ? '下载地址' :
          (data.type === 'table' ? '表格数据' :
            (
              ['link', 'customdata', 'customoperate'].includes(data.type) ? '链接地址' :
              (data.type === 'customshow' ?
                (data.options.contentType === 2 ? 'iframe链接' : '展示内容')
                :
                (data.type === 'tips' ? '提示内容' : '默认值')
              )
            )
          )"
      >
        <el-button-group class="set-type-group">
          <el-button
            v-if="data.type === 'textarea' || data.type === 'instruction' || data.type === 'input' || data.type === 'download' || data.type === 'customoperate' || data.type === 'customshow' || data.type === 'link' || data.type === 'customdata' || data.type === 'tips' || data.type === 'rate' || data.type === 'color' || data.type === 'switch' || data.type=='time'"
            :type="data.options.valueConfig.type === 1 ? 'primary' : ''"
            @click="setType('valueConfig', 1)"
          >
            静态值
          </el-button>
          <el-button
            v-if="(data.type === 'customshow' && data.options.contentType === 2) || (data.type !== 'customshow' && data.type !== 'checkbox' && data.type !== 'table' && ((data.type === 'select' && data.options.multiple) ? false : true))"
            :type="data.options.valueConfig.type === 2 ? 'primary' : ''"
            @click="setType('valueConfig', 2)"
          >
            公式
          </el-button>
          <el-button
            v-if="(data.type === 'customshow' && data.options.contentType === 2) || (data.type !== 'customshow' && data.type !== 'checkbox' && data.type !== 'table' && ((data.type === 'select' && data.options.multiple) ? false : true))"
            :type="data.options.valueConfig.type === 3 ? 'primary' : ''"
            @click="setType('valueConfig', 3)"
          >
            组件依赖
          </el-button>
          <el-button
            v-if="!isTemplate"
            :type="data.options.valueConfig.type === 4 ? 'primary' : ''"
            @click="setType('valueConfig', 4)"
          >
            数据源
          </el-button>
        </el-button-group>
        <!-- 手动设置默认值 -->
        <div v-if="data.options.valueConfig.type === 1">
          <el-input
            v-if="data.type=='textarea' || data.type=='instruction' || (data.type=='customshow' && data.options.contentType !== 2)"
            v-model="data.options.defaultValue"
            type="textarea"
            :rows="5"
          />
          <el-input
            v-if="data.type=='input' || data.type=='download' || (data.type=='customshow' && data.options.contentType === 2) || data.type=='link' || data.type=='customdata' || data.type === 'customoperate' || data.type === 'tips'"
            v-model="data.options.defaultValue"
          />
          <el-rate
            v-if="data.type == 'rate'"
            v-model="data.options.defaultValue"
            style="display:inline-block;vertical-align: middle;"
            :max="data.options.max"
            :allow-half="data.options.allowHalf"
          />
          <el-button
            v-if="data.type == 'rate'"
            type="text"
            style="display:inline-block;vertical-align: middle;margin-left: 10px;"
            @click="data.options.defaultValue=0"
          >
            清空
          </el-button>
          <el-color-picker
            v-if="data.type == 'color'"
            v-model="data.options.defaultValue"
            :show-alpha="data.options.showAlpha"
          />
          <el-switch
            v-if="data.type=='switch'"
            v-model="data.options.defaultValue"
          />
          <el-form-item
            v-if="data.type=='time' && Object.keys(data.options).indexOf('isRange')>=0"
            label="默认值"
          >
            <el-time-picker
              v-if="!data.options.isRange"
              key="1"
              v-model="data.options.defaultValue"
              style="width: 100%;"
              :arrow-control="data.options.arrowControl"
              :value-format="data.options.format"
            />
            <el-time-picker
              v-if="data.options.isRange"
              key="2"
              v-model="data.options.defaultValue"
              style="width: 100%;"
              is-range
              :arrow-control="data.options.arrowControl"
              :value-format="data.options.format"
            />
          </el-form-item>
        </div>
        <!-- 公式展示 -->
        <div
          v-else-if="data.options.valueConfig.type === 2"
          class="expression-text"
        >
          <div
            v-if="expressionShow(data.options.valueConfig.formula)"
            class="expression-title"
          >
            <span>公式内容：</span>
            <el-button
              icon="el-icon-close"
              type="danger"
              circle
              size="mini"
              title="删除公式"
              @click="clearFormula('valueConfig')"
            />
          </div>
          {{ expressionShow(data.options.valueConfig.formula) }}
        </div>
        <!-- 依据可选项的属性 -->
        <div v-else-if="data.options.valueConfig.type === 3">
          <el-select
            v-model="data.options.valueConfig.dependWidgetKey"
            clearable
            placeholder="依赖的组件"
            @change="initDepend"
          >
            <el-option
              v-for="(item, index) in getPropertyList"
              :key="index"
              :value="item.key"
              :label="item.name"
            />
          </el-select>
          <p
            v-if="dependWidget"
            class="sub-title"
          >
            选项属性
          </p>
          <el-select
            v-if="dependWidget"
            v-model="data.options.valueConfig.dependItemProperty"
            placeholder="选项属性"
          >
            <el-option
              v-for="(item, index) in (dependWidget.options.remote ? (dataSources[dependWidget.options.remoteDataKey] ? dataSources[dependWidget.options.remoteDataKey].attribute : {}) : (dependWidget.options.options ? dependWidget.options.options[0] : {value: undefined}))"
              :key="index"
              :label="dependWidget.options.remote ? item : index"
              :value="index"
            />
          </el-select>
        </div>
        <!-- 依据基础数据源 -->
        <div v-else-if="data.options.valueConfig.type === 4">
          <function-select-and-config
            :functions="dataSources"
            :type="data.type === 'table' ? ['table'] : ['object', 'basic']"
            :fields="linearFormList"
            :config="[{ params: data.options.valueConfig.params, name: data.options.valueConfig.dataSourceKey }]"
            data-source-mode
            radio-or-checkout="radio"
            @change="valueDataSourceChange"
          />
          <p
            v-if="data.options.valueConfig.dataSourceKey && (dataSources[data.options.valueConfig.dataSourceKey] ? dataSources[data.options.valueConfig.dataSourceKey].type === 'object' : false)"
            class="sub-title"
          >
            数据属性
          </p>
          <el-select
            v-if="data.options.valueConfig.dataSourceKey && dataSources[data.options.valueConfig.dataSourceKey] ? dataSources[data.options.valueConfig.dataSourceKey].type === 'object' : false"
            v-model="data.options.valueConfig.dataSourceProperty"
            placeholder="数据属性"
          >
            <el-option
              v-for="(property, index) in (dataSources[data.options.valueConfig.dataSourceKey] ? dataSources[data.options.valueConfig.dataSourceKey].attribute : {})"
              :key="index"
              :label="property"
              :value="index"
            />
          </el-select>
          <div
            v-if="data.options.valueConfig.dataSourceKey && dataSources[data.options.valueConfig.dataSourceKey] ? dataSources[data.options.valueConfig.dataSourceKey].type === 'object' : false"
          >
            <div style="color:#999">
              远程数据Key
            </div>
            <el-input
              v-model="data.options.valueConfig.dataSourceProperty"
              placeholder="远程数据Key"
            />
          </div>

          <template v-if="['input', 'textarea', 'time', 'date'].includes(data.type)">
            <div style="color:#999">
              值是否可编辑
            </div>
            <el-switch
              v-model="data.options.editable"
            />
          </template>
        </div>

        <el-tooltip
          v-if="data.type === 'tree'"
          placement="top"
        >
          <div slot="content">
            提交时会将所有勾选的节点值依次组织成一维数组提交
          </div>
          <el-button
            icon="el-icon-question"
            type="text"
          />
        </el-tooltip>
      </el-form-item>

      <template v-if="data.type == 'time' || data.type == 'date'">
        <el-form-item
          v-if="data.type == 'time'"
          label="时间模式"
        >
          <el-radio-group v-model="data.options.isRange">
            <el-radio :label="false">
              单个时间
            </el-radio>
            <el-radio :label="true">
              时间范围
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          v-if="data.type == 'date'"
          label="提交值"
        >
          <el-radio-group v-model="data.options.timestamp">
            <el-radio :label="false">
              字符串
            </el-radio>
            <el-radio :label="true">
              时间戳
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          v-if="data.type == 'date'"
          label="不可选日期"
        >
          <span style="color: #666">大于&emsp;</span>
          <el-select
            v-model="data.options.greaterThan"
            clearable
            placeholder="大于条件依赖组件"
          >
            <el-option
              v-for="item in getDateList"
              :key="item.key"
              :label="item.name"
              :value="item.model"
            />
          </el-select>
          <p style="margin: 0;color: #666">
            或
          </p>
          <span style="color: #666">等于&emsp;</span>
          <el-select
            v-model="data.options.equal"
            clearable
            placeholder="等于条件依赖组件"
          >
            <el-option
              v-for="item in getDateList"
              :key="item.key"
              :label="item.name"
              :value="item.model"
            />
          </el-select>
          <p style="margin: 0;color: #666">
            或
          </p>
          <span style="color: #666">小于&emsp;</span>
          <el-select
            v-model="data.options.lessThan"
            clearable
            placeholder="小于条件依赖组件"
          >
            <el-option
              v-for="item in getDateList"
              :key="item.key"
              :label="item.name"
              :value="item.model"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="(data.options.isRange) || data.options.type=='datetimerange' || data.options.type=='daterange'"
          label="开始时间占位内容"
        >
          <el-input v-model="data.options.startPlaceholder" />
        </el-form-item>
        <el-form-item
          v-if="data.options.isRange || data.options.type=='datetimerange' || data.options.type=='daterange'"
          label="结束时间占位内容"
        >
          <el-input v-model="data.options.endPlaceholder" />
        </el-form-item>
        <el-form-item
          v-if="data.type=='date'"
          label="格式"
        >
          <el-select v-model="data.options.format">
            <el-option value="yyyy" />
            <el-option value="yyyy-MM" />
            <el-option value="yyyy-MM-dd" />
            <el-option value="yyyy-MM-dd HH:mm:ss" />
          </el-select>
        </el-form-item>
      </template>

      <template v-if="data.type=='link' || data.type=='customoperate' || data.type=='customdata'">
        <el-form-item label="打开方式">
          <el-radio-group v-model="data.options.showType">
            <el-radio :label="1">
              弹窗
            </el-radio>
            <el-radio :label="2">
              新开页面
            </el-radio>
          </el-radio-group>
        </el-form-item>
      </template>

      <template v-if="data.type=='imgupload'">
        <el-form-item label="最大上传数">
          <el-input
            v-model.number="data.options.limit"
            type="number"
          />
        </el-form-item>
      </template>

      <template v-if="data.type=='fileupload'">
        <el-form-item label="最大上传数">
          <el-input
            v-model.number="data.options.limit"
            type="number"
          />
        </el-form-item>
        <el-form-item label="Excel文件在审批时是否直接展示">
          <el-switch
            v-model="data.options.showFile"
            style="margin-right: 1rem"
          />
          <el-tooltip placement="top">
            <div slot="content">
              设置为是时，若文件是Excel，则在审批阶段会把表格内容直接展示在页面内组件处。
            </div>
            <el-button
              icon="el-icon-question"
              type="text"
            />
          </el-tooltip>
        </el-form-item>
      </template>

      <template v-if="data.type=='tree' || data.type === 'cascader'">
        <el-form-item
          v-if="data.type=='tree'"
          label="父子节点关联设置"
        >
          <el-radio-group v-model="data.options.checkStrictly">
            <el-radio :label="false">
              关联
              <el-tooltip placement="top">
                <div slot="content">
                  若关联：子节点全部勾选则父节点会自动勾选，勾选某个节点则它的后代节点全部自动勾选
                </div>
                <el-button
                  icon="el-icon-question"
                  type="text"
                />
              </el-tooltip>
            </el-radio>
            <el-radio :label="true">
              不关联
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          v-if="data.type=='cascader'"
          label="是否可选择任意一级"
        >
          <el-switch
            v-model="data.options.checkStrictly"
            style="margin-right: 1rem"
          />
          <el-tooltip placement="top">
            <div slot="content">
              设置为否时，只能选最后一级
            </div>
            <el-button
              icon="el-icon-question"
              type="text"
            />
          </el-tooltip>
        </el-form-item>
        <el-form-item
          v-if="data.options.checkStrictly == true || data.type === 'cascader'"
          label="是否单选"
        >
          <el-switch
            v-model="data.options.singleElection"
          />
        </el-form-item>
        <el-form-item
          v-if="data.type === 'tree'"
          label="是否可搜索"
        >
          <el-switch
            v-model="data.options.filterable"
          />
        </el-form-item>
      </template>

      <template v-if="data.type=='blank'">
        <el-form-item label="绑定数据类型">
          <el-select v-model="data.options.defaultType">
            <el-option
              value="String"
              label="字符"
            />
            <el-option
              value="Object"
              label="对象"
            />
            <el-option
              value="Array"
              label="数组"
            />
          </el-select>
        </el-form-item>
      </template>

      <template v-if="data.type == 'grid' || data.type == 'subform'">
        <el-form-item
          v-if="data.type == 'grid'"
          label="栅格间隔"
        >
          <el-input
            v-model.number="data.options.gutter"
            type="number"
          >
            <template slot="append">
              px
            </template>
          </el-input>
        </el-form-item>
        <el-form-item label="列数与列宽比例">
          <draggable
            element="ul"
            :list="data.columns"
            :options="{group:{ name:'options'}, ghostClass: 'ghost',handle: '.drag-item'}"
          >
            <li
              v-for="(item, index) in data.columns"
              :key="index"
            >
              <i
                class="drag-item"
                style="font-size: 16px;margin: 0 5px;cursor: move;"
              ><icon name="bars" /></i>
              <el-input
                v-model.number="item.span"
                placeholder="栅格值"
                size="mini"
                style="width: 100px;"
                type="number"
              />

              <el-button
                circle
                plain
                type="danger"
                size="mini"
                icon="el-icon-minus"
                style="padding: 4px;margin-left: 5px;"
                @click="handleOptionsRemove(index)"
              />
            </li>
          </draggable>
          <div style="margin-left: 22px;">
            <el-button
              type="text"
              @click="handleAddColumn"
            >
              添加列
            </el-button>
          </div>
        </el-form-item>
        <el-form-item
          v-if="data.type == 'subform'"
          label="子表单宽度"
        >
          <el-input
            v-model="data.options.subformWidth"
            placeholder="大于100%的宽度时才会生效"
          >
            <template slot="append">
              px
            </template>
          </el-input>
        </el-form-item>
        <el-form-item label="水平排列方式">
          <el-select v-model="data.options.justify">
            <el-option
              value="start"
              label="左对齐"
            />
            <el-option
              value="end"
              label="右对齐"
            />
            <el-option
              value="center"
              label="居中"
            />
            <el-option
              value="space-around"
              label="两侧间隔相等"
            />
            <el-option
              value="space-between"
              label="两端对齐"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="data.type == 'grid'"
          label="垂直排列方式"
        >
          <el-select v-model="data.options.align">
            <el-option
              value="top"
              label="顶部对齐"
            />
            <el-option
              value="middle"
              label="居中"
            />
            <el-option
              value="bottom"
              label="底部对齐"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="data.type=='subform'"
          label="新增按钮的文案"
        >
          <el-input
            v-model="data.options.btnText"
            placeholder="新增按钮的文案"
          />
        </el-form-item>
        <el-form-item
          v-if="data.type=='subform'"
          label="是否可增删"
        >
          <el-switch
            v-model="data.options.addAndDelete"
          />
        </el-form-item>
        <el-form-item
          v-if="data.type=='subform'"
          label="申请时块显示"
        >
          <el-switch
            v-model="data.options.blockDisplay"
          />
        </el-form-item>
        <el-form-item
          v-if="data.type=='subform'"
          label="审批时块显示"
        >
          <el-switch
            v-model="data.options.detailBlockDisplay"
          />
        </el-form-item>
      </template>

      <!-- 交互属性设置 -->
      <el-form-item
        v-if="data.type === 'tips' || data.type === 'link'"
        :label="data.type === 'tips' ? '提示阶段' : '链接使用阶段'"
      >
        <el-radio-group v-model="data.options.stage">
          <el-radio label="apply">
            申请时
          </el-radio>
          <el-radio label="approve">
            审批时
          </el-radio>
          <el-radio
            label="all"
          >
            申请和审批时
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <!-- 使用与否 -->
      <el-form-item label="是否使用本控件">
        <el-button-group class="set-type-group">
          <el-button
            :type="data.options.display.type === 1 ? 'primary' : ''"
            @click="setType('display', 1)"
          >
            手动设置
          </el-button>
          <el-button
            :type="data.options.display.type === 2 ? 'primary' : ''"
            @click="setType('display', 2)"
          >
            公式
          </el-button>
        </el-button-group>
        <div v-if="data.options.display.type === 1">
          <el-radio-group v-model="data.options.display.value">
            <el-radio :label="true">
              使用
            </el-radio>
            <el-radio :label="false">
              不使用
            </el-radio>
          </el-radio-group>
        </div>
        <!-- 公式展示 -->
        <div
          v-else-if="data.options.display.type === 2"
          class="expression-text"
        >
          <div
            v-if="expressionShow(data.options.display.formula)"
            class="expression-title"
          >
            <span>公式内容：</span>
            <el-button
              icon="el-icon-close"
              type="danger"
              circle
              size="mini"
              title="删除公式"
              @click="clearFormula('display')"
            />
          </div>
          {{ expressionShow(data.options.display.formula) }}
        </div>
      </el-form-item>
      <!-- 显示与否 -->
      <el-form-item label="是否显示本控件">
        <el-button-group class="set-type-group">
          <el-button
            :type="data.options.show.type === 1 ? 'primary' : ''"
            @click="setType('show', 1)"
          >
            手动设置
          </el-button>
          <el-button
            :type="data.options.show.type === 2 ? 'primary' : ''"
            @click="setType('show', 2)"
          >
            公式
          </el-button>
        </el-button-group>
        <div v-if="data.options.show.type === 1">
          <el-radio-group v-model="data.options.show.value">
            <el-radio :label="true">
              显示
            </el-radio>
            <el-radio :label="false">
              不显示
            </el-radio>
          </el-radio-group>
        </div>
        <!-- 公式展示 -->
        <div
          v-else-if="data.options.show.type === 2"
          class="expression-text"
        >
          <div
            v-if="expressionShow(data.options.show.formula)"
            class="expression-title"
          >
            <span>公式内容：</span>
            <el-button
              icon="el-icon-close"
              type="danger"
              circle
              size="mini"
              title="删除公式"
              @click="clearFormula('show')"
            />
          </div>
          {{ expressionShow(data.options.show.formula) }}
        </div>
      </el-form-item>
      <template v-if="data.options.cardShow">
        <el-form-item label="是否在卡片上显示本控件">
          <div v-if="data.options.cardShow.type === 1">
            <el-radio-group v-model="data.options.cardShow.value">
              <el-radio :label="true">
                显示
              </el-radio>
              <el-radio :label="false">
                不显示
              </el-radio>
            </el-radio-group>
          </div>
        </el-form-item>
      </template>

      <!-- 是否禁用 -->
      <template v-if="!isCompoundFormItem">
        <el-form-item
          v-if="Object.keys(data.options).indexOf('disabled') >= 0 && !['download', 'instruction'].includes(data.type)"
          label="是否禁用"
        >
          <el-button-group class="set-type-group">
            <el-button
              :type="data.options.disabled.type === 1 ? 'primary' : ''"
              @click="setType('disabled', 1)"
            >
              手动设置
            </el-button>
            <el-button
              :type="data.options.disabled.type === 2 ? 'primary' : ''"
              @click="setType('disabled', 2)"
            >
              公式
            </el-button>
          </el-button-group>
          <div v-if="data.options.disabled.type === 1">
            <el-checkbox v-model="data.options.disabled.value">
              禁用
            </el-checkbox>
          </div>
          <!-- 公式展示 -->
          <div
            v-else-if="data.options.disabled.type === 2"
            class="expression-text"
          >
            <div
              v-if="expressionShow(data.options.disabled.formula)"
              class="expression-title"
            >
              <span>公式内容：</span>
              <el-button
                icon="el-icon-close"
                type="danger"
                circle
                size="mini"
                title="删除公式"
                @click="clearFormula('disabled')"
              />
            </div>
            {{ expressionShow(data.options.disabled.formula) }}
          </div>
        </el-form-item>
        <template v-if="!isCompoundFormItem && data.type !== 'download' && data.type !== 'link' && data.type !== 'customdata'" />
        <el-form-item
          v-if="Object.keys(data.options).indexOf('readonly')>=0"
          label="只读设置"
        >
          <el-button-group class="set-type-group">
            <el-button
              :type="data.options.readonly.type === 1 ? 'primary' : ''"
              @click="setType('readonly', 1)"
            >
              手动设置
            </el-button>
            <el-button
              :type="data.options.readonly.type === 2 ? 'primary' : ''"
              @click="setType('readonly', 2)"
            >
              公式
            </el-button>
          </el-button-group>
          <div v-if="data.options.readonly.type === 1">
            <el-checkbox v-model="data.options.readonly.value">
              只读
            </el-checkbox>
          </div>
          <!-- 公式展示 -->
          <div
            v-else-if="data.options.readonly.type === 2"
            class="expression-text"
          >
            <div
              v-if="expressionShow(data.options.readonly.formula)"
              class="expression-title"
            >
              <span>公式内容：</span>
              <el-button
                icon="el-icon-close"
                type="danger"
                circle
                size="mini"
                title="删除公式"
                @click="clearFormula('readonly')"
              />
            </div>
            {{ expressionShow(data.options.readonly.formula) }}
          </div>
        </el-form-item>
        <el-form-item
          v-if="Object.keys(data.options).indexOf('editable') >= 0 && (data.type === 'date' || data.type === 'time')"
          label="是否可手动输入"
        >
          <el-switch v-model="data.options.editable" />
        </el-form-item>
        <el-form-item
          v-if="Object.keys(data.options).indexOf('clearable')>=0"
          label="是否显示清除按钮"
        >
          <el-switch v-model="data.options.clearable" />
        </el-form-item>
        <el-form-item
          v-if="Object.keys(data.options).indexOf('arrowControl')>=0"
          label="时间选择方式"
        >
          <el-radio-group v-model="data.options.arrowControl">
            <el-radio :label="true">
              箭头
            </el-radio>
            <el-radio :label="false">
              滚动
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <template v-if="data.type=='instruction'">
          <el-form-item label="是否设置为标题">
            <el-switch
              v-model="data.options.isLabel"
            />
          </el-form-item>
        </template>
        <template
          v-if="!['download', 'instruction', 'table', 'link', 'customdata', 'customoperate', 'customshow', 'tips'].includes(data.type)"
        >
          <el-form-item
            label="校验"
          >
            <div>
              <el-checkbox v-model="data.options.required">
                必填
              </el-checkbox>
            </div>
            <el-select
              v-if="Object.keys(data.options).indexOf('dataType')>=0"
              v-model="data.options.dataType"
              placeholder="选择数据类型"
              clearable
              style="width:100%;margin-bottom:10px"
            >
              <el-option
                value="string"
                label="字符串"
              />
              <el-option
                value="number"
                label="数字"
              />
              <el-option
                value="boolean"
                label="布尔值"
              />
              <!-- <el-option
                value="integer"
                label="整数"
              />
              <el-option
                value="float"
                label="浮点数"
              /> -->
              <el-option
                value="url"
                label="URL地址"
              />
              <el-option
                value="email"
                label="邮箱地址"
              />
              <!-- <el-option
                value="hex"
                label="十六进制"
              /> -->
            </el-select>
            <div
              v-if="Object.keys(data.options).indexOf('pattern')>=0"
              style="width:100%;margin-bottom:10px"
            >
              <el-input
                v-model.lazy="data.options.pattern"
                placeholder="填写正则表达式，如：/^\d*$/"
              />
            </div>
            <div
              v-if="Object.keys(data.options).indexOf('verify')>=0"
              style="width:100%;display: table;"
            >
              <p style="margin-bottom: 0;color: #606266;">
                公式校验
              </p>
              <el-input
                v-model.lazy="data.options.verify.message"
                style=" width: 220px;margin-right:9px"
                placeholder="公式校验错误提示"
              />
              <el-button
                type="primary"
                icon="el-icon-edit"
                circle
                @click="enableExpressionEditor('verify')"
              />
              <div class="expression-text">
                <div
                  v-if="expressionShow(data.options.verify.formula)"
                  class="expression-title"
                >
                  <span>公式内容：</span>
                  <el-button
                    icon="el-icon-close"
                    type="danger"
                    circle
                    title="删除公式"
                    @click="clearFormula('verify')"
                  />
                </div>
                {{ expressionShow(data.options.verify.formula) }}
              </div>
            </div>
            <!-- <el-select
              v-if="Object.keys(data.options).indexOf('remoteId')>=0 || showRemoteValidate(data.type)"
              v-model="data.options.remoteId"
              size="mini"
              clearable
              placeholder="远程校验"
            >
              <el-option
                v-for="(item, index) in remoteFunctions"
                :key="index"
                :label="item.name"
                :value="index"
              >
                <span style="float: left">{{ item.name }}</span>
              </el-option>
            </el-select> -->
          </el-form-item>
        </template>
        <el-form-item
          v-if="data.type==='input'||data.type==='textarea'||data.type==='number'||data.type==='select'||data.type==='radio'||data.type==='checkbox'||data.type==='time'||data.type==='date'||data.type==='switch'"
          label="加密"
        >
          <el-checkbox
            v-model="isEncrypt"
            style="margin-right:10px"
            @change="isEncryptChange"
          />
          <el-select
            v-model="data.options.encipherment"
            :disabled="!isEncrypt"
            style="width:245px"
          >
            <el-option
              v-for="(v,k) in encrypts"
              :key="k"
              :value="k"
              :label="v"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="Object.keys(data.options).indexOf('sumformat')>=0"
          label="展示格式化"
        >
          <el-select
            v-model="data.options.sumformat"
            clearable
            style="width:100%"
          >
            <el-option
              value="formattingSum"
              label="金额格式化"
            />
          </el-select>
        </el-form-item>
      </template>
    </el-form>

    <el-dialog
      v-if="expressionEditorVisible"
      :visible.sync="expressionEditorVisible"
      :close-on-press-escape="false"
      custom-class="expression-dialog"
    >
      <expression-editor
        ref="expression"
        :source-available="!isTemplate"
        :function-available="true"
        :remote-function-available="true"
        :form-list="formList"
        :field-options="(expressionEditorProperty === 'verify' ? linearFormList : computeList)"
        :remote-functions="remoteFunctions"
        :subform-single-list="(expressionEditorProperty === 'verify' || expressionEditorProperty === 'valueConfig') && data.inSubform"
        :old-data="JSON.parse(JSON.stringify(activeFormula))"
        @submit-data="getExpression"
      />
      <div slot="footer">
        <el-button
          type="primary"
          @click="getExpression"
        >
          确定
        </el-button>
        <el-button
          @click="expressionEditorVisible = false"
        >
          取消
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Draggable from 'vuedraggable'
import ExpressionEditor from './ExpressionEditor'
import { getLinearFormList } from '../util/index'
import FunctionSelectAndConfig from './FunctionSelectAndConfig'
import { basicComponents, layoutComponents, advanceComponents } from './componentsConfig.js'

export default {
  name: 'WidgetConfig', // 表单组件的属性、特性等设计器
  components: {
    Draggable,
    ExpressionEditor,
    FunctionSelectAndConfig
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    formList: {
      type: Array,
      default: () => []
    },
    isTemplate: { // 是否是流程模板设计模式
      type: Boolean,
      default: false
    },
    remoteFunctions: { // 远程函数列表
      type: Object,
      default: () => {}
    },
    dataSources: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      newModel: this.data.model,
      oldData: null,
      expressionEditorVisible: false,
      expressionEditorProperty: '',
      validator: {
        type: null,
        required: null,
        pattern: null,
        range: null,
        length: null
      },
      switchOption: [
        {
          key: '开',
          value: true
        },
        {
          key: '关',
          value: false
        }
      ],
      remoteFunctionsLocal: this.remoteFunctions,
      isEncrypt: !!this.data.options.encipherment,
      encrypts: {},
      activeFormula: [], // 正在编辑的公式
      // function: {
      //   sub_task_call_back: {
      //     class: 'Kuainiu\OA\Workflow\Contracts\Pub\SubTaskCallBackContract',
      //     intro: '流程审批的子任务回调',
      //     method: 'callback',
      //     name: '远程校验1',
      //     params: {
      //       ext_params: '返回其它参数，array，非必传',
      //       remark: '账号必须是什么。。',
      //       status: '状态，int，0 => 成功； -1=> 失败，必传',
      //       task_id: '子任务id，int，必传',
      //       user_id: '处理人id，int，非必传'
      //     }
      //   },
      //   sub_task_call_back1: {
      //     class: 'Kuainiu\OA\Workflow\Contracts\Pub\SubTaskCallBackContract',
      //     intro: '流程审批的子任务回调',
      //     method: 'callback',
      //     name: '远程校验2',
      //     params: {
      //       ext_params: '返回其它参数，array，非必传',
      //       remark: '账号必须是什么。。',
      //       status: '状态，int，0 => 成功； -1=> 失败，必传',
      //       task_id: '子任务id，int，必传',
      //       user_id: '处理人id，int，非必传'
      //     }
      //   }
      // }
      // dependWidget: {}
    }
  },
  computed: {
    // 是否展示本组件内容
    show () {
      if (this.data && Object.keys(this.data).length > 0) {
        return true
      }
      return false
    },
    // 是否是布局组件：栅格布局、子表单
    isCompoundFormItem () {
      return (this.data.type === 'grid' || this.data.type === 'subform')
    },
    // 属性关联依赖的组件
    dependWidget () {
      return this.data.options.valueConfig ? this.linearFormList.filter(item => item.key === this.data.options.valueConfig.dependWidgetKey)[0] : null
    },
    // 线性一维控件数组
    linearFormList () {
      return getLinearFormList(this.formList)
    },
    // 线性一维子表单内控件数组
    linearSubformList () {
      return getLinearFormList(this.formList).filter(item => item.inSubform)
    },
    // 除去不能传入计算器的控件，剩下的一维线性控件数组
    computeList () {
      return this.linearFormList.filter(item => {
        return this.isDependable(item)
      })
    },
    // 属性关联可被依赖项数组
    getPropertyList () {
      return this.linearFormList.filter(item => {
        if (!this.data.inSubform && item.inSubform) {
          // 非子表单内的组件，不能依赖子表单内的组件
          return false
        }
        let type = item.type
        return (type === 'radio' || (type === 'select' && !item.options.multiple)) && this.isDependable(item)
      })
    },
    // 日期关联可被依赖项数组
    getDateList () {
      return this.linearFormList.filter(item => {
        if (!this.data.inSubform && item.inSubform) {
          // 非子表单内的组件，不能依赖子表单内的组件
          return false
        }
        return this.isDependable(item)
      })
    },
    // table、tree、radio、checkbox、select等的可选项：可绑定的远端数据源：的类型
    availableDataSources () {
      let dataSourceType = ''
      switch (this.data.type) {
        case 'tree':
          dataSourceType = 'tree'
          break
        case 'cascader':
          dataSourceType = 'tree'
          break
        case 'select':
          dataSourceType = 'array'
          break
        case 'checkbox':
          dataSourceType = 'array'
          break
        case 'radio':
          dataSourceType = 'array'
          break
        case 'table':
          dataSourceType = 'table'
          break
        default:
          dataSourceType = 'object'
          break
      }
      return dataSourceType
    },
  },
  watch: {
    'data.options.isRange': function (val) {
      if (typeof val !== 'undefined') {
        if (val) {
          this.data.options.defaultValue = null
        } else {
          if (Object.keys(this.data.options).indexOf('defaultValue') >= 0) { this.data.options.defaultValue = '' }
        }
      }
    },
    'data.options.required': function (val) {
      if (val) {
        this.validator.required = { required: true, message: `必须填写` }
      } else {
        this.validator.required = null
      }

      this.$nextTick(() => {
        this.generateRule()
      })
    },
    'data.options.dataType': function (val) {
      if (!this.show) {
        return false
      }

      if (val) {
        this.validator.type = { type: val, message: '格式不正确' }
      } else {
        this.validator.type = null
      }

      this.generateRule()
    },
    'dependWidget': function (val) {
      if (this.data && this.data.options && this.data.options.valueConfig) {
        if (val) {
          this.data.options.valueConfig.dependItemModel = val.model
        } else {
          this.data.options.valueConfig.dependItemModel = ''
        }
      }
    },
    'data.options.encipherment': function(val) {
      this.isEncrypt = !!val
    },
    'data.name': function(val, old) {
      if (this.oldData === this.data) {
        this.$emit('field-change', {
          val: {
            name: val,
            model: this.data.model
          },
          old: {
            name: old,
            model: this.data.model
          }
        })
      }
    },
    'data.model': function(val, old) {
      if (this.oldData === this.data) {
        this.$emit('field-change', {
          val: {
            model: val,
            name: this.data.name
          },
          old: {
            model: old,
            name: this.data.name
          }
        })
      }
    },
    data: {
      // deep: true,
      handler () {
        this.oldData = this.data
        this.newModel = this.data.model
        if (!this.data.model) {
          this.initModel()
        }
      }
    }
  },
  async created () {
    this.newModel = this.data.model
    if (!this.data.model) {
      this.initModel()
    }
    this.oldData = this.oldData || this.data
    if (this.data && this.data.dependWidgetKey) {
      this.initDepend(this.data.dependWidgetKey, true)
    }
    this.$http.get('/crypt/keys?tags=workflow').then(res => {
      this.encrypts = res.data.data
    })
    // 套件设计时：本地remoteFunctions为空，需请求
    if (!this.remoteFunctionsLocal) {
      // 获取租户的远程函数列表、审批的同步调用列表、任务列表！
      const res = await this.$http.get('/workflow/contract-config/index')
      if (res.data.code === 0) {
        this.remoteFunctionsLocal = res.data.data.function || {} // 远程函数列表
      }
    }
  },
  methods: {
    // 修改组件名时：未手动修改过model的组件自动更新组件的mode
    nameChange () {
      if (this.data && this.data.options && Object.keys(this.data.options).includes('hasModifyModel') && !this.data.options.hasModifyModel) {
        this.initModel()
      }
    },
    // 组件model为空、或者需要随标题变化时，自动生成model
    initModel () {
      this.newModel = this.data.name
      let error = this.validateDetail()
      while (error) {
        this.newModel = this.data.name + parseInt(Math.random().toFixed(3) * 1000)
        error = this.validateDetail()
      }
      this.data.model = this.newModel
    },
    // 组件model修改输入框失去焦点时：验证model的合法性
    validateModel () {
      // 值未修改，不验证
      if (this.data.model === this.newModel) {
        return
      }
      const error = this.validateDetail()
      if (error) {
        this.$alert(error, '错误', {
          type: 'error',
          callback: () => {
            this.$refs.modelInput.focus()
          }
        })
        return error
      }
      this.data.model = this.newModel
      this.$set(this.data.options, 'hasModifyModel', true)
    },
    // 验证组件model合法性的具体方法
    validateDetail () {
      let error = ''
      if (!this.newModel) {
        error = '组件的数据绑定Key必填！'
        // this.$message.error({ message: '组件的数据绑定Key必填！', duration: 10000, showClose: true })
        // return
      } else if (!this.newModel.trim()) {
        error = '组件的数据绑定Key不能全是空格！'
      } else {
        if (this.formList && this.formList.length > 0) {
          for (let i = 0; i < this.formList.length; i++) {
            if (this.formList[i] !== this.data && this.formList[i].model === this.newModel) {
              error = `"${this.newModel}"已被其他组件设置为数据绑定Key！`
              break
            }
            if ((this.formList[i].type === 'grid' || this.formList[i].type === 'subform') && this.formList[i].columns && this.formList[i].columns.length > 0) {
              for (let col = 0; col < this.formList[i].columns.length; col++) {
                if (this.formList[i].columns[col] && this.formList[i].columns[col].list && this.formList[i].columns[col].list.length > 0) {
                  for (let c = 0; c < this.formList[i].columns[col].list.length; c++) {
                    if (this.formList[i].columns[col].list[c] !== this.data && this.formList[i].columns[col].list[c].model === this.newModel) {
                      error = `"${this.newModel}"已被其他组件设置为数据绑定Key！`
                      break
                    }
                  }
                }
                if (error) {
                  break
                }
              }
            }
          }
        }
      }
      return error
    },
    // 判断是否需要远程验证
    showRemoteValidate (type) {
      const arr = basicComponents.filter(item => Object.keys(item.options).indexOf('remoteId') >= 0).map(item => item.type)
      return arr.includes(type)
    },
    // 默认值绑定的数据源变化时
    valueDataSourceChange (data) {
      if (data.length > 0) {
        this.data.options.valueConfig.dataSourceKey = data[0].name
        this.data.options.valueConfig.params = data[0].params
        this.clearProperty()
      }
    },
    // 可选项绑定的数据源变化时
    optionsDataSourceChange (data) {
      if (data.length > 0) {
        this.data.options.remoteDataKey = data[0].name
        this.data.options.params = data[0].params
      }
    },
    // 数据源变化时，清除值所绑定的数据源的key
    clearProperty () {
      this.data.options.valueConfig.dataSourceProperty = ''
    },
    // 清空公式
    clearFormula (key) {
      this.data.options[key].formula = []
      this.data.options[key].expression = ''
    },
    // 计算某个组件能否被依赖
    isDependable (item) {
      let status = true
      if (item.key === this.data.key) {
        // 本组件不能依赖自己
        status = false
      } else {
        if (item.options.valueConfig.type === 2) { // 可选组件默认值是公式计算时，若依赖了本组件，则本组件不能依赖它，否则循环引用
          for (let i = 0; i < item.options.valueConfig.formula.length; i++) {
            if (item.options.valueConfig.formula[i].type === 'variate' && item.options.valueConfig.formula[i].value === this.data.model) {
              status = false
            }
            if (item.options.valueConfig.formula[i].type === 'source' && item.options.valueConfig.formula[i].params && this.hasDependForParam(item.options.valueConfig.formula[i].params, this.data)) {
              status = false
            }
          }
        } else if (item.options.valueConfig.type === 3) { // 可选组件默认值是依赖其他组件时，若依赖了本组件，则本组件不能依赖它，否则循环变化
          if (item.options.valueConfig.dependWidgetKey === this.data.key) {
            status = false
          }
        } else if (item.options.valueConfig.type === 4 && item.options.valueConfig.params && this.hasDependForParam(item.options.valueConfig.params, this.data)) {
          status = false
        }
      }
      return status
    },
    // 根据参数数组和目标组件，判断参数里面是否有目标组件：有即依赖
    hasDependForParam (params, target) {
      if (params && params.length > 0) {
        for (let i = 0; i < params.length; i++) {
          if (params[i].value && params[i].value.length > 0) {
            for (let j = 0; j < params[i].value.length; j++) {
              if (params[i].value[j] === target.model) {
                return true
              }
            }
          }
        }
      }
    },
    // setType
    setType (property, type) {
      this.data.options[property].type = type
      if (type === 2) {
        this.enableExpressionEditor(property)
      }
    },
    // 激活计算器
    enableExpressionEditor (property) {
      this.expressionEditorProperty = property
      this.activeFormula = this.data.options[property].formula
      this.expressionEditorVisible = true
    },
    // 获取表达式
    getExpression () {
      if (!this.$refs.expression.getExpressionShow()) {
        return
      }
      this.data.options[this.expressionEditorProperty].formula = this.$refs.expression.getExpressionShow().stack
      this.data.options[this.expressionEditorProperty].expression = this.$refs.expression.getExpressionShow().text
      this.expressionEditorVisible = false
      console.log(  this.data.options);
    },
    // 计算出展示的表达式
    expressionShow (stack) {
      let text = ''
      stack.forEach(v => {
        if (v.type === 'variate') {
          text += `${v.label || v.value} `
        } else if (v.type === 'subform') {
          text += `${v.label || v.value} ${v.operator} ... `
        } else if (v.type === 'source') {
          text += `${v.label || (v.source + '.' + v.value)} `
        } else {
          if (v.fun) {
            text += `${v.label + v.value} `
          } else {
            text += `${v.label || v.value} `
          }
        }
      })
      return text
    },
    // 根据联动依赖项初始化本组件的数据
    initDepend () {
      this.data.options.valueConfig.dependItemProperty = ''
    },
    // 过滤出组件列表中能够被联动依赖的项（目前只做了一级组件。内部二级组件未考虑）
    canBeDepend (type) {
      return type === 'switch' || type === 'radio' || type === 'select' || type === 'checkbox'
    },
    // 依赖可选项
    dependOptions (widget) {
      if (widget.type === 'switch') {
        return this.switchOption
      } else {
        return widget.options.remote ? widget.options.remoteOptions : widget.options.options
      }
    },
    handleOptionsRemove (index) {
      if (this.data.type === 'grid' || this.data.type === 'subform') {
        this.data.columns.splice(index, 1)
      } else {
        this.data.options.options.splice(index, 1)
      }
    },
    handleAddOption () {
      if (this.data.options.showLabel) {
        this.data.options.options.push({
          value: '新选项值',
          label: '新选项名称'
        })
      } else {
        this.data.options.options.push({
          value: '新选项值'
        })
      }
    },
    handleAddColumn () {
      this.data.columns.push({
        span: '',
        list: []
      })
    },
    generateRule () {
      this.data.rules = []
      Object.keys(this.validator).forEach(key => {
        if (this.validator[key]) {
          this.data.rules.push(this.validator[key])
        }
      })
    },
    handleSelectMuliple (value) {
      if (value) {
        if (this.data.options.defaultValue) {
          this.data.options.defaultValue = [this.data.options.defaultValue]
        } else {
          this.data.options.defaultValue = []
        }
      } else {
        if (this.data.options.defaultValue.length > 0) {
          this.data.options.defaultValue = this.data.options.defaultValue[0]
        } else {
          this.data.options.defaultValue = ''
        }
      }
    },
    isEncryptChange() {
      if (!this.isEncrypt) {
        this.data.options.encipherment = null
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.set-type-group
  margin-bottom 10px
  .el-button
    padding-left 0.6rem
    padding-right 0.6rem
.expression-text
  line-height 22px
  font-size 14px
  color #aaa
  margin-top 10px
.sub-title
  line-height 20px
  font-size 14px
  color #999
.expression-title
  display flex
  span
    flex 1
    color #606266
.immutable-modal
  position absolute
  top 0
  right 0
  bottom 0
  left 0
  cursor not-allowed
  z-index 1
>>>.expression-dialog
  min-width 1000px
  max-height 94%
  overflow auto
  top 50%
  transform translateY(-50%)
  margin 0 auto!important
</style>
