const locale = window.ELEMENT.lang.en
export default {
    ...locale,
    "The approval version has been upgraded, the system will refresh it for you, please check it and try to submit again.": "The approval version has been upgraded, the system will refresh it for you, please check it and try to submit again.",
    "Cancel": "Cancel",
    "Cancle": "Cancel",
    "确定": "OK",
    "提示": "Hint",
    "Confirm": "Confirm",
    "cancel": "cancel",
    "确定删除该授权？": "Are you sure you want to delete this authorization?",
    "您确定要删除此申请吗？不可恢复": "Are you sure you want to delete this application? irrecoverable",
    "确定删除该分组？": "Are you sure you want to delete this group?",
    "删除分组": "Delete Group",
    "确定停用该流程？": "Are you sure you want to deactivate this process?",
    "确定启用该流程？": "Are you sure you want to enable this process?",
    "确定要删除该组件吗？": "Are you sure you want to remove this component?",
  "message.common.info": "Information",
    "message.common.code.askDecryptionAgain": "Do you want to decrypt again?",
    "message.common.code.decryptionFailed": "Decryption failed",
    "message.common.code.decryptionAgain": "Re-decrypt",
    "message.common.code.cancelDecryptionAgain": "You cancelled the re-decryption.",
    "message.common.code.inputCode": "Verification code",
    "message.common.code.sentCode": "Verification code sent",
    "message.common.code.wrongFormat": "Verification format wrong",
    "message.common.code.cancelCode": "You canceled the verification code submission.",
    "message.common.code.wrongSend": "Error sending verification code.",
    "message.common.code.wrongSubmit": "Error submitting verification code.",
    "message.common.code.successSubmit": "Verification code submitted successfully.",
    "message.common.code.reloadTry": "Please refresh the interface and try again.",
    "message.common.code.secondsLater": "seconds later,",
    "message.common.code.sendAgain": "send again",
    "message.common.code.tipsContent1": "The system requests to decrypt",
    "message.common.code.tipsContent3": "please input the verification code to complete the authorization.",
    "message.common.code.placeholder": "The verification code will be sent via TV or email",
    "message.common.code.frequent": "Verification code acquisition is too frequent, please get it again later",
    "message.common.code.wrongCode": "Verification code error",
    "message.common.code.wrongTooManyTimes": "The verification code has been input incorrectly for many times. Please get the verification code again",
    "message.common.code.overdueCode": "The verification code is invalid. Please get the verification code again",
    "message.common.uploadFile": "Upload file",
    "message.common.uploadPicture": "Upload picture",
    "message.common.ok": "OK",
    "message.common.cancel": "Cancel",
    "message.common.submit": "Submit",
    "message.common.saveAsDraft": "Save as draft",
    "message.common.tips": "Tips",
    "message.common.warning": "Warning",
    "message.common.start": "Start",
    "message.common.end": "End",
    "message.common.processNode": "Process node",
    "message.common.subTask": "Subtask",
    "message.common.pending": "Pending",
    "message.common.executeException": "Execute exception",
    "message.common.lostEfficacy": "Lost efficacy",
    "message.common.preview": "Preview",
    "message.common.download": "Download",
    "message.common.yes": "Yes",
    "message.common.no": "No",
    "message.common.width": "Width",
    "message.common.height": "Height",
    "message.common.add": "Add",
    "message.common.node": "Node",
    "message.common.select": "Select",
    "message.common.selected": "Selected",
    "message.common.validate.required": "Required",
    "message.common.validate.validateFaild": "Verification failed",
    "message.common.validate.wrongFormat": "Format mismatch",
    "message.common.validate.loadingData": "Loading data ..., please wait",
    "message.common.validate.noApproverSelected": "No approver selected",
    "message.common.placeholder.select": "Please select",
    "message.common.placeholder.remark": "Please input remark",
    "message.common.placeholder.notRequired": "Optional",
    "message.common.placeholder.keyTofilter": "Enter keywords to filter",
    "message.common.requestTips.failed": "Operation failed, please try again",
    "message.common.requestTips.success": "Successful operation",
    "message.common.requestTips.submitSuccess": "Submitted successfully",
    "message.common.requestTips.getDataFailed": "There was an error getting the data, please try again",
    "message.flowApply.systemFlow": "This process is a system process and cannot be initiated manually",
    "message.flowApply.resultPreview": "Results preview",
    "message.flowApply.attachmentsNotVisible": "Attachments are not visible",
    "message.flowApply.maxUpload": "Can only upload up to {number} files",
    "message.flowApply.selected": "Currently selected",
    "message.flowApply.personnelSelection": "Personnel selection",
    "message.flowApply.member": "Member",
    "message.flowApply.officer": "Supervisor",
    "message.flowApply.reporter": "Reporting",
  "message.flowApply.mutiple_reporter": "Multiple approval to {value}",
    "message.flowApply.role": "Roles",
    "message.flowApply.department": "Department",
    "message.flowApply.specialRole": "Special role",
    "message.flowApply.memberColumn": "Member reference field",
    "message.flowApply.officerColumn": "Supervisor reference field",
    "message.flowApply.reporterColumn": "Reporting reference field",
    "message.flowApply.columnPlaceholder": "The value is employee ID",
    "message.flowApply.memberFailed": "Member data acquisition failed!",
    "message.flowApply.officerFailed": "Supervisor data acquisition failed!",
    "message.flowApply.reporterFailed": "Report level data acquisition failed!",
    "message.flowApply.roleFailed": "Failed to obtain role data!",
    "message.flowApply.departmentFailed": "Department data acquisition failed!",
    "message.flowApply.specialRoleFailed": "Failed to obtain special character data!",
    "message.flowDetail.repeal": "Recall",
    "message.flowDetail.withdrawl": "Recalled",
    "message.flowDetail.print": "Print",
    "message.flowDetail.decode": "Decode",
    "message.flowDetail.decoded": "Decoded",
    "message.flowDetail.uploadFile": "Upload file",
    "message.flowDetail.uploadTips": "Only one attachment can be transmitted, please delete the transmitted attachment first",
    "message.flowDetail.pass": "Approve",
    "message.flowDetail.careOf": "Transfer",
    "message.flowDetail.turnDown": "Reject",
    "message.flowDetail.collapse": "Collapse",
    "message.flowDetail.expand": "Expand",
    "message.flowDetail.transferee": "Transferee",
    "message.flowDetail.sureTurnDown": "Are you sure you want to reject this approval?",
    "message.flowDetail.surePass": "Are you sure you want to pass this approval?",
    "message.flowDetail.sureRepeal": "Are you sure you want to revoke this approval?",
    "message.flowDetail.sureDispose": "Are you sure you want to process this task?",
    "message.flowDetail.sureDelete": "Are you sure you want to delete this approval?",
    "message.flowDetail.approveRecord": "Approve record",
    "message.flowDetail.rejected": "Rejected",
    "message.flowDetail.passed": "Approved",
    "message.flowDetail.pending": "Pending",
    "message.flowDetail.turnedDown": "Rejected",
    "message.flowDetail.draft": "Draft",
    "message.flowDetail.requireTransferee": "Require transferee",
    "message.flowDetail.complete": "Complete",
    "message.flowDetail.Redo": "Redo",
    "message.flowDetail.openInNewWindow": "Open in new window.",
    "date_picker_Dec": "Dec.",
    "date_picker_Nov": "Nov.",
    "date_picker_Oct": "Oct.",
    "date_picker_Sept": "Sept.",
    "date_picker_Aug": "Aug.",
    "date_picker_Jul": "Jul.",
    "date_picker_Jun": "Jun.",
    "date_picker_May": "May.",
    "date_picker_Apr": "Apr.",
    "date_picker_Mar": "Mar.",
    "date_picker_Feb": "Feb.",
    "date_picker_Jan": "Jan.",
    "date_picker_Saturday": "Sat.",
    "date_picker_Friday": "Fri.",
    "date_picker_Thursday": "Thur.",
    "date_picker_Wednesday": "Wed.",
    "date_picker_Tuesday": "Tue.",
    "date_picker_Monday": "Mon.",
    "date_picker_Sunday": "Sun.",
    "您确定要删除银行卡:no吗？": "Are you sure you want to delete the card :no?",
    "您确定要删除:start_date至:end_date的工作经历吗？": "Are you sure you want to delete the work experience from :start_date to :end_date?",
    "您确定要删除:name吗？": "Are you sure you want to delete :name?",
    "提交成功": "Submitted successfully",
    "闲置": "Idle",
    "Idle": "Idle",
    "已领用": "Received",
    "Received": "Received",
    "报废": "Scrapped",
    "Scrapped": "Scrapped",
    "维修中": "In maintenance",
    "In maintenance": "In maintenance",
    "归还中": "Returning",
    "Returning": "Returning",
    "Click to refresh": "Click to refresh",
    "QR code is invalid": "QR code is invalid",
    "Processing": "Processing",
    "系统异常": "System exception",
    "已复制好，可贴粘。": "It has been copied and can be pasted.",
    "more": "More",
    "OK": "OK",
    "more_items_with_num": "more :num items",
    "year_month": ":year-:month",
    "保存成功": "Save successfully",
    "Copy Success": "Copy Success",
    "Copy link": "Copy link",
    "Link copied": "Link copied",
    "请选择第 :idx 张银行卡的类型": "Please select the type of card number :idx",
    "第 :idx 张银行卡的卡号格式错误": "The card number format of the bank card :idx is incorrect",
    "请输入第 :idx 张银行卡的开户行城市": "Please enter the bank city of the bank card :idx",
    "请选择第 :idx 张银行卡的开户行": "Please select the bank name of the bank card :idx",
    "请输入第 :idx 张银行卡的支行": "Please enter the branch of the bank card :idx",
    "请输入第 :idx 张银行卡的预留手机号": "Please enter the reserved mobile number of the bank card :idx",
    "请输入第 :idx 张银行卡的身份证号": "Please enter the ID number of the bank card :idx",
    "请输入第 :idx 张银行卡的姓名": "Please enter the name of the bank card :idx",
    "请上传第 :idx 张银行卡的正面照片": "Please upload the front photo of the bank card :idx",
    "请上传第 :idx 张银行卡的反面照片": "Please upload the backside photo of the bank card :idx",
    "请": "Please",
    "第 :idx 份工作经历的证明人电话格式错误": "No.: idx work experience prover phone format is wrong",
    "第 :idx 个联系人的电话格式错误": "Malformed phone number for contact :idx",
    "保存草稿成功": "Save the draft successfully",
    "提醒": "Remind",
    "提交中，请稍候...": "Submitting, please wait...",
    "页面刷新中...": "Page refreshing...",
    "确认申请归档？": "Are you sure you want to archive contract?",
    "取消": "Cancel",
    "确认": "Confirm",
    "确认要删除该字段?": "Are you sure you want to delete this field?",
    "自定义浮动项列名不能为空字符串": "Custom floating item column name cannot be an empty string",
    "至少设置一个自定义浮动项列名": "Set at least one custom floating item column name",
    "作废后不可恢复，确认要作废 :txt 吗？": "It cannot be restored after being invalidated. Are you sure you want to invalidate :txt?",
    "确认删除[:name]记录?": "Are you sure you want to delete [:name] record?",
    "第 :line 行正在编辑中，请先完成该行的操作": "Line :line is being edited, please complete the operation on this line first",
    "第 :line 行正在删除中，请等待": "Line :line is being deleted, please wait",
    "第 :line 行正在保存中，请等待": "Line :line is being saved, please wait",
    "确定删除:name吗?": "Are you sure you want to delete :name?",
    "删除确认": "Delete confirmation",
    "确认删除?": "Confirm deletion?",
    "确认保存修改": "Confirm to save changes",
    "是否删除该费率记录【:id】": "Whether to delete the rate record【:id】",
    "第 :line 行数据不全": "Line :line has incomplete data",
    "请选择 :key 的薪资版本": "Please select the salary version of :key",
    "创建": "Create",
    "编辑": "Edit",
    "是否删除该模块": "Whether to delete the module",
    "年终奖计算": "Year-end bonus calculation",
    "操作成功": "Operate successfully",
    "保存": "Save",
    "刷新": "Refresh",
    "年终奖系数": "Year-end bonus factor",
    "绩效系数": "Performance factor",
    "姓名": "Name_XM",
    "名称": "Name",
    "职级": "Rank",
    "员工类型": "Employee Type",
    "绩效等级": "Performance level",
    "有": "Yes",
    "无": "None",
    "有无年终奖": "Year-end bonus eligibility",
    "类型": "Type",
    "年终奖": "Annual Bonus",
    "月薪": "Monthly salary",
    "入职时间": "Entry date",
    "司龄系数": "Company working years factor",
    "试算年终奖": "Trial calculation of year-end bonus",
    "浮动调整": "Float adjustment",
    "最终年终奖": "Final year-end bonus",
    "年总收入": "Annual gross income",
    "去年年终奖": "Last year's year-end bonus",
    "去年总收入": "Last year's gross income",
    "备注": "Remark",
    "全职": "Full-time",
    "兼职": "Part-time",
    "实习": "Intern",
    "劳务派遣": "Contractor",
    "劳务外包": "Outsourcing",
    "退休返聘": "Retirement",
    "顾问": "Consultant",
    "试用": "Probationary",
    "正式": "Regular",
    "待离职审核中": "Resignation Approving",
    "待离职已通过": "Resignation Approved",
    "Resigned": "Resigned",
    "Resigning": "Resigning",
    "提交": "Submit",
    "网络错误!": "Network Error!",
    "定稿": "Finalize",
    "若修改了数据，请先保存再下载数据": "If you modify the data, please save it before downloading the data",
    "数据下载": "Data download",
    "年终奖总额": "Total year-end bonus",
    "年终奖公式": "Year-end bonus formula",
    "月薪 * 年终奖系数 * 绩效系数 * 司龄系数 * 有无年终奖 + 月薪 * 司龄系数 * 有无13薪": "Monthly salary * year-end bonus factor * performance factor * seniority factor * year-end bonus eligibility + monthly salary * seniority factor * 13 months salary eligibility",
    "员工列表": "Employee list",
    "有无13薪": "13 months salary eligibility",
    "VP": "VP",
    "13薪": "13 months salary",
    "本次绩效": "This performance",
    "往期绩效": "Past performance",
    "共:cnt人": "Total :cnt people",
    "保存成功！": "Saved successfully!",
    "提交中...": "Submitting...",
    "绩效定义中:grade的绩效系数必填！": "In the performance definition: the performance factor of :grade is required!",
    "业务提成表中:name的绩效等级必选！": "In the business commission table: the performance level of :name is required!",
    "提交成功！": "Submitted successfully!",
    "输入您要生成报表的年月": "Enter the month and year you want to run the report",
    "审批中": "Approving",
    "网络超时,请重试!": "Network timed out, please try again!",
    "验证码发送失败": "Failed to send verification code",
    "是": "Yes",
    "否": "No",
    "下载模板": "Download template",
    "未知错误": "Unknown error",
    "验证码": "Verification code",
    "调薪晋升": "Salary and Promotion",
    "未知状态": "Unknown status",
    "导出": "Export",
    "总监": "Director",
    "上级": "Leader",
    "职位": "Position",
    "绩效": "Performance",
    "时间": "Time",
    "统计样本为全职员工，上下两条线之间是正常样本，上方的线是最大值，盒子代表中间的 50%，中间的线是中位数，下方的线是最小值。": "The statistical sample is full-time employees, between the upper and lower lines is the normal sample, the upper line is the maximum value, the box represents the middle 50%, the middle line is the median, and the lower line is the minimum value.",
    "总人数": "Total People",
    "推送成功": "Push successfully",
    "推送失败": "Push failed",
    "设置生效月份：": "Set effective month:",
    "生效月份：": "Effective month:",
    "禁止下级修改": "Subordinate modifications are prohibited",
    "上级已锁定": "superior locked",
    "按 VP": "by VP",
    "按 VP 统计": "Statistics by VP",
    "统计不包含 VP 及以上员工": "Statistics do not include VP and above employees",
    "日均工时": "Average working hours per day",
    "是否晋升": "Whether to promote",
    "晋升记录": "Promotion record",
    "当前薪资": "current salary",
    "调薪幅度": "salary increase",
    "调薪记录": "salary adjustment record",
    ":num次": ":num times",
    "共:num人": "Total :num people",
    "晋升时间": "promotion time",
    "原职级": "original rank",
    "调整后": "adjusted",
    "调薪时间": "salary adjustment time",
    "原薪资": "original salary",
    "备注时间": "Note time",
    "备注内容": "Remarks",
    "历史绩效": "historical performance",
    "历史备注记录": "History notes",
    "统计数据": "Statistical data",
    "薪资水平": "salary level",
    "调薪人数": "Number of salary adjustments",
    "调薪人数比例": "Salary adjustment ratio",
    "调薪前薪酬成本": "Salary cost before salary adjustment",
    "本期调薪总额": "Total salary adjustment for this period",
    "调薪后薪酬成本": "Salary cost after salary adjustment",
    "调薪总额比例": "Ratio of Total Salary Adjustment",
    "晋升人数": "Number of promotions",
    "晋升比例": "Promotion ratio",
    "数据已锁定": "data locked",
    "数据未锁定": "data not locked",
    ":name的薪资": ":name's salary",
    "未找到以下薪资统计组的数据:": "No data was found for the following salary statistics groups:",
    "该员工没有统计数据": "This employee has no statistics",
    "没有任何更改": "no changes",
    "所有调薪和晋升计划将在:month自动生效": "All salary adjustments and promotion plans will automatically take effect on: month",
    "提交后将无法修改": "Cannot be modified after submission",
    "确认是否提交？": "Are you sure to submit?",
    "定稿成功！": "Finalized successfully!",
    "确定要重新计算该员工薪资吗？": "Are you sure you want to recalculate this employee's salary?",
    "是否禁用租户": "Whether to disable the tenant",
    "是否启用租户": "Whether to enable the tenant",
    "是否删除该条目": "whether to delete this item",
    ":name的历史绩效": ":name's historical performance",
    ":name的晋升记录": ":name's promotion record",
    ":name的调薪记录": ":name's salary adjustment record",
    ":name的历史备注记录": ":name's history notes",
    ":name的统计数据": ":name's statistical data",
    ":name的薪资水平": ":name's salary level",
    "删除": "Delete",
    "您的改动会影响本假期的当前发放计划，请谨慎操作。确定修改吗？": "Your changes will affect the current distribution plan for this holiday, please proceed with caution. Are you sure to modify?",
    "Tips": "Tips",
    "确定要删除当前配置吗？": "Are you sure you want to delete the current configuration?",
    "重新获取": "Reacquire",
    "获取验证码": "Get verification code",
    ":time s后可重新获取": "Can be retrieved after :time s",
    "验证码发送中": "Verification code sending",
    "验证码已发送": "Verification code sent",
    "请求太频繁了哦，请在倒计时结束后再尝试获取": "The request is too frequent, please try to get it after the countdown is over",
    "因页面刷新请重新获取验证码再试!": "Please re-obtain the verification code and try again because the page is refreshed!",
    "请输入正确的验证码!": "Please enter correct verification code",
    "验证码长度错误!": "Verification code length error",
    "验证码无效，请重试": "The verification code is invalid, please try again",
    "验证码重试次数过多，请重新获取验证码后再试": "The verification code has been retried too many times, please re-obtain the verification code and try again",
    "验证码已过期，请重新获取验证码后再试": "The verification code has expired, please re-obtain the verification code and try again",
    "当前已存在一个解密任务,是否重新获取解密验证码,若是则上一个解密任务可能会失败!": "There is currently a decryption task, whether to reacquire the decryption verification code, if so, the previous decryption task may fail!",
    "系统申请解密“:scopes”数据，请输入验证码完成授权。": "The system applies to decrypt “:scopes”, data, please enter the verification code to complete the authorization.",
    "确定删除该文件？": "Are you sure you want to delete this file?",
    "处理中...": "Processing...",
    "是否重试该任务？": "Do you want to retry the task?",
    "是否删除该任务？": "Do you want to delete this task?",
    "是否清空所有任务？": "Do you want to clear all tasks?",
    "速度：:speed :units ，剩余时间：:resttime s": "Speed: :speed :units , remaining time: :resttime s",
    "上传已取消": "Upload canceled",
    "上传成功！": "Uploaded successfully",
    "上传失败：": "Upload failed:",
    "上传失败！": "Upload failed",
    "是否删除该文件": "Whether to delete the file",
    "是否删除该消息模板": "Whether to delete the message template",
    "错误：": "Error:",
    "不能为空": "Can not be empty",
    "描述：": "Description:",
    "验证码无效，请重试！": "The verification code is invalid, please try again.",
    "验证码重试次数过多，请重新获取验证码后再试！": "The verification code has been retried too many times, please re-obtain the verification code and try again.",
    "验证码已过期，请重新获取验证码后再试！": "The verification code has expired, please get the verification code again and try again.",
    "当前已存在一个解密任务,请稍后再试！": "There is currently a decryption task, please try again later.",
    "系统解密中": "System decrypting",
    "是否启用授权应用？": "Do you want to enable the authorized application?",
    "是否吊销授权应用？": "Do you want to revoke the authorized application?",
    "Are you sure you want to delete the current configuration?": "Are you sure you want to delete the current configuration?",
    "Reacquire": "Reacquire",
    "Get verification code": "Get verification code",
    "Can be retrieved after :time s": "Can be retrieved after :time s",
    "Verification code sending": "Verification code sending",
    "Verification code sent": "Verification code sent",
    "The request is too frequent, please try to get it after the countdown is over": "The request is too frequent, please try to get it after the countdown is over",
    "Failed to send verification code": "Failed to send verification code",
    "Please re-obtain the verification code and try again because the page is refreshed!": "Please re-obtain the verification code and try again because the page is refreshed!",
    "Please enter correct verification code": "Please enter correct verification code",
    "Verification code length error": "Verification code length error",
    "The verification code is invalid, please try again": "The verification code is invalid, please try again",
    "The verification code has been retried too many times, please re-obtain the verification code and try again": "The verification code has been retried too many times, please re-obtain the verification code and try again",
    "The verification code has expired, please re-obtain the verification code and try again": "The verification code has expired, please re-obtain the verification code and try again",
    "There is currently a decryption task, whether to reacquire the decryption verification code, if so, the previous decryption task may fail!": "There is currently a decryption task, whether to reacquire the decryption verification code, if so, the previous decryption task may fail!",
    "Yes": "Yes",
    "No": "No",
    "No. :index Meal Allowance": "No. :index Meal Allowance",
    "The system applies to decrypt “:scopes”, data, please enter the verification code to complete the authorization.": "The system applies to decrypt “:scopes”, data, please enter the verification code to complete the authorization.",
    "Verification code": "Verification code",
    "Are you sure you want to delete this file?": "Are you sure you want to delete this file?",
    "Processing...": "Processing...",
    "Download template": "Download template",
    "System exception": "System exception",
    "It has been copied and can be pasted.": "It has been copied and can be pasted.",
    "Hint": "Hint",
    "Do you want to retry the task?": "Do you want to retry the task?",
    "Do you want to delete this task?": "Do you want to delete this task?",
    "Do you want to clear all tasks?": "Do you want to clear all tasks?",
    "Speed: :speed :units , remaining time: :resttime s": "Speed: :speed :units , remaining time: :resttime s",
    "Upload canceled": "Upload canceled",
    "Uploaded successfully": "Uploaded successfully",
    "Upload failed:": "Upload failed:",
    "Upload failed": "Upload failed",
    "Whether to delete the file": "Whether to delete the file",
    "Whether to delete the message template": "Whether to delete the message template",
    "Operate successfully": "Operate successfully",
    "Error:": "Error:",
    "Unknown error": "Unknown error",
    "Can not be empty": "Can not be empty",
    "Description:": "Description:",
    "The verification code is invalid, please try again.": "The verification code is invalid, please try again.",
    "The verification code has been retried too many times, please re-obtain the verification code and try again.": "The verification code has been retried too many times, please re-obtain the verification code and try again.",
    "The verification code has expired, please get the verification code again and try again.": "The verification code has expired, please get the verification code again and try again.",
    "There is currently a decryption task, please try again later.": "There is currently a decryption task, please try again later.",
    "System decrypting": "System decrypting",
    "Do you want to enable the authorized application?": "Do you want to enable the authorized application?",
    "Do you want to revoke the authorized application?": "Do you want to revoke the authorized application?",
    "Save": "Save",
    "Name": "Name",
    "Name_XM": "Name",
    "Work Site": "Work Site",
    "Type": "Type",
    "Entry date": "Entry date",
    "Remark": "Remark",
    "Submit": "Submit",
    "Unknown status": "Unknown status",
    "Export": "Export",
    "Position": "Position",
    "Time": "Time",
    "Edit": "Edit",
    "Create": "Create",
    "处理中": "Processing",
    "请输入角色名称": "Please enter a role name",
    "选地址": "Select Address",
    "时间变更": "Time Change",
    "销除申请": "Revoke Application",
    "天": "Day(s)",
    "元": "Yuan",
    "提交变更申请": "Submit a Change Request",
    "数据加载中": "Loading",
    "变更原因不能为空": "Change reason cannot be empty",
    "出差未发生变更，无需审批": "No change in business trip, no approval required",
    "变更原因": "Reason for Change",
    "开始日期": "start date",
    "结束日期": "end date",
    "出发地": "Departure",
    "目的地": "Destination",
    "出差时长": "Business trip duration",
    "超支预算金额": "Amount over budget",
    "出差变更应在行程结束前发起申请，若未按规定时间发起，将会交由VP特批，并记录在案.": "The application for business trip change should be initiated before the end of the itinerary. If it is not initiated within the specified time, it will be submitted to VP for special approval and recorded.",
    "至少要有一段行程": "At least one itinerary",
    "有日期不合法或不连续，请检查": "Dates are illegal or not consecutive, please check",
    "第:num段行程有误，请检查": "The itinerary of segment :num is wrong, please check",
    "页面即将刷新": "The page will be refreshed soon",
    "第:index个餐补": "No.:index Meal Allowance",
    "Are you sure you want to delete :name?": "Are you sure you want to delete :name?",
    "Please enter a role name": "Please enter a role name",
    "确定要删除这个能力吗？": "Are you sure you want to delete this ability?",
    "是否禁用应用？": "Do you want to disable the application?",
    "是否启用应用？": "Do you want to enable the application?",
    "确定要执行该操作？": "Are you sure you want to take this action?",
    "请先选择上传文件": "Please select upload file first",
    "下载任务已取消": "Download task canceled",
    "点击下载": "Click to download",
    "分配": "Assign",
    "可选角色": "Available roles",
    "已分配角色": "Assigned roles",
    "File count exceeds the maximum limit of :limit.": "File count exceeds the maximum limit of :limit.",
    "请输入能力名称": "Please enter a ability name",
    "可用能力": "Available abilities",
    "已分配能力": "Assigned abilities",
    "确定要删除这个角色吗？": "Are you sure you want to delete this role?",
    "是否安装应用【:app_key】？": "Do you want to install the application [:app_key]?",
    "是否卸载应用【:app_key】？": "Do you want to uninstall the application [:app_key]?",
    "是否禁用【:app_key】？": "Do you want to disable [:app_key]?",
    "是否启用【:app_key】？": "Do you want to enable [:app_key]?",
    "是否禁用租户？": "Do you want to disable the tenant?",
    "是否启用租户？": "Enable tenant?",
    "确定执行该操作吗": "Are you sure you want to do this?",
    "Are you sure you want to delete this ability?": "Are you sure you want to delete this ability?",
    "Do you want to disable the application?": "Do you want to disable the application?",
    "Do you want to enable the application?": "Do you want to enable the application?",
    "Are you sure you want to take this action?": "Are you sure you want to take this action?",
    "Please select upload file first": "Please select upload file first",
    "Download task canceled": "Download task canceled",
    "Click to download": "Click to download",
    "Assign": "Assign",
    "Available roles": "Available roles",
    "Assigned roles": "Assigned roles",
    "Please enter a ability name": "Please enter a ability name",
    "Available abilities": "Available abilities",
    "Assigned abilities": "Assigned abilities",
    "Are you sure you want to delete this role?": "Are you sure you want to delete this role?",
    "Do you want to install the application [:app_key]?": "Do you want to install the application [:app_key]?",
    "Do you want to uninstall the application [:app_key]?": "Do you want to uninstall the application [:app_key]?",
    "Do you want to disable [:app_key]?": "Do you want to disable [:app_key]?",
    "Do you want to enable [:app_key]?": "Do you want to enable [:app_key]?",
    "Do you want to disable the tenant?": "Do you want to disable the tenant?",
    "Enable tenant?": "Enable tenant?",
    "Are you sure you want to do this?": "Are you sure you want to do this?",
    "sync_download_progress": "download progress",
    "sync_download_finished": "download finished",
    "Network Error!": "Network Error!",
    "Year-end bonus calculation": "Year-end bonus calculation",
    "Refresh": "Refresh",
    "Finalize": "Finalize",
    "If you modify the data, please save it before downloading the data": "If you modify the data, please save it before downloading the data",
    "Data download": "Data download",
    "Total year-end bonus": "Total year-end bonus",
    "Year-end bonus formula": "Year-end bonus formula",
    "Monthly salary * year-end bonus factor * performance factor * seniority factor * year-end bonus eligibility + monthly salary * seniority factor * 13 months salary eligibility": "Monthly salary * year-end bonus factor * performance factor * seniority factor * year-end bonus eligibility + monthly salary * seniority factor * 13 months salary eligibility",
    "Year-end bonus factor": "Year-end bonus factor",
    "Performance factor": "Performance factor",
    "Employee list": "Employee list",
    "Rank": "Rank",
    "Employee Type": "Employee Type",
    "Performance level": "Performance level",
    "13 months salary eligibility": "13 months salary eligibility",
    "None": "None",
    "Year-end bonus eligibility": "Year-end bonus eligibility",
    "13 months salary": "13 months salary",
    "Annual Bonus": "Annual Bonus",
    "Monthly salary": "Monthly salary",
    "Company working years factor": "Company working years factor",
    "This performance": "This performance",
    "Past performance": "Past performance",
    "Trial calculation of year-end bonus": "Trial calculation of year-end bonus",
    "Float adjustment": "Float adjustment",
    "Final year-end bonus": "Final year-end bonus",
    "Annual gross income": "Annual gross income",
    "Last year's year-end bonus": "Last year's year-end bonus",
    "Last year's gross income": "Last year's gross income",
    "Total :cnt people": "Total :cnt people",
    "Full-time": "Full-time",
    "Part-time": "Part-time",
    "Intern": "Intern",
    "Contractor": "Contractor",
    "Outsourcing": "Outsourcing",
    "Retirement": "Retirement",
    "Consultant": "Consultant",
    "Probationary": "Probationary",
    "Regular": "Regular",
    "Resignation Approving": "Resignation Approving",
    "Resignation Approved": "Resignation Approved",
    "Saved successfully!": "Saved successfully!",
    "Submitting...": "Submitting...",
    "In the performance definition: the performance factor of :grade is required!": "In the performance definition: the performance factor of :grade is required!",
    "In the business commission table: the performance level of :name is required!": "In the business commission table: the performance level of :name is required!",
    "Submitted successfully!": "Submitted successfully!",
    "Salary and Promotion": "Salary and Promotion",
    "Set effective month:": "Set effective month:",
    "Effective month:": "Effective month:",
    "Subordinate modifications are prohibited": "Subordinate modifications are prohibited",
    "superior locked": "superior locked",
    "by VP": "by VP",
    "Statistics by VP": "Statistics by VP",
    "Statistics do not include VP and above employees": "Statistics do not include VP and above employees",
    "Director": "Director",
    "Leader": "Leader",
    "Performance": "Performance",
    "Last three performances": "Last three performances",
    "RMB": "RMB",
    "USD": "USD",
    "Average working hours per day": "Average working hours per day",
    "Whether to promote": "Whether to promote",
    "Promotion record": "Promotion record",
    "current salary": "current salary",
    "salary increase": "salary increase",
    "salary adjustment record": "salary adjustment record",
    ":num times": ":num times",
    "Total :num people": "Total :num people",
    "promotion time": "promotion time",
    "original rank": "original rank",
    "adjusted": "adjusted",
    "salary adjustment time": "salary adjustment time",
    "original salary": "original salary",
    "Note time": "Note time",
    "Remarks": "Remarks",
    "The statistical sample is full-time employees, between the upper and lower lines is the normal sample, the upper line is the maximum value, the box represents the middle 50%, the middle line is the median, and the lower line is the minimum value.": "The statistical sample is full-time employees, between the upper and lower lines is the normal sample, the upper line is the maximum value, the box represents the middle 50%, the middle line is the median, and the lower line is the minimum value.",
    "Number of salary adjustments": "Number of salary adjustments",
    "Total People": "Total People",
    "Salary adjustment ratio": "Salary adjustment ratio",
    "Salary cost before salary adjustment": "Salary cost before salary adjustment",
    "Total salary adjustment for this period": "Total salary adjustment for this period",
    "Salary cost after salary adjustment": "Salary cost after salary adjustment",
    "Ratio of Total Salary Adjustment": "Ratio of Total Salary Adjustment",
    "Number of promotions": "Number of promotions",
    "Promotion ratio": "Promotion ratio",
    "data locked": "data locked",
    "data not locked": "data not locked",
    ":name's historical performance": ":name's historical performance",
    ":name's promotion record": ":name's promotion record",
    ":name's salary adjustment record": ":name accident records of the past year",
    ":name's accident record": ":name's accident record",
    ":name's history notes": ":name's history notes",
    ":name's statistical data": ":name's statistical data",
    ":name's salary level": ":name's salary level",
    ":name's salary": ":name's salary",
    "No data was found for the following salary statistics groups:": "No data was found for the following salary statistics groups:",
    "This employee has no statistics": "This employee has no statistics",
    "no changes": "no changes",
    "All salary adjustments and promotion plans will automatically take effect on: month": "All salary adjustments and promotion plans will automatically take effect on: month",
    "Cannot be modified after submission": "Cannot be modified after submission",
    "Are you sure to submit?": "Are you sure to submit?",
    "Save successfully": "Save successfully",
    "Finalized successfully!": "Finalized successfully!",
    "Submitted successfully": "Submitted successfully",
    "Whether to delete the module": "Whether to delete the module",
    "Enter the month and year you want to run the report": "Enter the month and year you want to run the report",
    "Push successfully": "Push successfully",
    "Push failed": "Push failed",
    "Are you sure you want to recalculate this employee's salary?": "Are you sure you want to recalculate this employee's salary?",
    "Whether to disable the tenant": "Whether to disable the tenant",
    "Whether to enable the tenant": "Whether to enable the tenant",
    "whether to delete this item": "whether to delete this item",
    "Are you sure you want to delete this field?": "Are you sure you want to delete this field?",
    "Custom floating item column name cannot be an empty string": "Custom floating item column name cannot be an empty string",
    "Set at least one custom floating item column name": "Set at least one custom floating item column name",
    "It cannot be restored after being invalidated. Are you sure you want to invalidate :txt?": "It cannot be restored after being invalidated. Are you sure you want to invalidate :txt?",
    "Are you sure you want to delete [:name] record?": "Are you sure you want to delete [:name] record?",
    "Line :line is being edited, please complete the operation on this line first": "Line :line is being edited, please complete the operation on this line first",
    "Line :line is being deleted, please wait": "Line :line is being deleted, please wait",
    "Line :line is being saved, please wait": "Line :line is being saved, please wait",
    "Delete confirmation": "Delete confirmation",
    "Confirm deletion?": "Confirm deletion?",
    "Confirm to save changes": "Confirm to save changes",
    "Whether to delete the rate record【:id】": "Whether to delete the rate record【:id】",
    "Line :line has incomplete data": "Line :line has incomplete data",
    "Please select the salary version of :key": "Please select the salary version of :key",
    "key.Last_year_end_bonus": "Last year's year-end bonus",
    "key.Last_year_gross_income": "Last year's gross income",
    "Failed to update the message status, please contact the administrator": "Failed to update the message status, please contact the administrator",
    "出差事后变更": "Business trip change",
    "当出差有变动，如提前结束等，可进行事后变更。通过审批后系统会自动更新考勤记录。": "When there is a change in the business trip, such as ending early, etc., it can be changed afterwards. After approval, the system will automatically update the attendance record.",
    "删除操作进行中，现在不能保存": "The delete operation is in progress and cannot be saved now",
    "请先填写并保存Key/原文": "Please fill in and save Key/Original",
    "将会删除这个key以及其所有语种的译文，确定删除吗？": "This key and its translations in all languages will be deleted. Are you sure to delete?",
    "数据更新中，暂时不能操作": "The data is being updated, temporarily unable to operate",
    "你有尚未保存的更改，确定关闭页面吗？": "You have unsaved changes, are you sure you want to close the page?",
    "Key/原文不可为空": "Key/original text cannot be empty",
    "Key/原文超过了最大长度: 255字符": "Key/original text exceeds the maximum length: 255 characters",
    "The delete operation is in progress and cannot be saved now": "The delete operation is in progress and cannot be saved now",
    "Please fill in and save Key/Original": "Please fill in and save Key/Original",
    "This key and its translations in all languages will be deleted. Are you sure to delete?": "This key and its translations in all languages will be deleted. Are you sure to delete?",
    "The data is being updated, temporarily unable to operate": "The data is being updated, temporarily unable to operate",
    "You have unsaved changes, are you sure you want to close the page?": "You have unsaved changes, are you sure you want to close the page?",
    "Key/original text cannot be empty": "Key/original text cannot be empty",
    "Key/original text exceeds the maximum length: 255 characters": "Key/original text exceeds the maximum length: 255 characters",
    "Start Date": "Start Date",
    "请选择员工": "Please select employee",
    "请填写备注": "Please fill in the remarks",
    "Please enter the month": "Please enter the month",
    "Remarks cannot be empty": "Remarks cannot be empty",
    "月份格式错误": "Month format error",
    "Please enter the employee": "Please enter the employee",
    "Please fill in the performance period": "Please fill in the performance period",
    "Month format error": "Month format error",
    "Revoke Application": "Revoke Application",
    "Departure": "Departure",
    "Destination": "Destination",
    "Yuan": "Yuan",
    "Loading": "Loading",
    "Delete": "Delete",
    "Select Address": "Select Address",
    "Business trip change": "Business trip change",
    "When there is a change in the business trip, such as ending early, etc., it can be changed afterwards. After approval, the system will automatically update the attendance record.": "When there is a change in the business trip, such as ending early, etc., it can be changed afterwards. After approval, the system will automatically update the attendance record.",
    "Time Change": "Time Change",
    "start date": "start date",
    "end date": "end date",
    "Business trip duration": "Business trip duration",
    "Day(s)": "Day(s)",
    "Amount over budget": "Amount over budget",
    "Reason for Change": "Reason for Change",
    "The application for business trip change should be initiated before the end of the itinerary. If it is not initiated within the specified time, it will be submitted to VP for special approval and recorded.": "The application for business trip change should be initiated before the end of the itinerary. If it is not initiated within the specified time, it will be submitted to VP for special approval and recorded.",
    "Submit a Change Request": "Submit a Change Request",
    "At least one itinerary": "At least one itinerary",
    "Change reason cannot be empty": "Change reason cannot be empty",
    "Dates are illegal or not consecutive, please check": "Dates are illegal or not consecutive, please check",
    "The itinerary of segment :num is wrong, please check": "The itinerary of segment :num is wrong, please check",
    "The page will be refreshed soon": "The page will be refreshed soon",
    "No change in business trip, no approval required": "No change in business trip, no approval required",
    "No.:index Meal Allowance": "No.:index Meal Allowance",
    "Your changes will affect the current distribution plan for this holiday, please proceed with caution. Are you sure to modify?": "Your changes will affect the current distribution plan for this holiday, please proceed with caution. Are you sure to modify?",
    "失败": "Failure",
    "文件不可用": "File not available",
    ":必填": ":required",
    ":item不存在或未启用": ":item does not exist or is not enabled",
    "原租户汇报人工号:number": "The original tenant report to the employee number :number",
    "File not available": "File not available",
    "Failure": "Failure",
    ":required": ":required",
    ":item does not exist or is not enabled": ":item does not exist or is not enabled",
    "Please select the type of card number :idx": "Please select the type of card number :idx",
    "The card number format of the bank card :idx is incorrect": "The card number format of the bank card :idx is incorrect",
    "Please enter the bank city of the bank card :idx": "Please enter the bank city of the bank card :idx",
    "Please select the bank name of the bank card :idx": "Please select the bank name of the bank card :idx",
    "Please enter the branch of the bank card :idx": "Please enter the branch of the bank card :idx",
    "Please enter the name of the bank card :idx": "Please enter the name of the bank card :idx",
    "Please enter the reserved mobile number of the bank card :idx": "Please enter the reserved mobile number of the bank card :idx",
    "Please enter the ID number of the bank card :idx": "Please enter the ID number of the bank card :idx",
    "Please upload the front photo of the bank card :idx": "Please upload the front photo of the bank card :idx",
    "Please upload the backside photo of the bank card :idx": "Please upload the backside photo of the bank card :idx",
    "Please": "Please",
    "Malformed phone number for contact :idx": "Malformed phone number for contact :idx",
    "No.: idx work experience prover phone format is wrong": "No.: idx work experience prover phone format is wrong",
    "The original tenant report to the employee number :number": "The original tenant report to the employee number :number",
    "Are you sure you want to delete the card :no?": "Are you sure you want to delete the card :no?",
    "Are you sure you want to delete the work experience from :start_date to :end_date?": "Are you sure you want to delete the work experience from :start_date to :end_date?",
    "Save the draft successfully": "Save the draft successfully",
    "Approving": "Approving",
    "Network timed out, please try again!": "Network timed out, please try again!",
    "Are you sure you want to delete this authorization?": "Are you sure you want to delete this authorization?",
    "Are you sure you want to delete this application? irrecoverable": "Are you sure you want to delete this application? irrecoverable",
    "Are you sure you want to delete this group?": "Are you sure you want to delete this group?",
    "Delete Group": "Delete Group",
    "Are you sure you want to deactivate this process?": "Are you sure you want to deactivate this process?",
    "Are you sure you want to enable this process?": "Are you sure you want to enable this process?",
    "Are you sure you want to remove this component?": "Are you sure you want to remove this component?",
    "确认删除该记录?": "Are you sure to delete this record?",
    "没有任何修改": "No modification",
    "值与备注都不可为空": "Both value and comment cannot be empty",
    "重跑节点或任务": "Rerun node or task",
    "触发【:type】通知事件": "Trigger [:type] notification event",
    "备注不可为空!": "Remarks cannot be empty!",
    "请输入正确的审批单号！": "Please enter the correct approval number!",
    "Are you sure to delete this record?": "Are you sure to delete this record?",
    "Are you sure to cancel this record?": "Are you sure to cancel this record?",
    "No modification": "No modification",
    "Both value and comment cannot be empty": "Both value and comment cannot be empty",
    "Rerun node or task": "Rerun node or task",
    "Trigger [:type] notification event": "Trigger [:type] notification event",
    "Remarks cannot be empty!": "Remarks cannot be empty!",
    "Please enter the correct approval number!": "Please enter the correct approval number!",
    "Seal type [:value] already exists": "Seal type [:value] already exists",
    "Creating a seal type [:typeName]": "Creating a seal type [:typeName]",
    "Remind": "Remind",
    "Submitting, please wait...": "Submitting, please wait...",
    "Page refreshing...": "Page refreshing...",
    "Are you sure you want to archive contract?": "Are you sure you want to archive contract?",
    "After clicking OK, the contract of :name (:number) will be transferred to :transName and irreversible. <br> Are you sure of the operation?": "After clicking OK, the contract of :name (:number) will be transferred to :transName and irreversible. <br> Are you sure of the operation?",
    "面试官": "Interviewer",
    "手机号格式不正确": "Phone number format is incorrect",
    "手机号码不能为空": "Mobile can not be blank",
    "请选择职级": "Please select a rank",
    "人": "People",
    "请选择": "Please choose",
    "搜索姓名/工号": "Search name/employee number",
    "简历": "CV",
    "HRBP": "HRBP",
    "预览": "Preview",
    "下载": "Download",
    "待评价": "To be Evaluated",
    "已入职": "Onboarded",
    "放弃入职": "Give Up Onboarding",
    "通过": "Approve",
    "取消招聘": "Cancel recruitment",
    "完成招聘": "Finish recruitment",
    "基本信息": "Basic Information",
    "职位名称": "Position Name",
    "职级名称": "Rank Name",
    "备注信息": "Remarks",
    "创建时间": "Create Time",
    "请正确填写": "Please fill in correctly",
    "服务器内部错误!": "Internal server error!",
    "规格或规格值信息不全,禁止提交!": "The specification or specification value information is incomplete, and the submission is prohibited!",
    "归还原因必填": "Return reason is required",
    "提交中": "Submitting",
    "当前为状态为：未登录": "The current status is: not logged in",
    "请联系管理员开启权限": "Please contact the administrator to enable permissions",
    "入库数量: 必须大于0": "Storage quantity must be greater than 0",
    "新建分组": "New Group",
    "重命名": "Rename",
    "排序": "Sort",
    "New Group": "New Group",
    "Rename": "Rename",
    "Sort": "Sort",
    "内推人": "Referrer",
    "候选人姓名:": "Candidate Name:",
    "手机号码:": "Phone number:",
    "当前状态:": "Current status:",
    "请选择当前状态": "Please select current status",
    "招聘渠道:": "Recruitment channels:",
    "内推人:": "Referrer:",
    "内推人必填": "Referrer is required",
    "面试流程:": "Interview process:",
    "第:num轮面试:": "Interview round :num:",
    "面试时间": "Interview time",
    "选择日期时间": "Select date time",
    "添加面试流程": "Add interview process",
    "会议室:": "Meeting room:",
    "请填写线下会议室地址或者腾讯会议室ID": "Please fill in the offline meeting room address or Tencent meeting room ID",
    "上传简历:": "Upload resume:",
    "点击上传": "Click to upload",
    "一": "one",
    "二": "two",
    "三": "three",
    "四": "four",
    "五": "five",
    "六": "six",
    "七": "seven",
    "八": "eight",
    "九": "nine",
    "十": "ten",
    "最多添加10轮面试": "Add up to 10 rounds of interviews",
    "操作失败，请重试！": "Operation failed, please try again!",
    "招聘部门:": "Recruiting department:",
    "当前部门总人数:total人（包含实习生:num人）": "The total number of people in the current department is :total (including interns :num people)",
    "职位名称:": "Job title:",
    "请选择职位名称": "Please select a job title",
    "职级名称:": "Rank title:",
    "招聘人数:": "Number of recruits:",
    "员工类型:": "Employee type:",
    "请选择员工类型": "Please select employee type",
    "招聘原因:": "Recruitment reason:",
    "请选择招聘原因": "Please select the reason for recruitment",
    "优先级:": "Priority:",
    "请选择优先级": "Please select a priority",
    "期望面试日期:": "Expected interview date:",
    "key.expected.date.interview": "Example:\nInterviewer 1: every Thursday afternoon\nInterviewer 2: every morning 11:00~12:00;\nInterviewer 3: every Tuesday, Wednesday and Thursday afternoon 3:00~6:00",
    "直属上级": "Immediate superior",
    "请选择直属上级": "Please select the immediate superior",
    "面试流程": "Interview process",
    "第:num轮面试": "Interview round :num",
    "请填写": "Please fill out",
    "岗位职责:": "Job Responsibilities:",
    "请先思考职位的绩效目标，然后思考为了完成绩效目标，该员工需要做哪些工作": "Think about the performance goals of the position first, then think about what the employee needs to do to accomplish the performance goals",
    "任职要求:": "Job requirements:",
    "请按以下纬度填写: 基本要求（学历、专业、经验）、能力要求、性格特质、工作经历（如有）": "Please fill in the following latitudes: basic requirements (education, major, experience), ability requirements, personality traits, work experience (if any)",
    "备注:": "Remark:",
    "招聘部门必选": "Recruitment department is required",
    "职位名称必选": "Job title is required",
    "招聘人数必填": "The number of recruits is required",
    "招聘人数至少为一人": "The number of recruits is at least one person",
    "招聘人数为正整数": "The number of recruits is a positive integer",
    "员工类型必选": "Employee type is required",
    "招聘原因必选": "The reason for recruitment is required",
    "招聘优先级必选": "Recruitment priority is required",
    "第:num轮面试官必选！": "No. :num round of interviewers must be selected!",
    "岗位职责必填": "Job responsibilities are required",
    "任职要求必填": "Job requirements are required",
    "招聘创建时间": "Recruitment create time",
    "上传简历日期": "Resume upload date",
    "简历渠道": "Resume channel",
    "会议室": "Meeting room",
    "未通过": "Candidate.Status.Failed",
    "评价时间": "Evaluation time",
    "前往评价": "Go to evaluate",
    "已通过": "Candidate.Status.Passed",
    "面试时间：:datetime": "Interview time: :datetime",
    "评价时间：:datetime": "Evaluation time: :datetime",
    "待筛选": "Resume screening",
    "筛选不通过": "Resume screening failed",
    "待邀约面试": "Interview invitation",
    "待面试": "Waiting for interview",
    "面试中": "In the interview",
    "面试未通过": "Interview failed",
    "面试通过": "Interview passed",
    "录用审批中": "Offer approving",
    "已发Offer": "Offered",
    "放弃面试": "Give up interview",
    "不好": "Not good",
    "可提升": "Can be improved",
    "一般": "General",
    "良好": "Good",
    "优秀": "Excellent",
    "Interview round :num": "Interview round :num",
    "面试结果必选": "Interview results are required",
    "面试评价必填": "Interview evaluation is required",
    "面试结果为:status，确认提交？": "The interview result is :status, confirm submission?",
    "结果确认": "Result Confirmation",
    ":name已于:date被:hr通过:channel添加至人才库,继续添加将在原来基础上增加一份简历，不影响历史信息。": ":name has been added to the talent pool by :hr via :channel on :date, and continuing to add a resume will add a resume on the original basis without affecting historical information.",
    "第:num面": "Interview round :num",
    "未找到该手机号的面试预约，请确认手机号，或手动填写签到信息": "No interview appointment with this phone number was found, please confirm the phone number, or manually fill in the check-in information",
    "Referrer": "Referrer",
    "Candidate Name:": "Candidate Name:",
    "Phone number:": "Phone number:",
    "Current status:": "Current status:",
    "Please select current status": "Please select current status",
    "Recruitment channels:": "Recruitment channels:",
    "Referrer:": "Referrer:",
    "Referrer is required": "Referrer is required",
    "Interview process:": "Interview process:",
    "Interview round :num:": "Interview round :num:",
    "Interviewer": "Interviewer",
    "Interview time": "Interview time",
    "Select date time": "Select date time",
    "Add interview process": "Add interview process",
    "Meeting room:": "Meeting room:",
    "Please fill in the offline meeting room address or Tencent meeting room ID": "Please fill in the offline meeting room address or Tencent meeting room ID",
    "Upload resume:": "Upload resume:",
    "Click to upload": "Click to upload",
    "Phone number format is incorrect": "Phone number format is incorrect",
    "Mobile can not be blank": "Mobile can not be blank",
    "one": "one",
    "two": "two",
    "three": "three",
    "four": "four",
    "five": "five",
    "six": "six",
    "seven": "seven",
    "eight": "eight",
    "nine": "nine",
    "ten": "ten",
    "Add up to 10 rounds of interviews": "Add up to 10 rounds of interviews",
    "Operation failed, please try again!": "Operation failed, please try again!",
    "Recruiting department:": "Recruiting department:",
    "The total number of people in the current department is :total (including interns :num people)": "The total number of people in the current department is :total (including interns :num people)",
    "Job title:": "Job title:",
    "Please select a job title": "Please select a job title",
    "Please select a job base": "Please select a job base",
    "Rank title:": "Rank title:",
    "Please select a rank": "Please select a rank",
    "Number of recruits:": "Number of recruits:",
    "People": "People",
    "Employee type:": "Employee type:",
    "Please select employee type": "Please select employee type",
    "Recruitment reason:": "Recruitment reason:",
    "Please select the reason for recruitment": "Please select the reason for recruitment",
    "Priority:": "Priority:",
    "Please select a priority": "Please select a priority",
    "Expected interview date:": "Expected interview date:",
    "Immediate superior": "Immediate superior",
    "Please select the immediate superior": "Please select the immediate superior",
    "Interview process": "Interview process",
    "Please choose": "Please choose",
    "Please fill out": "Please fill out",
    "Job Responsibilities:": "Job Responsibilities:",
    "Think about the performance goals of the position first, then think about what the employee needs to do to accomplish the performance goals": "Think about the performance goals of the position first, then think about what the employee needs to do to accomplish the performance goals",
    "Job requirements:": "Job requirements:",
    "Please fill in the following latitudes: basic requirements (education, major, experience), ability requirements, personality traits, work experience (if any)": "Please fill in the following latitudes: basic requirements (education, major, experience), ability requirements, personality traits, work experience (if any)",
    "Remark:": "Remark:",
    "Please fill in the remarks": "Please fill in the remarks",
    "Recruitment department is required": "Recruitment department is required",
    "Job title is required": "Job title is required",
    "The number of recruits is required": "The number of recruits is required",
    "The number of recruits is at least one person": "The number of recruits is at least one person",
    "The number of recruits is a positive integer": "The number of recruits is a positive integer",
    "Employee type is required": "Employee type is required",
    "The reason for recruitment is required": "The reason for recruitment is required",
    "Recruitment priority is required": "Recruitment priority is required",
    "No. :num round of interviewers must be selected!": "No. :num round of interviewers must be selected!",
    "Job responsibilities are required": "Job responsibilities are required",
    "Job requirements are required": "Job requirements are required",
    "Search name/employee number": "Search name/employee number",
    "Recruitment create time": "Recruitment create time",
    "CV": "CV",
    "Resume upload date": "Resume upload date",
    "Resume channel": "Resume channel",
    "preview": "Preview",
    "Preview": "Preview",
    "Download": "Download",
    "Meeting room": "Meeting room",
    "Candidate.Status.Failed": "Failed",
    "Candidate.Status.Passed": "Passed",
    "Candidate.Status.Pending": "Pending",
    "Evaluation time": "Evaluation time",
    "Go to evaluate": "Go to evaluate",
    "To be Evaluated": "To be Evaluated",
    "Interview time: :datetime": "Interview time: :datetime",
    "Evaluation time: :datetime": "Evaluation time: :datetime",
    "Resume screening": "Resume screening",
    "Resume screening failed": "Resume screening failed",
    "Interview invitation": "Interview invitation",
    "Waiting for interview": "Waiting for interview",
    "In the interview": "In the interview",
    "Interview failed": "Interview failed",
    "Interview passed": "Interview passed",
    "Offer approving": "Offer approving",
    "Offered": "Offered",
    "Give up interview": "Give up interview",
    "Onboarded": "Onboarded",
    "Give Up Onboarding": "Give Up Onboarding",
    "Not good": "Not good",
    "Can be improved": "Can be improved",
    "General": "General",
    "Good": "Good",
    "Excellent": "Excellent",
    "Interview results are required": "Interview results are required",
    "Interview evaluation is required": "Interview evaluation is required",
    "The interview result is :status, confirm submission?": "The interview result is :status, confirm submission?",
    "Approve": "Approve",
    "Result Confirmation": "Result Confirmation",
    ":name has been added to the talent pool by :hr via :channel on :date, and continuing to add a resume will add a resume on the original basis without affecting historical information.": ":name has been added to the talent pool by :hr via :channel on :date, and continuing to add a resume will add a resume on the original basis without affecting historical information.",
    "No interview appointment with this phone number was found, please confirm the phone number, or manually fill in the check-in information": "No interview appointment with this phone number was found, please confirm the phone number, or manually fill in the check-in information",
    "面试结果:": "Interview result:",
    "面试评价:": "Interview evaluation:",
    "输入面试评价": "Enter interview evaluation",
    "面试评价记录": "Interview Evaluation Record",
    "(已通过)": "(Passed)",
    "(已拒绝)": "(Rejected)",
    "查看评价详情": "View review details",
    "评价详情": "Evaluation Details",
    "Interview result:": "Interview result:",
    "Interview evaluation:": "Interview evaluation:",
    "Enter interview evaluation": "Enter interview evaluation",
    "Interview Evaluation Record": "Interview Evaluation Record",
    "(Passed)": "(Passed)",
    "(Rejected)": "(Rejected)",
    "(Pending)": "(Pending)",
    "View review details": "View review details",
    "Evaluation Details": "Evaluation Details",
    "男": "male",
    "女": "female",
    "未知": "unknown",
    "特殊性别": "special gender",
    "备注不能为空": "Remarks cannot be empty",
    "您确认要:name [:email] 吗？": "Are you sure you want :name [:email]?",
    "ap必填": "AP required",
    "ap地址格式不正确！": "AP address format is incorrect!",
    "配置名称必填": "Configuration name is required",
    "配置信息不是json格式": "Configuration information is not in json format",
    "请重试": "Please try again",
    "mac地址必填": "Mac address is required",
    "mac地址不正确！": "The mac address is incorrect!",
    "Are you sure you want :name [:email]?": "Are you sure you want :name [:email]?",
    "AP required": "AP required",
    "AP address format is incorrect!": "AP address format is incorrect!",
    "Configuration name is required": "Configuration name is required",
    "Configuration information is not in json format": "Configuration information is not in json format",
    "Please try again": "Please try again",
    "Mac address is required": "Mac address is required",
    "The mac address is incorrect!": "The mac address is incorrect!",
    "Internal server error!": "Internal server error!",
    "The specification or specification value information is incomplete, and the submission is prohibited!": "The specification or specification value information is incomplete, and the submission is prohibited!",
    "Return reason is required": "Return reason is required",
    "Please fill in correctly": "Please fill in correctly",
    "Submitting": "Submitting",
    "The current status is: not logged in": "The current status is: not logged in",
    "Please contact the administrator to enable permissions": "Please contact the administrator to enable permissions",
    "Storage quantity must be greater than 0": "Storage quantity must be greater than 0",
    "Please set the scheduling execution time": "Please set the scheduling execution time",
    "Upload": "Upload",
    "No data": "No data",
    "or meet": "or",
    "and meet": "and",
    "when meet": "when",
    "Condition group": "Condition group",
    "Add option": "Add option",
    "Add condition": "Add condition",
    "Add condition group": "Add condition group",
    "equal to": "equal to",
    "not equal to": "not equal to",
    "greater than": "greater than",
    "greater than or equal to": "greater than or equal to",
    "less than": "less than",
    "less than or equal to": "less than or equal to",
    "belong to": "belong to",
    "not belong to": "not belong to",
    "There is an unknown form field in conditional expression.": "There is an unknown form field in conditional expression.",
    "Some of the formulas which have been edited can not be parsed by the Basic editor.": "Some of the formulas which have been edited can not be parsed by the Basic editor.",
    "There is no form field for condition setting.": "There is no form field for condition setting.",
    "Sun.": "Sun.",
    "Mon.": "Mon.",
    "Wed.": "Wed.",
    "Fri.": "Fri.",
    "Sat.": "Sat.",
    "Jan.": "Jan.",
    "Feb.": "Feb.",
    "Mar.": "Mar.",
    "Apr.": "Apr.",
    "May.": "May.",
    "Jun.": "Jun.",
    "Jul.": "Jul.",
    "Aug.": "Aug.",
    "Sep.": "Sep.",
    "Oct.": "Oct.",
    "Nov.": "Nov.",
    "Dec.": "Dec.",
    "The earliest effective month for this statement is :month": "The earliest effective month for this statement is :month",
    "Select Approver": "Select Approver",
    "Special subsidies and policies": "Special allowance and policy",
    "Accident Record": "Incident Record",
    "accident.event_date": "Incident Date",
    "accident.title": "Incident Title",
    "accident.summary": "Incident Summary",
    "accident.level": "Incident Level",
    "accident.event_type": "Incident Type",
    "accident.penalty": "Penalty",
    "Details": "Details",
    "operate": "Operate",
    "check submit has accident record": "The following employees have a history of incidents in the last year:<br>\n :list <br>\n Please refer to the \":link\" to determine whether or not a salary and promotion should be granted.",
    "Incident Rating and Handling Policy": "Incident Rating and Handling Policy",
    "Job Base": "Base",
    "Job Base:": "Base:",
    "Are you sure you want to delete the report [:txt]": "Are you sure you want to delete the report [:txt]?",
    "Do you want to clear the benefit content of :text": "Do you want to clear the benefit content of :text?",
    "Please select the HRBP": "Please select the HRBP",
    "小学": "Primary School",
    "初中": "Junior High School",
    "高中": "High School",
    "中专": "Technical Secondary School",
    "大专": "College",
    "本科": "Undergraduate",
    "硕士研究生": "Master Student",
    "博士研究生": "PhD Student",
    "其他": "Other",
    "学士": "Bachelor",
    "硕士": "Master",
    "博士": "PhD",
    "居民身份证": "ID Card",
    "人事加密文件上传|查看": "Personnel encrypted file upload | View",
    "港澳居民来往内地通行证": "Mainland Travel Permit for Hong Kong and Macao Residents",
    "台湾居民来往大陆通行证": "Mainland Travel Permit for Taiwan Residents",
    "外国护照": "Foreign Passport",
    "众包": "Crowdsourcing",
    "待入职": "To be Onboarding",
    "离职": "Resignation",
    "未婚": "Unmarried",
    "已婚": "Married",
    "离异": "Divorced",
    "丧偶": "Widowed",
    "中国大陆": "Chinese Mainland",
    "中国台湾": "Taiwan, China",
    "中国香港": "Hong Kong, China",
    "中国澳门": "Macao, China",
    "印度": "India",
    "新加坡": "Singapore",
    "印尼": "Indonesia",
    "泰国": "Thailand",
    "菲律宾": "Philippines",
    "保加利亚": "Bulgaria",
    "孟加拉": "Bengal",
    "马来西亚": "Malaysia",
    "巴西": "Brazil",
    "墨西哥": "Mexico",
    "群众": "Citizen",
    "团员": "League Member",
    "党员": "Party Member",
    "其他党派人士": "Other Party Member"
}
