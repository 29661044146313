import Vue from 'vue'
import { fromEvent } from 'rxjs'
import { filter, throttleTime, map } from 'rxjs/operators'
import i18n from '../../i18n'
import Code from './Code'

const queue = []

// 加解密验证码操作入口
export const msgCode = (ask, param) => {
  if (ask) {
    // 错误重解密
    const promise = new Promise(resolve => {
      i18n.t('message.common.code.reloadTry')
      Vue.prototype.$confirm(i18n.t('message.common.code.askDecryptionAgain'), i18n.t('message.common.code.decryptionFailed'), {
        confirmButtonText: i18n.t('message.common.code.decryptionAgain'),
        cancelButtonText: i18n.t('message.common.cancel'),
        type: 'warning'
      }).then(async () => {
        const codeRes = await getSubmitCodeRes(promise, param)
        resolve(codeRes)
      }).catch(() => {
        resolve({
          type: 'cancelAgain',
          message: i18n.t('message.common.code.cancelDecryptionAgain')
        })
      })
    })
    // 将本次的promise放入队列
    queue.push(promise)
    return promise
  } else {
    // 开始处理本次验证码的promise对象
    const promise = getSubmitCodeRes(undefined, param)
    // 将本次的promise放入队列
    queue.push(promise)
    return promise
  }
}

// 排队依次开启验证码对话
function getSubmitCodeRes (parentPromise, param) {
  return new Promise(async (resolve) => {
    if (queue && queue.length > 0) {
      try {
        if (parentPromise) {
          const currentIndex = queue.findIndex(i => i === parentPromise)
          if (currentIndex < 0) {
            await queue[queue.length - 1]
            submitCodeStart(resolve, param)
          } else if (currentIndex === 0) {
            submitCodeStart(resolve, param)
          } else {
            await queue[currentIndex - 1]
            submitCodeStart(resolve, param)
          }
        } else {
          await queue[queue.length - 1]
          submitCodeStart(resolve, param)
        }
      } catch {
        submitCodeStart(resolve, param)
      }
    } else {
      submitCodeStart(resolve, param)
    }
  })
}

// 开启验证码弹窗、发送验证码、提交验证码
async function submitCodeStart (resolve, param) {
  const h = Vue.prototype.$createElement
  let codeApp
  function setApp (app) {
    codeApp = app
  }
  // 用户提交验证码
  Vue.prototype.$msgbox({
    title: i18n.t('message.common.code.inputCode'),
    confirmButtonText: i18n.t('message.common.ok'),
    confirmButtonClass: 'code-sure-btn',
    cancelButtonText: i18n.t('message.common.cancel'),
    inputPlaceholder: i18n.t('message.common.code.placeholder'),
    showCancelButton: false,
    closeOnClickModal: false,
    inputPattern: /^\d{6}$/,
    inputErrorMessage: i18n.t('message.common.code.wrongFormat'),
    beforeClose: (action, instance, done) => {
      if (action === 'confirm') {
        codeApp.validateFun(done)
      } else {
        done()
      }
    },
    message: h(Code, {
      props: {
        resolve,
        param,
        update: Math.random(),
        setApp
      }
    })
  }).then(async () => {
  }, () => {
    resolve({
      type: 'cancel',
      message: i18n.t('message.common.code.cancelCode')
    })
  })
}

export const qrcodeIframe = (url) => {
  // const openURL = (url && typeof url === 'string') ? url : undefined
  // const target = openURL || (location.origin.includes('172') ? 'http://kn.oa2-testing.kuainiujinke.com/crypt/auth' : location.origin + '/crypt/auth')
  if (location.search.includes('external=true')) {
    window.location.href = url
  } else {
    window.location.search += window.location.search ? '&ntrtfd=1' : '?ntrtfd=1'
  }

  // const target = 'http://172.16.39.222:3000/postMessage/index.html'
  // Vue.prototype.$msgbox({
  //   title: '权限验证',
  //   customClass: 'qrcode_iframe',
  //   // message: h('iframe', { attrs: { src: target, id: 'qrcode_iframe' }, style: 'width:100%;height:550px;border:none;border-radius:3px' })
  //   message: `<iframe src='${target}' id='qrcode_iframe' style='width:100%;height:550px;border:none;border-radius:3px'/>`,
  //   dangerouslyUseHTMLString: true
  // })
  const message = fromEvent(window, 'message')
  return message.pipe(
    filter(res => res.data === 'qrcode_success'),
    throttleTime(1000),
    map(() => {
      document.querySelector('div.qrcode_iframe').parentElement.remove()
      document.querySelector('div.v-modal').remove()
      return ''
    })
  )
}
