<template>
  <div>
    <!-- <el-upload
      class="upload-demo"
      :action="uploadURL"
      :on-preview="handlePreview"
      :on-remove="handleRemove"
      :file-list="fileList"
      list-type="picture"
      :disabled="disabled"
    >
      <el-button
        type="primary"
      >
        点击上传
      </el-button>
    </el-upload> -->

    <div v-loading="loading !== 0">
      <!-- upload -->
      <el-upload
        v-if="editable"
        ref="uploadwidget"
        :action="uploadURL"
        :limit="limit ? limit - fileList.length : 0"
        :disabled="disabled || !!(limit && (limit - fileList.length < 1))"
        :multiple="multiple"
        :show-file-list="false"
        :on-success="handleSuccess"
        :on-exceed="handleExceed"
        :on-error="handleError"
        :before-upload="handleBeforeUpload"
        :headers="{'X-CSRF-TOKEN':content}"
        :accept="type === 'file' ? '' : 'image/*'"
      >
        <el-button
          type="primary"
          :disabled="disabled || !!(limit && (limit - fileList.length < 1))"
          @click="btnClick"
        >
          {{ type === 'file' ? $t('message.common.uploadFile') : $t('message.common.uploadPicture') }}
        </el-button>
      </el-upload>
      <!-- file list -->
      <div v-if="!editable && widgetExtends && widgetExtends.permission && !widgetExtends.permission.download && !widgetExtends.permission.view">
        {{ $t('message.flowApply.attachmentsNotVisible') }}
      </div>
      <el-row v-else>
        <el-col
          v-for="(item, index) in fileList"
          :key="index"
          class="file-item"
        >
          <div
            v-if="editable"
            style="display:flex"
          >
            <div style="display:flex">
              <img
                v-if="type === 'img'"
                width="40px"
                height="40px"
                :src="imgUrl[index]"
              >
              <p
                style="margin-left:10px;"
              >
                {{ item.filename }}
              </p>
            </div>
            <el-button
              v-if="type !== 'file'"
              type="text"
              icon="el-icon-zoom-in"
              @click="handlePreview(index)"
            />
            <el-button
              type="text"
              icon="el-icon-delete"
              @click="handleRemove(index)"
            />
          </div>
          <!-- 下载链接 -->
          <template v-if="!editable">
            <template v-if="typeof item !== 'string'">
              <i
                style="color:rgba(14,31,53,0.85);font-size:14px;"
                class="el-icon-paperclip"
              />
              <span class="file-name">{{ item.filename }}</span>
              <a
                v-if="(!widgetExtends || !widgetExtends.permission || widgetExtends.permission.view) &&
                  ((uploadType.filter(items => items === item.filename.substring(item.filename.lastIndexOf('.')+1).toLocaleLowerCase()).length > 0) ||
                  (uploadType.filter(items => items === item.filename.substring(item.filename.lastIndexOf('/')+1).toLocaleLowerCase()).length > 0))"
                :href="`/workflow/entries/download/${item ? item.id : ''}/${flowId}/${modelKey}` + ($route.query.external === 'true' ? '?external=true' : '')"
                target="_blank"
                class="file-link"
              >
                {{ $t('message.common.preview') }}
              </a>
              <a
                v-if="!widgetExtends || !widgetExtends.permission || widgetExtends.permission.download"
                :href="`/workflow/entries/download/${item ? item.id : ''}/${flowId}/${modelKey}` + ($route.query.external === 'true' ? '?external=true' : '')"
                download=""
                class="file-link"
              >
                {{ $t('message.common.download') }}
              </a>
            </template>
            <template v-else>
              <i
                style="color:rgba(14,31,53,0.85);font-size:14px;"
                class="el-icon-paperclip"
              />
              <span class="file-name">{{ item }}</span>
              <a
                v-if="(!widgetExtends || !widgetExtends.permission || widgetExtends.permission.view) &&
                  ((uploadType.filter(items => items === item.substring(item.lastIndexOf('.')+1).toLocaleLowerCase()).length > 0) || (uploadType.filter(items => items === item.substring(item.lastIndexOf('/')+1).toLocaleLowerCase()).length > 0))"
                :href="item + ($route.query.external === 'true' ? '?external=true' : '')"
                target="_blank"
                class="file-link"
              >
                {{ $t('message.common.preview') }}
              </a>
              <a
                v-if="!widgetExtends || !widgetExtends.permission || widgetExtends.permission.download"
                :href="item + ($route.query.external === 'true' ? '?external=true' : '')"
                download=""
                class="file-link"
              >
                {{ $t('message.common.download') }}
              </a>
            </template>
          </template>
          <excel-show
            v-if="!editable && showFile && isShowFile(item ? item.filename : '') && (typeof item !== 'string')"
            :url="`/workflow/entries/download/${item ? item.id : ''}/${flowId}/${modelKey}` + ($route.query.external === 'true' ? '?external=true' : '')"
          />
        </el-col>
      </el-row>
      <!-- img preview dialog -->
      <el-dialog
        :append-to-body="true"
        :visible.sync="dialogVisible"
      >
        <img
          width="100%"
          :src="dialogImageUrl"
          alt=""
        >
      </el-dialog>
    </div>
  </div>
</template>

<script>
import ExcelShow from '../ExcelShow'

export default {
  name: 'Upload',
  components: {
    ExcelShow
  },
  props: {
    type: {
      type: String,
      default: 'file'
    },
    value: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: true
    },
    limit: {
      type: Number,
      default: 8
    },
    editable: { // 是否是可编辑表单
      type: Boolean,
      default: true
    },
    modelKey: { // 所绑定的字段的model
      type: String,
      default: ''
    },
    flowId: {
      type: String,
      default: ''
    },
    showFile: {
      type: Boolean,
      default: false
    },
    widgetExtends: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      uploadURL: `${process.env.MIX_VUE_APP_APIPRE}/workflow/forms/upload`,
      dialogImageUrl: '',
      dialogVisible: false,
      fileList: this.value,
      imgUrl: [],
      loading: 0,
      content: '',
      uploadType: [
        'pdf',
        'png',
        'jpg',
        'gif',
        'html',
        'json',
        'jpe',
        'jpeg'
      ],
      window
    }
  },
  watch: {
    fileList: {
      handler () {
        this.$emit('input', this.fileList)
      },
      deep: true
    },
    value: {
      handler () {
        this.fileList = this.value
      },
      deep: true
    }
  },
  created() {
    this.content = document.getElementsByName('csrf-token')[0] ? document.getElementsByName('csrf-token')[0].content : ''
    // this.content = 'XEi89vmlf3TcBH3sx27POootb1XJIQ9UMYDswfnO'
  },
  methods: {
    // 判断文件是否直接展示内容
    isShowFile(url) {
      return /\.xlsx$/.test(url)
    },
    handleRemove(index) {
      this.imgUrl.splice(index, 1)
      this.fileList.splice(index, 1)
    },
    handlePreview(index) {
      this.dialogImageUrl = this.imgUrl[index]
      this.dialogVisible = true
    },
    handleSuccess (res) {
      this.loading -= 1
      if (res && res.code === 0) {
        this.imgUrl.push(res.data.url)
        console.log(this.imgUrl)
        this.fileList.push(res.data.item)
      } else {
        this.$message.error({ message: this.$t('message.common.requestTips.failed'), duration: 10000, showClose: true })
      }
      // 结束后清空内部列表
      if (this.loading === 0) {
        this.$refs.uploadwidget.clearFiles()
      }
    },
    handleError () {
      this.loading -= 1
      this.$message.error({ message: this.$t('message.common.requestTips.failed'), duration: 10000, showClose: true })
      // 结束后清空内部列表
      if (this.loading === 0) {
        this.$refs.uploadwidget.clearFiles()
      }
    },
    // 点击上传按钮
    btnClick () {
      if ((this.limit && (this.limit - this.fileList.length < 1))) {
        this.handleExceed()
      }
    },
    handleExceed () {
      this.$message.error({ message: this.$t('message.flowApply.maxUpload', { number: this.limit }), duration: 10000, showClose: true })
    },
    handleBeforeUpload () {
      this.loading += 1
    }
  }
}
</script>

<style lang="stylus">
.file-item
  margin-bottom 18px
  white-space nowrap
  font-size 14px
  display flex
  align-items center
  p
    flex 1
    margin 0
  &:last-child
    border-bottom 0
.file-link
  text-decoration none
  color #3657FF
  margin-right 0.5rem
  font-size 14px
.file-link:hover
  text-decoration underline
.file-name
  max-width  54%
  overflow hidden
  text-overflow ellipsis
  white-space nowrap
  color rgba(14,31,53,0.65)
  margin 0 24px 0 7px
.file-item
  &:last-child
    margin-bottom 0px !important
</style>
