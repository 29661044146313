<template v-if="widget.type == 'input'">
  <div
    :class="[
      {'number-form-item': isNumber && !operations.readonlys[widget.model] && !operations.disableds[widget.model]}]"
  >
    <!-- 解决无法在小数点后面输入0的问题 start -->
    <div
      v-if="isNumber && !operations.readonlys[widget.model] && !operations.disableds[widget.model]"
      :class="['original-input-box', 'el-input', 'el-input--middle', 'el-input-group', {'el-input-group--append': widget.options.suffix}, {'el-input-group--prepend': widget.options.prefix}]"
      :style="{width: widget.options.width}"
    >
      <div
        v-if="widget.options.prefix"
        class="el-input-group__prepend"
      >
        {{ widget.options.prefix }}
      </div>
      <input
        ref="originalInput"
        v-model.number="dataModel"
        :placeholder="widget.options.placeholder"
        :autofocus="isFirst"
        class="el-input__inner"
        @input="numberInput"
        @change="$emit('blur')"
        @blur="$emit('blur')"
      >
      <div
        v-if="widget.options.suffix"
        class="el-input-group__append"
      >
        {{ widget.options.suffix }}
      </div>
    </div>
    <el-input
      v-if="widget.options.dataType == 'number' || widget.options.dataType == 'integer' || widget.options.dataType == 'float'"
      v-model.number="dataModel"
      :autofocus="isFirst"
      :type="widget.options.dataType"
      :placeholder="widget.options.placeholder"
      :style="{width: widget.options.width}"
      :readonly="operations.readonlys[widget.model]"
      :disabled="operations.disableds[widget.model]"
      :class="[{'read-only': operations.readonlys[widget.model]}]"
    >
      <template
        v-if="widget.options.prefix !== '' && widget.options.prefix !== undefined && widget.options.prefix !== null"
        slot="prepend"
      >
        {{ widget.options.prefix }}
      </template>
      <template
        v-if="widget.options.suffix !== '' && widget.options.suffix !== undefined && widget.options.suffix !== null"
        slot="append"
      >
        {{ widget.options.suffix }}
      </template>
    </el-input>
    <!-- 解决无法在小数点后面输入0的问题 end -->
    <el-input
      v-else
      v-model="dataModel"
      :autofocus="isFirst"
      :type="widget.options.dataType"
      :placeholder="widget.options.placeholder"
      :style="{width: widget.options.width}"
      :readonly="operations.readonlys[widget.model]"
      :disabled="operations.disableds[widget.model]"
      :class="[{'read-only': operations.readonlys[widget.model]}]"
      @input="input"
    >
      <template
        v-if="widget.options.prefix !== '' && widget.options.prefix !== undefined && widget.options.prefix !== null"
        slot="prepend"
      >
        {{ widget.options.prefix }}
      </template>
      <template
        v-if="widget.options.suffix !== '' && widget.options.suffix !== undefined && widget.options.suffix !== null"
        slot="append"
      >
        {{ widget.options.suffix }}
      </template>
    </el-input>
  </div>
</template>

<script>
export default {
  name: 'InputComponent',
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    widget: {
      type: Object,
      default: () => {},
      required: true
    },
    operations: {
      type: Object,
      default: () => {}
    },
    isFirst: { // 是否是表单的第一个组件：是的情况需要聚焦
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      dataModel: this.value
    }
  },
  computed: {
    isNumber () {
      return this.widget.options.dataType == 'number' || this.widget.options.dataType == 'integer' || this.widget.options.dataType == 'float'
    }
  },
  watch: {
    value: {
      handler () {
        this.initValue()
      },
      deep: true
    }
  },
  created () {
    this.initValue()
  },
  methods: {
    initValue () {
      if (this.value && this.isNumber && typeof this.value === 'string') {
        this.dataModel = parseFloat(this.value)
        if (isNaN(this.dataModel)) {
          this.dataModel = ''
        }
        this.$emit('input', this.dataModel)
      } else {
        this.dataModel = this.value
      }
    },
    getValueByEvent (e) {
      let value = e.target.value
      if (typeof value === 'string') {
        let str = value.replace(/[^0-9.]/g, '')
        value = str.length > 0 ? Number(str) : ''
      }
      this.dataModel = value
      return value
    },
    numberInput (e) {
      this.$emit('input', this.getValueByEvent(e))
    },
    input (value) {
      this.$emit('input', value)
    }
  }
}
</script>
<style>
  .el-input__inner {
    height: 2.25rem;
    line-height: 2.25rem;
  }
</style>
<style lang="stylus" scoped>
.read-only
  >.el-input__inner[readonly="readonly"]
  >.el-textarea__inner[readonly="readonly"]
  >>>.el-input__inner[readonly="readonly"]
  >>>.el-textarea__inner[readonly="readonly"]
    background-color #fafafa

.number-form-item
  >>>.el-form-item__content
    height 2.25rem
  .el-input
    opacity 0
.original-input-box
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity 1!important
.el-input-group__prepend
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
.el-input-group--append
  .original-input
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
.el-input-group__append
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
.el-input-group--prepend
  .original-input
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
.original-input
  border: 1px solid transparent;
  height: 100%;
  background-color: transparent;
  font-size: 14px;color: #606266;
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 2.25rem;
  line-height: 2.25rem;
  outline: 0;
  padding: 0 15px;
  -webkit-transition: border-color .2s cubic-bezier(.645,.045,.355,1);
  transition: border-color .2s cubic-bezier(.645,.045,.355,1);
  width: 100%;
.original-input:focus
  border-color #54C3F1!important
  outline: 0
.original-input::placeholder
  color:#C0C4CC
.el-form-item.is-error
  .original-input
    border-color: #f56c6c;
.el-form-item.is-success
  .original-input
    border-color: #67c23a;
</style>
