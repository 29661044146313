<template>
  <el-form
    size="mini"
    class="expression-container"
  >
    <el-form-item>
      <div
        id="stackContainer"
        class="expression-box"
        @click.self="unActiveItem"
      >
        <div
          class="stack-item"
          @click="editItem(-1)"
        >
          <span :class="['cursor-box', { 'active-cursor': activeIndex === -1 }]">
            <!-- <span class="cursor-show">|</span> -->

            <el-popover
              v-if="activeIndex === -1"
              :value="true"
              :popper-class="(keyboardInputVisible ? 'input-popover' : 'input-popover hide-popover') + (popoverLeft ? ' left-popover' : '')"
              trigger="manual"
              width="400"
              @click.stop="fun"
            >
              <div>
                <div class="el-input el-input--mini">
                  <input
                    ref="keyboardInput"
                    v-model="keyboardInputKey"
                    type="text"
                    autocomplete="off"
                    class="el-input__inner"
                    @input="activeSelectIndex = (options && options.length > 0) ? 0 : -1"
                    @keyup="inputKeyUp"
                    @click.stop="fun"
                    @keydown.38.prevent="fun"
                    @keydown.40.prevent="fun"
                    @keydown.9.prevent="fun"
                  >
                </div>
                <ul class="input-list">
                  <li
                    v-for="(i, itenIndex) in options"
                    :key="i.type + i.value + itenIndex"
                    :class="['select-item', { 'select-item-active': activeSelectIndex === itenIndex }]"
                    @click.stop="handleSelect(i)"
                  >
                    <pre
                      :class="[
                        'item-type-' + i.type
                      ]"
                    >{{ i.label }}</pre>
                  </li>
                </ul>
              </div>
              <span
                slot="reference"
                class="cursor-show"
              >|</span>
            </el-popover>
            <span
              v-else
              class="cursor-show"
            >|</span>
          </span>
        </div>
        <div
          v-for="(item, index) in stack"
          :id="'item' + index"
          :key="stack[index].value + (index)"
          :ref="'item' + stack[index].value + (index)"
          class="stack-item"
          @click="editItem(index)"
        >
          <el-popover
            v-if="item.fun === 'remoteFunction' && item.id"
            :value="activeIndex === index && activeTooltipVisible"
            :content="(remoteFunctions[item.id] || {}).intro"
            width="200"
            placement="top"
            trigger="hover"
            @hide="activeTooltipVisible = false"
          >
            <span
              slot="reference"
              :class="[
                'stack-item-show',
                'item-type-' + stack[index].type,
                { 'active-item': activeIndex === index },
                { 'error-item': errorIndex === index },
                { 'data-item': dataTypes.includes(stack[index].type) },
                { 'operator-item': operatorTypes.includes(stack[index].type) },
                { 'parenthesis-item': 'parenthesis' === stack[index].type },
                { 'parenthesis-couple-item': activeCoupleIndex === index },
                { 'comma-item': 'comma' === stack[index].type }
              ]"
            >
              {{ getItemShow(stack[index]) }}
            </span>
          </el-popover>
          <el-popover
            v-else-if="item.source"
            :content="getSourceTooltip(item)"
            width="200"
            placement="top"
            trigger="hover"
          >
            <span
              slot="reference"
              :class="[
                'stack-item-show',
                'item-type-' + stack[index].type,
                { 'active-item': activeIndex === index },
                { 'error-item': errorIndex === index },
                { 'data-item': dataTypes.includes(stack[index].type) },
                { 'operator-item': operatorTypes.includes(stack[index].type) },
                { 'parenthesis-item': 'parenthesis' === stack[index].type },
                { 'parenthesis-couple-item': activeCoupleIndex === index },
                { 'comma-item': 'comma' === stack[index].type }
              ]"
            >
              {{ getItemShow(stack[index]) }}
            </span>
          </el-popover>
          <span
            v-else
            :class="[
              'stack-item-show',
              'item-type-' + stack[index].type,
              { 'active-item': activeIndex === index },
              { 'error-item': errorIndex === index },
              { 'data-item': dataTypes.includes(stack[index].type) },
              { 'operator-item': operatorTypes.includes(stack[index].type) },
              { 'parenthesis-item': 'parenthesis' === stack[index].type },
              { 'parenthesis-couple-item': activeCoupleIndex === index },
              { 'comma-item': 'comma' === stack[index].type }
            ]"
          >
            <template v-if="stack[index].type === 'string'">
              <i>'</i>
              <pre>{{ getItemShow(stack[index]) }}</pre><i>'</i>
            </template>
            <template v-else>
              {{ getItemShow(stack[index]) }}
            </template>

          </span>

          <span
            :id="'stackCursor' + index"
            :class="['cursor-box', { 'active-cursor': activeIndex === index }]"
          >
            <el-popover
              v-if="activeIndex === index"
              :value="true"
              :popper-class="(keyboardInputVisible ? 'input-popover' : 'input-popover hide-popover') + (popoverLeft ? ' left-popover' : '')"
              trigger="manual"
              width="400"
              @click.stop="fun"
            >
              <div>
                <div class="el-input el-input--mini">
                  <input
                    ref="keyboardInput"
                    v-model="keyboardInputKey"
                    type="text"
                    autocomplete="off"
                    class="el-input__inner"
                    @input="activeSelectIndex = (options && options.length > 0) ? 0 : -1"
                    @keyup="inputKeyUp"
                    @click.stop="fun"
                    @keydown.38.prevent="fun"
                    @keydown.40.prevent="fun"
                    @keydown.9.prevent="fun"
                  >
                </div>
                <ul class="input-list">
                  <li
                    v-for="(i, itenIndex) in options"
                    :key="i.type + i.value + itenIndex"
                    :class="['select-item', { 'select-item-active': activeSelectIndex === itenIndex }]"
                    @click.stop="handleSelect(i)"
                  >
                    <pre
                      :class="[
                        'item-type-' + i.type
                      ]"
                    >{{ i.label }}</pre>
                  </li>
                </ul>
              </div>
              <span
                slot="reference"
                class="cursor-show"
              >|</span>
            </el-popover>
            <span
              v-else
              class="cursor-show"
            >|</span>
          </span>
        </div>
      </div>
      <div style="display: flex;color:red;height:50px;line-height:50px;">
        <div style="margin-right: 2rem;">
          <el-button
            type="primary"
            :disabled="activeIndex < 0"
            icon="el-icon-minus"
            title="删除一项"
            circle
            @click="minus()"
          />
        </div>
        <div style="flex: 1">
          <span
            v-show="tips"
            class="el-icon-warning"
          /> {{ tips }}
        </div>
      </div>
      <div class="d-flex m-b-10">
        <span class="item-label">基本表单值：</span>
        <div class="button-group-box">
          <!-- checkbox的值是数组结构，暂时不考虑公式计算的原始变量 -->
          <template v-for="(item, index) in fieldOptions">
            <template v-if="(subformSingleList ? true : !item.inSubform)">
              <el-button
                v-if="item.options.display.type === 1 && item.options.display.value && !containerMayNotDisplay[item.model]"
                :key="index"
                :disabled="verify_value"
                @click="add('variate', item.model, item.name)"
              >
                {{ item.name }}
              </el-button>
              <el-popover
                v-else
                :key="index"
                v-model="defaultObj[item.model + (item.inSubform ? 'base' : '')].visible"
                placement="top"
                width="200"
                style="float: left"
              >
                <el-switch
                  v-if="item.type === 'switch' || item.options.dataType === 'boolean'"
                  v-model="defaultObj[item.model + (item.inSubform ? 'base' : '')].value"
                />
                <el-input
                  v-else
                  v-model="defaultObj[item.model + (item.inSubform ? 'base' : '')].value"
                  placeholder="请输入默认值"
                  :type="item.type === 'number' || item.options.dataType === 'number' ? 'number' : 'text'"
                  @focus="stringOrNumberFocus"
                  @blur="stringOrNumberBlur"
                />
                <div style="text-align: right; margin: 10px 0 0 0">
                  <el-button
                    type="text"
                    @click="defaultObj[item.model + (item.inSubform ? 'base' : '')].visible = false"
                  >
                    取消
                  </el-button>
                  <el-button
                    type="primary"
                    @click="addDefault(item, defaultObj[item.model + (item.inSubform ? 'base' : '')].value)"
                  >
                    确定
                  </el-button>
                </div>
                <el-button
                  slot="reference"
                  :disabled="verify_value"
                >
                  {{ item.name }}
                </el-button>
              </el-popover>
            </template>
          </template>
        </div>
      </div>
      <div
        v-if="subformList.length > 0"
        class="d-flex m-b-10"
      >
        <span class="item-label">子表单值：</span>
        <div class="button-group-box">
          <template v-for="(item, index) in subformList">
            <el-popover
              :key="index"
              v-model="defaultObj[item.model].visible"
              placement="top"
              width="250"
            >
              <el-form
                :ref="item.key"
                :model="defaultObj[item.model]"
                label-position="top"
                size="mini"
              >
                <el-form-item
                  label="函数求值"
                  :rules="[{ required: true, message: '函数不能为空' }]"
                >
                  <el-radio-group v-model="defaultObj[item.model].fun">
                    <el-radio-button label="+">
                      累加
                    </el-radio-button>
                    <el-radio-button label="-">
                      递减
                    </el-radio-button>
                    <el-radio-button label="*">
                      累乘
                    </el-radio-button>
                    <el-radio-button label="/">
                      递除
                    </el-radio-button>
                    <el-radio-button label="max">
                      最大值
                    </el-radio-button>
                    <el-radio-button label="min">
                      最小值
                    </el-radio-button>
                    <el-radio-button label="|">
                      分割
                    </el-radio-button>
                  </el-radio-group>
                </el-form-item>
                <el-form-item
                  v-if="(item.options.display.type === 1 && !item.options.display.value) || item.options.display.type === 2 || containerMayNotDisplay[item.model]"
                  label="默认值"
                  :rules="[{ required: true, message: '默认值不能为空' }]"
                >
                  <el-switch
                    v-if="item.type === 'switch' || item.options.dataType === 'boolean'"
                    v-model="defaultObj[item.model].value"
                  />
                  <el-input
                    v-else
                    v-model="defaultObj[item.model].value"
                    placeholder="请输入默认值"
                    :type="item.type === 'number' || item.options.dataType === 'number' ? 'number' : 'text'"
                    @focus="stringOrNumberFocus"
                    @blur="stringOrNumberBlur"
                  />
                </el-form-item>
              </el-form>
              <div style="text-align: right; margin: 10px 0 0 0">
                <el-button
                  type="text"
                  @click="defaultObj[item.model].visible = false"
                >
                  取消
                </el-button>
                <el-button
                  type="primary"
                  @click="addObjectFunction(item, defaultObj[item.model].fun, defaultObj[item.model].value)"
                >
                  确定
                </el-button>
              </div>
              <el-button
                slot="reference"
                :disabled="verify_value"
              >
                {{ item.name }}
              </el-button>
            </el-popover>
          </template>
          <!-- popover: subform item operator -->
        </div>
      </div>
      <div
        v-if="sourceAvailable"
        class="m-b-10 d-flex"
      >
        <span class="item-label">数据源</span>
        <function-select-and-config
          :functions="dataSources"
          :type="['object', 'basic']"
          :fields="fieldOptions"
          :config="[{ params, name: activeSource }]"
          data-source-mode
          radio-or-checkout="radio"
          style="display: inline-block"
          @change="formulaDataSourceChange"
        />
        <div v-if="activeSource && dataSources[activeSource] && dataSources[activeSource].type === 'object'">
          <span style="width:90px;display:inline-block;margin-left: 40px;">
            使用字段
          </span>
          <el-select v-model="activeProperty">
            <el-option
              v-for="(property, index) in (dataSources[activeSource] ? dataSources[activeSource].attribute : {})"
              :key="index"
              :label="property"
              :value="index"
            />
          </el-select>
        </div>
        <div>
          <el-button
            v-if="activeProperty || (activeSource && dataSources[activeSource] && dataSources[activeSource].type === 'basic')"
            type="primary"
            :disabled="verify_value"
            icon="el-icon-plus"
            style="margin-left: 9px;"
            circle
            @click="add('source', activeSource + '.' + activeProperty, dataSources[activeSource].name + (activeProperty ? '的' + dataSources[activeSource].attribute[activeProperty] : ''))"
          />
        </div>
      </div>
      <div class="basic-value-box d-flex">
        <div class="m-b-10">
          <span class="item-label">字符串：</span>
          <el-input
            v-model="stringInput"
            style="width:180px;margin-right:9px"
            @focus="stringOrNumberFocus"
            @blur="stringOrNumberBlur"
          />
          <el-button
            type="primary"
            :disabled="verify_value"
            icon="el-icon-plus"
            circle
            @click="stringAddClick(stringInput)"
          />
        </div>

        <div class="m-b-10">
          <span class="item-label">数字：</span>
          <el-input
            v-model="numberInput"
            style="width:180px;margin-right:9px"
            type="number"
            @focus="stringOrNumberFocus"
            @blur="stringOrNumberBlur"
          />
          <el-button
            type="primary"
            :disabled="verify_value"
            icon="el-icon-plus"
            circle
            @click="numberAddClick(numberInput)"
          />
        </div>
        <div
          v-if="booleanAvailable"
          class="m-b-10"
        >
          <span class="item-label">布尔值：</span>
          <el-button
            type="primary"
            :disabled="verify_value"
            @click="add('boolean', true, '真')"
          >
            真
          </el-button>
          <el-button
            type="primary"
            :disabled="verify_value"
            @click="add('boolean', false, '假')"
          >
            假
          </el-button>
        </div>
      </div>
      <div class="m-b-10 d-flex">
        <span class="item-label">运算符：</span>
        <div class="button-group-box">
          <el-button
            type="primary"
            :disabled="verify_parenthesis_right"
            @click="add('parenthesis', '(')"
          >
            (
          </el-button>
          <el-button
            type="primary"
            :disabled="verify_parenthesis_left || parenthesis_right_available < 1"
            @click="add('parenthesis', ')')"
          >
            )
          </el-button>
          <el-button
            type="primary"
            :disabled="verify_bool"
            @click="add('bool', 'AND')"
          >
            与
          </el-button>
          <el-button
            type="primary"
            :disabled="verify_bool"
            @click="add('bool', 'OR')"
          >
            或
          </el-button>
          <el-button
            type="primary"
            :disabled="verify_calculate"
            @click="add('calculate', '+')"
          >
            +
          </el-button>
          <el-button
            type="primary"
            :disabled="verify_calculate"
            @click="add('calculate', '-')"
          >
            -
          </el-button>
          <el-button
            type="primary"
            :disabled="verify_calculate"
            @click="add('calculate', '*')"
          >
            *
          </el-button>
          <el-button
            type="primary"
            :disabled="verify_calculate"
            @click="add('calculate', '/')"
          >
            /
          </el-button>
          <el-button
            type="primary"
            :disabled="verify_compare"
            @click="add('compare', '==')"
          >
            ==
          </el-button>
          <el-button
            type="primary"
            :disabled="verify_compare"
            @click="add('compare', '!=')"
          >
            !=
          </el-button>
          <el-button
            type="primary"
            :disabled="verify_compare"
            @click="add('compare', '>')"
          >
            &gt;
          </el-button>
          <el-button
            type="primary"
            :disabled="verify_compare"
            @click="add('compare', '>=')"
          >
            &gt;=
          </el-button>
          <el-button
            type="primary"
            :disabled="verify_compare"
            @click="add('compare', '<')"
          >
            &lt;
          </el-button>
          <el-button
            type="primary"
            :disabled="verify_compare"
            @click="add('compare', '<=')"
          >
            &lt;=
          </el-button>
          <el-button
            type="primary"
            :disabled="verify_compare"
            @click="add('comma', ',')"
          >
            ,
          </el-button>
        </div>
      </div>
      <!-- 前端同步函数 -->
      <div
        v-if="functionAvailable"
        class="d-flex m-b-10"
      >
        <span class="item-label">本地函数：</span>
        <div class="button-group-box">
          <el-button
            v-for="(item, index) in funs"
            :key="index"
            :disabled="verify_parenthesis_right"
            type="primary"
            @click="add('parenthesis', '(', item.label, item.value)"
          >
            {{ item.label }}
          </el-button>
        </div>
      </div>
      <!-- 请求API的异步函数 -->
      <div
        v-if="remoteFunctionAvailable"
        class="d-flex"
      >
        <span class="item-label">远程函数：</span>
        <div class="button-group-box">
          <el-popover
            v-for="(item, index) in remoteFunctions"
            :key="index"
            placement="top-start"
            title="函数描述"
            width="200"
            trigger="hover"
            :content="item.intro"
          >
            <el-button
              slot="reference"
              :disabled="verify_parenthesis_right"
              type="primary"
              @click="add('parenthesis', '(', item.name, 'remoteFunction', index)"
            >
              {{ item.name }}
            </el-button>
          </el-popover>
        </div>
      </div>
    </el-form-item>
  </el-form>
</template>

<script>
import FunctionSelectAndConfig from './FunctionSelectAndConfig'


const countParentesis = stack => {
  let left = 0
  let right = 0
  stack.forEach(v => {
    if (v.value === '(') {
      left += 1
    }
    if (v.value === ')') {
      right += 1
    }
  })
  return { left, right }
}

export default {
  name: 'ExpressionEditor', // 公式设计器
  components: {
    FunctionSelectAndConfig
  },
  props: {
    formList: {
      type: Array,
      default: () => []
    },
    fieldOptions: {
      type: Array,
      default: () => []
    },
    sourceAvailable: {
      type: Boolean,
      default: false
    },
    functionAvailable: {
      type: Boolean,
      default: false
    },
    remoteFunctionAvailable: {
      type: Boolean,
      default: false
    },
    booleanAvailable: { // 是否提供布尔值输入
      type: Boolean,
      default: true
    },
    subformSingleList: { // 是否在基本组件列表中展示子表单内的组件
      type: Boolean,
      default: false
    },
    remoteFunctions: { // 远程函数列表
      type: Object,
      default: () => { }
    },
    oldData: { // 编辑时，旧的公式
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      stack: this.oldData || [],
      stringInput: '',
      numberInput: '',
      popoverVisible: false,
      popoverModel: '',
      defaultVisible: false,
      defaultValue: '',
      defaultObj: this.fieldOptions.reduce((a, c) => {
        const value = c.type === 'switch' ? false : ''
        if (c.inSubform) {
          a[c.model + 'base'] = { visible: false, value, fun: '+' }
        }
        a[c.model] = { visible: false, value, fun: '+' }
        return a;
      }, {}),
      objectFunc: '+',

      verify_value: false,
      verify_parenthesis_right: false,
      verify_parenthesis_left: true,
      verify_calculate: true,
      verify_bool: true,
      verify_compare: true,
      parenthesis_right_available: 0, // 可以添加的反括号的数量，小于1时不能添加

      tips: '',
      dataSources: {},
      activeSource: '',
      activeProperty: '',
      activeSubformPropertyName: '',
      funs: [ // 具体实现在util下面的computeFunction里面，增加时要去增加对应的实现
        {
          label: '大写金额',
          value: 'largeMoney'
        },
        {
          label: '整天',
          value: 'wholeDay'
        },
        {
          label: '四舍五入',
          value: 'round'
        },
        {
          label: '上取整',
          value: 'ceil'
        },
        {
          label: '下取整',
          value: 'floor'
        },
        {
          label: '绝对值',
          value: 'abs'
        }, {
          label: '保留指定位的小数',
          value: 'toNFixed'
        },
        {
          label: '转字符串',
          value: 'num2Str'
        },
        {
          label: '数组包含判断',
          value: 'arrayIncludes'
        }, {
          label: 'IF',
          value: 'condition'
        }
      ],
      params: [],
      activeIndex: -1,
      activeCoupleIndex: undefined,
      errorIndex: -1, // 验证时有错误的公式元素的下标
      dataTypes: ['variate', 'number', 'string', 'boolean', 'source'],
      operatorTypes: ['calculate', 'compare', 'bool'],
      keyboardInputVisible: false,
      keyboardInputKey: '',
      keyboardInputValue: null,
      inputFocusInterval: null, // 输入框focus的计时器
      isInputFocus: false,
      isStringOrNumberFocus: false,
      activeSelectIndex: -1,
      activeTooltipVisible: false,
      operators: [
        {
          type: 'parenthesis',
          value: '('
        },
        {
          type: 'parenthesis',
          value: ')'
        },
        {
          type: 'bool',
          value: 'AND',
          label: '与'
        },
        {
          type: 'bool',
          value: 'OR',
          label: '或'
        },
        {
          type: 'calculate',
          value: '+'
        },
        {
          type: 'calculate',
          value: '-'
        },
        {
          type: 'calculate',
          value: '*'
        },
        {
          type: 'calculate',
          value: '/'
        },
        {
          type: 'compare',
          value: '=='
        },
        {
          type: 'compare',
          value: '!='
        },
        {
          type: 'compare',
          value: '>'
        },
        {
          type: 'compare',
          value: '>='
        },
        {
          type: 'compare',
          value: '<'
        },
        {
          type: 'compare',
          value: '<='
        },
        {
          type: 'comma',
          value: ','
        }
      ],
      closeInputEnter: false
    }
  },
  computed: {
    expressionText: function () {
      let text = ''
      this.stack.forEach(v => {
        if (v.type === 'variate') {
          text += `$${v.value} `
        } else if (v.type === 'subform') {
          text += `$${v.value} ${v.operator} ... `
        } else if (v.type === 'source') {
          text += `$${v.source}.${v.value} `
        } else if (v.type === 'parenthesis' && v.value === '(' && v.fun && v.id) {
          text += `${v.id}${v.value} `
        } else {
          text += `${v.value} `
        }
      })
      return text
    },
    expressionShow: function () {
      let text = ''
      this.stack.forEach(v => {
        if (v.type === 'variate') {
          text += `${v.label || v.value} `
        } else if (v.type === 'subform') {
          text += `${v.label || v.value} ${v.operator} ... `
        } else if (v.type === 'source') {
          text += `${v.label || (v.source + '.' + v.value)} `
        } else {
          if (v.fun) {
            text += `${v.label + v.value} `
          } else {
            text += `${v.label || v.value} `
          }
        }
      })
      return text
    },
    // 子表单字段
    subformList() {
      return this.fieldOptions.filter(item => {
        return item.inSubform
      }) || []
    },
    containerMayNotDisplay() {
      const needFill = {}
      this.formList.forEach(item => {
        if (['grid', 'subform'].includes(item.type) && ((item.options.display.type === 1 && !item.options.display.value) || item.options.display.type === 2) && item.columns) {
          item.columns.forEach(col => {
            if (col.list) {
              col.list.forEach(c => {
                needFill[c.model] = true
              })
            }
          })
        }
      })
      return needFill
    },
    // 输入内容得到的可选
    options() {
      if (this.keyboardInputKey) {
        let key = this.keyboardInputKey
        let keyStr = ''
        keyStr = key.split('').map(s => {
          if (/\W$/.test(s)) {
            s = '\\' + s
            return s
          } else {
            return s
          }
        }).join('')
        const keyStrRegExp = new RegExp(keyStr, 'i')
        let res = []
        // 数字
        const num = parseFloat(key)
        if (!isNaN(num)) {
          res.push({
            type: 'number',
            label: `${num} (数字)`,
            value: num
          })
        }
        // 基础组件字段
        this.fieldOptions.forEach(c => {
          if ((this.subformSingleList ? true : !c.inSubform) && c.name.match(keyStrRegExp)) {
            res.push({
              type: 'basic',
              label: `${c.name} (基础组件)`,
              value: c.model,
              component: c
            })
          }
        })
        // 子表单字段
        this.subformList.forEach(s => {
          if (s.name.match(keyStrRegExp)) {
            res = res.concat([
              {
                type: 'subform',
                label: `${s.name} (子表单组件累加)`,
                value: s.model + '+',
                operator: '+',
                component: s
              },
              {
                type: 'subform',
                label: `${s.name} (子表单组件递减)`,
                value: s.model + '-',
                operator: '-',
                component: s
              },
              {
                type: 'subform',
                label: `${s.name} (子表单组件累乘)`,
                value: s.model + '*',
                operator: '*',
                component: s
              },
              {
                type: 'subform',
                label: `${s.name} (子表单组件递除)`,
                value: s.model + '/',
                operator: '/',
                component: s
              },
               {
                type: 'subform',
                label: `${s.name} (子表单组件最大值)`,
                value: s.model + 'max',
                operator: 'max',
                component: s
              },
               {
                type: 'subform',
                label: `${s.name} (子表单组件最小值)`,
                value: s.model + 'min',
                operator: 'min',
                component: s
              },
               {
                type: 'subform',
                label: `${s.name} (子表单组件分割)`,
                value: s.model + '|',
                operator: '|',
                component: s
              }
            ])
          }
        })
        // 布尔型
        if ('true'.match(keyStrRegExp) || key === '真') {
          res.push({
            type: 'boolean',
            label: `真/true (布尔值)`,
            value: true
          })
        }
        if ('false'.match(keyStrRegExp) || key === '假') {
          res.push({
            type: 'boolean',
            label: `假/false (布尔值)`,
            value: false
          })
        }
        // 运算符
        this.operators.forEach(o => {
          if (keyStrRegExp.test(o.value) || keyStrRegExp.test(o.label)) {
            // if (o.value.match(key)) {
            res.push({
              type: o.type,
              label: `${o.value} (运算符)`,
              value: o.value
            })
          }
        })
        // local function
        this.funs.forEach(f => {
          if (keyStrRegExp.test(f.label)) {
            res.push({
              type: 'localFunction',
              label: `${f.label} (本地函数)`,
              value: f
            })
          }
        })
        // remote function
        const remoteFunctions = Object.keys(this.remoteFunctions).map(k => {
          return { ...this.remoteFunctions[k], index: k }
        }) || []
        remoteFunctions.forEach(f => {
          if (keyStrRegExp.test(f.name)) {
            res.push({
              type: 'remoteFunction',
              label: `${f.name} (远程函数)`,
              value: f
            })
          }
        })
        // 符串串
        res.push({
          type: 'string',
          label: `'${key}' (字符串)`,
          value: key
        })
        return res
      } else {
        return [
          {
            type: 'string',
            label: `'' (字符串)`,
            value: ''
          }
        ]
      }
    },
    popoverLeft() {
      // 根据选中项位置，处理输入popover是否right: 0
      const containerEl = document.getElementById('stackContainer')
      const itemContainerEl = document.getElementById('item' + this.activeIndex)
      const activeCursorEl = document.getElementById('stackCursor' + this.activeIndex)
      if (itemContainerEl && activeCursorEl) {
        return containerEl.clientWidth - itemContainerEl.offsetLeft - itemContainerEl.clientWidth < 400
      }
      return false
    }
  },
  watch: {
    // 当前active的下标变化时
    activeIndex(v) {
      if (v > -1) {
        this.verify(this.stack[v].type, this.stack[v].value)
      } else {
        this.verify('', '')
      }

      // 若是括号，找到与之对应的另一半（若有的话），高亮以便阅读公式
      const item = this.stack[this.activeIndex]
      if (item && item.type === 'parenthesis') {
        const coupleIndex = this.findParenthesisCoupleIndex(this.activeIndex)
        if (coupleIndex !== undefined) {
          this.activeCoupleIndex = coupleIndex
        } else {
          this.activeCoupleIndex = undefined
        }
      } else {
        this.activeCoupleIndex = undefined
      }
    }
  },
  async created() {
    if (this.sourceAvailable) {
      const res = await this.$http.get('/workflow/data-source')
      if (res.data.code === 0) {
        this.dataSources = res.data.data
      }
    }
    if (this.stack && this.stack.length > 0) {
      this.verify(this.stack[this.stack.length - 1].type, this.stack[this.stack.length - 1].value)
    }
  },
  mounted() {
    this.unActiveItem()
    this.bindKeyOperate()
    this.bindEscCloseInput()
  },
  beforeDestroy() {
    // this.unbindKeyOperate()
    this.unbindEscCloseInput()
    window.removeEventListener('keyup', this.keyOperate)
    window.removeEventListener('click', this.closeInput)
    this.endInterval()
  },
  methods: {
    fun() { },
    stringOrNumberFocus() {
      this.isStringOrNumberFocus = true
      this.unbindKeyOperate()
    },
    stringOrNumberBlur() {
      this.isStringOrNumberFocus = false
      this.bindKeyOperate()
    },
    // 绑定键盘事件监听
    bindKeyOperate() {
      window.addEventListener('keyup', this.keyOperate)
      window.removeEventListener('click', this.closeInput)
      // console.log('bind')
      this.startInterval()
    },
    // 删除键盘事件监听
    unbindKeyOperate() {
      window.removeEventListener('keyup', this.keyOperate)
      window.addEventListener('click', this.closeInput)
      this.endInterval()
      // console.log('unbind')
    },
    // 检查数据源的字段选择、字符串输入、数字输入是否focus，若都没有focus，则focus输入框
    startInterval() {
      this.endInterval()
      this.inputFocusInterval = setInterval(() => {
        this.inputFocus()
      }, 100)
    },
    endInterval() {
      if (this.inputFocusInterval) {
        clearInterval(this.inputFocusInterval)
        this.inputFocusInterval = null
      }
    },
    bindEscCloseInput() {
      window.addEventListener('keyup', this.escCloseInput)
    },
    unbindEscCloseInput() {
      window.removeEventListener('keyup', this.escCloseInput)
    },
    // 键盘esc关闭输入框
    escCloseInput(e) {
      if (e.keyCode === 27) {
        this.closeInput()
      }
    },
    closeInput() {
      this.$nextTick(() => {
        // console.log('input close')
        this.keyboardInputKey = ''
        this.keyboardInputVisible = false
        this.isInputFocus = false
        if (!this.isStringOrNumberFocus) {
          this.bindKeyOperate()
        }
      })
    },
    // 键盘输入focus
    inputFocus() {
      this.$nextTick(() => {
        const keyboardInput = this.$refs.keyboardInput
        // console.log(keyboardInput)
        if (Array.isArray(keyboardInput)) {
          if (!this.isStringOrNumberFocus && keyboardInput && keyboardInput[0]) {
            this.$refs.keyboardInput[0].focus({ preventScroll: true })
            this.isInputFocus = true
          }
        } else if (keyboardInput && !this.isStringOrNumberFocus) {
          this.$refs.keyboardInput.focus({ preventScroll: true })
          this.isInputFocus = true
        }
      })
    },
    /**
     * 寻找公式里面括号的另一半
     * @param index 原括号在公式数组里面的下标
     * @return index 另一半的下标，未找到则为undefined
     */
    findParenthesisCoupleIndex(index) {
      let coupleIndex
      const item = this.stack[index]
      if (item && item.type === 'parenthesis') {
        if (item.value === '(') {
          let couple = 1
          for (let i = index + 1; i < this.stack.length; i++) {
            if (this.stack[i].type === 'parenthesis') {
              couple += this.stack[i].value === '(' ? 1 : -1
            }
            if (couple === 0) {
              coupleIndex = i
              break
            }
          }
        } else {
          let couple = 1
          if (index > 0) {
            for (let i = index - 1; i >= 0; i--) {
              if (this.stack[i].type === 'parenthesis') {
                couple += this.stack[i].value === ')' ? 1 : -1
              }
              if (couple === 0) {
                coupleIndex = i
                break
              }
            }
          }
        }
      }
      return coupleIndex
    },
    // 键盘输入确定回调
    handleSelect(res) {
      if (this.keyboardInputVisible) {
        // console.log(res, 11112222)
        this.closeInputEnter = true
        switch (res.type) {
          case 'basic':
            if (res.component.options.display.type === 1 && res.component.options.display.value && !this.containerMayNotDisplay[res.component.model]) {
              this.add('variate', res.component.model, res.component.name)
            } else {
              this.add('parenthesis', '(', '默认取值', 'defaultValue')
              this.add('variate', res.component.model, res.component.name)
              this.add('comma', ',')
            }
            break
          case 'subform':
            switch (res.operator) {
              case '+':
                this.add('parenthesis', '(', '累加', 'reduceAdd')
                break
              case '-':
                this.add('parenthesis', '(', '递减', 'reduceSub')
                break
              case '*':
                this.add('parenthesis', '(', '累乘', 'reduceMul')
                break
              case '/':
                this.add('parenthesis', '(', '递除', 'reduceDiv')
                break
              case 'max':
                this.add('parenthesis', '(', '最大值', 'reduceMax')
                break
              case 'min':
                this.add('parenthesis', '(', '最小值', 'reduceMin')
                break
              case '|':
                this.add('parenthesis', '(', '切割', 'reduceCut')
                break
            }
            this.add('variate', res.component.model, res.component.name)
            if ((res.component.options.display.type === 1 && !res.component.options.display.value) || res.component.options.display.type === 2 || this.containerMayNotDisplay[res.component.model]) {
              this.add('comma', ',')
            }
            break
          case 'parenthesis':
            this.add(res.type, res.value)
            break
          case 'bool':
            this.add(res.type, res.value)
            break
          case 'calculate':
            this.add(res.type, res.value)
            break
          case 'compare':
            this.add(res.type, res.value)
            break
          case 'comma':
            this.add(res.type, res.value)
            break
          case 'string':
            this.add(res.type, res.value)
            break
          case 'number':
            this.add(res.type, res.value)
            break
          case 'boolean':
            this.add(res.type, res.value, res.value === true ? '真' : '假')
            break
          case 'localFunction':
            this.add('parenthesis', '(', res.value.label, res.value.value)
            break
          case 'remoteFunction':
            this.add('parenthesis', '(', res.value.name, 'remoteFunction', res.value.index)
            break
          default:
            break
        }
      }
      this.closeInput()
    },
    // 输入框键盘事件
    inputKeyUp(e) {
      // console.log(e, e.key, e.shiftKey, e.keyCode, 'inputKeyUp')
      if (e.keyCode === 40) {
        // bottom arrow
        if (this.activeSelectIndex < this.options.length - 1) {
          this.activeSelectIndex += 1
        } else if (this.options.length > 0) {
          this.activeSelectIndex = 0
        } else {
          this.activeSelectIndex = -1
        }
      } else if (e.keyCode === 38) {
        // top arrow
        if (this.activeSelectIndex > 0) {
          this.activeSelectIndex -= 1
        } else if (this.options.length > 0) {
          this.activeSelectIndex = this.options.length - 1
        } else {
          this.activeSelectIndex = -1
        }
      } else if (e.keyCode === 13 && this.activeSelectIndex !== -1) {
        this.handleSelect(this.options[this.activeSelectIndex])
      }
    },
    // 键盘操作：左右、删除、单个运算符、括号
    keyOperate(e) {
      if (this.closeInputEnter) {
        this.closeInputEnter = false
        return
      }
      // console.log(e, e.key, e.shiftKey, e.keyCode, 'keyOperate')
      const isNumber = e.keyCode >= 48 && e.keyCode <= 57 // 数字
      const isLetter = e.keyCode >= 65 && e.keyCode <= 90 // 字母
      const symbolKeyCodes = [
        192, // ` ~
        187, // - _
        189, // = +
        219, // [ {
        221, // ] }
        220, // \ |
        186, // ; :
        222, // ' "
        188, // , <
        190, // . >
        191, // / ?
        111, // /(number)
        106, // *(number)
        109, // -(number)
        107, // +(number)
        110, // .(number)
        32 // space
      ] // 特殊字符
      const isSymbol = symbolKeyCodes.includes(e.keyCode)
      if (isNumber || isLetter || isSymbol) {
        this.keyboardInputValue = null
        this.keyboardInputVisible = true
        this.inputFocus()
        this.unbindKeyOperate()
        return
      }
      if (e.keyCode === 8 && this.activeIndex >= 0) {
        // backspace
        this.minus()
      } else if (e.keyCode === 37 && this.activeIndex >= 0) {
        // left
        this.activeIndex -= 1
      } else if (e.keyCode === 46 && this.activeIndex < this.stack.length - 1) {
        // delete
        this.activeIndex += 1
        this.minus()
      } else if (e.keyCode === 39 && this.activeIndex < this.stack.length - 1) {
        // right
        this.activeIndex += 1
      } else if (e.keyCode === 38) {
        // top
        this.activeIndex = -1
      } else if (e.keyCode === 40) {
        // down
        this.activeIndex = this.stack.length - 1
      } else if (e.keyCode === 13) {
        // enter
        const res = this.getExpressionShow()
        if (res) {
          this.$emit('submit-data')
        }
      } else {
        switch (e.keyCode) {
          case 187:
            // + 和 =
            if (e.shiftKey && !this.verify_calculate) {
              // +
              this.add('calculate', '+')
            } else if (!e.shiftKey && !this.verify_compare) {
              // =
              this.add('compare', '==')
            }
            break
          case 49:
            // !=
            if (e.shiftKey && !this.verify_compare) {
              this.add('compare', '!=')
            }
            break
          case 189:
            // -
            if (!e.shiftKey && !this.verify_calculate) {
              this.add('calculate', '-')
            }
            break
          case 56:
            // -
            if (e.shiftKey && !this.verify_calculate) {
              this.add('calculate', '*')
            }
            break
          case 191:
            // /
            if (!e.shiftKey && !this.verify_calculate) {
              this.add('calculate', '/')
            }
            break
          case 57:
            // (
            if (e.shiftKey && !this.verify_parenthesis_right) {
              this.add('parenthesis', '(')
            }
            break
          case 48:
            // )
            if (e.shiftKey && !this.verify_parenthesis_left) {
              this.add('parenthesis', ')')
            }
            break
          case 188:
            // < 和 ,
            if (e.shiftKey && !this.verify_compare) {
              // <
              this.add('compare', '<')
            } else if (!e.shiftKey && !this.verify_compare) {
              // ,
              this.add('comma', ',')
            }
            break
          case 190:
            // >
            if (e.shiftKey && !this.verify_compare) {
              // >
              this.add('compare', '>')
            }
            break
          case 55:
            // &&:AND
            if (e.shiftKey && !this.verify_bool) {
              // >
              this.add('bool', 'AND')
            }
            break
          case 220:
            // ||:OR
            if (e.shiftKey && !this.verify_bool) {
              // >
              this.add('bool', 'OR')
            }
            break
        }
      }
    },
    getComponentByModel(model) {
      for (let i = 0; i < this.fieldOptions.length; i++) {
        if (this.fieldOptions[i].model === model) {
          return this.fieldOptions[i]
        }
      }
    },
    getSourceTooltip(v) {
      if (v.params && v.params.length > 0) {
        let tootip = ''
        v.params.forEach(p => {
          if (p.value && p.value.length > 0) {
            p.value.forEach(model => {
              const component = this.getComponentByModel(model)
              if (component) {
                tootip += ((tootip ? ', ' : '') + component.name)
              }
            })
          }
        })
        if (tootip) {
          tootip = '(' + tootip + ')'
        }
        let source = this.dataSources[v.source]
        if (source) {
          tootip = source.name + tootip
          if (source.attribute && source.attribute[v.value]) {
            tootip = tootip + '的' + source.attribute[v.value]
          }
        }
        return tootip
      } else {
        return `${v.label || (v.source + '.' + v.value)}`
      }
    },
    // 计算出公式的某一项的展示内容
    getItemShow(v) {
      if (v.type === 'variate') {
        return `${v.label || v.value}`
      } else if (v.type === 'subform') {
        return `${v.label || v.value} ${v.operator} ...`
      } else if (v.type === 'source') {
        return `${v.label || (v.source + '.' + v.value)}`
      } else {
        if (v.fun) {
          return `${v.label + v.value}`
        } else {
          return `${v.label || v.value}`
        }
      }
    },
    editItem(index) {
      this.activeIndex = index
    },
    unActiveItem() {
      this.activeIndex = this.stack.length - 1
    },
    // 数据源确定
    formulaDataSourceChange(data) {
      this.activeSource = data[0].name
      this.params = data[0].params
      this.activeProperty = ''
    },
    // 公式增加默认值
    addDefault(item, value) {
      this.add('parenthesis', '(', '默认取值', 'defaultValue');
      this.add('variate', item.model, item.name);
      this.add('comma', ',');
      this.add(this.getType(item), value)
      this.add('parenthesis', ')');
      this.defaultObj[item.model + (item.inSubform ? 'base' : '')].visible = false;
    },
    // 公式增加子表单组件的累计运算
    addObjectFunction(item, val, defaultVal) {
      this.$refs[item.key][0].validate((valid) => {
        if (valid) {
          if (val === '+') {
            this.add('parenthesis', '(', '累加', 'reduceAdd');
          } else if (val === '-') {
            this.add('parenthesis', '(', '递减', 'reduceSub');
          } else if (val === '*') {
            this.add('parenthesis', '(', '累乘', 'reduceMul');
          } else if (val === '/') {
            this.add('parenthesis', '(', '递除', 'reduceDiv');
          }
          else if (val === 'max') {
            this.add('parenthesis', '(', '最大值', 'reduceMax');
          }
          else if (val === 'min') {
            this.add('parenthesis', '(', '最小值', 'reduceMin');
          }
          else if (val === '|') {
            this.add('parenthesis', '(', '切割', 'reduceCut');
          }
          this.add('variate', item.model, item.name);
          if (defaultVal) {
            this.add('comma', ',');
            this.add(this.getType(item), defaultVal);
          }
          this.add('parenthesis', ')');
          this.defaultObj[item.model].visible = false;
        } else {
          return false;
        }
      });
    },
    getType(item) {
      return item.type === 'switch' || item.options.dataType === 'boolean' ? 'boolean' : item.type === 'number' || item.options.dataType === 'number' ? 'number' : 'string'
    },
    stringAddClick(stringInput) {
      this.add('string', stringInput)
      this.stringInput = ''
    },
    numberAddClick(numberInput) {
      this.add('number', numberInput)
      this.numberInput = ''
    },
    /**
     * 新增一项
     * @param type 新增项的类型
     * @param value 新增项的值
     * @param label 新增项的名称：展示用
     * @param fun 新增项是函数时：函数名
     * @param id 新增项是远程函数时：远程函数的id（key）
     */
    add(type, value, label, fun, id) {
      if (type === 'number') {
        value = Number(value)
      }
      if (type === 'subform') {
        this.popoverModel = value
        this.popoverVisible = true
        this.verify_value = true
        this.verify_parenthesis_right = true
        this.verify_calculate = true
        this.verify_bool = true
        this.verify_compare = true
        this.activeSubformPropertyName = label
      } else {
        const obj = { type, value, label, fun, id }
        if (type === 'source') {
          obj.source = this.activeSource
          obj.value = this.activeProperty
          obj.params = this.params
          this.params = []
          this.activeSource = ''
          this.activeProperty = ''
        } else {
          if (value === '(') {
            this.parenthesis_right_available += 1
          } else if (value === ')') {
            this.parenthesis_right_available -= 1
          }
        }
        if (id === undefined) {
          delete obj.id
        }
        if (fun === undefined) {
          delete obj.fun
        }
        this.stack.splice(this.activeIndex + 1, 0, obj)
        this.activeIndex += 1
      }
      // 新增项是远程函数时：直接展示tooltip
      if (fun === 'remoteFunction' && id) {
        this.$nextTick(() => {
          this.activeTooltipVisible = true
        })
      }
      this.errorIndex = -1
    },
    // backspace：删除一项
    minus() {
      if (this.stack[this.activeIndex].value === ')') {
        this.parenthesis_right_available += 1
      } else if (this.stack[this.activeIndex].value === '(') {
        this.parenthesis_right_available -= 1
      }
      this.stack.splice(this.activeIndex, 1)
      this.activeIndex = this.activeIndex - 1
      this.errorIndex = -1
    },
    // 检查前一项以确定当前可输入的内类型（不可输入的禁用掉）
    verify(type, value) {
      this.tips = ''
      if (type === 'variate' || type === 'string' || type === 'number' || type === 'boolean' || type === 'source' || value === ')') {
        this.verify_value = true
        this.verify_parenthesis_right = true
        this.verify_calculate = false
        this.verify_bool = false
        this.verify_compare = false
        const { left, right } = countParentesis(this.stack)
        if (left > right) {
          this.verify_parenthesis_left = false
        }
      } else {
        if (value !== ')') {
          this.verify_value = false
          this.verify_parenthesis_right = false
          this.verify_parenthesis_left = true
          this.verify_calculate = true
          this.verify_bool = true
          this.verify_compare = true
        }
      }
    },
    // 验证公式正确性
    validateExpression() {
      // const last = this.stack[this.stack.length - 1]
      // if (!last) {
      //   this.tips = '表达式不完整！'
      //   return null
      // }
      // if (
      //   last.value === '(' ||
      //   last.type === 'calculate' ||
      //   last.type === 'bool' ||
      //   last.type === 'compare'
      // ) {
      //   this.tips = '表达式不完整！'
      //   return null
      // }
      // const { left, right } = countParentesis(this.stack)
      // if (left !== right) {
      //   this.tips = '括号数不匹配！'
      //   return null
      // }
      // new validate
      if (!this.stack || this.stack.length < 1) {
        this.tips = '公式不能为空'
        return null
      } else {
        for (let i = 0; i < this.stack.length; i++) {
          const item = this.stack[i]
          // 第一个元素只能是数据、左括号
          if (i === 0 && (this.operatorTypes.includes(item.type) || item.type === 'comma' || item.value === ')')) {
            this.tips = '公式第一个元素不能是运算符、逗号、右括号'
            this.errorIndex = i
            return null
          }
          // 最后一个元素只能是数据、右括号
          if (i === this.stack.length - 1 && (this.operatorTypes.includes(item.type) || item.type === 'comma' || item.value === '(')) {
            this.tips = '公式最后一个元素不能是运算符、逗号、左括号'
            this.errorIndex = i
            return null
          }
          // 括号必须要另一半
          if (item.type === 'parenthesis' && this.findParenthesisCoupleIndex(i) === undefined) {
            this.tips = '括号必须要有另一半'
            this.errorIndex = i
            return null
          }
          // 相邻元素类型匹配与否：类型有：数据、运算符、左括号、右括号、逗号
          const next = this.stack[i + 1]
          // 数据类型后面不能跟数据、左括号
          if (this.dataTypes.includes(item.type) && next && (this.dataTypes.includes(next.type) || (next.type === 'parenthesis' && next.value === '('))) {
            this.tips = '公式的元素类型错误'
            this.errorIndex = i + 1
            return null
          }
          // 运算符后面不能跟运算符、右括号
          if (this.operatorTypes.includes(item.type) && next && (this.operatorTypes.includes(next.type) || (next.type === 'parenthesis' && next.value === ')'))) {
            this.tips = '公式的元素类型错误'
            this.errorIndex = i + 1
            return null
          }
          // 左括号后面不能跟右括号、逗号、运算符
          if (item.value === '(' && next && (this.operatorTypes.includes(next.type) || next.type === 'comma' || next.value === ')')) {
            this.tips = '公式的元素类型错误'
            this.errorIndex = i + 1
            return null
          }
          // 右括号后面不能跟左括号、数据
          if (item.value === ')' && next && (this.dataTypes.includes(next.type) || next.value === '(')) {
            this.tips = '公式的元素类型错误'
            this.errorIndex = i + 1
            return null
          }
          // 逗号后面不能跟右括号、运算符、逗号、
          if (item.value === ',' && next && (this.operatorTypes.includes(next.type) || next.value === ')' || next.value === ',')) {
            this.tips = '公式的元素类型错误'
            this.errorIndex = i + 1
            return null
          }
          const basicDataTypes = ['string', 'number', 'boolean']
          // 校验基础组件可能不使用时必须要有填充值，且为string、number、boolean类型
          if (item.fun === 'defaultValue' && (!this.stack[i + 2] || !this.stack[i + 3] || this.stack[i + 2].type !== 'comma' || !basicDataTypes.includes(this.stack[i + 3].type))) {
            this.tips = '默认取值函数的填充值参数不正确，填充值只能是字符串、数字或布尔值'
            this.errorIndex = i
            return null
          }
          // 校验子表单内组件累计运算在可能不使用时，必须要有填充值，且为string、number、boolean类型
          const funs = ['reduceAdd', 'reduceSub', 'reduceMul', 'reduceDiv','reduceMax', 'reduceMin','reduceCut']
          if (funs.includes(item.fun) && this.stack[i + 1]) {
            // 累计运算函数的第一个参数只能是组件
            if (this.stack[i + 1].type !== 'variate') {
              this.tips = '累计运算函数的第一个参数只能是组件'
              this.errorIndex = i + 1
              return null
            }
            const component = this.getComponentByModel(this.stack[i + 1].value)
            // 累计运算函数的第一个参数组件可能不使用时：需要设置填充值
            if ((component.options.display.type === 1 && !component.options.display.value) || component.options.display.type === 2 || this.containerMayNotDisplay[component.model]) {
              if (!this.stack[i + 2] || !this.stack[i + 3] || this.stack[i + 2].type !== 'comma' || !basicDataTypes.includes(this.stack[i + 3].type)) {
                this.tips = `${component.name}可能不使用，累计运算必须设置填充值，且填充值只能是字符串、数字或布尔值`
                this.errorIndex = i
                return null
              }
            }
          }
        }
      }
      this.errorIndex = -1
      return true
    },
    // text是表单变量名构成的表达式
    getExpression() {
      if (this.validateExpression()) {
        return { text: this.expressionText, stack: this.stack }
      }
    },
    // text是语义化的变量名构成的表达式
    getExpressionShow() {
      if (this.validateExpression()) {
        return { text: this.expressionShow, stack: this.stack }
      }
    },
    clear() {
      this.tips = ''
      this.stack = []
      this.verify('', '')
    }
  }
}
</script>
<style scoped>
.m-b-10 {
  margin-bottom: 10px;
}

.expression-container {
  height: 100%;
  overflow: auto;
}

.expression-container>.el-form-item {
  margin: 0;
  padding-bottom: 0 !important;
  border-bottom: 0 !important;
}

.d-flex {
  display: flex;
}

.basic-value-box>div {
  margin-right: 2rem;
}

.basic-value-box>div:last-child {
  margin-right: 0;
}

.expression-box {
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  flex-direction: row-reverse;
  align-items: center;
  overflow-y: hidden;
  min-height: 52px;
}

.subform-cancel {
  text-align: center;
  margin-top: 10px;
}

.item-label {
  width: 90px;
  display: inline-block;
}

.button-group-box {
  flex: 1;
}

.button-group-box .el-button {
  margin-bottom: 0.25rem;
  margin-right: 0.25rem;
  margin-left: 0;
}

.stack-item {
  float: left;
  margin: 3px 0;
  cursor: pointer;
  line-height: 1.25rem;
  padding: 0 2px;
}

.stack-item-show {
  padding: 1px 1px;
  border: 1px dashed transparent;
}

.active-item.item-type-parenthesis,
.parenthesis-couple-item {
  border-color: #bbb;
}

.stack-item-show>i {
  color: #aaa;
}

.stack-item-show>i:first-child {
  padding-right: 3px;
}

.stack-item-show>i:last-child {
  padding-left: 3px;
}

.stack-item-show>pre {
  display: inline;
  margin: 0;
  padding: 0;
}

.item-type-number {
  color: rgba(50, 200, 50, 0.8);
}

.item-type-calculate,
.item-type-compare,
.item-type-bool {
  color: blue;
}

.item-type-string {
  color: rgba(180, 30, 50, 0.7);
}

.item-type-source {
  color: rgba(255, 120, 0, 0.9);
}

.item-type-boolean {
  color: rgba(255, 0, 0, 0.9);
}

.cursor-box {
  padding: 1px 0;
}

.cursor-show {
  color: #fff;
}

.error-item {
  background-color: red;
  color: #fff;
}

@keyframes cursor-show {
  from {
    color: #000;
  }

  to {
    color: #fff;
  }
}

.active-cursor .cursor-show {
  animation: cursor-show 0.8s infinite;
}

.select-item {
  padding: 4px 5px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.select-item pre {
  margin: 0;
}

.select-item-active {
  background-color: #eeeeee;
}

.select-item:hover {
  background-color: #f2f2f2;
}

.input-list {
  max-height: 200px;
  overflow: auto;
  padding: 0;
  margin: 0;
}
</style>
<style>
.hide-popover {
  left: -5000px;
}

.input-popover {
  padding: 0;
}

.input-popper .el-autocomplete-suggestion__wrap {
  padding: 0;
}

.left-popover {
  right: 0;
}
</style>
