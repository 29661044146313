<template>
  <div style="width: 100%;overflow: auto;">
    <table>
      <tbody>
        <tr
          v-for="(row, rowIndex) in result"
          :key="rowIndex"
        >
          <td
            v-for="(item, colIndex) in row"
            :key="colIndex"
            :rowspan="item.row"
            :colspan="item.col"
          >
            <div class="table-ceil-box">
              <p v-if="!editable">
                {{ item.value }}
              </p>
              <input
                v-if="editable"
                v-model="item.value"
              >
              <el-popover
                v-if="editable"
                v-model="item.menuVisible"
                placement="bottom"
                trigger="hover"
              >
                <span slot="reference">...</span>
                <el-button
                  @click="operateTable('rightAdd', rowIndex, colIndex, item)"
                >
                  右侧增加一列
                </el-button>
                <el-button
                  @click="operateTable('leftAdd', rowIndex, colIndex, item)"
                >
                  左侧增加一列
                </el-button>
                <el-button
                  @click="operateTable('bottomAdd', rowIndex, colIndex, item)"
                >
                  下方增加一行
                </el-button>
                <el-button
                  @click="operateTable('topAdd', rowIndex, colIndex, item)"
                >
                  上方增加一行
                </el-button>
                <el-button
                  @click="operateTable('deleteCol', rowIndex, colIndex, item)"
                >
                  删除本列
                </el-button>
                <el-button
                  @click="operateTable('deleteRow', rowIndex, colIndex, item)"
                >
                  删除本行
                </el-button>
                <el-button
                  :disabled="mergeAble(rowIndex, colIndex, item).right"
                  @click="operateTable('rightMerge', rowIndex, colIndex, item)"
                >
                  向右合并一格
                </el-button>
                <el-button
                  :disabled="mergeAble(rowIndex, colIndex, item).bottom"
                  @click="operateTable('bottomMerge', rowIndex, colIndex, item)"
                >
                  向下合并一格
                </el-button>
              </el-popover>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'Statement',
  props: {
    value: {
      type: Array,
      default: () => [
        [
          {
            row: 1,
            col: 1,
            value: ''
          },
          {
            row: 1,
            col: 1,
            value: ''
          },
          {
            row: 1,
            col: 1,
            value: ''
          }
        ]
      ]
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      result: this.value,
      popoverVisible: {}
    }
  },
  watch: {
    value: {
      handler () {
        this.result = this.value
      },
      deep: true
    }
  },
  methods: {
    // 初始化一行
    initRow (rowIndex) {
      let cols = 0
      this.result[rowIndex].forEach(item => {
        cols += item.col
      })
      let row = []
      for (let i = 0; i < cols; i++) {
        row.push({
          row: 1,
          col: 1,
          value: ''
        })
      }
      return row
    },
    // 判断是否禁用向右、向下合并按钮
    mergeAble (rowIndex, colIndex, item) {
      const res = {
        right: false,
        bottom: false
      }
      // 右侧格子的列下标
      const nextColIndex = colIndex + this.result[rowIndex][colIndex].col
      // 右侧无格子时，不能合并
      if (!this.result[rowIndex][nextColIndex]) {
        res.right = true
      } else if (this.result[rowIndex][nextColIndex].row > item.row) {
        // 右侧格子行高大于本格子时不能合并
        res.right = true
      }
      // 下方格子的行下标
      const nextRowIndex = rowIndex + this.result[rowIndex][colIndex].row
      // 下方无格子时，不能合并
      if (!this.result[nextRowIndex] || !this.result[nextRowIndex][colIndex]) {
        res.bottom = true
      } else if (this.result[nextRowIndex][colIndex].col > item.col) {
        // 下方格子列宽大于本格子时不能合并
        res.bottom = true
      }
      return res
    },
    // 操作表格
    operateTable (type, rowIndex, colIndex, item) {
      switch (type) {
        // 右侧增加一列
        case 'rightAdd':
          this.result.forEach(row => {
            row.splice(colIndex + 1, 0, {
              row: 1,
              col: 1,
              value: ''
            })
          })
          break
        // 左侧增加一列
        case 'leftAdd':
          this.result.forEach(row => {
            row.splice(colIndex, 0, {
              row: 1,
              col: 1,
              value: ''
            })
          })
          break
        // 下方增加一行
        case 'bottomAdd':
          this.result.splice(rowIndex + 1, 0, this.initRow(rowIndex))
          break
        // 上方增加一行
        case 'topAdd':
          this.result.splice(rowIndex, 0, this.initRow(rowIndex))
          break
        // 删除本列
        case 'deleteCol':
          this.result.forEach(row => {
            row.splice(colIndex, 1)
          })
          break
        // 删除本行
        case 'deleteRow':
          this.result.splice(rowIndex, 1)
          break
        // 向右合并一格
        case 'rightMerge': {
          // 右侧格子的列下标
          const nextColIndex = colIndex + this.result[rowIndex][colIndex].col
          // 右侧格子对应的值
          const nextColIndexValue = this.result[rowIndex][nextColIndex].value
          // 右侧格子的列宽：占几列
          const nextColColspan = this.result[rowIndex][nextColIndex].col
          if (nextColIndexValue !== '' && nextColIndexValue !== null && nextColIndexValue !== undefined) {
            this.$confirm('此操作将丢弃右侧表格内容, 是否继续?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              this.result[rowIndex].splice(nextColIndex, 1)
              this.result[rowIndex][colIndex].col += nextColColspan
            }).catch(() => {
            })
          } else {
            this.result[rowIndex].splice(nextColIndex, 1)
            this.result[rowIndex][colIndex].col += nextColColspan
          }
          break
        }
        // 向下合并一格
        case 'bottomMerge': {
          // 下方格子的行下标
          const nextRowIndex = rowIndex + this.result[rowIndex][colIndex].row
          // 下方格子对应的值
          const nextRowIndexValue = this.result[nextRowIndex][colIndex].value
          // 下方格子的行高：占几行
          const nextRowRowspan = this.result[nextRowIndex][colIndex].row
          if (nextRowIndexValue !== '' && nextRowIndexValue !== null && nextRowIndexValue !== undefined) {
            this.$confirm('此操作将丢弃下方表格内容, 是否继续?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              this.result[nextRowIndex].splice(colIndex, 1)
              this.result[rowIndex][colIndex].row += nextRowRowspan
            }).catch(() => {
            })
          } else {
            this.result[nextRowIndex].splice(colIndex, 1)
            this.result[rowIndex][colIndex].row += nextRowRowspan
          }
          break
        }
      }
      item.menuVisible = false
    }
  }
}
</script>

<style lang="stylus" scoped>
table
  width 100%
  border-collapse collapse
  border 1px solid #dcdfe6
  overflow auto
  tbody
    border 1px solid #dcdfe6
    tr
      border: 1px solid #dcdfe6
      td
        border 1px solid #dcdfe6
        padding 0 .3rem 0 0
        word-break keep-all
        div
          display flex
          min-width 5rem
          p, input
            padding 0.5rem 0.5rem
            word-break break-all
            line-height 1.3rem
            min-height 2.3rem
            margin 0
            border 0
            outline none
            width 100%
            flex 1
.el-button
  display block
  width 100%
  margin .3rem 0
</style>
