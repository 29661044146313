export default function (Vue, axios, apiPre) {
  // 配置axios
  const instance = axios.create({
    baseURL: apiPre || ''
    // headers: {
    //   token: store.state.user.token || '',
    //   username: store.state.user.username || ''
    // }
  })
  instance.interceptors.request.use(function (config) {
    config.headers['X-Requested-With'] = 'XMLHttpRequest'
    return config
  }, function (error) {
    return Promise.reject(error)
  })

  // // Add a response interceptor
  // instance.interceptors.response.use(function (response) {
  //   // Any status code that lie within the range of 2xx cause this function to trigger
  //   // Do something with response data
  //   console.log(new Date - performance.timeOrigin)
  //   return response;
  // }, function (error) {
  //   // Any status codes that falls outside the range of 2xx cause this function to trigger
  //   // Do something with response error
  //   return Promise.reject(error);
  // })

  Vue.http = Vue.prototype.$http = instance
}
