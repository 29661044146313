/**
 * 所有表单组件的初始配置
 */
const options = {
  show: {
    type: 1, // 1手动设置，2公式计算
    value: true,
    expression: '',
    formula: []
  },
  cardShow: {
    type: 1, // 1手动设置，2公式计算
    value: false,
    expression: '',
    formula: []
  },
  display: {
    type: 1, // 1手动设置，2依据其他控件值计算判断
    value: true,
    expression: '',
    formula: []
  },
  valueConfig: {
    type: 1, // 1默认值填充，2依据其他控件值计算，3依据特定类型控件的数据源的选中项的其他属性，4基础数据源
    expression: '',
    formula: [],
    dependWidgetKey: '',
    dependItemProperty: '',
    dependItemModel: '',
    dataSourceKey: '',
    params: [],
    dataSourceProperty: ''
  },
  disabled: {
    type: 1, // 1手动设置，2依据其他控件值计算判断
    expression: '',
    formula: [],
    value: false
  },
  verify: {
    message: '',
    expression: '',
    formula: [] // 公式校验
  },
  required: false,
  pattern: '',
  placeholder: '',
  width: '',
  defaultValue: '',
  encipherment: '', // 加密方式
  hasModifyModel: false // 是否手动修改过model
}
export const basicComponents = [
  {
    type: 'input',
    name: '单行文本',
    icon: 'regular/keyboard',
    options: Object.assign({}, options, {
      prefix: '',
      suffix: '',
      sumformat: null,
      dataType: '',
      readonly: {
        type: 1, // 1手动设置，2依据其他控件值计算
        value: false,
        expression: '',
        formula: []
      },
      width: ''
    })
  },
  {
    type: 'textarea',
    name: '多行文本',
    icon: 'server',
    options: Object.assign({}, options, {
      readonly: {
        type: 1, // 1手动设置，2依据其他控件值计算
        value: false,
        expression: '',
        formula: []
      }
    })
  },
  {
    type: 'number',
    name: '计数器',
    icon: 'sort-numeric-up',
    options: Object.assign({}, options, {
      width: '',
      defaultValue: 0,
      min: '',
      max: '',
      step: 1,
      controlsPosition: ''
    })
  },
  {
    type: 'radio',
    name: '单选框组',
    icon: 'regular/dot-circle',
    options: Object.assign({}, options, {
      inline: false,
      showLabel: true,
      defaultFirst: false,
      isSubformData: false,
      subformDataKey: '',
      options: [
        {
          value: '选项值1',
          label: '选项名称1'
        },
        {
          value: '选项值2',
          label: '选项名称2'
        },
        {
          value: '选项值3',
          label: '选项名称3'
        }
      ],
      width: '',
      remote: false,
      params: [],
      remoteDataKey: '',
      showKey: '',
      submitKey: '',
      remoteOptions: [],
      props: {
        value: 'value',
        label: 'label'
      },
      disableKey: ''
    })
  },
  {
    type: 'checkbox',
    name: '多选框组',
    icon: 'regular/check-square',
    options: Object.assign({}, options, {
      defaultValue: [],
      showLabel: true,
      isSubformData: false,
      subformDataKey: '',
      options: [
        {
          value: '选项值1',
          label: '选项名称1'
        },
        {
          value: '选项值2',
          label: '选项名称2'
        },
        {
          value: '选项值3',
          label: '选项名称3'
        }
      ],
      inline: false,
      width: '',
      remote: false,
      params: [],
      remoteDataKey: '',
      showKey: '',
      submitKey: '',
      remoteOptions: [],
      props: {
        value: 'value',
        label: 'label'
      },
      disableKey: ''
    })
  },
  {
    type: 'time',
    name: '时间选择器',
    icon: 'regular/clock',
    options: Object.assign({}, options, {
      editable: false,
      clearable: true,
      placeholder: '',
      startPlaceholder: '',
      endPlaceholder: '',
      isRange: false,
      arrowControl: false,
      format: 'HH:mm:ss',
      readonly: {
        type: 1, // 1手动设置，2依据其他控件值计算
        value: false,
        expression: '',
        formula: []
      },
      width: ''
    })
  },
  {
    type: 'date',
    name: '日期选择器',
    icon: 'regular/calendar-alt',
    options: Object.assign({}, options, {
      editable: false,
      clearable: true,
      placeholder: '',
      startPlaceholder: '',
      endPlaceholder: '',
      type: 'date',
      format: 'yyyy-MM-dd',
      timestamp: false,
      readonly: {
        type: 1, // 1手动设置，2依据其他控件值计算
        value: false,
        expression: '',
        formula: []
      },
      width: '',
      greaterThan: '',
      equal: '',
      lessThan: ''
    })
  },
  {
    type: 'select',
    name: '下拉选择框',
    icon: 'regular/caret-square-down',
    options: Object.assign({}, options, {
      multiple: false,
      filterable: true, // 是否可搜索
      remoteFilter: false, // 是否远程搜索(可选项是数据源时才能远程搜索，搜索链接即是数据源地址，带上搜索关键词)
      defaultFirst: false,
      clearable: true,
      placeholder: '',
      showLabel: true,
      width: '',
      isSubformData: false,
      subformDataKey: '',
      options: [
        {
          value: '下拉项值1',
          label: '下拉项名称1'
        },
        {
          value: '下拉项值2',
          label: '下拉项名称2'
        },
        {
          value: '下拉项值3',
          label: '下拉项名称3'
        }
      ],
      remote: false,
      params: [],
      remoteDataKey: '',
      showKey: '',
      submitKey: '',
      remoteOptions: [],
      props: {
        value: 'value',
        label: 'label'
      },
      disableKey: ''
    })
  },
  {
    type: 'switch',
    name: '开关',
    icon: 'toggle-off',
    options: Object.assign({}, options, {
      defaultValue: false
    })
  },
  {
    type: 'download',
    name: '下载按钮',
    icon: 'download',
    options: Object.assign({}, options)
  },
  {
    type: 'instruction',
    name: '说明文本',
    icon: 'question',
    options: Object.assign({}, options, {
      isLabel: false
    })
  },
  {
    type: 'tree',
    name: '树形控件',
    icon: 'tree',
    options: Object.assign({}, options, {
      options: [],
      emptyText: '',
      defaultValue: [],
      defaultExpandAll: false,
      remote: true,
      checkStrictly: true, // 父子节点关联
      filterable: false, // 是否可搜索
      singleElection: false, // 是否单选
      params: [],
      nodeKey: 'id',
      remoteDataKey: '',
      remoteOptions: [],
      draggable: true
    })
  },
  {
    type: 'cascader',
    name: '级联选择器',
    icon: 'check',
    options: Object.assign({}, options, {
      options: [],
      defaultValue: [],
      remote: true,
      checkStrictly: true, // 是否可选任意一级
      filterable: false, // 是否可搜索
      singleElection: true, // 是否单选
      params: [],
      remoteDataKey: '',
      remoteOptions: [],
      props: {
        value: 'value',
        label: 'label',
        disabled: 'disabled'
      },
      separator: '/',
      itemSeparator: ';'
    })
  },
  {
    type: 'imgupload',
    name: '图片上传',
    icon: 'regular/image',
    options: Object.assign({}, options, {
      defaultValue: [],
      // size: {
      //   width: 100,
      //   height: 100
      // },
      tokenFunc: 'funcGetToken',
      token: '',
      // domain: 'http://pfp81ptt6.bkt.clouddn.com/',
      limit: 8,
      multiple: true
    })
  },
  {
    type: 'fileupload',
    name: '文件上传',
    icon: 'upload',
    options: Object.assign({}, options, {
      defaultValue: [],
      tokenFunc: 'funcGetToken',
      token: '',
      // accept: '.pdf, .doc,.txt,.docx,.xlsx,.xls',
      // domain: 'http://pfp81ptt6.bkt.clouddn.com/',
      limit: 8,
      fileList: [],
      listType: 'text',
      type: 'file',
      multiple: true,
      showFile: false
    })
  },
  {
    type: 'table',
    name: '数据表格',
    icon: 'th-large',
    options: {
      show: {
        type: 1, // 1手动设置，2公式计算
        value: true,
        expression: '',
        formula: []
      },
      display: {
        type: 1, // 1手动设置，2依据其他控件值计算判断
        value: true,
        expression: '',
        formula: []
      },
      defaultValue: [],
      valueConfig: {
        type: 4, // 1默认值填充，2依据其他控件值计算，3依据特定类型控件的数据源的选中项的其他属性，4基础数据源
        expression: '',
        formula: [],
        dependWidgetKey: '',
        dependItemProperty: '',
        dependItemModel: '',
        dataSourceKey: '',
        params: [],
        dataSourceProperty: ''
      },
      required: false,
      width: ''
    }
  },
  {
    type: 'link',
    name: '链接',
    icon: 'link',
    options: Object.assign({}, options, {
      showType: 1, // 操作界面展示类型：1模态框，2新开页面
      buttonText: '', // 按钮的文案
      stage: 'approve' // 链接使用阶段：申请时apply，审批时approve
    })
  },
  // {
  //   type: 'customdata',
  //   name: '链接(申请时)',
  //   icon: 'clone',
  //   options: Object.assign({}, options, {
  //     showType: 1, // 操作界面展示类型：1模态框，2新开页面
  //     buttonText: '' // 按钮的文案
  //   })
  // },
  // {
  //   type: 'customoperate',
  //   name: '链接(审批时)',
  //   icon: 'edit',
  //   options: Object.assign({}, options, {
  //     valueConfig: {
  //       type: 1, // 1默认值填充，2依据其他控件值计算，3依据特定类型控件的数据源的选中项的其他属性，4基础数据源
  //       expression: '',
  //       formula: [],
  //       dependWidgetKey: '',
  //       dependItemProperty: '',
  //       dependItemModel: '',
  //       dataSourceKey: '',
  //       params: [],
  //       dataSourceProperty: ''
  //     },
  //     showType: 1, // 操作界面展示类型：1模态框，2新开页面
  //     buttonText: '' // 按钮的文案
  //   })
  // },
  {
    type: 'customshow',
    name: '内容注入',
    icon: 'edit',
    options: Object.assign({}, options, {
      valueConfig: {
        type: 4, // 1默认值填充，2依据其他控件值计算，3依据特定类型控件的数据源的选中项的其他属性，4基础数据源
        expression: '',
        formula: [],
        dependWidgetKey: '',
        dependItemProperty: '',
        dependItemModel: '',
        dataSourceKey: '',
        params: [],
        dataSourceProperty: ''
      },
      contentType: 1, // 1直接展示数据源返回的html内容，2iframe展示其他界面
      iframeHeight: 21 // iframe展示时，iframe的高度
    })
  },
  {
    type: 'tips',
    name: '弹窗提醒',
    icon: 'info-circle',
    options: Object.assign({}, options, {
      valueConfig: {
        type: 1, // 1默认值填充，2依据其他控件值计算，3依据特定类型控件的数据源的选中项的其他属性，4基础数据源
        expression: '',
        formula: [],
        dependWidgetKey: '',
        dependItemProperty: '',
        dependItemModel: '',
        dataSourceKey: '',
        params: [],
        dataSourceProperty: ''
      },
      tipsType: 'warning', // 提示类型
      stage: 'approve' // 组件生效阶段：申请时apply，审批时approve
    })
  }
]

export const advanceComponents = [
  {
    type: 'subform',
    name: '明细/表格',
    icon: 'table',
    columns: [
      {
        span: 12,
        list: []
      },
      {
        span: 12,
        list: []
      }
    ],
    options: {
      show: {
        type: 1, // 1手动设置，2公式计算
        value: true,
        expression: '',
        formula: []
      },
      display: {
        type: 1, // 1手动设置，2依据其他控件值计算判断
        value: true,
        expression: '',
        formula: []
      },
      gutter: 20,
      justify: 'start',
      align: 'top',
      addAndDelete: true,
      subformWidth: '',
      blockDisplay: false,
      detailBlockDisplay: false,
      btnText: '+'
    }
  }
]

export const layoutComponents = [
  {
    type: 'grid',
    name: '栅格',
    icon: 'th',
    columns: [
      {
        span: 12,
        list: []
      },
      {
        span: 12,
        list: []
      }
    ],
    options: {
      show: {
        type: 1, // 1手动设置，2公式计算
        value: true,
        expression: '',
        formula: []
      },
      display: {
        type: 1, // 1手动设置，2依据其他控件值计算判断
        value: true,
        expression: '',
        formula: []
      },
      gutter: 20,
      justify: 'start',
      align: 'top'
    }
  }
]
