import Vue from 'vue'
import i18n from '../i18n'

export const loadJs = (url) => {
  return new Promise((resolve) => {
    const script = document.createElement('script')
    script.src = url
    script.type = 'text/javascript'
    document.body.appendChild(script)
    script.onload = () => {
      resolve()
    }
  })
}

export const loadCss = (url) => {
  return new Promise((resolve) => {
    const link = document.createElement('link')
    link.rel = 'stylesheet'
    link.href = url
    document.head.appendChild(link)
    link.onload = () => {
      resolve()
    }
  })
}

/**
 * 把表单设计数据一维扁平化，只保留基础组件：清除布局组件（栅格、子表单）
 * @param {Array} formList 组件数组
 * @param {Boolean} inSubform 是否是子表单内组件
 * @return {Array} 基础组件一维数组
 */
export const getLinearFormList = (formList, inSubform) => {
  let array = []
  for (let i = 0; i < formList.length; i++) {
    if (formList[i].type === 'grid' || formList[i].type === 'subform') {
      for (let j = 0; j < formList[i].columns.length; j++) {
        let subList = getLinearFormList(formList[i].columns[j].list, formList[i].type === 'subform')
        array = array.concat(subList)
      }
    } else {
      formList[i].inSubform = inSubform
      array.push(formList[i])
    }
  }
  return array
}

/**
 * 把表单设计数据一维扁平化，包含布局组件（栅格、子表单）
 * @param {Array} formList 组件数组
 * @param {Boolean} inSubform 是否是子表单内组件
 * @return {Array} 所有组件一维数组
 */
export const getLinearFormListWidthContainer = (formList, inSubform) => {
  let array = []
  for (let i = 0; i < formList.length; i++) {
    formList[i].inSubform = inSubform
    array.push(formList[i])
    if (formList[i].type === 'grid' || formList[i].type === 'subform') {
      for (let j = 0; j < formList[i].columns.length; j++) {
        let subList = getLinearFormList(formList[i].columns[j].list, formList[i].type === 'subform')
        array = array.concat(subList)
      }
    }
  }
  return array
}

/**
 * 获取所有子表单组件
 * @param {Array} formList 表单组件数组
 * @returns {Array} 所有子表单组件的数组
 */
export const getSubformList = (formList) => {
  let array = []
  for (let i = 0; i < formList.length; i++) {
    if (formList[i].type === 'subform') {
      let subform = formList[i]
      subform.widgetList = []
      for (let j = 0; j < formList[i].columns.length; j++) {
        let subList = getLinearFormList(formList[i].columns[j].list, true)
        subform.widgetList = subform.widgetList.concat(subList)
      }
      array.push(subform)
    }
  }
  return array
}

// 远程规则校验
export const remoteValidate = (rule, value, callback) => {
  let params = {
    key: [rule.remoteId],
    params: [value]
  }
  Vue.http.post('/workflow/contract-config', params).then(function (response) {
    if (response.data.code !== 0) {
      callback(new Error(response.data.message))
    } else {
      callback()
    }
  }).catch(function () {
    callback(new Error('服务器异常'))
  })
}

// 根据契约key获取契约名称
export const getFunNameByKey = (key, contractConfig) => {
  let keys = Object.keys(contractConfig)
  let name = ''
  for (let i = 0; i < keys.length; i++) {
    if (Object.keys(contractConfig[keys[i]]).includes(key)) {
      name = contractConfig[keys[i]][key].name
    }
  }
  return name
}

// 验证契约（带参数据源、事件、任务、带参数函数、同步调用等）参数:自定义参数是数组状态就直接通过
export const validateParam = (funList, modelKeys, contractConfig, contractsParamsConfig) => {
  let allKeys = []
  allKeys = allKeys.concat(modelKeys)
  if (contractsParamsConfig) {
    allKeys = allKeys.concat(Object.keys(contractsParamsConfig))
  }
  let errorMessage
  // 循环契约
  for (let funIndex = 0; funIndex < funList.length; funIndex++) {
    if (funList[funIndex].params && funList[funIndex].params.length > 0) {
      // 循环参数
      for (let paramIndex = 0; paramIndex < funList[funIndex].params.length; paramIndex++) {
        if (funList[funIndex].params[paramIndex].value && funList[funIndex].params[paramIndex].value.length > 0) {
          // 循环参数绑定的表单字段
          for (let valueIndex = 0; valueIndex < funList[funIndex].params[paramIndex].value.length; valueIndex++) {
            if (!allKeys.includes(funList[funIndex].params[paramIndex].value[valueIndex]) && !Array.isArray(funList[funIndex].params[paramIndex].value[valueIndex])) {
              errorMessage = {
                fun: getFunNameByKey(funList[funIndex].name || funList[funIndex].source || funList[funIndex].dataSourceKey, contractConfig),
                param: funList[funIndex].params[paramIndex].intro,
                model: funList[funIndex].params[paramIndex].value[valueIndex]
              }
            }
            if (errorMessage) {
              break
            }
          }
        }
        if (errorMessage) {
          break
        }
      }
    }
    if (errorMessage) {
      break
    }
  }
  return errorMessage
}

// 根据组件的model判断组件是否在子表单内
export const isInSubform = function (model, linearFormList) {
  let inSubform = false
  if (linearFormList && linearFormList.length > 0) {
    const arr = linearFormList.filter(item => {
      return item.model === model
    })
    if (arr.length > 0) {
      inSubform = arr[0].inSubform
    }
  }
  return inSubform
}



/**
 * 根据值、组件设计获取展示内容
 * @param {*} component 组件
 * @param {*} value 值
 * @param {*} subFormKey 子表单行号
 * @param {*} formExtends 快照扩展
 * @param {*} dataModel 初步展示值
 */
export const getShowData = (component, value, subFormKey, formExtends) => {
  if (component.type === 'select' || component.type === 'radio' || component.type === 'checkbox' || component.type === 'tree') {
    let options
    if (subFormKey !== undefined) {
      // 子表单时
      options = formExtends[component.model + 'Extends'] && formExtends[component.model + 'Extends'].options && formExtends[component.model + 'Extends'].options['item' + subFormKey] ? formExtends[component.model + 'Extends'].options['item' + subFormKey] : []
    } else {
      // 非子表单时
      options = formExtends[component.model + 'Extends'] && formExtends[component.model + 'Extends'].options ? formExtends[component.model + 'Extends'].options : []
    }
    return options ? options.map(item => item ? item[component.type !== 'tree' && component.options.remote ? component.options.showKey : 'label'] : item).join() : ''
  } else if (component.type === 'cascader') {
    if (subFormKey !== undefined) {
      // 子表单时
      let res = ''
      if (formExtends[component.model + 'Extends'].options) {
        res = formExtends[component.model + 'Extends'].options['item' + subFormKey] || (Array.isArray(value) ? (component.options.singleElection ? value.join(component.options.separator || '') : value.map(item => item.join(component.options.separator || '')).join(';')) : value) || ''
      }
      return res
    } else {
      // 非子表单时
      return formExtends[component.model + 'Extends'].options || (Array.isArray(value) ? (component.options.singleElection ? value.join(component.options.separator || '') : value.map(item => item.join(component.options.separator || '')).join(';')) : value) || ''
    }
  } else {
    if (typeof value === 'boolean') {
      return value ? i18n.t('message.common.yes') : i18n.t('message.common.no')
    } else if (component.type == 'instruction' && component.options.defaultValue_i18n && component.options.valueConfig.type == 1) {
      // 说明文本的静态默认值，有翻译则取翻译
      return component.options.defaultValue_i18n
    } else {
      return value
    }
  }
}
