<template>
  <el-container class="tool-box">
    <el-aside style="wdith: 250px;">
      <div
        v-loading="loadingCompounds"
        class="components-list"
      >
        <div>
          <div class="widget-cate">控件</div>
          <draggable
            element="ul"
            :list="components"
            :options="{group:{ name:'people', pull:'clone',put:false},sort:false, ghostClass: 'ghost'}"
            :move="handleMove"
            @end="handleMoveEnd"
            @start="handleMoveStart"
          >
            <li v-for="(item, index) in components" :key="index" class="form-edit-widget-label">
              <a><icon class="icon" :name="item.icon"/>
                <span>{{ item.name }}</span>
              </a>
            </li>
          </draggable>
        </div>

        <div v-if="!isCompoundWidgetMode">
          <div class="widget-cate">套件
             <a style="float: right;margin-right: 1rem;cursor: pointer;" v-if="!moreComponent" @click="handleMoreComponent">更多</a>
          </div>
         
          <draggable
            element="ul"
            :list="assemblyComponentsWithTip"
            :options="{group:{ name:'people', pull:'clone',put:false},sort:false, ghostClass: 'ghost'}"
            :move="handleMove"
            @end="handleMoveEnd"
            @start="handleMoveStart"
          >
            <li v-for="(item, index) in assemblyComponentsWithTip" :key="index" class="form-edit-widget-label">
              <el-tooltip
                class="item"
                effect="dark"
                :content="(item.key ? 'key: ' + item.key + '; ' : '') + 'id: ' + item.id"
                placement="bottom-start"
              >
                <a :class="[{'immutable-component': item.data.config.immutable}]">
                  <span>&emsp;{{ item.name }}</span>
                </a>
              </el-tooltip>
            </li>
          </draggable>
        </div>
      </div>
    </el-aside>
    <el-container
      class="center-container"
      direction="vertical"
    >
      <el-header class="btn-bar">
        <div class="btn-box">
          <el-upload
            v-if="isCompoundWidgetMode"
            :on-change="fileChange"
            :before-upload="() => false"
            :show-file-list="false"
            action=""
            accept="text/json"
            style="display: inline-block;margin-right: 20px"
          >
            <el-button
              type="text"
              size="medium"
              icon="el-icon-upload2"
            >
              导入
            </el-button>
          </el-upload>
          <el-button
            type="text"
            size="medium"
            icon="el-icon-view"
            @click="handlePreview"
          >
            预览表单
          </el-button>
          <el-button
            v-if="isCompoundWidgetMode"
            v-loading="submitCompoundLoading"
            type="text"
            size="medium"
            icon="el-icon-document-remove"
            @click="handleSubmit"
          >
            保存并检查
          </el-button>
          <el-button
            v-if="isCompoundWidgetMode"
            v-loading="submitCompoundLoading"
            type="text"
            size="medium"
            icon="el-icon-tickets"
            @click="handlePublish"
          >
            发布
          </el-button>
        </div>
      </el-header>
      <el-main :class="{'widget-empty': widgetForm.list.length == 0}">
        <widget-form
          ref="widgetForm"
          :data="widgetForm"
          :select.sync="widgetFormSelect"
          :can-delete-field="canDeleteField || canDeleteFormField"
          :start-edit-id="startEditId"
          :get-compound-by-key-or-id="getCompoundByKeyOrId"
        />
      </el-main>
    </el-container>

    <el-aside
      width="305px"
      class="widget-config-container"
    >
      <el-container>
        <el-header height="45px">
          <div
            class="config-tab"
            :class="{active: configTab=='widget' && widgetName}"
            @click="handleConfigSelect('widget')"
          >
            {{ widgetName }}
          </div>

          <div
            class="config-tab"
            :class="{active: configTab=='form'}"
            @click="handleConfigSelect('form')"
          >
            {{ isCompoundWidgetMode ? '套件属性' : '表单属性' }}
          </div>
        </el-header>
        <el-main class="config-content">

          <widget-config
            v-if="widgetFormSelect"
            v-show="configTab=='widget'"
            :data-sources="dataSources"
            :is-template="isTemplate"
            :remote-functions="remoteFunctionsLocal"
            :data="widgetFormSelect"
            :form-list="widgetForm.list"
            @field-change="fieldChange"
          />

          <form-config
            v-show="configTab=='form'"
            ref="formConfig"
            :data="widgetForm.config"
            :is-compound-widget-mode="isCompoundWidgetMode"
            :placeholdername="placeholdername"
            :component-old-key="componentOldKey"
            :form-name="form_name"
            :component-key-out="componentKey"
            @updateFormName="v => form_name = v"
            @updateComponentKey="v => componentKey = v"
          />
        </el-main>
      </el-container>
    </el-aside>

    <cus-dialog
      ref="widgetPreview"
      :visible="previewVisible"
      width="1000px"
      form
      @on-close="previewVisible = false"
      @on-submit="handleTest"
    >
      <generate-form
        v-if="previewVisible"
        ref="generateForm"
        v-model="widgetModels"
        :data="JSON.parse(JSON.stringify(widgetForm))"
      >
        <template
          slot="blank"
          slot-scope="scope"
        >
          宽度：<el-input
            v-model="scope.model.blank.width"
            style="width: 100px"
          />
          高度：<el-input
            v-model="scope.model.blank.height"
            style="width: 100px"
          />
        </template>
      </generate-form>
    </cus-dialog>

    <cus-dialog
      ref="jsonPreview"
      :visible="jsonVisible"
      width="800px"
      form
      @on-close="jsonVisible = false"
    >
      <div
        id="jsoneditor"
        style="height: 400px;width: 100%;"
      >
        {{ jsonTemplate }}
      </div>

      <template slot="action">
        <el-button
          id="copybtn"
          data-clipboard-target=".ace_text-input"
        >
          双击复制
        </el-button>
      </template>
    </cus-dialog>

    <cus-dialog
      ref="codePreview"
      :visible="codeVisible"
      width="800px"
      form
      :action="false"
      @on-close="codeVisible = false"
    >
      <div
        id="codeeditor"
        style="height: 500px; width: 100%;"
      >
        {{ htmlTemplate }}
      </div>
    </cus-dialog>

    <!-- 修改套件id -->
    <el-dialog
      title="套件id修改"
      :visible.sync="edittingComponentIdDialog"
      width="50%"
    >
      <el-input v-model.number="newComponentId" />
      <p>
        {{ newCompoundComponent ? '套件名: ' + newCompoundComponent.name : '无匹配的套件' }}
      </p>
      <span slot="footer">
        <el-button @click="edittingComponentIdDialog = false">取 消</el-button>
        <el-button
          type="primary"
          @click="sureEditComponentId"
        >确 定</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>

<script>
import Draggable from 'vuedraggable'
import WidgetConfig from './WidgetConfig'
import FormConfig from './FormConfig'
import WidgetForm from './WidgetForm'
import CusDialog from './CusDialog'
import GenerateForm from './GenerateForm'
import { basicComponents, layoutComponents, advanceComponents } from './componentsConfig.js'
import { getLinearFormList, validateParam } from '../util/index'

export default {
  name: 'FormDesign',
  components: {
    Draggable, // 拖动容器组件
    WidgetConfig, // 字段配置组件
    FormConfig, // 表单配置组件
    WidgetForm, // 表单设计组件
    CusDialog, // 弹窗组件
    GenerateForm // 表单预览组件
  },
  props: {
    // 父组件传下来的表单设计数据
    value: {
      type: Object,
      default: () => null
    },
    isTemplate: { // 是否是流程模板设计模式
      type: Boolean,
      default: false
    },
    remoteFunctions: { // 远程函数列表
      type: Object,
      default: () => {}
    },
    canDeleteField: { // 判断组件是否能够删除
      type: Function,
      default: null
    },
    assemblyComponents: {
      type: Array,
      default: () => []
    }, // 平台套件数组
    tenancyComponents: {
      type: Array,
      default: () => []
    }, // 租户套件数组,
    loadingCompounds: {
      type: Boolean,
      default: false
    } // 套件列表loading状态
  },
  data () {
    return {
      remoteFunctionsLocal: this.remoteFunctions,
      form_name: '',
      componentKey: '',
      componentOldKey: '',
      form_id: '',
      components:basicComponents.concat(advanceComponents, layoutComponents),
      assemblyComponentsProp: [],
      moreComponent: false,
      placeholderid: '',
      placeholdername: '',
      widgetForm: this.value || {
        list: [],
        config: {
          labelWidth: 100,
          labelPosition: 'top',
          immutable: true
        },
        compound: []
      },
      compoundStatus: 0,
      configTab: 'widget',
      widgetFormSelect: null,
      previewVisible: false,
      jsonVisible: false,
      codeVisible: false,
      widgetModels: {},
      blank: '',
      htmlTemplate: '',
      jsonTemplate: '',
      formkey: '',
      dataSources: {},
      submitCompoundLoading: false,
      edittingComponentId: '',
      edittingComponentIdDialog: false,
      newComponentId: '',
      formHasChanged: false
    }
  },
  computed: {
    assemblyComponentsWithTip() {
      // 检测流程依赖的套件版本，有新版本则提示更新
      this.assemblyComponentsProp = this.assemblyComponentsProp.length > 0 ? this.assemblyComponentsProp : this.assemblyComponents;
      if(this.assemblyComponentsProp.length > 5){
        this.moreComponent = true;
      }

      const arr = [...this.assemblyComponentsProp].concat([...this.tenancyComponents])
      if (this.widgetForm && this.widgetForm.compound) {
        const compoundIds = {}
        this.widgetForm.compound
        .filter(item => {
          if (item.immutable) {
            compoundIds['v' + item.compoundId] = item.version
          }
        })
        arr.forEach(item => {
          if (compoundIds['v' + item.id] && compoundIds['v' + item.id] !== item.version) {
            item.hasNewVersion = true
          } else {
            item.hasNewVersion = undefined
          }
        })
      }
      return arr
    },
    newCompoundComponent() {
      for(let i = 0; i < this.assemblyComponentsWithTip.length; i++) {
        if (this.assemblyComponentsWithTip[i].id === this.newComponentId && this.assemblyComponentsWithTip[i].data.config.immutable) {
          return this.assemblyComponentsWithTip[i]
        }
      }
      return null
    },
    fields() {
      return getLinearFormList((this.widgetForm && this.widgetForm.list) ? this.widgetForm.list : [])
    },
    modelKeys() {
      return this.fields.map(item => item.model)
    },
    mode () {
      return this.$route.query.mode
    },
    isCompoundWidgetMode () {
      return this.mode === 'compoundWidget'
    },
    // 所有组件的对象，以组件的key为对象的属性key
    formKeyObject() {
      const obj = {}
      if (this.value && this.value.list) {
        this.value.list.forEach(c => {
          obj[c.key] = c
          if (c.columns) {
            c.columns.forEach(col => {
              if (col.list) {
                col.list.forEach(component => {
                  obj[component.key] = component
                })
              }
            })
          }
        })
      }
      return obj
    },
    compoundsAll () {
      return [].concat(this.assemblyComponentsProp).concat(this.tenancyComponents)
    },
    widgetName(){
      let result = null;
      if (this.widgetFormSelect) {
        for (let i = 0; i < basicComponents.length; i++) {
          let item = basicComponents[i];
          if (item.type === this.widgetFormSelect.type) {
            result = item.name;
            break;
          }
        }
      }
     return result;
    },
  },
  watch: {
    remoteFunctions: {
      handler () {
        this.remoteFunctionsLocal = this.remoteFunctions
      },
      deep: true
    },
    formKeyObject: {
      deep: true,
      handler () {
        this.$emit('form-change', this.formKeyObject)
      }
    },
    widgetForm: {
      deep: true,
      handler: function () {
        this.$emit('input', this.widgetForm)
        this.formHasChanged = true
      }
    },
    value: {
      handler () {
        this.widgetForm = this.value || {
          list: [],
          config: {
            labelWidth: 100,
            labelPosition: 'top',
            immutable: true
          },
          compound: []
        }
      },
      deep: true
    }
  },
  async created () {
    // 流程设计时
    if (!this.isTemplate) {
      this.query()
    }

    // 套件设计时：load specific form
    if (this.$route.query.formId) {
      this.handleLoad()
    }

    // 套件设计时：本地remoteFunctions为空，需请求
    if (!this.remoteFunctionsLocal) {
      // 获取租户的远程函数列表、审批的同步调用列表、任务列表！
      const res = await this.$http.get('/workflow/contract-config/index')
      if (res.data.code === 0) {
        this.remoteFunctionsLocal = res.data.data.function || {} // 远程函数列表
      }
    }

    this.getDataSources()
  },

  methods: {
    // 根据组件获取所在套件信息
    getCompoundByKeyOrId (component) {
      let res
      if (component && component.immutable && component.compoundKey) {
        res = this.compoundsAll.find(compound => compound.key === component.compoundKey)
        if (res) {
          this.$set(res, 'originId', component.compoundId)
          return res
        }
      }
      if (component && component.immutable && (component.compoundId || component.compoundId === 0)) {
        res = this.compoundsAll.find(compound => compound.id === component.compoundId)
        if (res) {
          this.$set(res, 'originId', component.compoundId)
          return res
        }
      }
    },
    // 编辑套件的id
    startEditId (component) {
      if (component && component.immutable && (component.compoundId || component.compoundId === 0)) {
        this.edittingComponentId = component.compoundId
        this.newComponentId = component.compoundId
      }
      this.edittingComponentIdDialog = true
    },
    // 修改表单使用到的套件的id
    sureEditComponentId () {
      if (this.edittingComponentId === this.newComponentId) {
        this.$message.error({ message: '请输入新的套件id！', duration: 10000, showClose: true })
      } else if (this.newCompoundComponent) {
        // 已经存在的不能加
        if (this.widgetForm.compound.map(i => i.compoundId).includes(this.newComponentId)) {
          this.$message.error({ message: '该套件已存在，不能重复！', duration: 10000, showClose: true })
          return
        }
        // 替换表单配置里面的compoundId
        for(let i = 0; i < this.widgetForm.compound.length; i++) {
          if (this.widgetForm.compound[i].compoundId === this.edittingComponentId) {
            this.widgetForm.compound[i].compoundId = this.newComponentId
            this.widgetForm.compound[i].version = -1
            break
          }
        }
        // 替换所有组件里面的compoundId
        this.widgetForm.list.forEach(c => {
          if (c.immutable && c.compoundId === this.edittingComponentId) {
            c.compoundId = this.newComponentId
          }
          if ((c.type === 'grid' || c.type === 'subform') && c.columns) {
            c.columns.forEach(col => {
              if (col.list) {
                col.list.forEach(innerComponent => {
                  if (innerComponent.immutable && innerComponent.compoundId === this.edittingComponentId) {
                    innerComponent.compoundId = this.newComponentId
                  }
                })
              }
            })
          }
        })
        this.edittingComponentIdDialog = false
      } else {
        this.$message.error({ message: '请正确填写id，以匹配对应的套件！', duration: 10000, showClose: true })
      }
    },
    // 直接读取json文件内容，填充到当前的套件
    fileChange(file) {
      const fileReader = new FileReader()
      fileReader.onload = () => {
        const res = JSON.parse(fileReader.result)

        // 赋值
        this.widgetForm.list = res.list || res.form_define.list;
        if (res.form_define && res.form_define.config) {
          const keys = Object.keys(res.form_define.config)
          if (keys.includes('immutable')) {
            this.widgetForm.config.immutable = res.form_define.config.immutable
          }
          if (keys.includes('information')) {
            this.widgetForm.config.information = res.form_define.config.information
          }
          if (keys.includes('labelPosition')) {
            this.widgetForm.config.labelPosition = res.form_define.config.labelPosition
          }
          if (keys.includes('labelWidth')) {
            this.widgetForm.config.labelWidth = res.form_define.config.labelWidth
          }
        }
      }
      fileReader.readAsText(file.raw)
    },
    // 表单改变时，对表单的引用自动更新
    updateFormDepend (field) {
      // 表单内的依赖更新
      if (this.widgetForm && this.widgetForm.list) {
        this.widgetForm.list.forEach(c => {
          this.updateOneComponent(field, c)
          if (c.columns) {
            c.columns.forEach(col => {
              if (col.list) {
                col.list.forEach(inComponent => {
                  this.updateOneComponent(field, inComponent)
                })
              }
            })
          }
        })
      }
    },
    updateOneComponent (field, component) {
      if (field.old.name !== field.val.name) {
        // name改变
        // 更新公式内的组件依赖
        // 默认值
        if (component && component.options && component.options.valueConfig && component.options.valueConfig.formula) {
          this.updateFormulaName(component.options.valueConfig.formula, field)
        }
        // 是否使用本控件
        if (component && component.options && component.options.display && component.options.display.formula) {
          this.updateFormulaName(component.options.display.formula, field)
        }
        // 是否显示本控件
        if (component && component.options && component.options.show && component.options.show.formula) {
          this.updateFormulaName(component.options.show.formula, field)
        }
        // 是否禁用本控件
        if (component && component.options && component.options.disabled && component.options.disabled.formula) {
          this.updateFormulaName(component.options.disabled.formula, field)
        }
        // 是否只读本控件
        if (component && component.options && component.options.readonly && component.options.readonly.formula) {
          this.updateFormulaName(component.options.readonly.formula, field)
        }
        // 本控件的验证规则
        if (component && component.options && component.options.verify && component.options.verify.formula) {
          this.updateFormulaName(component.options.verify.formula, field)
        }
      }
      if (field.old.model !== field.val.model) {
        // model改变
        // 更新可选项远程数据源参数
        if (component && component.options && component.options.remote && component.options.params) {
          this.updateParamsModel(component.options.params, field)
        }
        // 更新可选项子表单依赖
        if (component && component.options && component.options.isSubformData && component.options.subformDataKey === field.old.model) {
          component.options.subformDataKey = field.val.model
        }
        // 更新默认值远程数据源参数
        if (component && component.options && component.options.valueConfig && component.options.valueConfig.dataSourceKey && component.options.valueConfig.params) {
          this.updateParamsModel(component.options.valueConfig.params, field)
        }
        // 更新默认值组件依赖
        if (component && component.options && component.options.valueConfig && component.options.valueConfig.dependItemModel === field.old.model) {
          component.options.valueConfig.dependItemModel = field.val.model
        }
        // 更新公式内的组件依赖
        // 默认值
        if (component && component.options && component.options.valueConfig && component.options.valueConfig.formula) {
          this.updateFormulaModel(component.options.valueConfig.formula, field)
        }
        // 是否使用本控件
        if (component && component.options && component.options.display && component.options.display.formula) {
          this.updateFormulaModel(component.options.display.formula, field)
        }
        // 是否显示本控件
        if (component && component.options && component.options.show && component.options.show.formula) {
          this.updateFormulaModel(component.options.show.formula, field)
        }
        // 是否禁用本控件
        if (component && component.options && component.options.disabled && component.options.disabled.formula) {
          this.updateFormulaModel(component.options.disabled.formula, field)
        }
        // 是否只读本控件
        if (component && component.options && component.options.readonly && component.options.readonly.formula) {
          this.updateFormulaModel(component.options.readonly.formula, field)
        }
        // 本控件的验证规则
        if (component && component.options && component.options.verify && component.options.verify.formula) {
          this.updateFormulaModel(component.options.verify.formula, field)
        }
        // 日期组件的禁用规则
        // 大于
        if (component && component.options && component.options.greaterThan && component.options.greaterThan === field.old.model) {
          component.options.greaterThan = field.val.model
        }
        // 等于
        if (component && component.options && component.options.equal && component.options.equal === field.old.model) {
          component.options.equal = field.val.model
        }
        // 小于
        if (component && component.options && component.options.lessThan && component.options.lessThan === field.old.model) {
          component.options.lessThan = field.val.model
        }
      }
    },
    // 更新params里面的参数组件的model
    updateParamsModel (params, field) {
      params.forEach(param => {
        if (param.value) {
          param.value.forEach((value, i) => {
            if (value === field.old.model) {
              param.value[i] = field.val.model
            }
          })
        }
      })
    },
    // 更新params里面的参数组件的name
    updateParamsName (params, field) {
      params.forEach(param => {
        if (param.value) {
          param.value.forEach((value, i) => {
            if (value === field.old.model) {
              param.value[i] = field.val.model
            }
          })
        }
      })
    },
    // 更新公式里面的变量组件的model
    updateFormulaModel (formula, field) {
      formula.forEach(item => {
        if (item.type === 'variate' && item.value === field.old.model) {
          item.value = field.val.model
        }
        if (item.type === 'source' && item.params) {
          this.updateParamsModel(item.params, field)
        }
      })
    },
    // 更新公式里面的变量组件的name
    updateFormulaName (formula, field) {
      formula.forEach(item => {
        if (item.type === 'variate' && item.label === field.old.name && item.value === field.val.model) {
          item.label = field.val.name
        }
      })
    },
    // 组件的name或者model修改时
    fieldChange (field) {
      this.updateFormDepend(field)
      this.$emit('field-change', field)
    },
    // 获取数据源类型
    async getDataSources () {
      if (!this.isTemplate && !Object.keys(this.dataSources) < 1) {
        const res = await this.$http.get('/workflow/data-source')
        if (res.data.code === 0) {
          this.dataSources = res.data.data
        }
      }
    },
    // 获取已有自定义组件
    async query () {
      if (this.mode !== 'compoundWidget') {
        this.placeholderid = '表单Id'
        this.placeholdername = '表单名称'
      } else {
        this.placeholderid = '组件Id'
        this.placeholdername = '组件名称'
      }
    },
    // 升级套件
    upgradeCompound (compound) {
      this.$confirm('确定升级?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 覆盖具体组件内容
        const indexs = []
        this.widgetForm.list.forEach((component, index) => {
          if (compound.id === component.compoundId) {
            indexs.push(index)
          }
        })
        const subList = compound.data ? (compound.data.list || []) : []
        subList.forEach(item => {
          if (item.type === 'subform' || item.type === 'grid') {
            item.columns && item.columns.forEach(col => {
              col.list && col.list.forEach(component => {
                component.compoundId = compound.id
                component.immutable = compound.data.config.immutable
              })
            })
          }
          item.compoundId = compound.id
          item.immutable = compound.data.config.immutable
        })
        this.widgetForm.list.splice(indexs[0], indexs.length, ...subList)
        // 覆盖版本信息
        for (let i = 0; i < this.widgetForm.compound.length; i++) {
          if (this.widgetForm.compound[i].compoundId === compound.id) {
            this.widgetForm.compound[i].version = compound.version
            break
          }
        }
        this.$message.success({ message: '已升级，发布或保存后生效!', duration: 10000, showClose: true })
      })
    },
    // 获取表单数据
    getFormData () {
      return this.widgetForm
    },
    // handleLoad 获取指定表单/套件的数据
    handleLoad () {
      this.$http.get('/workflow/forms/' + (this.$route.query.formId || this.form_id))
        .then((response) => {
          if (response.data.code === 0) {
            this.widgetForm = response.data.data.data
            this.form_name = response.data.data.name
            this.form_id = response.data.data.id
            if (this.isCompoundWidgetMode) {
              this.componentKey = response.data.data.key
              this.componentOldKey = response.data.data.key
              this.compoundStatus = response.data.data.status
            }
            this.$nextTick(() => {
              this.formHasChanged = false
            })
          }
        })
    },
    handleConfigSelect (value) {
      this.configTab = value
    },
    handleMoveEnd (evt) {
      //console.log('end', evt)
    },
    handleMoveStart ({ oldIndex }) {
      //console.log('start', oldIndex)
    },
    handleMove () {
      return true
    },
    async handleMoreComponent(){
      const res = await this.$http.get('/workflow/forms/subunit')
      if (res.data.code === 0) {
        this.moreComponent = true
        this.assemblyComponentsProp = res.data.data.lists || []
      }
    },
    // 预览表单
    handlePreview () {
      // 验证通过才能预览，不然表单展示可能有问题
      if (!this.validateModel()) {
        return
      }
      if (!this.validateFunParam()) {
        return
      }
      // 清空旧数据
      this.widgetModels = {}
      this.previewVisible = true
    },
    handleTest () {
      this.$refs.generateForm.getData()
        .then(data => {
          this.$alert(data, '').catch(e => {
            console.error(e)
          })
          this.$refs.widgetPreview.end()
        }).catch(e => {
          console.error(e)
          this.$refs.widgetPreview.end()
        })
    },

    // 发布套件
    async handlePublish () {
      if (this.submitCompoundLoading) {
        return
      }

      if (!(this.$route.query.formId && this.compoundStatus === 2 && !this.formHasChanged)) {
        this.$message.error({ message: '请先保存并检查，通过后才可以发布！', duration: 10000, showClose: true })
        return
      }
      if (this.$route.query.formId) {
        try {
          this.submitCompoundLoading = true
          const response = await this.$http.post('/workflow/forms/upgrade/' + '' + this.$route.query.formId)
          this.submitCompoundLoading = false
          if (response.data && response.data.code === 0) {
            this.compoundStatus = 1
            this.$message.success({ message: '发布成功', duration: 10000, showClose: true })
          } else {
            this.$message.error({ message: response.data.message, duration: 10000, showClose: true })
          }
        } catch (e) {
          this.submitCompoundLoading = false
          this.$message.error({ message: e.message, duration: 10000, showClose: true })
        }
      }
    },
    // 提交数据（保存草稿）
    async handleSubmit () {
      if (this.submitCompoundLoading) {
        return
      }

      this.$refs.formConfig && this.$refs.formConfig.validate()
      if (!this.form_name) {
        this.$message.error({ message: '名称必填', duration: 10000, showClose: true })
        this.handleConfigSelect('form')
        return
      }
      if (this.isCompoundWidgetMode && this.widgetForm.config.immutable && !this.componentKey) {
        this.$message.error({ message: '套件key必填', duration: 10000, showClose: true })
        this.handleConfigSelect('form')
        return
      }
      if (!this.validateModel()) {
        return
      }
      if (!this.validateFunParam()) {
        return
      }
      if (this.widgetForm.list.length < 1) {
        this.$message.error({ message: '套件必须包含至少1个组件', duration: 10000, showClose: true })
        return
      }
      // this.mode = this.$route.query.mode
      // 编辑表单或套件
      // const submitUrl = window.location.pathname === '/workflow/formDesign' ? '/workflow/forms' : '/forms/submit'
      const submitUrl = '/workflow/forms'
      this.submitCompoundLoading = true
      if (this.$route.query.formId) {
        try {
          const componentData = {
            // form_type: this.form_type,
            form_name: this.form_name,
            // create_user_id: this.create_user_id,
            form_data: this.widgetForm,
            id: this.$route.query.formId
          }
          if (this.isCompoundWidgetMode && this.widgetForm.config.immutable) {
            componentData.form_key = this.componentKey
          }
          const response = await this.$http.put(submitUrl + '/' + this.$route.query.formId, componentData)
          this.submitCompoundLoading = false
          if (response.data && response.data.code === 0) {
            if (response.data.data.status === 2) {
              this.compoundStatus = response.data.data.status
              this.$message.success({ message: '保存草稿成功，发布后生效，请注意租户的流程标题是否有引用组件的内容！', duration: 10000, showClose: true })
              this.formHasChanged = false
            } else if (response.data.data.status === 9) {
              this.compoundStatus = response.data.data.status
              this.$message.error({ message: '已保存草稿，但' + ((response.data.data.desc_note && response.data.data.desc_note.length > 0) ? response.data.data.desc_note[response.data.data.desc_note.length - 1] : '') + '。因此套件禁止发布', duration: 10000, showClose: true })
            }

            if (this.isCompoundWidgetMode && this.widgetForm.config.immutable) {
              this.componentOldKey = response.data.data.key
            }
          } else {
            // this.$message.error({ message: '提交错误，请重试', duration: 10000, showClose: true })
            this.$message.error({ message: response.data.message, duration: 10000, showClose: true })
          }
        } catch (e) {
          this.submitCompoundLoading = false
          this.$message.error({ message: e.message, duration: 10000, showClose: true })
        }
      } else {
        // 新建表单或套件
        try {
          const response = await this.$http.post(submitUrl, {
            // form_type: this.mode !== 'compoundWidget' ? 1 : 2,
            form_name: this.form_name,
            // create_user_id: '11',
            form_data: this.widgetForm,
            form_key: this.componentKey
          })
          this.submitCompoundLoading = false
          if (response.data && response.data.code === 0) {
            this.compoundStatus = response.data.data.status
            this.$message.success({ message: '保存草稿成功，发布后生效，请注意租户的流程标题是否有引用组件的内容！', duration: 10000, showClose: true })
            this.formHasChanged = false

            this.componentOldKey = this.componentKey
            // this.$message.success({ message: '提交成功', duration: 10000, showClose: true })
            // this.widgetForm.list = []
            // this.$router.push('/workflow/formDesign?mode=compoundWidget&formId=' + response.data.data.id)
            this.$router.push(window.location.pathname + '?mode=compoundWidget&formId=' + response.data.data.id)
          } else {
            // this.$message.error({ message: '提交错误，请重试', duration: 10000, showClose: true })
            this.$message.error({ message: response.data.message, duration: 10000, showClose: true })
          }
        } catch (e) {
          this.submitCompoundLoading = false
          this.$message.error({ message: e.message, duration: 10000, showClose: true })
        }
      }
    },
    // 公式验证
    validateFormula (formula) {
      let errorModel
      // 循环表达式数组
      for (let formulaIndex = 0; formulaIndex < formula.length; formulaIndex++) {
        // 变量不存在时
        if (formula[formulaIndex].type === 'variate' && !this.modelKeys.includes(formula[formulaIndex].value)) {
          errorModel = {
            type: 'variate',
            message: formula[formulaIndex].label
          }
          break
        } else if (formula[formulaIndex].type === 'source' && formula[formulaIndex].params && formula[formulaIndex].params.length > 0) {
          // 数据源时
          errorModel = validateParam([formula[formulaIndex]], this.modelKeys, { function: this.remoteFunctionsLocal, dataSources: this.dataSources })
          if (errorModel) {
            errorModel = {
              type: 'source',
              obj: errorModel
            }
            break
          }
        }
      }
      return errorModel
    },
    // 验证所有组件的moel必填
    validateModel () {
      let res = true
      if (this.widgetForm && this.widgetForm.list && this.widgetForm.list.length > 0) {
        const list = this.widgetForm.list
        for (let i = 0; i < list.length; i++) {
          if (!list[i].model) {
            res = false
            this.$message.error({ message: `组件${list[i].name}的数据绑定Key为必填！`, duration: 10000, showClose: true })
            break
          }
          if ((list[i].type === 'grid' || list[i].type === 'subform') && list[i].columns && list[i].columns.length > 0) {
            for (let c = 0; c < list[i].columns.length; c++) {
              if (list[i].columns[c].list && list[i].columns[c].list.length > 0) {
                for (let com = 0; com < list[i].columns[c].list.length; com++) {
                  if (!list[i].columns[c].list[com].model) {
                    res = false
                    this.$message.error({ message: `组件${list[i].columns[c].list[com].name}的数据绑定Key为必填！`, duration: 10000, showClose: true })
                    break
                  }
                }
              }
              if (!res) {
                break
              }
            }
          }
          if (!res) {
            break
          }
        }
      }
      return res
    },
    // 验证带参数据源参数、函数参数是否存在：表单字段可能删除
    validateFunParam () {
      if (this.fields && this.fields.length > 0) {
        let errorMessage = ''
        let errorModel
        // 套件id在套件列表中不存在时：不能提交，强制修改id以匹配
        const immutableCompounds = this.widgetForm.compound.filter(i => (typeof i === 'object') && i.immutable)
        const compoundsList = [].concat(this.assemblyComponentsWithTip)
        for (let i = 0; i < immutableCompounds.length; i++) {
          const compound = immutableCompounds[i]
          // 套件有key时，验证key、id以及他们的映射关系
          if (compound.compoundKey) {
            const matchItem = compoundsList.find(item => {
              return item.key === compound.compoundKey
            })
            if (!matchItem) {
              this.$message.error({ message: `套件列表不存在key为${compound.compoundKey}(表单中第${i + 1}个套件的id)的套件！`, duration: 10000, showClose: true })
              return false
            } else if (matchItem.id !== compound.compoundId) {
              this.$message.error({ message: `套件列表中key为${compound.compoundKey}的套件与表单中第${i + 1}个套件的key匹配，但是id不匹配！`, duration: 10000, showClose: true })
              return false
            }
          } else {
            // 套件无key时，验证id
            const listItem = compoundsList.find(item => {
              return item.id === compound.compoundId
            })
            if (!listItem) {
              this.$message.error({ message: `套件列表不存在id为${compound.compoundId}(表单中第${i + 1}个套件的id)的套件！`, duration: 10000, showClose: true })
              return false
            }
            if (listItem && !listItem.data.config.immutable) {
              this.$message.error({ message: `套件列表中id为${compound.compoundId}(表单中第${i + 1}个套件)的套件不是不可变的！`, duration: 10000, showClose: true })
              return false
            }
          }
        }

        for (let fieldIndex = 0; fieldIndex < this.fields.length; fieldIndex++) {
          if (this.fields[fieldIndex].options) {
            // 验证默认值的公式变量和公式内数据源的参数变量
            if (this.fields[fieldIndex].options.valueConfig) {
              if (this.fields[fieldIndex].options.valueConfig.type === 2 && this.fields[fieldIndex].options.valueConfig.formula && this.fields[fieldIndex].options.valueConfig.formula.length > 0) {
                errorModel = this.validateFormula(this.fields[fieldIndex].options.valueConfig.formula)
                if (errorModel) {
                  errorMessage = '默认值的公式'
                  errorMessage = errorMessage ? this.fields[fieldIndex].name + '组件的' + errorMessage : ''
                  break
                }
              } else if (this.fields[fieldIndex].options.valueConfig.type === 3 && this.fields[fieldIndex].options.valueConfig.dependItemModel) {
                // 只选了组件，未指定属性值时
                if (!this.fields[fieldIndex].options.valueConfig.dependItemProperty) {
                  errorMessage = this.fields[fieldIndex].name + '组件的默认值的组件依赖的选项属性必选'
                } else if (!this.modelKeys.includes(this.fields[fieldIndex].options.valueConfig.dependItemModel)) {
                // 变量不存在时
                  errorMessage = this.fields[fieldIndex].name + '组件的默认值的组件依赖不存在'
                  break
                }
              }
            }
            // 验证是否使用本控件的公式变量和公式内数据源的参数变量
            if (this.fields[fieldIndex].options.display && this.fields[fieldIndex].options.display.type === 2 && this.fields[fieldIndex].options.display.formula && this.fields[fieldIndex].options.display.formula.length > 0) {
              errorModel = this.validateFormula(this.fields[fieldIndex].options.display.formula)
              if (errorModel) {
                errorMessage = '是否使用本控件的公式'
                errorMessage = errorMessage ? this.fields[fieldIndex].name + '组件的' + errorMessage : ''
                break
              }
            }
            // 验证是否显示本控件的公式变量和公式内数据源的参数变量
            if (this.fields[fieldIndex].options.show && this.fields[fieldIndex].options.show.type === 2 && this.fields[fieldIndex].options.show.formula && this.fields[fieldIndex].options.show.formula.length > 0) {
              errorModel = this.validateFormula(this.fields[fieldIndex].options.show.formula)
              if (errorModel) {
                errorMessage = '是否显示本控件的公式'
                errorMessage = errorMessage ? this.fields[fieldIndex].name + '组件的' + errorMessage : ''
                break
              }
            }
            // 验证禁用设置的公式变量和公式内数据源的参数变量
            if (this.fields[fieldIndex].options.disabled && this.fields[fieldIndex].options.disabled.type === 2 && this.fields[fieldIndex].options.disabled.formula && this.fields[fieldIndex].options.disabled.formula.length > 0) {
              errorModel = this.validateFormula(this.fields[fieldIndex].options.disabled.formula)
              if (errorModel) {
                errorMessage = '禁用设置的公式'
                errorMessage = errorMessage ? this.fields[fieldIndex].name + '组件的' + errorMessage : ''
                break
              }
            }
            // 验证只读设置的公式变量和公式内数据源的参数变量
            if (this.fields[fieldIndex].options.readonly && this.fields[fieldIndex].options.readonly.type === 2 && this.fields[fieldIndex].options.readonly.formula && this.fields[fieldIndex].options.readonly.formula.length > 0) {
              errorModel = this.validateFormula(this.fields[fieldIndex].options.readonly.formula)
              if (errorModel) {
                errorMessage = '只读设置的公式'
                errorMessage = errorMessage ? this.fields[fieldIndex].name + '组件的' + errorMessage : ''
                break
              }
            }
            // 验证默认值的数据源的参数变量
            if (this.fields[fieldIndex].options.valueConfig && this.fields[fieldIndex].options.valueConfig.type === 4) {
              errorModel = validateParam([this.fields[fieldIndex].options.valueConfig], this.modelKeys, { function: this.remoteFunctionsLocal, dataSources: this.dataSources })
              if (errorModel) {
                errorModel = {
                  type: 'source',
                  obj: errorModel
                }
                errorMessage = '默认值'
                errorMessage = errorMessage ? this.fields[fieldIndex].name + '组件的' + errorMessage : ''
                break
              }
            }
            // 验证可选项数据源的参数是否存在
            if (this.fields[fieldIndex].options.remote && this.fields[fieldIndex].options.params && this.fields[fieldIndex].options.params.length > 0) {
              errorModel = validateParam([this.fields[fieldIndex].options], this.modelKeys, { function: this.remoteFunctionsLocal, dataSources: this.dataSources })
              if (errorModel) {
                errorModel = {
                  type: 'source',
                  obj: errorModel
                }
                errorMessage = '可选项'
                errorMessage = errorMessage ? this.fields[fieldIndex].name + '组件的' + errorMessage : ''
                break
              }
            }

            // 验证公式验证的公式变量和公式内数据源的参数变量
            if (this.fields[fieldIndex].options.verify && this.fields[fieldIndex].options.verify.formula && this.fields[fieldIndex].options.verify.formula.length > 0) {
              errorModel = this.validateFormula(this.fields[fieldIndex].options.verify.formula)
              if (errorModel) {
                errorMessage = '公式验证的公式'
                errorMessage = errorMessage ? this.fields[fieldIndex].name + '组件的' + errorMessage : ''
                break
              }
            }
          }
        }
        if (errorMessage) {
          if (errorModel) {
            if (errorModel.type === 'variate') {
              errorMessage = `${errorMessage}的变量:${errorModel.message}不存在`
            } else {
              errorMessage = `${errorMessage}的数据源:${errorModel.obj.fun}的参数:${errorModel.obj.param}的字段:${errorModel.obj.model}不存在`
            }
          }
          this.$message.error({ message: errorMessage, duration: 10000, showClose: true })
          return false
        }
        return true
      }
      return true
    },
    // 根据表单设计，判断指定的组件是否可删除:被引用的不能删除
    canDeleteFormField (widget, container) {
      // 目标组件是布局组件时
      if (['subform', 'grid'].includes(widget.type)) {
        for (let col = 0; col < widget.columns.length; col++) {
          for (let i = 0; i < widget.columns[col].list.length; i++) {
            let errorMessage = this.canDeleteFormField(widget.columns[col].list[i], widget)
            if (errorMessage) {
              return errorMessage
            }
          }
        }
      }
      // 循环组件
      for (let j = 0; j < this.widgetForm.list.length; j++) {
        // 外层组件验证
        let res = this.hasDepend(this.widgetForm.list[j], widget, container)
        if (res) {
          return (container ? (container.type === 'grid' ? '栅格布局' : container.name) + '内的' : '') + res
        }
        // 容器组件内的组件验证
        if (this.widgetForm.list[j].type === 'subform' || this.widgetForm.list[j].type === 'grid') {
          for (let col = 0; col < this.widgetForm.list[j].columns.length; col++) {
            for (let i = 0; i < this.widgetForm.list[j].columns[col].list.length; i++) {
              let errorMessage = this.hasDepend(
                this.widgetForm.list[j].columns[col].list[i],
                widget,
                container,
                this.widgetForm.list[j]
              )
              if (errorMessage) {
                return (container ? (container.type === 'grid' ? '栅格布局' : container.name) + '内的' : '') + errorMessage
              }
            }
          }
        }
      }
    },
    // 判断origin组件是否依赖target组件：带参数据源、公式(默认值、属性、验证)、组件依赖、、、、
    hasDepend (origin, target, targetContainer, originContainer) {
      console.log(origin.name, target.name, targetContainer, originContainer)
      // 自己依赖自己，是可以删除的
      if (origin.model === target.model || (targetContainer && (targetContainer === originContainer || targetContainer === origin)) || (origin.compoundId === target.compoundId && origin.compoundId !== undefined && origin.immutable === true) || (origin.compoundKey && origin.compoundKey === target.compoundKey && origin.immutable === true)) {
        return
      }
      // 可选项带参数据源
      if (origin.options.remote && origin.options.params && origin.options.params.length > 0) {
        let res = this.hasDependForParam(origin.options.params, target)
        if (res) {
          return `${target.name}被组件${origin.name}的选项---数据源引用，请先删除引用，再删除${target.name}。`
        }
      }

      // 可选项：子表单
      if (origin.options.isSubformData && origin.options.subformDataKey === target.model) {
        return `${target.name}被组件${origin.name}的选项---子表单引用，请先删除引用，再删除${target.name}。`
      }

      // 默认值公式
      if (origin.options.valueConfig && origin.options.valueConfig.type === 2 && origin.options.valueConfig.formula) {
        let res = this.hasDependForFormula(origin.options.valueConfig.formula, target)
        if (res) {
          return `${target.name}被组件${origin.name}的默认值---公式引用，请先删除引用，再删除${target.name}。`
        }
      }


      // 默认值组件依赖
      if (origin.options.valueConfig && origin.options.valueConfig.type === 3 && origin.options.valueConfig.dependItemModel === target.model) {
        return `${target.name}被组件${origin.name}的默认值---组件依赖引用，请先删除引用，再删除${target.name}。`
      }

      // 默认值带参数据源
      if (origin.options.valueConfig && origin.options.valueConfig.type === 4 && origin.options.valueConfig.dataSourceKey && origin.options.valueConfig.params) {
        let res = this.hasDependForParam(origin.options.valueConfig.params, target)
        if (res) {
          return `${target.name}被组件${origin.name}的默认值---数据源引用，请先删除引用，再删除${target.name}。`
        }
      }

      // 属性公式
      const properties = ['display', 'show', 'disabled', 'readonly']
      const propertyName = {
        display: '是否使用本控件',
        show: '是否显示本控件',
        disabled: '禁用设置',
        readonly: '只读设置'
      }
      for (let i = 0; i < properties.length; i++) {
        if (origin.options[properties[i]] && origin.options[properties[i]].type === 2 && origin.options[properties[i]].formula && this.hasDependForFormula(origin.options[properties[i]].formula, target)) {
          return `${target.name}被组件${origin.name}的${propertyName[properties[i]]}---公式引用，请先删除引用，再删除${target.name}。`
        }
      }

      // 验证公式
      if (origin.options.verify && origin.options.verify.formula && this.hasDependForFormula(origin.options.verify.formula, target)) {
        return `${target.name}被组件${origin.name}的校验---公式引用，请先删除引用，再删除${target.name}。`
      }
    },
    // 根据参数数组和目标组件，判断参数里面是否有目标组件：有即依赖
    hasDependForParam (params, target) {
      if (params && params.length > 0) {
        for (let i = 0; i < params.length; i++) {
          if (params[i].value && params[i].value.length > 0) {
            for (let j = 0; j < params[i].value.length; j++) {
              if (params[i].value[j] === target.model) {
                return true
              }
            }
          }
        }
      }
    },
    // 根据公式数组和目标组件，判断公式是否引用目标组件：有即依赖
    hasDependForFormula (formula, target) {
      if (formula && formula.length > 0) {
        for (let i = 0; i < formula.length; i++) {
          // 变量时
          if (formula[i].type === 'variate' && formula[i].value === target.model) {
            return true
          }
          // 数据源参数时
          if (formula[i].type === 'source' && formula[i].params && this.hasDependForParam(formula[i].params, target)) {
            return true
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../styles/cover.scss';
.tool-box {
  height: 100%;
}
.widget-empty{
  background: url('../assets/form_empty.png') no-repeat;
  background-position: 50%;
}
.btn-bar {
  display: block;
  justify-content: flex-end;
  line-height: 40px;
  height: auto !important;
  font-size: 0;
  .el-form {
    display: inline;
    white-space: normal;
    float: left;
    text-align: left;
  }
}
.input-box {
  display: inline-block;
  margin-right: 20px;
}
.btn-box {
  display: inline-block;
  white-space: normal;
}
.immutable-component {
  background-color: #dddddd!important;
  border-color: #dddddd!important;
}
.form-edit-widget-label {
  a {
    .el-badge {
      width: 97%;
      height: 26px;
    }
    svg {
      margin-bottom: 0;
    }
    span {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>
