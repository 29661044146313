import numberal from 'numeral'
import { add, sub, mul, div,max,min,cut } from './evaluation'
// 上取整
export const ceil = number => {
  return Math.ceil(number)
}
// 下取整
export const floor = number => {
  return Math.floor(number)
}
// 四舍五入取整
export const round = number => {
  return Math.round(number)
}
/**
 * 保留指定位数的小数
 * @param {number} number 需要处理的小数
 * @param {number} n 需要保留的小数位数：默认0位，即取整
 */
export const toNFixed = (number, n = 0) => {
  return parseFloat(number).toFixed(n)
}
// 绝对值
export const abs = number => {
  return Math.abs(number)
}

// 数字转字符串
export const num2Str = number => {
  return number.toString()
}

// 数组包含判断
export const arrayIncludes = (arr, val) => {
  if (typeof arr == "string") {
    arr = arr.split(",")
  }

  if (Array.isArray(arr)) {
    if (typeof arr[0] == "string") {
      val = val.toString();
    }
    return arr.includes(val);
  }
  return false;
}

// 普通数字转大些金额
export const largeMoney = (n) => {
  if (!/^(0|[1-9]\d*)(\.\d+)?$/.test(n)) { return '' }
  var unit = '仟佰拾亿仟佰拾万仟佰拾元角分'; var str = ''
  n += '00'
  var p = n.indexOf('.')
  if (p >= 0) { n = n.substring(0, p) + n.substr(p + 1, 2) }
  unit = unit.substr(unit.length - n.length)
  for (var i = 0; i < n.length; i++) { str += '零壹贰叁肆伍陆柒捌玖'.charAt(n.charAt(i)) + unit.charAt(i) }
  return str.replace(/零(仟|佰|拾|角)/g, '零').replace(/(零)+/g, '零').replace(/零(万|亿|元)/g, '$1').replace(/(亿)万|壹(拾)/g, '$1$2').replace(/^元零?|零分/g, '').replace(/元$/g, '元整').replace(/^分$/, '')
}

// 时间字符串转成时间戳
export const timestamp = v => new Date(v).getTime()

// 整天
export const wholeDay = (min, max) => {
  if (!min || !max) {
    return 0
  }
  const a = new Date(min).getTime()
  const b = new Date(max).getTime()
  const c = b - a
  const res = c / 1000 / 60 / 60 / 24
  return Math.floor(Math.abs(res))
}

// 工作天？？？？
export const workday = (min, max) => {
  if (!min || !max) {
    return 0
  }
  const a = new Date(min).getTime()
  const b = new Date(max).getTime()
  const c = b - a
  const res = c / 1000 / 60 / 60 / 24
  return Math.floor(Math.abs(res))
}
export const money = (num) => {
  return numberal(num).format('0,0.00')
}
export const formattingSum = (num) => {
  return numberal(num).format('0,0.00')
}
export const defaultValue = (a, b) => {
  return a === undefined ? b : a
}
export const reduceAdd = (arr, def) => arr ? objReduce(arr, add, typeof arr[0] === 'number' ? 0 : '') : def
export const reduceSub = (arr, def) => arr ? objReduce(arr, sub, 0) + arr[0] : def
export const reduceMul = (arr, def) => arr ? objReduce(arr, mul, 1) : def
export const reduceDiv = (arr, def) => arr ? objReduce(arr, div, 1) : def
export const reduceMax = (arr, def) => arr ? objFilter(arr, max,0) : def
export const reduceMin = (arr, def) => arr ? objFilter(arr, min,0) : def
export const reduceCut = (arr, def) => arr ? objFilter(arr, cut) : def

const objReduce = (obj, fun, init) => {
  return Object.keys(obj).reduce((a, c) => {
    if (c !== 'length') {
      return fun(a, obj[c])
    }
    return a
  }, init)
}
const objFilter=(obj, fun,init)=>{
  return fun(obj)||init
}

/**
 * 条件判断函数
 * @param {*} condition 用来判断真假的条件参数
 * @param {*} value1 值1
 * @param {*} value2 值2
 */
export const condition = (condition, value1, value2) => {
  return condition ? value1 : value2
}
