const locale = window.ELEMENT.lang.es
export default {
    ...locale,
    "The approval version has been upgraded, the system will refresh it for you, please check it and try to submit again.": "La versión aprobada se ha actualizado, el sistema la actualizará por usted, verifíquela e intente enviarla nuevamente.",
    "Cancel": "Cancelar",
    "Click to refresh": "Haga click para actualizar",
    "QR code is invalid": "El código QR es inválido",
    "Processing": "En proceso",
    "Confirm": "Confirmar",
    "取消": "Cancelar",
    "确定": "Confirmar",
    "提示": "Recordatorio",
    "系统异常": "Error del sistema",
    "已复制好，可贴粘。": "Se ha copiado y se puede pegar.",
    "more": "Más",
    "OK": "Confirmar",
    "cancel": "Cancelar",
  "Copy Success": "Copiado",
  "Copy link": "Copiar enlace",
    "more_items_with_num": "Quedan:numde artículos",
    "year_month": ":yearaño:monthmes",
    "确定删除该授权？": "¿Está seguro de eliminar esta autorización?",
    "您确定要删除此申请吗？不可恢复": "¿Estás seguro de que quieres eliminar esta aplicación? No se puede restaurar",
    "确定删除该分组？": "¿Seguro que quieres eliminar este grupo?",
    "删除分组": "Borrar el grupo",
    "确定停用该流程？": "¿Está seguro de que quiere desactivar este proceso?",
    "确定启用该流程？": "¿Seguro que va a iniciar este proceso?",
    "确定要删除该组件吗？": "¿Estás seguro de que quieres eliminar el componente?",
  "message.common.info": "Información",
    "message.common.code.askDecryptionAgain": "¿Quiere descifrar de nuevo?",
    "message.common.code.decryptionFailed": "Fallo de desencriptación",
    "message.common.code.decryptionAgain": "Volver a descifrar",
    "message.common.code.cancelDecryptionAgain": "Ha cancelado la redecriptación.",
    "message.common.code.inputCode": "Código de verificación",
    "message.common.code.sentCode": "Código de verificación enviado",
    "message.common.code.wrongFormat": "mensaje.común.código.incorrectoFormato",
    "message.common.code.cancelCode": "Ha cancelado la subida del código de verificación.",
    "message.common.code.wrongSend": "Error al enviar el código de verificación.",
    "message.common.code.wrongSubmit": "Error al subir el código de verificación.",
    "message.common.code.successSubmit": "Código de verificación subido con éxito.",
    "message.common.code.reloadTry": "Por favor, actualice la interfaz e inténtelo de nuevo.",
    "message.common.code.secondsLater": "Se puede   segundos después",
    "message.common.code.sendAgain": "Se obtiene nuevamente",
    "message.common.code.tipsContent1": "El sistema solicita descifrar",
    "message.common.code.tipsContent3": "datos, introduzca el código de verificación para completar la autorización.",
    "message.common.code.placeholder": "El código de verificación se enviará por televisión o por correo electrónico",
    "message.common.code.frequent": "La adquisición del código de verificación es demasiado frecuente, por favor, obténgalo de nuevo más tarde",
    "message.common.code.wrongCode": "Error de código de verificación",
    "message.common.code.wrongTooManyTimes": "El código de verificación ha sido introducido incorrectamente muchas veces. Por favor, obtenga el código de verificación de nuevo",
    "message.common.code.overdueCode": "El código de verificación no es válido. Por favor, obtenga el código de verificación de nuevo",
    "message.common.uploadFile": "Subir archivo",
    "message.common.uploadPicture": "Subir foto",
    "message.common.ok": "Confirmar",
    "message.common.cancel": "Cancelar",
    "message.common.submit": "Subir",
    "message.common.saveAsDraft": "Guardar como borrador",
    "message.common.tips": "Recordar",
    "message.common.warning": "Advertir",
    "message.common.start": "Empezar",
    "message.common.end": "Ternimar",
    "message.common.processNode": "Puntos de proceso",
    "message.common.subTask": "Subtarea",
    "message.common.pending": "Pendiente de hacer",
    "message.common.executeException": "Excepción de la ejecución",
    "message.common.lostEfficacy": "Ya no es válido",
    "message.common.preview": "Vista previa",
    "message.common.download": "Descargar",
    "message.common.yes": "Sí",
    "message.common.no": "No",
    "message.common.width": "Ancho",
    "message.common.height": "Altura",
    "message.common.add": "Agregar",
    "message.common.node": "Puntos",
    "message.common.select": "Seleccionar",
    "message.common.selected": "Seleccionado",
    "message.common.validate.required": "Obligatorio",
    "message.common.validate.validateFaild": "Verificación fallida",
    "message.common.validate.wrongFormat": "Error de formato",
    "message.common.validate.loadingData": "Cargando datos ..., por favor espere",
    "message.common.validate.noApproverSelected": "No se ha seleccionado ningún aprobador",
    "message.common.placeholder.select": "Por favor, seleccione",
    "message.common.placeholder.remark": "Por favor, introduzca un comentario",
    "message.common.placeholder.notRequired": "Opcional",
    "message.common.placeholder.keyTofilter": "Introduzca las palabras clave para filtrar",
    "message.common.requestTips.failed": "Operación fallida, por favor, inténtelo de nuevo",
    "message.common.requestTips.success": "Operación exitosa",
    "message.common.requestTips.submitSuccess": "Enviado con éxito",
    "message.common.requestTips.getDataFailed": "Hubo un error al obtener los datos, por favor inténtelo de nuevo",
    "message.flowApply.systemFlow": "Este proceso es un proceso del sistema y no puede ser iniciado manualmente",
    "message.flowApply.resultPreview": "Vista previa de los resultados",
    "message.flowApply.attachmentsNotVisible": "Los archivos adjuntos no son visibles",
    "message.flowApply.maxUpload": "Sólo se pueden cargar hasta {número} de archivos",
    "message.flowApply.selected": "Actualmente seleccionado",
    "message.flowApply.personnelSelection": "Selección de personal",
    "message.flowApply.member": "Empleado",
    "message.flowApply.officer": "Supervisor",
    "message.flowApply.reporter": "NIvel de informes",
    "message.flowApply.role": "Roles(funciones)",
    "message.flowApply.department": "Departamento",
    "message.flowApply.specialRole": "Roles especiales",
    "message.flowApply.memberColumn": "Empleado especificado por el campo del formulario",
    "message.flowApply.officerColumn": "Supervisor del empleado especificado en el campo del formulario",
    "message.flowApply.reporterColumn": "Nivel de informes del empleado especificado en el campo del formulario",
    "message.flowApply.columnPlaceholder": "El valor es el ID del empleado",
    "message.flowApply.memberFailed": "¡Fallo en la adquisición de datos del empleado!",
    "message.flowApply.officerFailed": "¡Fallo en la adquisición de datos del supervisor!",
    "message.flowApply.reporterFailed": "No se han podido obtener datos del nivel de informe.",
    "message.flowApply.roleFailed": "No se han podido obtener los datos de la función.",
    "message.flowApply.departmentFailed": "¡La adquisición de datos del departamento ha fallado!",
    "message.flowApply.specialRoleFailed": "No se han podido obtener los datos de los roles especiales.",
    "message.flowDetail.repeal": "Revocar",
    "message.flowDetail.withdrawl": "Revocado",
    "message.flowDetail.print": "Imprimir",
    "message.flowDetail.decode": "Descrifrar",
    "message.flowDetail.decoded": "Descifrado",
    "message.flowDetail.uploadFile": "Subir el adjunto",
    "message.flowDetail.uploadTips": "Sólo se puede transmitir un archivo adjunto, por favor, borre primero el archivo adjunto cargado",
    "message.flowDetail.pass": "Aprobar",
    "message.flowDetail.careOf": "Transmitir",
    "message.flowDetail.turnDown": "Rechazar",
    "message.flowDetail.collapse": "Retirar",
    "message.flowDetail.expand": "Desplegar",
    "message.flowDetail.transferee": "Destinatario de la transferencia",
    "message.flowDetail.sureTurnDown": "¿Está seguro de que quiere rechazar esta aprobación?",
    "message.flowDetail.surePass": "¿Estás seguro de que quiere pasar esta aprobación?",
    "message.flowDetail.sureRepeal": "¿Está seguro de que quiere revocar esta aprobación?",
    "message.flowDetail.sureDispose": "¿Está seguro de que quiere procesar esta tarea?",
    "message.flowDetail.approveRecord": "Registros de aprobación",
    "message.flowDetail.rejected": "Rechazado",
    "message.flowDetail.passed": "Aprobado",
    "message.flowDetail.pending": "Pendiente de aprobación",
    "message.flowDetail.turnedDown": "Rechazado",
    "message.flowDetail.draft": "Borrador",
    "message.flowDetail.requireTransferee": "Por favor, seleccione el destinatario de la transferencia",
    "message.flowDetail.complete": "Completado",
  "message.flowDetail.Redo": "Rehacer",
    "date_picker_Dec": "Diciembre",
    "date_picker_Nov": "Noviembre",
    "date_picker_Oct": "Octubre",
    "date_picker_Sept": "Septiembre",
    "date_picker_Aug": "Agosto",
    "date_picker_Jul": "Julio",
    "date_picker_Jun": "Junio",
    "date_picker_May": "Mayo",
    "date_picker_Apr": "abril",
    "date_picker_Mar": "marzo",
    "date_picker_Feb": "febrero",
    "date_picker_Jan": "Enero",
    "date_picker_Saturday": "Seis",
    "date_picker_Friday": "Cinco",
    "date_picker_Thursday": "Cuatro",
    "date_picker_Wednesday": "Tres",
    "date_picker_Tuesday": "Dos",
    "date_picker_Monday": "Uno",
    "date_picker_Sunday": "Día",
    "Cancle": "Cancelar",
    "确认": "Confirmar",
    "操作成功": "Operación exitosa",
    "保存": "Guardar",
    "姓名": "Nombre completo",
    "职级": "Categoría",
    "员工类型": "Tipo de Empleado",
    "有": "Sí",
    "无": "Nada",
    "类型": "Tipo",
    "年终奖": "Bonificación de fin de año",
    "入职时间": "Fecha de incorporación",
    "备注": "Notas",
    "实习": "pasantía",
    "劳务派遣": "Envío de mano de obra",
    "劳务外包": "Subcontratación de servicios laborales",
    "试用": "Prueba",
    "正式": "Formal",
    "提交": "Subir",
    "编辑": "Editar",
    "创建": "Crear",
    "审批中": "En aprobación",
    "验证码发送失败": "Se ha producido un error al enviar el código de verificación",
    "是": "Sí",
    "否": "No",
    "下载模板": "Descargar plantilla",
    "未知错误": "Error desconocido",
    "调薪晋升": "Ajustes salariales y promociones",
    "导出": "Exportar",
    "职位": "Puesto",
    "绩效": "Rendimiento",
    "时间": "Tiempo",
    "Failed to send verification code": "Se ha producido un error al enviar el código de verificación",
    "Yes": "Sí",
    "No": "No",
    "Download template": "Descargar plantilla",
    "System exception": "Error del sistema",
    "It has been copied and can be pasted.": "Se ha copiado y se puede pegar.",
    "Hint": "Recordatorio",
    "Operate successfully": "Operación exitosa",
    "Unknown error": "Error desconocido",
    "Save": "Guardar",
    "Name": "Nombre",
    "Type": "Tipo",
    "Remark": "Notas",
    "Submit": "Subir",
    "Export": "Exportar",
    "Position": "Puesto",
    "Time": "Tiempo",
    "Edit": "Editar",
    "Create": "Crear",
    "处理中": "En proceso",
    "时间变更": "Cambio de tiempo",
    "销除申请": "Solicitud de revocación",
    "天": "Día",
    "元": "Yuan",
    "提交变更申请": "Presentar una solicitud de cambio",
    "数据加载中": "Cargando",
    "变更原因不能为空": "El motivo del cambio no puede estar vacío",
    "出差未发生变更，无需审批": "No hay cambios en el viaje de negocios, no se requiere aprobación",
    "删除": "Eliminar",
    "变更原因": "Motivo del cambio",
    "Rank": "Categoría",
    "Employee Type": "Tipo de Empleado",
    "None": "Nada",
    "Annual Bonus": "Bonificación de fin de año",
    "Start Date": "Fecha de incorporación",
    "Intern": "pasantía",
    "Contractor": "Envío de mano de obra",
    "Outsourcing": "Subcontratación de servicios laborales",
    "Probationary": "Prueba",
    "Regular": "Formal",
    "Salary and Promotion": "Ajustes salariales y promociones",
    "Performance": "Rendimiento",
    "请填写备注": "Por favor, rellene los comentarios",
    "Remarks cannot be empty": "Los comentarios no pueden estar vacíos",
    "Yuan": "Yuan",
    "Loading": "Cargando",
    "Delete": "Eliminar",
    "Time Change": "Cambio de tiempo",
    "Revoke Application": "Solicitud de revocación",
    "Day(s)": "Día",
    "Reason for Change": "Motivo del cambio",
    "Submit a Change Request": "Presentar una solicitud de cambio",
    "Change reason cannot be empty": "El motivo del cambio no puede estar vacío",
    "No change in business trip, no approval required": "No hay cambios en el viaje de negocios, no se requiere aprobación",
    "失败": "Fracaso",
    "Failure": "Fracaso",
    "Approving": "En aprobación",
    "Are you sure you want to delete this authorization?": "¿Está seguro de eliminar esta autorización?",
    "Are you sure you want to delete this application? irrecoverable": "¿Estás seguro de que quieres eliminar esta aplicación? No se puede restaurar",
    "Are you sure you want to delete this group?": "¿Seguro que quieres eliminar este grupo?",
    "Delete Group": "Borrar el grupo",
    "Are you sure you want to deactivate this process?": "¿Está seguro de que quiere desactivar este proceso?",
    "Are you sure you want to enable this process?": "¿Seguro que va a iniciar este proceso?",
    "Are you sure you want to remove this component?": "¿Estás seguro de que quieres eliminar el componente?",
    "面试官": "Entrevistador",
    "手机号格式不正确": "El formato del número telefónico es incorrecto",
    "人": "Persona",
    "请选择": "Seleccionar",
    "HRBP": "HRBP",
    "通过": "Aprobado",
    "创建时间": "Hora de creación",
    "请正确填写": "Por favor, rellene correctamente",
    "新建分组": "Crear un nuevo grupo",
    "重命名": "Cambiar el nombre de",
    "排序": "Ordenar por",
    "New Group": "Crear un nuevo grupo",
    "Rename": "Cambiar el nombre de",
    "Sort": "Ordenar por",
    "Interviewer": "Entrevistador",
    "Phone number format is incorrect": "El formato del número telefónico es incorrecto",
    "People": "Persona",
    "Please choose": "Seleccionar",
    "Please fill in the remarks": "Por favor, rellene los comentarios",
    "Approve": "Aprobado",
    "备注不能为空": "Los comentarios no pueden estar vacíos",
    "Please fill in correctly": "Por favor, rellene correctamente",
    "Upload": "Cargar",
    "Sun.": "Domingo",
    "Mon.": "Lunes",
    "Wed.": "Miércoles",
    "Fri.": "Viernes",
    "Sat.": "Sábado",
    "Jan.": "Enero",
    "Feb.": "Febrero",
    "Mar.": "Marzo",
    "Apr.": "Abril",
    "May.": "Mayo",
    "Jun.": "Junio",
    "Jul.": "Julio",
    "Aug.": "Agosto",
    "Sep.": "Septiembre",
    "Oct.": "Octubre",
    "Nov.": "Noviembre",
    "Dec.": "Diciembre",
    "Entry date": "Fecha de incorporación",
    "Details": "Detalles"
}
