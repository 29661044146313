<template>
  <div class="widget-form-container">
    <el-form
      :label-position="data.config.labelPosition"
      :label-width="data.config.labelWidth + 'px'"
    >
      <draggable
        v-model="data.list"
        class="widget-form-list out-widget-form-list"
        :options="{group:'people', ghostClass: 'ghost'}"
        @end="handleMoveEnd"
        @add="handleWidgetAdd"
      >
        <template v-for="(element, index) in data.list">
          <template v-if="element.type === 'subform' && element.options.blockDisplay === true">
            <div
              v-if="element && element.key"
              :key="element.key"
              :class="['widget-grid-container', 'data-grid',
                       {'immutable-item': element.immutable},
                       {'compound-start': element.immutable && (!data.list[index - 1] || (data.list[index - 1] && data.list[index - 1].compoundId !== element.compoundId))},
                       {'compound-end': element.immutable && (!data.list[index + 1] || (data.list[index + 1] && data.list[index + 1].compoundId !== element.compoundId))}
              ]"
              style="position: relative;"
            >
              <el-row
                v-if="element.type === 'subform'"
                :class="{active: selectWidget.key == element.key}"
                class="widget-grid"
                style="padding-bottom: 0;"
                @click.native="handleSelectWidget(index)"
              >
                <label
                  :style="{
                    'margin-left': (element.options.gutter ? element.options.gutter / 2 : 0) + 'px',
                    'padding-left': '5px solid transparent'
                  }"
                  class="el-form-item__label"
                >{{ element.name }}</label>
              </el-row>
              <el-row
                class="widget-grid"
                type="flex"
                :class="{active: selectWidget.key == element.key}"
                :gutter="element.options.gutter ? element.options.gutter : 0"
                :justify="element.options.justify"
                :align="element.options.align"
                style="flex-wrap: wrap"
                @click.native="handleSelectWidget(index)"
              >
                <el-col
                  v-for="(col, colIndex) in element.columns"
                  :key="colIndex"
                  :span="col.span ? col.span : 0"
                  :style="{'flex': col.span ? col.span : 0}"
                >
                  <div style="border: 1px dashed #999;margin-bottom:4px">
                    <draggable
                      v-if="!element.immutable"
                      v-model="col.list"
                      class="widget-form-list"
                      style="padding-bottom: 50px;"
                      filter="widget-grid-container"
                      :options="{group:'people', ghostClass: 'ghost'}"
                      @end="handleMoveEnd($event, element.columns[colIndex].list)"
                      @add="handleWidgetColAdd($event, element, colIndex)"
                    >
                      <widget-form-item
                        v-for="(el, i) in col.list"
                        :key="el.key"
                        :element="el"
                        :select.sync="selectWidget"
                        :index="i"
                        :data="col"
                        :can-delete-field="canDeleteField"
                        :validate-compound="validateCompound"
                        :show-compound-tips="showCompoundTips"
                        :start-edit-id="startEditId"
                        :get-compound-by-key-or-id="getCompoundByKeyOrId"
                        class="from-inner"
                      />
                    </draggable>
                    <template v-else>
                      <widget-form-item
                        v-for="(el, i) in col.list"
                        :key="el.key"
                        :element="el"
                        :select.sync="selectWidget"
                        :index="i"
                        :data="col"
                        :can-delete-field="canDeleteField"
                        :validate-compound="validateCompound"
                        :show-compound-tips="showCompoundTips"
                        :start-edit-id="startEditId"
                        :get-compound-by-key-or-id="getCompoundByKeyOrId"
                      />
                    </template>
                  </div>
                </el-col>
              </el-row>
              <el-button
                v-if="(!element.immutable && selectWidget.key == element.key) || (element.immutable && (!data.list[index + 1] || (data.list[index + 1] && data.list[index + 1].compoundId !== element.compoundId)))"
                title="删除"
                style="bottom: -20px;"
                class="widget-action-delete"
                circle
                plain
                icon="el-icon-delete"
                type="danger"
                @click.stop="handleWidgetDelete(index)"
              >
              </el-button>

              <el-button
                v-if="element.immutable && (!data.list[index + 1] || (data.list[index + 1] && data.list[index + 1].compoundId !== element.compoundId))"
                title="套件说明"
                style="bottom: -20px;"
                class="widget-action-clone"
                circle
                plain
                icon="el-icon-info"
                type="primary"
                @click="() => {
                  const compound = getCompoundByKeyOrId(data.list[index])
                  showCompoundTips(compound)
                }"
              />

            </div>
          </template>

          <template v-else-if="element.type == 'grid' || element.type === 'subform' || (element.type === 'subform' && element.options.blockDisplay === false)">
            <div
              v-if="element && element.key"
              :key="element.key"
              :class="['widget-grid-container', 'data-grid',
                       {'immutable-item': element.immutable},
                       {'compound-start': element.immutable && (!data.list[index - 1] || (data.list[index - 1] && data.list[index - 1].compoundId !== element.compoundId))},
                       {'compound-end': element.immutable && (!data.list[index + 1] || (data.list[index + 1] && data.list[index + 1].compoundId !== element.compoundId))}
              ]"
              style="position: relative;"
            >
              <el-row
                v-if="element.type === 'subform'"
                :class="{active: selectWidget.key == element.key}"
                class="widget-grid"
                style="padding-bottom: 0"
                @click.native="handleSelectWidget(index)"
              >
                <label
                  :style="{
                    'margin-left': (element.options.gutter ? element.options.gutter / 2 : 0) + 'px',
                    'padding-left': '5px solid transparent'
                  }"
                  class="el-form-item__label"
                >{{ element.name }}</label>
              </el-row>
              <el-row
                class="widget-grid"
                type="flex"
                :class="{active: selectWidget.key == element.key}"
                :gutter="element.options.gutter ? element.options.gutter : 0"
                :justify="element.options.justify"
                :align="element.options.align"
                @click.native="handleSelectWidget(index)"
              >
                <el-col
                  v-for="(col, colIndex) in element.columns"
                  :key="colIndex"
                  :span="col.span ? col.span : 0"
                  :style="{'flex': col.span ? col.span : 0}"
                >
                  <div style="border: 1px dashed #999;">
                    <draggable
                      v-if="!element.immutable"
                      v-model="col.list"
                      class="widget-form-list"
                      style="padding-bottom: 50px;"
                      filter="widget-grid-container"
                      :options="{group:'people', ghostClass: 'ghost'}"
                      @end="handleMoveEnd($event, element.columns[colIndex].list)"
                      @add="handleWidgetColAdd($event, element, colIndex)"
                    >
                      <widget-form-item
                        v-for="(el, i) in col.list"
                        :key="el.key"
                        :element="el"
                        :select.sync="selectWidget"
                        :index="i"
                        :data="col"
                        :can-delete-field="canDeleteField"
                        :validate-compound="validateCompound"
                        :show-compound-tips="showCompoundTips"
                        :start-edit-id="startEditId"
                        :get-compound-by-key-or-id="getCompoundByKeyOrId"
                        class="from-inner"
                      />
                    </draggable>
                    <template v-else>
                      <widget-form-item
                        v-for="(el, i) in col.list"
                        :key="el.key"
                        :element="el"
                        :select.sync="selectWidget"
                        :index="i"
                        :data="col"
                        :can-delete-field="canDeleteField"
                        :validate-compound="validateCompound"
                        :show-compound-tips="showCompoundTips"
                        :start-edit-id="startEditId"
                        :get-compound-by-key-or-id="getCompoundByKeyOrId"
                      />
                    </template>
                  </div>
                </el-col>
              </el-row>
              <el-button
                v-if="(!element.immutable && selectWidget.key == element.key) || (element.immutable && (!data.list[index + 1] || (data.list[index + 1] && data.list[index + 1].compoundId !== element.compoundId)))"
                title="删除"
                style="bottom: -20px;"
                class="widget-action-delete"
                circle
                plain
                icon="el-icon-delete"
                type="danger"
                @click.stop="handleWidgetDelete(index)"
              >
              </el-button>

              <el-button
                v-if="element.immutable && (!data.list[index + 1] || (data.list[index + 1] && data.list[index + 1].compoundId !== element.compoundId))"
                title="套件说明"
                style="bottom: -20px;"
                class="widget-action-clone"
                circle
                plain
                icon="el-icon-info"
                type="primary"
                @click="() => {
                  const compound = getCompoundByKeyOrId(data.list[index])
                  showCompoundTips(compound)
                }"
              />
            </div>
          </template>

          <template v-else>
            <widget-form-item
              v-if="element && element.key"
              :key="element.key"
              :element="element"
              :select.sync="selectWidget"
              :index="index"
              :data="data"
              :can-delete-field="canDeleteField"
              :validate-compound="validateCompound"
              :show-compound-tips="showCompoundTips"
              :start-edit-id="startEditId"
              :get-compound-by-key-or-id="getCompoundByKeyOrId"
              :is-compound-end="element.immutable && (!data.list[index + 1] || (data.list[index + 1] && data.list[index + 1].compoundId !== element.compoundId))"
              :class="[{'immutable-item': element.immutable},
                       {'compound-start': element.immutable && (!data.list[index - 1] || (data.list[index - 1] && data.list[index - 1].compoundId !== element.compoundId))},
                       {'compound-end': element.immutable && (!data.list[index + 1] || (data.list[index + 1] && data.list[index + 1].compoundId !== element.compoundId))}]"
            />
          </template>
        </template>
      </draggable>
    </el-form>
  </div>
</template>

<script>
import Draggable from 'vuedraggable'
import WidgetFormItem from './WidgetFormItem'
import { clone } from 'ramda'
import { getLinearFormList } from '../util/index'
// import _ from 'lodash'

export default {
  name: 'WidgetForm', // 表单设计时：整个表单设计的展示
  components: {
    Draggable,
    WidgetFormItem
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    select: {
      type: Object,
      default: () => {}
    },
    canDeleteField: { // 判断组件是否能够删除
      type: Function,
      default: null
    },
    startEditId: { // 开始编辑套件的id：打开弹窗
      type: Function,
      default: null
    },
    getCompoundByKeyOrId: { // 根据组件获取所在套件信息
      type: Function,
      default: null
    }
  },
  data () {
    return {
      selectWidget: this.select || {}
    }
  },
  watch: {
    select (val) {
      this.selectWidget = val || {}
    },
    selectWidget: {
      handler (val) {
        if (!val.options) return
        if (!val.options.verify) {
          this.$set(val.options, 'verify', {
            message: '',
            expression: '',
            formula: [] // 公式校验
          })
        }
        if (!val.options.show) {
          this.$set(val.options, 'show', {
            type: 1, // 1手动设置，2公式计算
            value: true,
            expression: '',
            formula: []
          })
        }
        if (!val.options.cardShow) {
          this.$set(val.options, 'cardShow', {
            type: 1,
            value: false,
          })
        }

        this.$emit('update:select', val)
      },
      deep: true
    }
  },
  created () {
    this.data.compound = this.data.compound || []
    if (this.data && this.data.list && this.data.list.length > 0) {
      this.clearData(getLinearFormList(this.data.list))
    }
  },
  methods: {
    // 展示套件说明
    showCompoundTips (compound) {
      const name = compound ? compound.name : '套件名'
      const key = compound ? compound.key || '' : '套件Key'
      const id = compound ? (compound.originId + '') || '' : '套件id'
      const str = compound ? compound.data.config.information || '' : '套件列表未找到与之匹配的套件，请检查'
      const h = this.$createElement;
      this.$alert(str, name, {
        confirmButtonText: '确定',
        customClass: 'compound-tips',
        message: h('div', null, [
          h('p', null, '套件名：' + name),
          h('p', null, '套件Key：' + key),
          h('p', null, '套件id：' + id),
          h('p', null, '套件描述：' + str)
        ])
      });
    },
    // 清除老旧的无用设计数据
    clearData (list) {
      for (let i = 0; i < list.length; i++) {
        // 清除复杂数据结构时的默认值(不可更改的套件的子组件则不需要清空)
        if (!(list[i].compoundId !== undefined && list[i].immutable) && list[i].options && typeof list[i].options.defaultValue === 'object' && list[i].options.defaultValue !== null) {
          list[i].options.defaultValue = []
        }
        // 清除组件设计时遗留的remoteOptions
        if (list[i].options) {
          list[i].options.remoteOptions = []
        }
      }
    },
    handleMoveEnd (event, list) {
      let { newIndex, oldIndex } = event
      console.log('index', newIndex, oldIndex)

      // 当移动到外层列表时：若是移到了套件之间，需要还原
      if (event.to.className.indexOf('out-widget-form-list') > -1 && this.data.list[newIndex].immutable || (this.data.list[newIndex + 1] && this.data.list[newIndex + 1].immutable && this.data.list[newIndex - 1] && (this.data.list[newIndex + 1].compoundId === this.data.list[newIndex - 1].compoundId))) {
        if (event.item.className.indexOf('from-inner') === -1) {
          // 当被移动的组件来自外层列表时，只需要位置调回去
          const item = this.data.list[newIndex]
          this.data.list.splice(newIndex, 1)
          this.data.list.splice(oldIndex, 0, item)
          return false
        } else {
          // 当被移动的组件来自内层(栅格、子表单)列表时，外层不添加，内层还原
          const item = this.data.list[newIndex]
          list.splice(oldIndex, 0, item)
          this.data.list.splice(newIndex, 1)
        }
      }

      if (newIndex === oldIndex) {
        return false
      }
    },
    handleSelectWidget (index) {
      console.log(index, '#####')
      this.selectWidget = this.data.list[index]
    },
    // 根据表单控件数据初始化控件
    initComponent (component) {
      const key = Date.parse(new Date()) + '_' + Math.ceil(Math.random() * 99999)
      console.log(key)
      // 为拖拽到容器的元素添加唯一 key
      component = {
        ...component,
        options: {
          ...component.options
        },
        key: component.key || key,
        // 绑定键值(平台的套件，除栅格外，都没默认model)
        // model: component.model || (component.type !== 'grid' && this.$route.query.mode === 'compoundWidget' && this.$route.query.platform ? '' : (component.type + '_' + key)),
        model: component.model || (component.type !== 'grid' ? '' : (component.type + '_' + key)),
        rules: []
      }
      component.options = clone(component.options)
      if (component.type === 'radio' || component.type === 'checkbox') {
        component = {
          ...component,
          options: {
            ...component.options,
            options: component.options.options.map(item => ({
              ...item
            }))
          }
        }
      }
      if (component.type === 'grid' || component.type === 'subform') {
        component = {
          ...component,
          columns: component.columns.map(item => ({ ...item }))
        }
      }
      return component
      // debugger

      // console.log('------------------')
      // console.log(this.data.list)
      // console.log(newIndex)
      // console.log('------------------')

      // 为拖拽到容器的元素添加唯一 key
      // this.$set(this.data.list, newIndex, {
      //   ...this.data.list[newIndex],
      //   options: {
      //     ...this.data.list[newIndex].options,
      //     remoteFunc: 'func_' + key
      //   },
      //   key,
      //   // 绑定键值
      //   model: this.data.list[newIndex].type + '_' + key,
      //   rules: []
      // })

      // if (this.data.list[newIndex].type === 'radio' || this.data.list[newIndex].type === 'checkbox') {
      //   this.$set(this.data.list, newIndex, {
      //     ...this.data.list[newIndex],
      //     options: {
      //       ...this.data.list[newIndex].options,
      //       options: this.data.list[newIndex].options.options.map(item => ({
      //         ...item
      //       }))
      //     }
      //   })
      // }

      // if (this.data.list[newIndex].type === 'grid' || this.data.list[newIndex].type === 'subform') {
      //   this.$set(this.data.list, newIndex, {
      //     ...this.data.list[newIndex],
      //     columns: this.data.list[newIndex].columns.map(item => ({ ...item }))
      //   })
      // }
    },
    handleWidgetAdd (evt) {
      console.log('add', evt)
      console.log('end', evt)
      const newIndex = evt.newIndex
      if (evt.item.className.includes('form-edit-widget-label') && this.data.list[newIndex + 1] && this.data.list[newIndex + 1].immutable && this.data.list[newIndex - 1] && (this.data.list[newIndex + 1].compoundId === this.data.list[newIndex - 1].compoundId)) {
        this.data.list.splice(newIndex, 1)
        return false
      }
      // compound components：套件
      if (this.data.list[newIndex].id !== undefined) {
        const nowArr = this.data.compound.filter(item => item.compoundKey === this.data.list[newIndex].key || item.compoundId === this.data.list[newIndex].id)
        if (!(nowArr && nowArr.length > 0)) {
          this.data.compound.push({
            compoundId: this.data.list[newIndex].id,
            compoundKey: this.data.list[newIndex].key,
            immutable: this.data.list[newIndex].data.config.immutable,
            version: this.data.list[newIndex].version
          })
          const subList = this.data.list[newIndex].data ? (this.data.list[newIndex].data.list || []) : []
          subList.forEach(item => {
            if (item.type === 'subform' || item.type === 'grid') {
              item.columns && item.columns.forEach(col => {
                col.list && col.list.forEach(component => {
                  component.compoundId = this.data.list[newIndex].id
                  component.compoundKey = this.data.list[newIndex].key
                  component.immutable = this.data.list[newIndex].data.config.immutable
                })
              })
            }
            item.compoundId = this.data.list[newIndex].id
            item.immutable = this.data.list[newIndex].data.config.immutable
          })
          this.data.list.splice(newIndex, 1, ...subList)
          // 不需要初始化
          // for (let i = newIndex; i < newIndex + subList.length; i++) {
          //   this.$set(this.data.list, i, { ...this.initComponent(this.data.list[i]) })
          // }
          this.selectWidget = this.data.list[newIndex]
        } else {
          this.data.list.splice(newIndex, 1)
          this.$message.warning({ message: '套件只能添加一次！', duration: 10000, showClose: true })
        }
      } else {
        // 基础组件
        // this.initComponent(this.data.list[newIndex])
        this.$set(this.data.list, newIndex, { ...this.initComponent(this.data.list[newIndex]) })
        this.selectWidget = this.data.list[newIndex]
      }
    },
    handleWidgetColAdd ($event, row, colIndex) {
      console.log('coladd', $event, row, colIndex)
      const newIndex = $event.newIndex
      const oldIndex = $event.oldIndex
      const item = $event.item

      // 防止布局元素和套件的嵌套拖拽
      // if (item.className.indexOf('data-grid') >= 0) {
      if (((row.columns[colIndex].list[newIndex].key || row.columns[colIndex].list[newIndex].id !== undefined) && !Object.keys(row.columns[colIndex].list[newIndex]).includes('model')) || row.columns[colIndex].list[newIndex].type === 'grid' || row.columns[colIndex].list[newIndex].type === 'subform') {
        // 如果是列表中拖拽的元素需要还原到原来位置
        item.tagName === 'DIV' && this.data.list.splice(oldIndex, 0, row.columns[colIndex].list[newIndex])

        row.columns[colIndex].list.splice(newIndex, 1)

        return false
      }

      // compound components：套件
      // if (row.columns[colIndex].list[newIndex].id !== undefined) {
      //   if (!this.data.compound.includes(row.columns[colIndex].list[newIndex].id)) {
      //     this.data.compound.push(row.columns[colIndex].list[newIndex].id)
      //     let subList = row.columns[colIndex].list[newIndex].data ? (row.columns[colIndex].list[newIndex].data.list || []) : []
      //     subList = subList.filter(item => item.type !== 'grid' && item.type !== 'subform')
      //     row.columns[colIndex].list.splice(newIndex, 1, ...subList)
      //     // 不需要初始化了
      //     // for (let i = newIndex; i < newIndex + subList.length; i++) {
      //     //   this.$set(row.columns[colIndex].list, i, { ...this.initComponent(row.columns[colIndex].list[i]) })
      //     // }
      //   } else {
      //     row.columns[colIndex].list.splice(newIndex, 1)
      //     this.$message.warning({ message: '套件只能添加一次！', duration: 10000, showClose: true })
      //   }
      // } else {
        // 基础组件
        // this.initComponent(row.columns[colIndex].list[newIndex])
      this.$set(row.columns[colIndex].list, newIndex, { ...this.initComponent(row.columns[colIndex].list[newIndex]) })
      // }
      // this.$set(row.columns[colIndex], 'list', row.columns[colIndex].list)
      this.selectWidget = row.columns[colIndex].list[newIndex] || this.selectWidget

      // const key = Date.parse(new Date()) + '_' + Math.ceil(Math.random() * 99999)
      // this.$set(row.columns[colIndex].list, newIndex, {
      //   ...row.columns[colIndex].list[newIndex],
      //   options: {
      //     ...row.columns[colIndex].list[newIndex].options,
      //     remoteFunc: 'func_' + key
      //   },
      //   key,
      //   // 绑定键值
      //   model: row.columns[colIndex].list[newIndex].type + '_' + key,
      //   rules: []
      // })

      // if (row.columns[colIndex].list[newIndex].type === 'radio' || row.columns[colIndex].list[newIndex].type === 'checkbox') {
      //   this.$set(row.columns[colIndex].list, newIndex, {
      //     ...row.columns[colIndex].list[newIndex],
      //     options: {
      //       ...row.columns[colIndex].list[newIndex].options,
      //       options: row.columns[colIndex].list[newIndex].options.options.map(item => ({
      //         ...item
      //       }))
      //     }
      //   })
      // }

      // this.selectWidget = row.columns[colIndex].list[newIndex]
    },
    handleWidgetDelete (index) {
      // 验证是否能删除
      const isCompoundEnd = this.data.list[index].immutable && (!this.data.list[index + 1] || (this.data.list[index + 1] && this.data.list[index + 1].compoundId !== this.data.list[index].compoundId))
      if (this.canDeleteField) {
        if (isCompoundEnd) {
          // 组件是不可编辑的套件的最后一个时，代表删除整个套件：需要验证套件的每个子孙组件是否能被删除
          const compoundId = this.data.list[index].compoundId
          for (let i = 0; i < this.data.list.length; i++) {
            if (this.data.list[i].compoundId === compoundId) {
              let deleteValidate = this.canDeleteField(this.data.list[i])
              if (deleteValidate) {
                this.$message.error({ message: deleteValidate, duration: 10000, showClose: true })
                return
              }
            }
          }
        } else {
          let deleteValidate = this.canDeleteField(this.data.list[index])
          if (deleteValidate) {
            this.$message.error({ message: deleteValidate, duration: 10000, showClose: true })
            return
          }
        }
      }
      // 处理active的组件
      const compoundArr = this.data.list.filter(item => item.compoundId === this.data.list[index].compoundId)
      const newIndex = index - compoundArr.length
      if (this.data.list.length - 1 === index) {
        if (isCompoundEnd) {
          if (newIndex < 0) {
            this.selectWidget = {}
          } else {
            this.selectWidget = this.data.list[newIndex]
          }
        } else {
          if (index === 0) {
            this.selectWidget = {}
          } else {
            this.selectWidget = this.data.list[index - 1]
          }
        }
      } else {
        this.selectWidget = this.data.list[index + 1]
      }
      // 删除组件
      this.$nextTick(() => {
        if (isCompoundEnd) {
          this.data.compound.forEach((item, i) => {
            if (item.compoundId === this.data.list[index].compoundId) {
              this.data.compound.splice(i, 1)
            }
          })
          this.data.list.splice(newIndex + 1, compoundArr.length)
        } else {
          const compoundId = this.data.list[index].compoundId
          this.data.list.splice(index, 1)
          this.validateCompound(compoundId)
        }
      })
    },
    // 可编辑的套件，删除其内容时，检测表单是否还有该套件的内容存在，没有则清楚表单的compound里面的该套件，以便可再次添加
    validateCompound (compoundId) {
      let shouldClear = true
      for (let i = 0; i < this.data.list.length; i++) {
        if (this.data.list[i].compoundId === compoundId) {
          shouldClear = false
        }
        if (this.data.list[i].columns && (this.data.list[i] === 'grid' || this.data.list[i] === 'subform')) {
          for (let j = 0; j < this.data.list[i].columns.length; j++) {
            if (this.data.list[i].columns[j] && this.data.list[i].columns[j].list) {
              for (let k = 0; k < this.data.list[i].columns[j].list.length; k++) {
                if (this.data.list[i].columns[j].list[k].compoundId === compoundId) {
                  shouldClear = false
                  break
                }
              }
              if (!shouldClear) {
                break
              }
            }
          }
        }
        if (!shouldClear) {
          break
        }
      }
      if (shouldClear && this.data.compound) {
        for (let i = 0; i < this.data.compound.length; i++) {
          if (this.data.compound[i].compoundId === compoundId) {
            this.data.compound.splice(i, 1)
            break
          }
        }
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.immutable-item
  margin-left 10px!important
  margin-right 10px!important
  border-left 2px solid #409eff!important
  border-right 2px solid #409eff!important
.immutable-item.active
  border-left 5px solid #409eff!important
.compound-start
  margin-top 10px!important
  border-top 2px solid #409eff!important
.compound-end
  margin-bottom 20px!important
  border-bottom 2px solid #409eff!important
.widget-form-list
  overflow-x hidden
</style>
<style lang="stylus">
body
  .compound-tips
    width 50%
.widget-form-list
  .el-button
    svg
      margin-bottom 0
</style>
