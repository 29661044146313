<template>
  <el-form-item
    :prop="(subFormKey !== undefined && models[widget.model]) ? `${widget.model}.item${subFormKey}` : widget.model"
    :rules="rules[widget.model]"
    :class="[
      {'el-form-item-instruction': widget.options.isLabel},
      {'number-form-item': (widget.options.dataType == 'number' || widget.options.dataType == 'integer' || widget.options.dataType == 'float') && !operations.readonlys[widget.model] && !operations.disableds[widget.model]}]"
    :label="widget.name"
  >
    <template v-if="widget.type == 'input'">
      <input-component
        v-model="dataModel"
        v-bind="$props"
        @input="inputValue"
        @blur="blur"
      />
    </template>
    <template v-if="widget.type == 'textarea'">
      <el-input
        v-model="dataModel"
        type="textarea"
        :autofocus="isFirst"
        :rows="5"
        :placeholder="widget.options.placeholder"
        :disabled="operations.disableds[widget.model]"
        :readonly="operations.readonlys[widget.model]"
        :style="{width: widget.options.width}"
        :class="[{'read-only': operations.readonlys[widget.model]}]"
        @input="inputValue"
      />
    </template>
    <template v-if="widget.type == 'instruction'">
      <div>
        <p
          v-for="(str, strIndex) in (dataModel || '').split('\n')"
          :key="strIndex"
          class="instruction-content"
        >
          {{ str }}
        </p>
      </div>
    </template>
    <template v-if="widget.type == 'number'">
      <el-input-number
        v-model="dataModel"
        :autofocus="isFirst"
        :style="{width: widget.options.width}"
        :step="widget.options.step"
        :disabled="operations.disableds[widget.model]"
        controls-position="right"
        @change="inputValue"
      />
    </template>
    <template v-if="widget.type == 'cascader'">
      <el-cascader
        ref="cascader"
        v-model="dataModel"
        :options="options"
        :separator="widget.options.separator || ''"
        :props="{
          checkStrictly: widget.options.checkStrictly,
          multiple: !widget.options.singleElection,
          value: widget.options.props.value || 'value',
          label: widget.options.props.label || 'label',
          disabled: widget.options.props.disabled || 'disabled'
        }"
        :placeholder="widget.options.placeholder"
        :disabled="operations.disableds[widget.model]"
        :style="{width: widget.options.width}"
        filterable
        clearable
        @change="inputValue"
      />
    </template>
    <div
      v-if="widget.type == 'tree'"
      :style="{width: widget.options.width}"
    >
      <tree-component
        ref="tree"
        v-model="dataModel"
        :visible="innerVisible"
        :widget="widget"
        :models="models"
        :form-extends="formExtends"
        :data-source="options"
        :disabled="operations.disableds[widget.model]"
        :sub-form-key="subFormKey"
        append-to-body
        @input="inputValueAndValidate"
      />
    </div>
    <template v-if="widget.type == 'time'">
      <el-time-picker
        v-model="dataModel"
        :is-range="widget.options.isRange"
        :placeholder="widget.options.placeholder"
        :start-placeholder="widget.options.startPlaceholder"
        :end-placeholder="widget.options.endPlaceholder"
        :readonly="operations.readonlys[widget.model]"
        :disabled="operations.disableds[widget.model]"
        :editable="widget.options.editable"
        :clearable="widget.options.clearable"
        :arrow-control="widget.options.arrowControl"
        :value-format="widget.options.format"
        :style="{width: widget.options.width}"
        :class="[{'read-only': operations.readonlys[widget.model]}]"
        @input="inputValue"
      />
    </template>
    <template v-if="widget.type == 'date'">
      <el-date-picker
        v-model="dataModel"
        :type="getTypeByFormat(widget.options.format)"
        :placeholder="widget.options.placeholder"
        :start-placeholder="widget.options.startPlaceholder"
        :end-placeholder="widget.options.endPlaceholder"
        :readonly="operations.readonlys[widget.model]"
        :disabled="operations.disableds[widget.model]"
        :editable="widget.options.editable"
        :clearable="widget.options.clearable"
        :value-format="widget.options.timestamp ? 'timestamp' : widget.options.format"
        :format="widget.options.format"
        :style="{width: widget.options.width}"
        :class="[{'read-only': operations.readonlys[widget.model]}]"
        :picker-options="{disabledDate}"
        @input="inputValue"
      />
    </template>
    <!-- radio -->
    <template v-if="widget.type == 'radio'">
      <el-radio-group
        v-model="dataModel"
        :style="{width: widget.options.width}"
        :disabled="operations.disableds[widget.model]"
        @change="inputValue"
      >
        <el-radio
          v-for="(item, index) in (options)"
          :key="index"
          :style="{display: widget.options.inline ? 'inline-block' : 'block'}"
          :label="(widget.options.remote ? item[widget.options.submitKey] : item.value)"
          :disabled="!!item[widget.options.disableKey]"
        >
          <template v-if="widget.options.remote">
            {{ (item[widget.options.showKey + '_i18n'] || item[widget.options.showKey]) }}
          </template>
          <template v-else>
            {{ widget.options.showLabel ? (item.label_i18n || item.label) : item.value }}
          </template>
        </el-radio>
      </el-radio-group>
    </template>
    <!-- checkbox -->
    <template v-if="widget.type == 'checkbox'">
      <el-checkbox-group
        v-model="dataModel"
        :style="{width: widget.options.width}"
        :disabled="operations.disableds[widget.model]"
        @change="inputValue"
      >
        <el-checkbox
          v-for="(item, index) in (options)"
          :key="index"
          :style="{display: widget.options.inline ? 'inline-block' : 'block'}"
          :label="(widget.options.remote ? item[widget.options.submitKey] : item.value)"
          :disabled="!!item[widget.options.disableKey]"
        >
          <template v-if="widget.options.remote">
            {{ (item[widget.options.showKey + '_i18n'] || item[widget.options.showKey]) }}
          </template>
          <template v-else>
            {{ widget.options.showLabel ? (item.label_i18n || item.label) : item.value }}
          </template>
        </el-checkbox>
      </el-checkbox-group>
    </template>
    <!-- select -->
    <template v-if="widget.type == 'select'">
      <el-select
        v-if="widget.options.multiple"
        v-model="dataModel"
        :filterable="widget.options.filterable"
        :disabled="operations.disableds[widget.model]"
        :clearable="widget.options.clearable"
        :placeholder="widget.options.placeholder"
        :style="{width: widget.options.width}"
        :remote="widget.options.remoteFilter"
        :remote-method="remoteFilter"
        multiple
        @change="inputValue"
        @blur="blur"
      >
        <el-option
          v-for="(item, index) in (options)"
          :key="index"
          :value="widget.options.remote ? item[widget.options.submitKey] : item.value"
          :label="widget.options.remote ? (item[widget.options.showKey + '_i18n'] || item[widget.options.showKey]) : (item.label_i18n || item.label || item.value)"
          :disabled="!!item[widget.options.disableKey]"
        />
      </el-select>
      <el-select
        v-else
        v-model="dataModel"
        :filterable="widget.options.filterable"
        :disabled="operations.disableds[widget.model]"
        :clearable="widget.options.clearable"
        :placeholder="widget.options.placeholder"
        :style="{width: widget.options.width}"
        :remote="widget.options.remoteFilter"
        :remote-method="remoteFilter"
        @change="inputValue"
        @blur="blur"
      >
        <el-option
          v-for="(item, index) in (options)"
          :key="index"
          :value="widget.options.remote ? item[widget.options.submitKey] : item.value"
          :label="widget.options.remote ? (item[widget.options.showKey + '_i18n'] || item[widget.options.showKey]) : (item.label_i18n || item.label || item.value)"
          :disabled="!!item[widget.options.disableKey]"
        />
      </el-select>
    </template>
    <template v-if="widget.type == 'switch'">
      <el-switch
        v-model="dataModel"
        :disabled="operations.disableds[widget.model]"
        @change="inputValueAndValidate"
      />
    </template>
    <template v-if="widget.type == 'imgupload'">
      <file-upload
        v-model="dataModel"
        :disabled="operations.disableds[widget.model]"
        :limit="widget.options.limit"
        :multiple="widget.options.multiple"
        :editable="true"
        :flow-id="flowId"
        :model-key="dataKey"
        type="img"
        @input="inputValueAndValidate"
      />
    </template>
    <template v-if="widget.type == 'fileupload'">
      <file-upload
        v-model="dataModel"
        :disabled="operations.disableds[widget.model]"
        :limit="widget.options.limit"
        :multiple="widget.options.multiple"
        :editable="true"
        :flow-id="flowId"
        :model-key="dataKey"
        @input="inputValueAndValidate"
      />
    </template>
    <div v-if="widget.type == 'link' || widget.type == 'customdata'">
      <el-button
        v-if="widget.options.showType !== 2"
        type="text"
        @click="startCustomData"
      >
        {{ widget.options.buttonText || widget.name }}
      </el-button>
      <a
        v-else
        target="_blank"
        style="text-decoration:none"
        class="el-button el-button--text el-button--middle"
        :href="dataModel"
      >{{ widget.options.buttonText || widget.name }}</a>
    </div>
    <div v-if="widget.type == 'download'">
      <div v-if="formExtends[widget.model + 'Extends'] && formExtends[widget.model + 'Extends'].permission && !formExtends[widget.model + 'Extends'].permission.download && !formExtends[widget.model + 'Extends'].permission.view">
        {{ $t('message.flowApply.attachmentsNotVisible') }}
      </div>
      <div v-else>
        <div v-if="Array.isArray(dataModel)">
          <div
            v-for="(item, index) in dataModel"
            :key="index"
          >
            <div
              v-if="typeof item === 'string'"
              style="position:relative;padding-left: 12px;padding-top: 5px;"
            >
              <i
                style="color:rgba(14,31,53,0.85);font-size:14px;"
                class="el-icon-paperclip"
              />
              <span class="file-name">{{ item }}</span>
              <span>
                <a
                  v-if="(uploadType.filter(items => items === item.substring(item.lastIndexOf('/')+1).toLocaleLowerCase()).length > 0) || (uploadType.filter(items => items === item.substring(item.lastIndexOf('.')+1).toLocaleLowerCase()).length > 0)"
                  :href="item"
                  target="_blank"
                  class="file-link"
                >
                  {{ $t('message.common.preview') }}
                </a>
                <a
                  :href="item"
                  download=""
                  class="file-link"
                >
                  {{ $t('message.common.download') }}
                </a>
              </span>
            </div>
            <div
              v-else
              style="position:relative;padding-left: 12px;"
            >
              <i
                style="color:rgba(14,31,53,0.85);font-size:14px;"
                class="el-icon-paperclip"
              />
              <span class="file-name">{{ item.name }}</span>
              <span style="">
                <a
                  v-if="(uploadType.filter(items => items === item.url.substring(item.url.lastIndexOf('/')+1).toLocaleLowerCase()).length > 0) || (uploadType.filter(items => items === item.url.substring(item.url.lastIndexOf('.')+1).toLocaleLowerCase()).length > 0)"
                  :href="item.url"
                  target="_blank"
                  class="file-link"
                >
                  {{ $t('message.common.preview') }}
                </a>
                <a
                  :href="item.url"
                  download=""
                  class="file-link"
                >
                  {{ $t('message.common.download') }}
                </a>
              </span>
            </div>
          </div>
        </div>
        <div
          v-if="(widget.options.defaultValue || dataModel) && !Array.isArray(dataModel)"
          style="position:relative;padding-left: 12px;"
        >
          <i
            style="color:rgba(14,31,53,0.85);font-size:14px;"
            class="el-icon-paperclip"
          />
          <span class="file-name">{{ widget.name }}</span>
          <span>
            <a
              v-if="(uploadType.filter(items => items === (widget.options.defaultValue || dataModel).substring((widget.options.defaultValue || dataModel).lastIndexOf('/')+1).toLocaleLowerCase()).length > 0) ||
                (uploadType.filter(items => items === (widget.options.defaultValue || dataModel).substring((widget.options.defaultValue || dataModel).lastIndexOf('.')+1).toLocaleLowerCase()).length > 0)"
              :href="widget.options.defaultValue || dataModel"
              target="_blank"
              class="file-link"
            >
              {{ $t('message.common.preview') }}
            </a>
            <a
              :href="widget.options.defaultValue || dataModel"
              download=""
              class="file-link"
            >
              {{ $t('message.common.download') }}
            </a>
          </span>
        </div>
      </div>
    </div>
    <statement
      v-if="widget.type == 'table'"
      v-model="dataModel"
      :data="dataModel"
      :editable="false"
    />
    <el-dialog
      v-if="showCustomData"
      :visible.sync="showCustomData"
      :title="widget.name"
      :append-to-body="true"
    >
      <div v-loading="loadingCustomData">
        <iframe
          :src="dataModel"
          class="custom-data"
          @load="loadingCustomData = false"
        />
      </div>
    </el-dialog>
  </el-form-item>
</template>

<script>
  import {Observable} from 'rxjs/Rx'
  import FileUpload from './formComponents/Upload'
  import TreeComponent from './formComponents/TreeComponent.vue'
  import Statement from './formComponents/Statement'
  import InputComponent from './formComponents/InputComponent'

  export default {
  name: 'GenerateFormItem', // 组件：表单填写时的某个表单组件
  components: {
    FileUpload,
    TreeComponent,
    Statement,
    InputComponent
  },
  props: {
    widget: {
      type: Object,
      default: () => {},
      required: true
    },
    models: {
      type: Object,
      default: () => {},
      rquired: true
    },
    operations: {
      type: Object,
      default: () => {}
    },
    rules: {
      type: Object,
      default: () => {}
    },
    formExtends: {
      type: Object,
      default: () => {}
    },
    subFormKey: {
      type: Number,
      default: undefined
    },
    flowId: {
      type: String,
      default: ''
    },
    validateField: { // 验证指定字段
      type: Function,
      default: undefined
    },
    isFirst: { // 是否是表单的第一个组件：是的情况需要聚焦
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      uploadURL: `${process.env.MIX_VUE_APP_APIPRE}/workflow/forms/upload`,
      dataModel: (this.subFormKey !== undefined) ? (this.models[this.widget.model] ? this.models[this.widget.model]['item' + this.subFormKey] : this.widget.options.defaultValue) : this.models[this.widget.model],
      activeIndex: null,
      activeItem: null,
      innerVisible: false,
      valueParams: {}, // 默认值：上次请求带参数据源时的参数值
      optionsParams: {}, // 可选项：上次请求带参数据源时的参数值
      remoteOptions: [],
      localOptions: [],
      uploadType: [
        'pdf',
        'png',
        'jpg',
        'gif',
        'html',
        'json',
        'jpe',
        'jpeg'
      ],
      showCustomData: false,
      loadingCustomData: false,
      observer: undefined // 人机交互修改组件值事件的观察者，手动修改组件值后调用一次观察者
    }
  },
  computed: {
    // 子表单内组件时：行key
    subformLineKey () {
      return 'item' + this.subFormKey
    },
    // 是否是子表单内的子组件
    isInSubformComponent () {
      return this.subFormKey !== undefined
    },
    // 组件的model
    dataKey () {
      return this.widget.model
    },
  },
  async created () {
    // 子表单时
    if (this.models[this.dataKey]) {
      this.dataModel = this.isInSubformComponent ? (this.models[this.dataKey] ? this.models[this.dataKey][this.subformLineKey] : '') : this.models[this.dataKey]
    }

    // 说明文本或弹窗提醒，静态默认值有翻译的时候用翻译
    if (
      this.widget.type
      && (this.widget.type == 'tips' || this.widget.type == 'instruction')
      && this.widget.options.defaultValue_i18n
      && this.widget.options.valueConfig.type == 1
    ) {
      this.dataModel = this.widget.options.defaultValue_i18n
    }

    // 绑定字段值改变的监听事件，更新本组件的值
    const watchKey = 'models.' + this.dataKey
    this.$watch(watchKey, (v, o) => {
      if (v !== o || (v !== null && typeof v === 'object')) {
        // 更新组件的值
        if (this.isInSubformComponent) {
          // 子表单内组件时
          this.dataModel =  v[this.subformLineKey]
        } else {
          this.dataModel = v
        }
      }
    }, {
      deep: true
    })
  },
  methods: {
    getTypeByFormat (format) {
      let type = ''
      switch (format) {
        case 'yyyy':
          type = 'year'
          break
        case 'yyyy-MM':
          type = 'month'
          break
        case 'yyyy-MM-dd':
          type = 'date'
          break
        case 'yyyy-MM-dd HH:mm:ss':
          type = 'datetime'
          break
        default:
          type = 'date'
          break
      }
      return type
    },
    inputValueAndValidate (value) {
      this.inputValue(value)
      this.blur()
    },
    inputValue (value) {
      // console.log(this.widget.name, value, 'input', 777777)
      if(this.widget.model === 'apply_number' && this.widget.options.remoteDataKey === 'getLoginUserApplicant'){
        const urlParams = new URLSearchParams(window.location.search);

        let paramKey, paramValue;
        urlParams.forEach((value, key) => {
          if(key === 'flowKey'){
             paramKey = key
             paramValue = value
          }else if(key === 'flowId'){
            paramKey = key
            paramValue = value
          }
        });
        this.$http.get('/workflow/accredit/checkIsRedirect/' + value + '?'+paramKey+'='+paramValue).then(res => {
          if(res.data.data.info.isRedirect === true){
            window.location.href = res.data.data.info.url
          }
        }).catch(() => {
        })
      }

      this.dataModel = value
      if (this.dataModel && (typeof this.dataModel !== 'number') && this.widget.type === 'input' && (this.widget.options.dataType == 'number' || this.widget.options.dataType == 'integer' || this.widget.options.dataType == 'float')) {
        this.dataModel = parseFloat(this.dataModel)
      }
      let data = {
        model: this.dataKey,
        subFormKey: '',
        data: this.dataModel
      }
      // 级联选择器的activeOptions处理
      if (this.widget.type === 'cascader') {
        this.$nextTick(()=>{
          let checkedNodes = this.$refs.cascader.getCheckedNodes()
          if (!this.widget.options.singleElection && this.dataModel && this.dataModel.length !== checkedNodes.length) {
            const values = this.dataModel.map(item => item[item.length - 1])
            checkedNodes = checkedNodes.filter(item => values.includes(item.data[this.widget.options.props.value || 'value']))
          }
          data.activeOptions = checkedNodes.map(item => {
            return this.getLabels(item).join(this.widget.options.separator || '/')
          }).join(this.widget.options.itemSeparator || ';')
          return this.$emit('updateComponentValue', data);
        })
      }
      // tree的activeOptions处理
      if (this.widget.type === 'tree') {
        data.activeOptions = this.$refs.tree.getCheckedNodes()
      }
      if (this.isInSubformComponent) {
        // 子表单时
        data.subFormKey = this.subFormKey
      }
      // 输入防抖，300ms：单行文本、多行文本、数字
      if (['input', 'textarea', 'number'].includes(this.widget.type)) {
        if (!this.observer) {
          new Observable(observer => {
            this.observer = observer
            this.observer.next(data)
          })
          .debounce(() => Observable.interval(300))
          .subscribe(data => {
            this.$emit('updateComponentValue', data)
          })
        } else {
          this.observer.next(data)
        }
      } else {
        this.$emit('updateComponentValue', data)
      }
    },
    // select远程搜索
    async remoteFilter (filterKey) {
      let data = {
        model: this.dataKey,
        subFormKey: '',
        data: this.dataModel,
        filterKey
      }
      if (this.isInSubformComponent) {
        // 子表单时
        data.subFormKey = this.subFormKey
      }
      this.$emit('updateComponentValue', data)
    },
    getLabels (node, i18n) {
      return node ? this.getLabels(node.parent).concat([node.data[this.widget.options.props.label || 'label']]) : []
    },
    // 展示iframe，开始自定义参数
    startCustomData () {
      this.showCustomData = true
      window.endCustomData = this.endCustomData
      window.models = JSON.parse(JSON.stringify(this.models))
      this.loadingCustomData = true
    },
    endCustomData () {
      this.showCustomData = false
    },
    // 部分组件onchange事件手动发起验证
    blur () {
      this.$nextTick(() => {
        this.validateField((this.isInSubformComponent) ? `${this.dataKey}.item${this.subFormKey}` : this.dataKey)
      })
    },
    // 日期组件范围设置
    disabledDate (time) {
      var equal = this.widget.options.equal
      var lessThan = this.widget.options.lessThan
      var greaterThan = this.widget.options.greaterThan

      return (
        // 大于
        ((this.isInSubformComponent && typeof(this.models[greaterThan]) === 'object') ?
        time > new Date(new Date(new Date(new Date(this.models[greaterThan][this.subformLineKey]).setHours('0')).setMinutes('0')).setSeconds('0')) :
        time > new Date(new Date(new Date(new Date(this.models[greaterThan]).setHours('0')).setMinutes('0')).setSeconds('0'))) ||
        // 等于
        ((this.isInSubformComponent && typeof(this.models[equal]) === 'object') ?
        time.getDate() === new Date(this.models[equal][this.subformLineKey]).getDate() &&
        time.getMonth() === new Date(this.models[equal][this.subformLineKey]).getMonth() &&
        time.getFullYear() === new Date(this.models[equal][this.subformLineKey]).getFullYear() :
        time.getDate() === new Date(this.models[equal]).getDate() &&
        time.getMonth() === new Date(this.models[equal]).getMonth() &&
        time.getFullYear() === new Date(this.models[equal]).getFullYear()) ||
        // 小于
        ((this.isInSubformComponent && typeof(this.models[lessThan]) === 'object') ?
        time < new Date(new Date(new Date(new Date(this.models[lessThan][this.subformLineKey]).setHours('0')).setMinutes('0')).setSeconds('0')) :
        time < new Date(new Date(new Date(new Date(this.models[lessThan]).setHours('0')).setMinutes('0')).setSeconds('0')))
      )
    }
  }
}
</script>

<style lang="stylus" scoped>
.instruction-content
  line-height 20px
  margin 10px 0 0!important
.instruction-line
  height 40px
  line-height 40px
  margin 0 0 0.5rem 0
  word-break break-word
.el-form-item__labelin
  font-size 16px
  color: rgb(14,31,53,0.85)
.el-form-item-instruction
  >>>.el-form-item__label
    font-size 16px
    color rgba(14,31,53,0.85) !important
    border-left 3px solid #3657FF
    padding: 0;
    line-height: 16px;
    margin-left: -4px;
    width 100%!important
    text-align left!important
  >>>.el-form-item__content
    display none
.el-checkbox
  height 25px
.detail-page
  .el-input.is-disabled
    >>>.el-input__inner
      background-color #fff!important
      border-color #dcdfe6!important
      color #606266!important
      cursor default!important
  >>>.el-textarea.is-disabled
    .el-textarea__inner
      background-color #fff!important
      border-color #dcdfe6!important
      color #606266!important
      cursor default!important
  >>>.el-input.is-disabled
    .el-input__inner
      background-color #fff!important
      border-color #dcdfe6!important
      color #606266!important
      cursor default!important
  >>>.el-switch.is-disabled
    opacity 1
    .el-switch__core,.el-switch__label
      cursor default
  >>>.el-radio.is-disabled
    cursor default!important
  >>>.el-radio__input.is-disabled
    .el-radio__inner
      border-color #dcdfe6!important
      background-color #fff!important
      cursor default!important
  >>>.el-radio__input.is-disabled
    .el-radio__inner:after
      background-color #fff!important
      cursor default!important
  >>>.el-radio__input.is-disabled.is-checked
    .el-radio__inner
      border-color #54c3f1!important
      background-color #54c3f1!important
      cursor default!important
  >>>.el-radio__input.is-disabled
    +span.el-radio__label
      color #606266!important
      cursor default!important
  >>>.el-checkbox.is-disabled
    cursor default!important
  >>>.el-checkbox__input.is-disabled.is-checked
    .el-checkbox__inner:after
      border-color #fff!important
      cursor default!important
  >>>.el-checkbox__input.is-disabled
    .el-checkbox__inner
      border-color #dcdfe6!important
      background-color #fff!important
      cursor default!important
  >>>.el-checkbox__input.is-disabled.is-checked
    .el-checkbox__inner
      border-color #54c3f1!important
      background-color #54c3f1!important
      cursor default!important
  >>>.el-checkbox__input.is-disabled
    +span.el-checkbox__label
      color #606266!important
      cursor default!important
  >>>.el-checkbox__input.is-checked
    +span.el-checkbox__label
      color #54c3f1!important
.el-col-12
  min-width 240px!important
.el-select
.el-date-editor
.el-input-number
.el-cascader
.el-switch__core
  width 100%
.file-name
  line-height normal !important
.custom-data
  border 0
  width 100%
  height 400px
</style>
<style>
@media (max-width: 768px) {
  .instruction-line{
    height: auto !important;
  }
  .el-cascader__dropdown {
    left: 0!important;
    width: 100%;
    overflow: auto;
  }
  .el-dialog {
    width: 98%!important;
    overflow: auto;
  }
}
.el-cascader__dropdown .el-cascader-node .el-radio {
  margin-bottom: 0;
}
</style>
