<template>
  <div>
    <el-button
      v-if="!disabled"
      type="primary"
      icon="el-icon-edit"
      circle
      @click="innerVisible=true"
    />
    <el-popover
      placement="top-start"
      :title="$t('message.flowApply.selected')"
      trigger="hover"
      :content="checkeds"
      width="400"
    >
      <div
        slot="reference"
        class="checked-lable"
        :style="{
          'width':widget.options.width
        }"
      >
        {{ checkeds }}
      </div>
    </el-popover>
    <el-dialog
      :visible.sync="innerVisible"
      width="600px"
      append-to-body
      :style="{opacity: editable ? 1 : 0}"
      :modal="editable"
    >
      <el-input
        v-model="filterText"
        :placeholder="$t('message.common.placeholder.keyTofilter')"
        :style="{
          'display':widget.options.filterable === true ? 'block' : 'none'
        }"
      />
      <el-tree
        ref="tree"
        show-checkbox
        :node-key="widget.options.nodeKey"
        :data="dataSource"
        :default-expand-all="widget.options.defaultExpandAll"
        :props="widget.options.defaultProps"
        :include-half-checked="true"
        :filter-node-method="filterNode"
        :check-strictly="widget.options.checkStrictly"
        @check-change="orgCheckChange"
      />
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="cancel()">{{ $t('message.common.cancel') }}</el-button>
        <el-button
          type="primary"
          @click="onSubmit()"
        >{{ $t('message.common.ok') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'TreeComponent',
  props: {
    visible: Boolean,
    disabled: {
      type: Boolean,
      default: false
    },
    editable: {
      type: Boolean,
      default: true
    },
    dataSource: {
      type: Array,
      default: () => []
    },
    widget: {
      type: Object,
      default: () => {},
      required: true
    },
    subFormKey: { // 子表单行号
      type: Number,
      default: undefined
    },
    formExtends: {
      type: Object,
      default: () => {}
    },
    models: {
      type: Object,
      default: () => {}
    },
    value: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      innerVisible: this.visible,
      checkedId: [],
      filterText: '',
      checkeds: '',
      checkedLabel: [],
      orgsid: []
    }
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val)
    },
    dataSource: {
      handler (val) {
        if (!this.editable && val && val.length > 0) {
          this.$nextTick(() => {
            this.$refs.tree.setCheckedKeys(this.value)
            this.onSubmit()
          })
        }
      },
      deep: true
    }
  },
  created() {
    if (this.subFormKey !== undefined) {
      // 子表单时
      if (this.formExtends[this.widget.model + 'Extends'] && this.formExtends[this.widget.model + 'Extends'].options && this.formExtends[this.widget.model + 'Extends'].options['item' + this.subFormKey] && this.formExtends[this.widget.model + 'Extends'].options['item' + this.subFormKey].map(item => item.label).length > 0) {
        this.checkeds = this.formExtends[this.widget.model + 'Extends'].options['item' + this.subFormKey].map(item => item.label).join(',')
      }
    } else {
      // 非子表单时
      if (this.formExtends[this.widget.model + 'Extends'] && this.formExtends[this.widget.model + 'Extends'].options && this.formExtends[this.widget.model + 'Extends'].options.map(item => item.label).length > 0) {
        this.checkeds = this.formExtends[this.widget.model + 'Extends'].options.map(item => item.label).join('、')
      }
    }
  },
  methods: {
    // 点击节点时更新选中数据
    getCheckedNodes () {
      this.checkedId = this.$refs.tree.getCheckedNodes().map(item => item.id)
      this.checkedLabel = this.$refs.tree.getCheckedNodes().map(item => item.label)
      var checked = this.$refs.tree.getCheckedNodes().map(item => {
        const obj = { ...item }
        delete obj.children
        return obj
      })
      this.checkeds = this.checkedLabel.join('、')
      // this.$emit('extendsChange', checked)
      return checked
    },
    // 取消选择
    cancel() {
      this.innerVisible = false
    },
    onSubmit() {
      this.getCheckedNodes()
      this.$emit('input', this.checkedId)
      this.innerVisible = false
    },
    // 搜索
    filterNode(value, data) {
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },
    // 单选功能
    orgCheckChange(data, checked) {
      // console.log(this.widget.options.singleElection)
      if (this.widget.options.singleElection === true) {
        this.widget.options.checkStrictly = true
        // 获取当前选择的id在数组中的索引
        const indexs = this.orgsid.indexOf(data.id)
        // 如果不存在数组中，并且数组中已经有一个id并且checked为true的时候，代表不能再次选择。
        if (indexs < 0 && this.orgsid.length === 1 && checked) {
          this.$message({
            message: '当前为单选',
            type: 'error',
            duration: 10000,
            showClose: true
          })
          // 设置已选择的节点为false 很重要
          this.$refs.tree.setChecked(data, false)
        } else if (this.orgsid.length === 0 && checked) {
          // 发现数组为空 并且是已选择
          // 防止数组有值，首先清空，再push
          this.orgsid = []
          this.orgsid.push(data.id)
        } else if (
          indexs >= 0 &&
          this.orgsid.length === 1 &&
          !checked
        ) {
          // 再次直接进行赋值为空操作
          this.orgsid = []
        }
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.checked-lable
  color #8c8a8a
  overflow hidden
  text-overflow ellipsis
  white-space nowrap
</style>
