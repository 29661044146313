const locale = window.ELEMENT.lang.th
export default {
    ...locale,
    "Cancel": "ยกเลิก",
    "确定": "ยืนยัน",
    "提示": "ข้อสังเกต",
    "Confirm": "ยืนยัน",
    "cancel": "ยกเลิก",
  "Copy Success": "คัดลอกแล้ว",
  "Copy link": "คัดลอกลิงก์",
    "确定删除该授权？": "คุณแน่ใจหรือไม่ว่าจะลบการให้สิทธิ์นี้",
    "您确定要删除此申请吗？不可恢复": "คุณแน่ใจหรือไม่ว่าจะลบการสมัครนี้ เอาคืนไม่ได้",
    "确定删除该分组？": "คุณแน่ใจหรือไม่ว่าจะลบกลุ่มนี้",
    "删除分组": "ลบกลุ่ม",
    "确定启用该流程？": "คุณแน่ใจหรือไม่ว่าจะเปิดใช้งานกระบวนการ",
    "确定要删除该组件吗？": "คุณแน่ใจหรือไม่ว่าจะลบองค์ประกอบนี้",
  "message.common.info": "ข้อมูล",
    "message.common.code.askDecryptionAgain": "จะถอดรหัสผ่านอีกหรือไม่",
    "message.common.code.decryptionFailed": "ปลอดรหัสผ่านไม่สำเร็จ",
    "message.common.code.decryptionAgain": "ถอดรหัสผ่านอีกครั้ง",
    "message.common.code.cancelDecryptionAgain": "คุณยกเลิกการถอดรหัสผ่านอีกครั้ง",
    "message.common.code.inputCode": "รหัสยืนยัน",
    "message.common.code.sentCode": "ได้ส่งรหัสยืนยันแล้ว",
    "message.common.code.wrongFormat": "รูปแบบรหัสยืนยันไม่ถูกต้อง",
    "message.common.code.cancelCode": "คุณยกเลิกการส่งรหัสยืนยัน",
    "message.common.code.wrongSend": "เกิดข้อผิดพลาดในการส่งรหัสยืนยัน",
    "message.common.code.wrongSubmit": "เกิดข้อผิดพลาดในการยื่นรหัสยืนยัน",
    "message.common.code.successSubmit": "ส่งรหัสยืนยันเรียบร้อยแล้ว",
    "message.common.code.reloadTry": "โปรดรีเฟรชหน้าแล้ว ลองอีกครั้ง",
    "message.common.code.secondsLater": "วินาทีต่อมา",
    "message.common.code.sendAgain": "รับใหม่อีกครั้ง",
    "message.common.code.tipsContent1": "ระบบสมัครการถอดรหัสผ่าน",
    "message.common.code.tipsContent3": "ข้อมูล โปรดใส่รหัสยืนยันเพื่อให้การอนุญาตสมบูรณ์",
    "message.common.code.placeholder": "รหัสยืนยันจะส่งให้คุณผ่านTVหรืออีเมล",
    "message.common.code.frequent": "ได้รับรหัสยืนยันบ่อยเกินไป โปรดรับอีกใหม่ในภายหลัง",
    "message.common.code.wrongCode": "รหัสยืนยันไม่ถูกต้อง โปรดลองอีกครั้ง",
    "message.common.code.wrongTooManyTimes": "ใส่รหัสยืนยันผิดหลายครั้ง โปรดรับรหัสยืนยันใหม่อีกคร้ง",
    "message.common.code.overdueCode": "รหัสยืนยันหมดอายุแล้ว โปรดรับรหัสยืนยันใหม่อีกครั้ง",
    "message.common.uploadFile": "อัพโหลดไฟล์",
    "message.common.uploadPicture": "อัปโหลดรูปภาพ",
    "message.common.ok": "ตกลง",
    "message.common.cancel": "ยกเลิก",
    "message.common.submit": "ส่ง",
    "message.common.saveAsDraft": "บันทึกเป็นแบบร่าง",
    "message.common.tips": "ข้อสังเกต",
    "message.common.warning": "คำเตือน",
    "message.common.start": "เริ่มต้น",
    "message.common.end": "สิ้นสุด",
    "message.common.processNode": "โหนดกระบวนการ",
    "message.common.subTask": "งานรอง",
    "message.common.pending": "รอการดำเนินการ",
    "message.common.executeException": "การดำเนินการผิดปกติ",
    "message.common.lostEfficacy": "หมดอายุแล้ว",
    "message.common.preview": "พรีวิว",
    "message.common.download": "ดาวน์โหลด",
    "message.common.yes": "ใช่",
    "message.common.no": "ไม่ใช่",
    "message.common.width": "ความกว้าง",
    "message.common.height": "ความสูง",
    "message.common.add": "เพิ่มเติม",
    "message.common.node": "โหนด",
    "message.common.select": "เลือก",
    "message.common.selected": "เลือกแล้ว",
    "message.common.validate.required": "จำเป็นต้องใส่",
    "message.common.validate.validateFaild": "ยืนยันไม่ผ่าน",
    "message.common.validate.wrongFormat": "รูปแบบไม่ตรงกัน",
    "message.common.validate.loadingData": "ข้อมูลกำลังโหลด...รอสักครู่",
    "message.common.validate.noApproverSelected": "ไม่ได้เลือกผู้อนุมัติ\n",
    "message.common.placeholder.select": "กรุณาเลือก",
    "message.common.placeholder.remark": "กรุณาใส่หมายเหตุ",
    "message.common.placeholder.notRequired": "เลือกหรือไม่ก็ได้",
    "message.common.placeholder.keyTofilter": "ใส่คำสำคัญเพื่อกรอง",
    "message.common.requestTips.failed": "ดำเนินการล้มเหลว กรุณาลองอีกครั้ง",
    "message.common.requestTips.success": "ดำเนินการสำเร็จ",
    "message.common.requestTips.submitSuccess": "ส่งสำเร็จแล้ว",
    "message.common.requestTips.getDataFailed": "เกิดข้อผิดพลาดในการรับข้อมูล กรุณาลองอีกครั้ง",
    "message.flowApply.systemFlow": "กระบวนการนี้เป็นกระบวนการของระบบ ไม่รองรับการเริ่มต้นด้วยตนเอง",
    "message.flowApply.resultPreview": "ผลพรีวิว",
    "message.flowApply.attachmentsNotVisible": "ขณะนี้ไม่รองรับการดูไฟล์แนบ",
    "message.flowApply.maxUpload": "อัปโหลดได้สูงสุด{number}ไฟล์",
    "message.flowApply.selected": "ที่เลือกไว้",
    "message.flowApply.personnelSelection": "การคัดเลือกบุคลากร",
    "message.flowApply.member": "พนักงาน",
    "message.flowApply.officer": "เจ้าพนักงาน",
    "message.flowApply.reporter": "ระดับการรายงาน",
    "message.flowApply.role": "ตัวละคร",
    "message.flowApply.department": "แผนก",
    "message.flowApply.specialRole": "ตัวละครพิเศษ",
    "message.flowApply.memberColumn": "พนักงานที่ระบุโดยฟิลด์แบบฟอร์ม",
    "message.flowApply.officerColumn": "หัวหน้างานของพนักงานที่ระบุโดยฟิลด์แบบฟอร์ม",
    "message.flowApply.reporterColumn": "ระดับการรายงานของพนักงานที่ระบุโดยฟิลด์แบบฟอร์ม",
    "message.flowApply.columnPlaceholder": "ค่าคือ ID พนักงาน",
    "message.flowApply.memberFailed": "การรับข้อมูลพนักงานล้มเหลว",
    "message.flowApply.officerFailed": "การรับข้อมูลหัวหน้างานล้มเหลว",
    "message.flowApply.reporterFailed": "การรับข้อมูลระดับรายงานล้มเหลว",
    "message.flowApply.roleFailed": "การรับข้อมูลตัวละครล้มเหลว",
    "message.flowApply.departmentFailed": "การรับข้อมูลแผนกล้มเหลว",
    "message.flowApply.specialRoleFailed": "การรับข้อมูลตัวละครพิเศษล้มเหลว",
    "message.flowDetail.repeal": "ถอน",
    "message.flowDetail.withdrawl": "ถอนแล้ว",
    "message.flowDetail.print": "พิมพ์",
    "message.flowDetail.decode": "ถอดรหัส",
    "message.flowDetail.decoded": "รหัสถูกถอดแล้ว",
    "message.flowDetail.uploadFile": "อัพโหลดไฟล์แนบ",
    "message.flowDetail.uploadTips": "สามารถอัปโหลดไฟล์แนบได้เพียงเท่านั้น กรุณาลบไฟล์แนบที่อัพโหลดก่อน",
    "message.flowDetail.pass": "ผ่านแล้ว",
    "message.flowDetail.careOf": "โอนย้าย",
    "message.flowDetail.turnDown": "ปฏิเสธ",
    "message.flowDetail.collapse": "เก็บไว้",
    "message.flowDetail.expand": "ขยาย",
    "message.flowDetail.transferee": "โอนไปยังผู้รับโอน",
    "message.flowDetail.sureTurnDown": "คุณแน่ใจหรือไม่ว่าต้องการปฏิเสธใบสมัครนี้",
    "message.flowDetail.surePass": "คุณแน่ใจหรือไม่ว่าต้องการอนุมัติการสมัครนี้",
    "message.flowDetail.sureRepeal": "คุณแน่ใจหรือไม่ว่าต้องการถอนการสมัครนี้",
    "message.flowDetail.sureDispose": "คุณแน่ใจหรือว่าต้องการดำเนินการงานนี้",
    "message.flowDetail.approveRecord": "บันทึกการอนุมัติ",
    "message.flowDetail.rejected": "ถูกปฏิเสธแล้ว",
    "message.flowDetail.passed": "ผ่านแล้ว",
    "message.flowDetail.pending": "รอการอนุมัติ",
    "message.flowDetail.turnedDown": "ถูกปฏิเสธแล้ว",
    "message.flowDetail.draft": "แบบร่าง",
    "message.flowDetail.requireTransferee": "กรุณาเลือกผู้รับโอน",
    "message.flowDetail.complete": "สำเร็จเรียบร้อย",
  "message.flowDetail.Redo": "ทำอีกครั้ง",
    "date_picker_Dec": "ธันวาคม",
    "date_picker_Nov": "พฤศจิกายน",
    "date_picker_Oct": "ตุลาคม",
    "date_picker_Sept": "กันยายน",
    "date_picker_Aug": "สิงหาคม",
    "date_picker_Jul": "กรกฎาคม",
    "date_picker_Jun": "มิถุนายน",
    "date_picker_May": "พฤษภาคม",
    "date_picker_Apr": "เมษายน",
    "date_picker_Mar": "มีนาคม",
    "date_picker_Feb": "กุมภาพันธ์",
    "date_picker_Jan": "มกราคม",
    "date_picker_Saturday": "ส.",
    "date_picker_Friday": "ศ.",
    "date_picker_Thursday": "พฤ.",
    "date_picker_Wednesday": "พ.",
    "date_picker_Tuesday": "อ.",
    "date_picker_Monday": "จ.",
    "date_picker_Sunday": "อา.",
    "more": "more",
    "more_items_with_num": "ยังมี :num รายการ",
    "year_month": ":yearปี:monthเดือน",
    "请选择第 :idx 张银行卡的类型": "กรุณาเลือก :idx ประเภทของบัตรธนาคาร",
    "第 :idx 张银行卡的卡号格式错误": ":idx รูปแบบของหมายเลขบัตรธนาคารไม่ถูกต้อง",
    "请输入第 :idx 张银行卡的开户行城市": "กรุณาใส่ :idx เมืองที่เปิดบัตรธนาคาร",
    "请选择第 :idx 张银行卡的开户行": "กรุณาเลือก :idx ธนาคารบัญชีของบัตรธนาคาร",
    "请输入第 :idx 张银行卡的支行": "กรุณาใส่ :idx สาขาบัตรธนาคาร",
    "请输入第 :idx 张银行卡的预留手机号": "กรุณาใส่ :idx เบอร์โทรศัพท์มือถือที่จองบัตรธนาคาร",
    "请输入第 :idx 张银行卡的身份证号": "กรุณาใส่ :idx หมายเลขบัตรประชาชน",
    "请输入第 :idx 张银行卡的姓名": "กรุณาใส่ :idx ชื่อบัตรธนาคาร",
    "请上传第 :idx 张银行卡的正面照片": "กรุณาอัพโหลด :idx รูปภาพด้านหน้าของบัตรธนาคาร",
    "请上传第 :idx 张银行卡的反面照片": "กรุณาอัพโหลด :idx รูปภาพด้านหลังของบัตรธนาคาร",
    "第 :idx 份工作经历的证明人电话格式错误": ":idx รูปแบบของเบอร์โทรศัพท์ของบุคคลอ้างอิงสำหรับประวัติการทำงานไม่ถูกต้อง",
    "第 :idx 个联系人的电话格式错误": ":idx รูปแบบของเบอร์โทรศัพท์ติดต่อไม่ถูกต้อง",
    "保存草稿成功": "บันทึกร่างเรียบร้อย",
    "取消": "ยกเลิก",
    "确认": "ยืนยัน",
    "操作成功": "ดำเนินการสำเร็จแล้ว",
    "保存": "บันทึก",
    "姓名": "ชื่อ",
    "职级": "ระดับตำแหน่ง",
    "员工类型": "ประเภทพนักงาน",
    "有": "มี",
    "无": "ไม่มี",
    "类型": "ประเภท",
    "年终奖": "รางวัลส่งท้ายปี",
    "入职时间": "เวลาเข้าทำงาน",
    "备注": "หมายเหตุ",
    "全职": "ทำงานเต็มเวลา",
    "兼职": "ทำงานพาร์ทไท",
    "实习": "การฝึกงาน",
    "劳务派遣": "ส่งแรงงาน",
    "劳务外包": "จ้างแรงงาน",
    "退休返聘": "เกษียณเชื้อเชิญกลับมารับตำแหน่งเดิม",
    "顾问": "ผู้ปรึกษา",
    "试用": "ทดลองใช้",
    "正式": "เป็นทางการ",
    "待离职审核中": "การลาออกกำลังตรวจสอบ",
    "待离职已通过": "การลาออกเรียบร้อบแล้ว",
    "提交": "ส่ง",
    "编辑": "แก้ไข",
    "是": "ใช่",
    "否": "ไม่ใช่",
    "验证码": "รหัสยืนยัน",
    "导出": "ส่งออก",
    "总监": "ผู้อำนวยการ",
    "上级": "หัวหน้า",
    "职位": "ตำแหน่ง",
    "绩效": "ผลงาน",
    "时间": "เวลา",
    "Yes": "ใช่",
    "No": "ไม่ใช่",
    "Verification code": "รหัสยืนยัน",
    "OK": "ยืนยัน",
    "Hint": "ข้อสังเกต",
    "Operate successfully": "ดำเนินการสำเร็จแล้ว",
    "Save": "บันทึก",
    "Name": "ชื่อ",
    "Type": "ประเภท",
    "Remark": "หมายเหตุ",
    "Submit": "ส่ง",
    "Export": "ส่งออก",
    "Position": "ตำแหน่ง",
    "Time": "เวลา",
    "Edit": "แก้ไข",
    "处理中": "กำลังดำเนินการ",
    "选地址": "เลือกที่อยู่",
    "天": "วัน",
    "删除": "ลบ",
    "Processing": "กำลังดำเนินการ",
    "Rank": "ระดับตำแหน่ง",
    "Employee Type": "ประเภทพนักงาน",
    "None": "ไม่มี",
    "Annual Bonus": "รางวัลส่งท้ายปี",
    "Start Date": "เวลาเข้าทำงาน",
    "Full-time": "ทำงานเต็มเวลา",
    "Part-time": "ทำงานพาร์ทไท",
    "Intern": "การฝึกงาน",
    "Contractor": "ส่งแรงงาน",
    "Outsourcing": "จ้างแรงงาน",
    "Retirement": "เกษียณเชื้อเชิญกลับมารับตำแหน่งเดิม",
    "Consultant": "ผู้ปรึกษา",
    "Probationary": "ทดลองใช้",
    "Regular": "เป็นทางการ",
    "Resignation Approving": "การลาออกกำลังตรวจสอบ",
    "Resignation Approved": "การลาออกเรียบร้อบแล้ว",
    "Director": "ผู้อำนวยการ",
    "Leader": "หัวหน้า",
    "Performance": "ผลงาน",
    "Remarks": "หมายเหตุข้อความ",
    "Delete": "ลบ",
    "Select Address": "เลือกที่อยู่",
    "Day(s)": "วัน",
    "失败": "ไม่สำเร็จ",
    "Failure": "ไม่สำเร็จ",
    "Please select the type of card number :idx": "กรุณาเลือก :idx ประเภทของบัตรธนาคาร",
    "The card number format of the bank card :idx is incorrect": ":idx รูปแบบของหมายเลขบัตรธนาคารไม่ถูกต้อง",
    "Please enter the bank city of the bank card :idx": "กรุณาใส่ :idx เมืองที่เปิดบัตรธนาคาร",
    "Please select the bank name of the bank card :idx": "กรุณาเลือก :idx ธนาคารบัญชีของบัตรธนาคาร",
    "Please enter the branch of the bank card :idx": "กรุณาใส่ :idx สาขาบัตรธนาคาร",
    "Please enter the name of the bank card :idx": "กรุณาใส่ :idx ชื่อบัตรธนาคาร",
    "Please enter the reserved mobile number of the bank card :idx": "กรุณาใส่ :idx เบอร์โทรศัพท์มือถือที่จองบัตรธนาคาร",
    "Please enter the ID number of the bank card :idx": "กรุณาใส่ :idx หมายเลขบัตรประชาชน",
    "Please upload the front photo of the bank card :idx": "กรุณาอัพโหลด :idx รูปภาพด้านหน้าของบัตรธนาคาร",
    "Please upload the backside photo of the bank card :idx": "กรุณาอัพโหลด :idx รูปภาพด้านหลังของบัตรธนาคาร",
    "Malformed phone number for contact :idx": ":idx รูปแบบของเบอร์โทรศัพท์ติดต่อไม่ถูกต้อง",
    "No.: idx work experience prover phone format is wrong": ":idx รูปแบบของเบอร์โทรศัพท์ของบุคคลอ้างอิงสำหรับประวัติการทำงานไม่ถูกต้อง",
    "Save the draft successfully": "บันทึกร่างเรียบร้อย",
    "Are you sure you want to delete this authorization?": "คุณแน่ใจหรือไม่ว่าจะลบการให้สิทธิ์นี้",
    "Are you sure you want to delete this application? irrecoverable": "คุณแน่ใจหรือไม่ว่าจะลบการสมัครนี้ เอาคืนไม่ได้",
    "Are you sure you want to delete this group?": "คุณแน่ใจหรือไม่ว่าจะลบกลุ่มนี้",
    "Delete Group": "ลบกลุ่ม",
    "Are you sure you want to enable this process?": "คุณแน่ใจหรือไม่ว่าจะเปิดใช้งานกระบวนการ",
    "Are you sure you want to remove this component?": "คุณแน่ใจหรือไม่ว่าจะลบองค์ประกอบนี้",
    "请选择": "โปรดเลือก",
    "HRBP": "HRBP",
    "预览": "แสดงตัวอย่าง",
    "放弃入职": "ปฏิเสธเข้าทำงาน",
    "通过": "เรียบร้อย",
    "基本信息": "ข้อมูลพื้นฐาน",
    "备注信息": "หมายเหตุข้อความ",
    "创建时间": "สร้างเวลา",
    "Please choose": "โปรดเลือก",
    "preview": "แสดงตัวอย่าง",
    "Preview": "แสดงตัวอย่าง",
    "Give Up Onboarding": "ปฏิเสธเข้าทำงาน",
    "Approve": "เรียบร้อย",
    "Upload": "ที่อัพโหลด",
    "Upload failed": "อัพโหลดล้มเหลว",
    "Unknown error": "ความผิดพลาดที่ไม่รู้",
    "Sun.": "วันอาทิตย์",
    "Mon.": "วันจันทร์",
    "Wed.": "วันพุธ",
    "Fri.": "วันศุกร์",
    "Sat.": "วันเสาร์",
    "Entry date": "เวลาเข้าทำงาน",
    "Details": "รายละเอียด",
    "Work Site": "สถานที่ที่ทำงาน"
}
