<template>
  <!-- 详情页内容展示 -->
  <el-form-item
    :id="'component-' + widget.model"
    v-loading="customShowLoading"
    :class="[
      {'no-before': (widget.type === 'fileupload' || widget.type === 'imgupload' || widget.type === 'download')},
      {'el-form-item-instruction': widget.options.isLabel}
    ]"
    :label="widget.name"
  >
    <!-- 前缀 -->
    <template v-if="widget.options.prefix !== '' && widget.options.prefix !== undefined && widget.options.prefix !== null">
      {{ widget.options.prefix }}
    </template>
    <!-- 展示值开始 -->
    <!-- 单可选项 -->
    <template v-if="widget.type == 'radio' || (widget.type === 'select' && !widget.options.multiple)">
      {{ dataModel }}
    </template>
    <!-- 多可选项 -->
    <template v-else-if="widget.type == 'checkbox' || widget.type == 'tree' || (widget.type === 'select' && widget.options.multiple)">
      <template v-if="widget.type === 'select' && widget.options.multiple && widget.options.remoteFilter">
        {{ dataModel }}
      </template>

      <template v-else>
        {{ dataModel }}
      </template>

    </template>
    <!-- 多行文本和说明文本：换行需要特殊处理 -->
    <template v-else-if="widget.type == 'instruction' || widget.type == 'textarea'">
      <div>
        <p
          v-for="(str, strIndex) in (dataModel || '').split('\n')"
          :key="strIndex"
          class="instruction-line"
        >
          {{ str }}
        </p>
      </div>
    </template>
    <!-- 图片上传 -->
    <template v-else-if="widget.type == 'imgupload'">
      <file-upload
        v-model="dataModel"
        :disabled="!editable || operations.disableds[widget.model]"
        :limit="widget.options.limit"
        :multiple="widget.options.multiple"
        :editable="editable"
        :flow-id="flowId"
        :model-key="dataKey"
        :widget-extends="formExtends[widget.model + 'Extends']"
        type="img"
      />
    </template>
    <!-- 文件上传 -->
    <template v-else-if="widget.type == 'fileupload'">
      <file-upload
        v-model="dataModel"
        :disabled="!editable || operations.disableds[widget.model]"
        :limit="widget.options.limit"
        :multiple="widget.options.multiple"
        :editable="editable"
        :flow-id="flowId"
        :model-key="dataKey"
        :show-file="widget.options.showFile"
        :widget-extends="formExtends[widget.model + 'Extends']"
        style="width: 100%"
      />
    </template>
    <!-- 自定义展示: 审批时注入 -->
    <template v-else-if="widget.type == 'customshow'">
      <div
        v-if="widget.options.contentType !== 2 && formMounted"
        ref="customShow"
        style="width: 100%;"
        v-html="dataModel"
      />
      <iframe
        v-else
        :src="dataModel ? dataModel.replace('{{processId}}', $route.query.flowId) : dataModel"
        :style="[{height: widget.options.iframeHeight + 'px'}]"
        class="custom-show-iframe"
        @load="customShowLoading = false"
      />
    </template>
    <!-- 审批时自定义操作 -->
    <template v-else-if="widget.type === 'customoperate' || (widget.type === 'link' && (widget.options.stage === 'approve' || widget.options.stage === 'all'))">
      <el-button
        v-if="widget.options.showType !== 2"
        type="text"
        @click="customOperate"
      >
        {{ widget.options.buttonText || widget.name }}
      </el-button>
      <a
        v-else
        target="_blank"
        style="text-decoration:none"
        class="el-button el-button--text el-button--middle"
        :href="dataModel.replace('{{processId}}', $route.query.flowId)"
      >{{ widget.options.buttonText || widget.name }}</a>
    </template>
    <!-- 下载按钮 -->
    <template v-else-if="widget.type == 'download'">
      <div v-if="formExtends[widget.model + 'Extends'] && formExtends[widget.model + 'Extends'].permission && !formExtends[widget.model + 'Extends'].permission.download && !formExtends[widget.model + 'Extends'].permission.view">
        {{ $t('message.flowApply.attachmentsNotVisible') }}
      </div>
      <div v-else>
        <div
          v-if="Array.isArray(dataModel)"
          slot="reference"
          :style="{
            'width': widget.options.width
          }"
        >
          <template>
            <div
              v-for="(item, index) in dataModel"
              :key="index"
            >
              <div
                v-if="typeof item === 'string'"
                style="position:relative;"
              >
                <i
                  style="color:rgba(14,31,53,0.85);font-size:14px;"
                  class="el-icon-paperclip"
                />
                <span class="file-name">{{ widget.name }}</span>
                <span style="">
                  <a
                    v-if="(uploadType.filter(items => items === item.substring(item.lastIndexOf('.')+1).toLocaleLowerCase()).length > 0) || (uploadType.filter(items => items === item.substring(item.lastIndexOf('/')+1).toLocaleLowerCase()).length > 0)"
                    :href="item"
                    target="_blank"
                    class="file-link"
                  >
                    {{ $t('message.common.preview') }}
                  </a>
                  <a
                    :href="item"
                    download=""
                    class="file-link"
                  >
                    {{ $t('message.common.download') }}
                  </a>
                </span>
              </div>
              <div
                v-else
                style="position:relative;"
              >
                <i
                  style="color:rgba(14,31,53,0.85);font-size:14px;"
                  class="el-icon-paperclip"
                />
                <span class="file-name">{{ widget.name }}</span>
                <span style="">
                  <a
                    v-if="(uploadType.filter(items => items === item.url.substring(item.url.lastIndexOf('/')+1).toLocaleLowerCase()).length > 0) || (uploadType.filter(items => items === item.url.substring(item.url.lastIndexOf('.')+1).toLocaleLowerCase()).length > 0)"
                    :href="item.url"
                    target="_blank"
                    class="file-link"
                  >
                    {{ $t('message.common.preview') }}
                  </a>
                  <a
                    :href="item.url"
                    download=""
                    class="file-link"
                  >
                    {{ $t('message.common.download') }}
                  </a>
                </span>
              </div>
            </div>
          </template>
        </div>
        <template v-if="!Array.isArray(dataModel)">
          <div style="position:relative;">
            <i
              style="color:rgba(14,31,53,0.85);font-size:14px;"
              class="el-icon-paperclip"
            />
            <span class="file-name">{{ widget.name }}</span>
            <span style="">
              <a
                v-if="(((uploadType.filter(items => items === (widget.options.defaultValue || dataModel).substring((widget.options.defaultValue || dataModel).lastIndexOf('/')+1).toLocaleLowerCase()))).length > 0) ||
                  (uploadType.filter(items => items === (widget.options.defaultValue || dataModel).substring((widget.options.defaultValue || dataModel).lastIndexOf('.')+1).toLocaleLowerCase()).length > 0)"
                :href="widget.options.defaultValue || dataModel"
                target="_blank"
                class="file-link"
              >
                {{ $t('message.common.preview') }}
              </a>
              <a
                :href="widget.options.defaultValue || dataModel"
                download=""
                class="file-link"
              >
                {{ $t('message.common.download') }}
              </a>
            </span>
          </div>
        </template>
      </div>
    </template>
    <!-- 报表展示 -->
    <statement
      v-else-if="widget.type == 'table'"
      v-model="dataModel"
      :editable="false"
    />
    <!-- 级联选择器展示 -->
    <template v-else-if="widget.type == 'cascader'">
      {{ dataModel }}
    </template>
    <!-- 普通值组件 -->
    <template v-else>
      {{ dataModel }}
    </template>
    <!-- 展示值结束 -->
    <!-- 后缀 -->
    <template v-if="widget.options.suffix !== '' && widget.options.suffix !== undefined && widget.options.suffix !== null">
      {{ widget.options.suffix }}
    </template>
    <el-dialog
      v-if="operateVisible"
      :visible.sync="operateVisible"
      :title="widget.name"
      width="80%"
      class="operate-modal"
    >
      <iframe
        :src="dataModel.replace('{{processId}}', $route.query.flowId)"
      />
    </el-dialog>
  </el-form-item>
</template>

<script>
import { mapActions } from 'vuex'
import FileUpload from './formComponents/Upload'
import Statement from './formComponents/Statement'
import * as computeFunction from '../util/computeFunction'
import { getShowData } from '../util/index'

export default {
  name: 'GenerateShowItem', // 查看表单详情时的某个组件
  components: {
    FileUpload,
    Statement
  },
  props: {
    widget: {
      type: Object,
      default: () => {},
      required: true
    },
    models: {
      type: Object,
      default: () => {},
      rquired: true
    },
    operations: {
      type: Object,
      default: () => {}
    },
    rules: {
      type: Object,
      default: () => {}
    },
    remote: {
      type: Object,
      default: () => {}
    },
    subFormKey: {
      type: Number,
      default: undefined
    },
    flowId: {
      type: String,
      default: ''
    },
    formExtends: {
      type: Object,
      default: () => {}
    },
    linearFormList: { // 表单的组件的一维数组
      type: Array,
      default: () => []
    },
    formMounted: { // 是否已经挂载的页面
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      editable: false,
      dataModel: (this.subFormKey !== undefined) ? this.models[this.widget.model]['item' + this.subFormKey] : this.models[this.widget.model],
      uploadType: [
        'pdf',
        'png',
        'jpg',
        'gif',
        'html',
        'json',
        'jpe',
        'jpeg'
      ],
      operateVisible: false,
      customShowLoading: this.widget.type === 'customshow' && (this.widget.options.contentType === 2 || (this.widget.options.contentType !== 2 && this.widget.options.valueConfig.dataSourceKey && this.widget.options.valueConfig.type === 4)),
      gotCustomShowData: false
    }
  },
  computed: {
    dataModelForMutSelect(){
      let res =this.models && this.models[this.widget.model];
      if (res.length === 0) {
        return this.$t('None');
      }
      return res.join(",")
    },
    dataKey () {
      return this.widget.model
    }
  },
  watch: {
    models: {
      deep: true,
      handler () {
        this.initDataModel()
      }
    },
    formMounted () {
      this.$nextTick(() => {
        if (this.gotCustomShowData) {
          this.executeScript()
        }
      })
    }
  },
  async created () {
    this.initDataModel()
  },
  methods: {
    ...mapActions(['getStoreDataSource']),
    // 点击审批时自定义操作
    customOperate () {
      this.operateVisible = true
      window.closeOperate = this.closeOperate
    },
    // 关闭自定义操作弹窗
    closeOperate () {
      this.operateVisible = false
    },
    async initDataModel () {
      // 初始化展示值
      // 空内容“--”展示
      if ((this.models[this.dataKey] === null || this.models[this.dataKey] === "" || this.models[this.dataKey] === undefined) && (this.widget.type !== "instruction") && (this.widget.type !== "table")) {
        this.dataModel = "--"
      } else if (this.widget.type === "table" && this.models[this.dataKey] === null)  {
        this.dataModel = [[
          {
            row: 1,
            col: 1,
            value: '--'
          },
          {
            row: 1,
            col: 1,
            value: '--'
          },
          {
            row: 1,
            col: 1,
            value: '--'
          }
        ]]
      } else if (this.widget.type === 'customshow' && this.widget.options.contentType !== 2) {
        if (this.widget.options.valueConfig && this.widget.options.valueConfig.type && this.widget.options.valueConfig.type === 4) {
          // 开始请求
          const dataSource = await this.getDataSource(this.widget.options.valueConfig.dataSourceKey, this.widget.options.valueConfig.params)
          this.customShowLoading =false
          if (this.widget.options.valueConfig.dataSourceProperty) {
            // 有key时
            this.dataModel = dataSource ? dataSource[this.widget.options.valueConfig.dataSourceProperty] : dataSource
          } else {
            // 无key时
            this.dataModel = dataSource
          }
          if (this.formMounted) {
            this.executeScript()
          } else {
            this.gotCustomShowData = true
          }
        } else {
          this.dataModel = this.widget.options.defaultValue
          if (this.formMounted) {
            this.executeScript()
          } else {
            this.gotCustomShowData = true
          }
        }
      } else {
        // 未加“--”展示
        if(this.models[this.dataKey]) {
          if (this.subFormKey !== undefined) {
            // 子表单时
            this.dataModel = this.getDataModel(this.models[this.dataKey]['item' + this.subFormKey])
          } else {
            // 非子表单时
            this.dataModel = this.getDataModel(this.models[this.dataKey])
          }
        } else {
          this.dataModel = this.getDataModel(this.models[this.dataKey])
        }
      }

      // 处理“济南觅飏商业保理有限公司”展示问题
      if (typeof this.dataModel === 'string') {
        this.dataModel = this.dataModel.replace(/济南觅飏商业保理有限公司/g, 'MY')
      }

      // 展示格式化
      if (this.widget.options.sumformat && computeFunction[this.widget.options.sumformat]) {
        this.dataModel = computeFunction[this.widget.options.sumformat](this.dataModel)
      }
    },
    executeScript () {
      const div = document.createElement('div')
      div.innerHTML = this.dataModel
      div.querySelectorAll('script').forEach(s => {
        const scriptEl = document.createElement('script')
        if (s.src) {
          scriptEl.src = s.src
        }
        scriptEl.innerHTML = s.innerHTML
        document.body.appendChild(scriptEl)

        // eval(s.innerHTML)
      })
    },
    // 组件可选项、或值：有远程数据源时，获取远程数据
    async getDataSource (key, params) {
      const res = await this.getStoreDataSource({
        key,
        models: this.models,
        params,
        inSubform: this.widget.inSubform,
        linearFormList: this.linearFormList,
        subFormKey: this.subFormKey
      })
      return res
    },
    // get data Model:获取展示的数据格式
    getDataModel (value) {
      return getShowData(this.widget, value, this.subFormKey, this.formExtends, this.dataModel)
    }
  }
}
</script>

<style lang="stylus" scoped>
.el-form-item
  margin-bottom 0.8rem !important
  >>>.el-form-item__label
    // padding-bottom 0.5rem
    width auto !important
    line-height inherit
    font-size 14px
    color rgba(14,31,53,0.45) !important
  >>>.el-form-item__content
    margin-left 0px !important
.el-form-item-instruction
  margin-top 5px !important
  >>>.el-form-item__label
    font-size 16px
    color rgba(14,31,53,0.85) !important
    border-left 3px solid #3657FF
    padding: 0;
    margin-left: -4px;
    height: 14px;
    line-height: 16px;
    width 100%!important
    text-align left!important
  >>>.el-form-item__content
    display none
.el-form-item
  >>>.el-form-item__label
    ::before
      content "*"
      color #fff
      margin-right 4px
.el-form-item
  >>>.el-form-item__content
    line-height inherit
    color rgba(14,31,53,0.85)
    display flex
    padding-left 12px
    word-break break-all
.el-form-item
  >>>.el-form-item__label
    margin 0
  .el-button--text
    padding 0
    line-height inherit
.no-before
  >>>.el-form-item__content
    >div
      &:before
        display none
      >span
        &:before
          display none
      >div
        &:before
          display none
        >div
          &:before
            display none
.instruction-line
  margin 0 0 1rem 0
.download-btn
  margin-left 0
  display block
  text-align left
  margin-bottom 1rem
  padding 0
.download-link
  text-decoration none
  color #3cb9e2
  word-break break-all
  white-space normal
  text-align left
.operate-modal.el-dialog__wrapper
  >>>.el-dialog
    height 70%
    .el-dialog__body
      height calc(100% - 54px)
      iframe
        width 100%
        height 100%
        border 0
        margin 0
        padding 0
.custom-show-iframe
  padding 0
  margin 0
  border 0
  width 100%
</style>

<style lang="stylus">
@media screen and (max-width: 768px) {
  .detail-tips {
    width 90%
  }
}
</style>
