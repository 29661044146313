import Vue from 'vue'
import Vuex from 'vuex'
import Cookies from 'js-cookie'
import { isInSubform } from '../util/index'
import { msgCode } from '../components/EncryptionAndDecryption/qrcodebox'
import i18n from '../i18n'

/**
 * getDefaultLanguage
 *
 * 1. 第一优先级获取 Cookie 中的 lang
 * 2. 第二优先级 PHP 注入到上下文中的全局变量 defaultLocale
 * 3. 第三优先级 浏览器自带的 [language](https://developer.mozilla.org/en-US/docs/Web/API/Navigator/language) 属性
 * 4. 最后回退到 'en-US' 语言
 *
 * @returns {string}
 */
const getDefaultLanguage = () => {
  let language = Cookies.get('lang') || ''

  if (String(language).length) {
    language = '' + language
  } else {
    language = window.defaultLocale || navigator.language || 'en-US'
  }

  /**
   * 兼容 PHP 配置的 language == 'zh-cn'
   * config/app.php:113
   * // 后端统一用小写，但前端因三方库原因需要地区码大写(zh-CN)，故传给前端（cookie）时可调用CommonHelper::convertLocaleCode()作处理。
   * ```php
    'localeList' => [
        'en'    => "English",
        'th'    => "ภาษาไทย",
        'es'    => "Español",
        'zh-cn' => "简体中文",
    ]
    ```
   */
  if (language === 'zh-cn') {
    language = "zh-CN"
  }

  if (process.env.NODE_ENV === 'development') {
    console.log(`Current using language is ${language}`);
  }

  return language
}

Vue.use(Vuex)

const store = new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  devtools: process.env.NODE_ENV !== 'production',
  state: {
    dataSources: {
      ['loginInfo_[]']: window.loginInfo
    },
    remoteFunctions: {},
    loading: 0,
    languages: {},
    language: getDefaultLanguage(),
    flowDefine: undefined,
    loadingData: false,
    flowDetail: undefined,
    loadingDetail: false,
    loadingOldData: false,
    oldData: null
  },
  mutations: {
    SET_DATA_SOURCE (state, { key, dataSource }) {
      state.dataSources[key] = dataSource
    },
    DELETE_DATA_SOURCE (state, { key }) {
      delete state.dataSources[key]
    },
    SET_REMOTE_FUNCTION (state, { key, remoteFunction }) {
      state.remoteFunctions[key] = remoteFunction
    },
    DELETE_REMOTE_FUNCTION (state, { key }) {
      delete state.remoteFunctions[key]
    },
    LOADINGADD (state) {
      state.loading++
    },
    LOADINGSUBTRACT (state) {
      state.loading--
    },
    SET_LANGUAGES (state, { languages }) {
      state.languages = languages
    },
    SET_FLOW_DEFINE (state, { flowDefine }) {
      state.flowDefine = flowDefine
    },
    SET_LOADING_DATA (state, { loadingData }) {
      state.loadingData = loadingData
    },
    SET_FLOW_DETAIL (state, { flowDetail }) {
      state.flowDetail = flowDetail
    },
    SET_LOADING_DETAIL (state, { loadingDetail }) {
      state.loadingDetail = loadingDetail
    },
    SET_OLD_DATA (state, { oldData }) {
      state.oldData = oldData
    },
    SET_LOADING_OLD_DATA (state, { loadingOldData }) {
      state.loadingOldData = loadingOldData
    }
  },
  actions: {
    // 根据流程详情id获取流程信息
    async getFlowDetailById ({ commit }, flowId) {
      try {
        commit('SET_LOADING_DETAIL', { loadingDetail: true })
        const res = await Vue.http.get(
          '/workflow/entries/' + flowId
        )
        commit('SET_LOADING_DETAIL', { loadingDetail: false })
        if (res.data.code === 0) {
          commit('SET_FLOW_DETAIL', { flowDetail: res.data.data })
        } else {
          Vue.prototype.$message.error({ message: res.data.message, duration: 10000, showClose: true })
        }
      } catch (e) {
        Vue.prototype.$message.error({ message: i18n.t('message.common.requestTips.getDataFailed'), duration: 10000, showClose: true })
        commit('SET_LOADING_DETAIL', { loadingDetail: false })
      }
    },
    // 根据流程id获取流程设计信息
    async getFlowDefineById ({ commit }, flowId) {
      try {
        commit('SET_LOADING_DATA', { loadingData: true })
        let res = {}
        if (window.approvalFlow !== undefined) {
          console.log("flowId",flowId)
           res.data = {}
           res.data.data = window.approvalFlow
           res.data.code = 0
        }else{
           res = await Vue.http.get(
              '/workflow/flows/viewFlow/' + flowId
          )
        }
        commit('SET_LOADING_DATA', { loadingData: false })
        if (res.data.code === 0) {
          commit('SET_FLOW_DEFINE', { flowDefine: res.data.data })
        } else {
          Vue.prototype.$message.error({ message: res.data.message, duration: 10000, showClose: true })
        }
      } catch (e) {
        Vue.prototype.$message.error({ message: i18n.t('message.common.requestTips.getDataFailed'), duration: 10000, showClose: true })
        commit('SET_LOADING_DATA', { loadingData: false })
      }
    },
    // 重新发起时，请求申请的就数据
    async getOldData({ commit }, entryId) {
      try {
        commit('SET_LOADING_OLD_DATA', { loadingOldData: true })
        const res = await Vue.http.get(
          '/workflow/entries/' + entryId
        )
        commit('SET_LOADING_OLD_DATA', { loadingOldData: false })
        if (res.data.code === 0) {
          commit('SET_OLD_DATA', { oldData: res })
        } else {
          Vue.prototype.$message.error({ message: res.data.message, duration: 10000, showClose: true })
        }
      } catch (e) {
        Vue.prototype.$message.error({ message: i18n.t('message.common.requestTips.getDataFailed'), duration: 10000, showClose: true })
        commit('SET_LOADING_OLD_DATA', { loadingOldData: false })
      }
    },
    // 批量请求无参数据源
    async getBatchDataSources ({ commit, state }, keys) {
      let loginInfoIndex = keys.indexOf('loginInfo')
      if (state.dataSources['loginInfo_[]'] && loginInfoIndex > -1) {
        keys.splice(loginInfoIndex, 1)
      }
      if (!keys || keys.length < 1) return
      // 请求
      const promise = Vue.http.post(
        '/workflow/data-source-batch',
        keys
      )
      // 设置每个key
      keys.forEach(key => {
        commit('SET_DATA_SOURCE', {
          key,
          dataSource: promise
        })
      })

      // 等待结果
      const res = await promise
      if (res.data.code === 0) {
        Object.keys(res.data.data).forEach(k => {
          commit('SET_DATA_SOURCE', {
            key: k + '_[]',
            dataSource: res.data.data[k]
          })
        })
      } else {
        // 错误时：清空数据
        keys.forEach(key => {
          commit('SET_DATA_SOURCE', {
            key,
            dataSource: undefined
          })
          commit('SET_DATA_SOURCE', {
            key: key + '_[]',
            dataSource: undefined
          })
        })
      }
    },
    // 请求数据源队列
    async getStoreDataSource({ commit, dispatch }, { key, models, params, inSubform, linearFormList, subFormKey, filterKey }) {
      const promise = dispatch('requestDataSource', { key, models, params, inSubform, linearFormList, subFormKey, filterKey })
      commit('SET_DATA_SOURCE', {
        key,
        dataSource: promise
      })
      // const res = await promise
      // return res
      return promise
    },
    // 请求数据源
    async requestDataSource ({ state, commit, dispatch }, { key, models, params, inSubform, linearFormList, subFormKey, filterKey, inner }) {
      // inner：是否内部调起
      // 同一个数据源有未完成的：需要等待完成才能进行下一个请求
      if (state.dataSources[key] && !inner) {
        await state.dataSources[key]
      }
      // 请求的参数
      const dataParams = (params && params.length > 0) ? params.map(item => {
        let value
        if (inSubform && isInSubform(item.value[0], linearFormList)) {
          value = [models[item.value[0]]['item' + subFormKey]]
        } else {
          value = [models[item.value[0]]]
        }
        return {
          intro: item.intro,
          value: value
        }
      }) : []
      // 当select组件远程搜索时，加上搜索参数
      if (filterKey) {
        dataParams.push({
          intro: 'selectRemoteFilterKey',
          value:[filterKey]
        })
      }
      // 请求参数的值数组
      const dataKeyArray = dataParams.map(item => {
        return item.value
      })
      // 数据存储key
      let dataKey = key + '_' + JSON.stringify(dataKeyArray)
      // if (state.dataSources[dataKey] !== undefined) {
      if (Object.keys(state.dataSources).includes(dataKey) && !inner) {
        // 已有数据源不再请求，直接用已有的
        if (state.dataSources[dataKey] instanceof Promise) {
          try {
            const res = await state.dataSources[dataKey]
            return res
          } catch (e) {
            return undefined
          }
        } else {
          return state.dataSources[dataKey]
        }
      } else {
        try {
          commit('LOADINGADD')
          const newPromise = new Promise(async resolve => {
            const res = await Vue.http.post(
              '/workflow/data-source',
              {
                key,
                params: dataParams
              }
            )
            if (res.data.code === 0) {
              resolve(res)
            } else if (res.data.code === 5000 || res.data.code === 5001) {
              // res.data.code === 6000：即解密失败，可让用户选择是否重试
              const codeRes = await msgCode(res.data.code === 5001, {
                routeName: res.data.route_name,
                scopeAlias: res.data.scope_alias
              })
              if (codeRes.type === 'success') {
                const newRes = dispatch('requestDataSource', { key, models, params, inSubform, linearFormList, subFormKey, filterKey, inner: true })
                newRes.then(r => {
                  resolve(r)
                })
              } else {
                Vue.prototype.$message.error({ message: codeRes.message + i18n.t('message.common.code.reloadTry'), duration: 10000, showClose: true })
                resolve(undefined)
              }
            } else {
              resolve(undefined)
            }
          })

          if (!inner) {
            commit('SET_DATA_SOURCE', {
              key: dataKey,
              dataSource: newPromise
            })
          }
          const outRes = await newPromise
          commit('LOADINGSUBTRACT')
          if (outRes && outRes.data && outRes.data.code === 0) {
            if (!inner) {
              commit('SET_DATA_SOURCE', {
                key: dataKey,
                dataSource: outRes.data.data
              })
            }
            return outRes.data.data
          } else {
            if (!inner) {
              commit('SET_DATA_SOURCE', {
                key: dataKey,
                dataSource: outRes
              })
            }
            return outRes
          }
        } catch (e) {
          commit('LOADINGSUBTRACT')
          if (!inner) {
            commit('DELETE_DATA_SOURCE', {
              key: dataKey
            })
          }
          return undefined
        }
      }
    },

    // 远程函数队列
    async getRemoteFunction({ commit, dispatch }, { key, params }) {
      const promise = dispatch('requestRemoteFunction', { key, params })
      commit('SET_REMOTE_FUNCTION', {
        key,
        remoteFunction: promise
      })
      // const res = await promise
      // return res
      return promise
    },
    // 远程函数请求
    async requestRemoteFunction ({ state, commit }, { key, params }) {
      // 同一个远程函数有未完成的：需要等待完成才能进行下一个请求
      if (state.remoteFunctions[key]) {
        await state.remoteFunctions[key]
      }
      // 数据存储key
      let functionKey = key + '_' + JSON.stringify(params)
      if (Object.keys(state.remoteFunctions).includes(functionKey)) {
        // 无参数的已有远程函数不再请求，直接用已有的
        if (state.remoteFunctions[functionKey] instanceof Promise) {
          try {
            const res = await state.remoteFunctions[functionKey]
            return res.data
          } catch (e) {
            return e
          }
        } else {
          return state.remoteFunctions[functionKey]
        }
      } else {
        try {
          commit('LOADINGADD')
          const promise = Vue.http.post(
            '/workflow/contract-config',
            {
              key,
              params
            }
          )
          commit('SET_REMOTE_FUNCTION', {
            key: functionKey,
            remoteFunction: promise
          })
          const res = await promise
          commit('LOADINGSUBTRACT')
          commit('SET_REMOTE_FUNCTION', {
            key: functionKey,
            remoteFunction: res.data
          })
          return res.data
        } catch (e) {
          commit('LOADINGSUBTRACT')
          commit('DELETE_REMOTE_FUNCTION', {
            key: functionKey
          })
          return e
        }
      }
    },
    // 远程函数请求
    async getLanguages ({ commit }) {
      try {
        let res = {}
        if (window.approvalLocale !== undefined) {
          res.data = {}
          res.data.data = window.approvalLocale
          res.data.code = 0
        }else{
          res = await Vue.http.get('/locale')
        }
        if (res.data && res.data.code === 0) {
          commit('SET_LANGUAGES', { languages: res.data.data})
        }
      } catch (e) {
        console.error(e)
      }
    }
  }
})

export default store
