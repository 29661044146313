<template>
  <div
    ref="box"
    class="table-box"
  />
</template>

<script>
import xlsx from 'xlsx'

// 根据Excel的URL请求文件并展示
export default {
  name: 'ExcelShow', // 表格（Excel）文件展示组件
  props: {
    url: {
      type: String,
      default: '/aa.xlsx'
    }
  },
  watch: {
    url: {
      handler () {
        this.getExcel(this.url)
      }
    }
  },
  created () {
    this.getExcel(this.url)
  },
  methods: {
    async getExcel (url) {
      if (url) {
        const response = await this.$http({
          url,
          method: 'get',
          responseType: 'arraybuffer'
        })
        if (response.status !== 200) {
          return
        } else if (response.data.code === 1000014008) {
          this.$message.error({ message: response.data.message, duration: 10000, showClose: true })
          return
        }
        const res = xlsx.read(response.data, {
          type: 'buffer'
        })
        let sheetName = res.Sheets[res.SheetNames[0]]
        const webStr = xlsx.utils.sheet_to_html(sheetName)
        const startIndex = webStr.indexOf('<table>')
        this.$refs.box.innerHTML = webStr.substr(startIndex, webStr.indexOf('</body>') - startIndex)
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.table-box
  width 100%
  overflow auto
  >>>table
    min-width 100%
    border-collapse collapse
    td
      border 1px solid #dcdfe6
      padding .5rem
      line-height 1.3rem
      min-height 1.8rem
      min-width 10rem
      word-break break-all
</style>
